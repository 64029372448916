import {
  RidesTimeseries,
  RidesTimeseriesCombined,
  RidesTimeseriesCompanies,
  UtilizationTimeseriesLineChartCombinedCard
} from "components/Card";
import { DashboardHeader } from "components/Header";
import { RoutePathEnum } from "controllers/router";
import {
  endOfWeek,
  format,
  getISOWeek,
  getYear,
  startOfWeek,
  subWeeks,
} from "date-fns";
import { useDateRange, useOperatorMode, usePageTitle } from "hooks";
import { useMemo } from "react";
import { TextH5, TextMuted } from "../../components/Typography/Text";
import { cn } from "../../utils/classnames";

interface Week {
  idx: number;
  year: number;
  weekNumber: number;
  startDay: string;
  endDay: string;
  startRaw: Date;
  endRaw: Date;
}

const LIST_OF_WEEKS = 52;

function getLast50ISOWeeks(): Week[] {
  const weeks = [];

  for (let i = 1; i < LIST_OF_WEEKS; i++) {
    // Subtracting weeks from the current date to get a date within each previous week
    const weekDate = subWeeks(new Date(), i);

    // Calculating the start and end of each week
    const startDay = startOfWeek(weekDate, { weekStartsOn: 1 }); // ISO week starts on Monday
    const endDay = endOfWeek(weekDate, { weekStartsOn: 1 }); // ISO week ends on Sunday

    // Getting ISO week number and year
    const weekNumber = getISOWeek(weekDate);
    const year = getYear(weekDate);

    // Formatting the dates for readability
    const formatString = "dd MMM";
    weeks.push({
      idx: i,
      year: year,
      weekNumber: weekNumber,
      startDay: format(startDay, formatString),
      endDay: format(endDay, formatString),
      startRaw: startDay,
      endRaw: endDay,
    });
  }

  return weeks;
}

const Year = ({ week }: { week: Week }) => {
  const showYear = week.idx === 1 || week.weekNumber === LIST_OF_WEEKS;
  if (showYear) {
    return (
      <div className="border-b sticky top-0 bg-slate-100 px-2">
        <TextMuted>{week.year}</TextMuted>
      </div>
    );
  }
  return <></>;
};

const Item = ({ week }: { week: Week }) => {
  const { setSelectedDateRange, selectedDateRange } = useDateRange();

  function handleClick(week: Week) {
    setSelectedDateRange({
      from: week.startRaw,
      to: week.endRaw,
    });
  }

  const isSelected = selectedDateRange.from?.getDate() === week.startRaw.getDate();

  return (
    <>
      <Year week={week} />
      <div
        onClick={() => handleClick(week)}
        className={cn(
          "hover:bg-slate-200 cursor-pointer p-2 rounded-md",
          isSelected && "bg-slate-200"
        )}
      >
        <TextH5>Week {week.weekNumber}</TextH5>
        <TextMuted>
          {week.startDay} - {week.endDay}
        </TextMuted>
      </div>
    </>
  );
};

const Sidebar = () => {
  const weekList = useMemo(() => getLast50ISOWeeks(), []);
  return (
    <div className="flex flex-col w-full space-y-4 mr-4 h-[400px] overflow-y-scroll">
      {weekList.map((week) => (
        <Item key={week.idx} week={week} />
      ))}
    </div>
  );
};

export const ReportPage = () => {
  const { operatorMode } = useOperatorMode();
  const title = usePageTitle(RoutePathEnum.Report);

  return (
    <>
      <DashboardHeader title={title} />

      <div className="flex relative">
        <div className="w-1/5 mr-8">
          <div className="sticky top-[160px]">
            <Sidebar />
          </div>
        </div>
        <div className="w-4/5">
          <div className="flex flex-col space-y-12">
            <RidesTimeseries />

            {operatorMode && <RidesTimeseriesCombined />}

            {operatorMode && <UtilizationTimeseriesLineChartCombinedCard />}


            <RidesTimeseriesCompanies />
          </div>
        </div>
      </div>
    </>
  );
};
