import { useDeviceGroup, useDeviceType, useTranslate } from "../../hooks";
import { BaseButton } from "../Button";
import {
  CitySelect,
  DayOfWeekSelect,
  DeviceTypeSelectBasedOnGroup,
  DistrictSelect,
  HourOfDaySelect,
  RideLengthSelect,
} from "../Select";
import { CompanySelect } from "../Select/Filter/CompanySelect";
import { CountrySelect } from "../Select/Filter/CountrySelect";
import { EngineTypeSelect } from "../Select/Filter/EngineTypeSelect";
import { Button } from "../shadcn/components/ui/button";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../shadcn/components/ui/sheet";

const FilterOptions = () => {
  const { isCarsharingActive } = useDeviceType();
  return (
    <div className="flex flex-col space-y-4">
      <>
        {/* <BaseCheckbox
          disabled={!operator_id}
          checked={operatorMode || false}
          value="operator_mode"
          onChange={handleOperatorMode}
          label={translate("operator_mode_filter_label")}
        /> */}
        <CountrySelect />
        <CitySelect />
        <DistrictSelect />
        {/* <DeviceTypeGroupTabs /> */}
        <DeviceTypeSelectBasedOnGroup />
        {isCarsharingActive && <EngineTypeSelect />}
        <CompanySelect />
        <RideLengthSelect />
        <DayOfWeekSelect />
        <HourOfDaySelect />
      </>
    </div>
  );
};

export const FilterSheet = () => {
  const translate = useTranslate();

  return (
    <Sheet>
      <SheetTrigger asChild>
        <BaseButton>{translate("filter_button_text")}</BaseButton>
      </SheetTrigger>
      <SheetContent className="overflow-x-hidden overflow-y-auto p-0">
        <SheetHeader className="mx-6 my-4">
          <SheetTitle>{translate("Filter")}</SheetTitle>
        </SheetHeader>
        <div className="grid gap-4 py-4 p-6">
          <FilterOptions />
        </div>
        {/* <SheetFooter className="sticky bottom-0 p-6 bg z-50 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/80 border-t">
          <SheetClose asChild>
            <Button type="submit">
              {translate("filter_apply_button_text")}
            </Button>
          </SheetClose>
        </SheetFooter> */}
      </SheetContent>
    </Sheet>
  );
};
