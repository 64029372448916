import { useQuery } from "@tanstack/react-query";
import { api, geo } from "@vuumly-common/common";
import axios from "axios";
import { getEndpoint } from "../../controllers/http/Endpoints";
import { useRouter } from "../index";

interface Props {
  geohash_cluster_size?: geo.GeohashSize;
  heatmap_min_ride_count?: number;
  isEnable?: boolean;
}

export function usePopularityGridQuery(props: Props) {
  const { geohash_cluster_size, heatmap_min_ride_count, isEnable } = props;

  const {} = useRouter();

  const params = new URLSearchParams(window.location.search);

  if (geohash_cluster_size) {
    params.set("geohash_cluster_size", String(geohash_cluster_size));
  }

  if (heatmap_min_ride_count !== undefined) {
    params.set("heatmap_min_ride_count", String(heatmap_min_ride_count));
  }

  return useQuery<api.ApiResponse<api.response.RidesGrid>>({
    staleTime: Infinity,
    enabled: !!params && isEnable,
    queryKey: [api.Endpoint.RidesGrid, params.toString()],
    queryFn: () =>
      axios(getEndpoint(api.Endpoint.RidesGrid) + `?${params.toString()}`, {
        headers: {
          authorization: localStorage.getItem("authorizationToken"),
        },
      }).then((res) => res.data),
  });
}
