import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  TimeScale,
  TimeSeriesScale,
  Filler,
} from "chart.js";
import "chartjs-adapter-date-fns";
import ChartDataLabels from "chartjs-plugin-datalabels";
// @ts-ignore
import chartTrendline from "chartjs-plugin-trendline";
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";

import { defaults } from "chart.js";

defaults.font.family = "Inter, sans-serif";

ChartJS.register(
  chartTrendline,
  Filler,
  ChartjsPluginStacked100,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  TimeScale,
  TimeSeriesScale
);
