import {
  Tabs,
  TabsList,
  TabsTrigger,
} from "components/shadcn/components/ui/tabs";
import { useEffect, useState } from "react";

export interface IBaseTab {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface IBaseTabs {
  items: IBaseTab[];
  onChange?: (value: string) => void;
  onClick?: (value: IBaseTab) => void;
  defaultValue: string;
}

export function BaseTabs(props: IBaseTabs) {
  const { items = [], onChange, defaultValue, onClick } = props;
  const [selected, setSelected] = useState(defaultValue);

  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <Tabs
        onValueChange={(val) => {
          onChange && onChange(val);
          setSelected(val);
        }}
        value={selected}
      >
        <TabsList>
          {items.map((item) => (
            <div onClick={() => onClick && onClick(item)} key={item.value}>
              <TabsTrigger disabled={item.disabled} value={item.value}>
                {item.label}
              </TabsTrigger>
            </div>
          ))}
        </TabsList>
      </Tabs>
    </>
  );
}
