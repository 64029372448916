import { DeviceEnum } from "@vuumly-common/common";
import { Color, Colors } from "../../../utils/colors";

const colorMapping: Record<DeviceEnum | string, Color> = {
  [DeviceEnum.Car]: Colors.Car,
  [DeviceEnum.Cargo]: Colors.Cargo,
  [DeviceEnum.Bike]: Colors.Bike,
  [DeviceEnum.EBike]: Colors.EBike,
  [DeviceEnum.Moped]: Colors.Moped,
  [DeviceEnum.Scooter]: Colors.Scooter,

  car_rides: Colors.Primary,
  diff: Colors.Orange,

  _1: Colors.Orange,
  _2: Colors.Red,
  _3: Colors.Orange,
  _4: Colors.Green,
  _9: Colors.Black,
  _10: Colors.Green,
  _11: Colors.Purple,
  _23: Colors.Blue,
};

interface Props {
  deviceType: DeviceEnum;
}

export const getColor = ({ deviceType }: Props): string => {
  try {
    return colorMapping[deviceType].hex;
  } catch(err) {
    // console.log("Couldn't identify color", deviceType)
  }
  return Colors.Primary.hex
};
