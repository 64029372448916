import { api, db } from "@vuumly-common/common";
import { BaseCard, ExploreMoreCardFooter } from "../index";
import { useQuery } from "hooks";
import { TextH5 } from "../../Typography/Text";
import { Dataset, useTranslate } from "../../../hooks";
import { RoutePathEnum } from "../../../controllers/router";
import { BaseVerticalBarChart } from "../../Charts";
import { useBarChartParser } from "../../../hooks/v1";
import _ from "lodash";

interface Props {
  showExploreMoreButton?: boolean;
  height?: number;
}

export const LengthGroupTotalCompare = (props: Props) => {
  const translate = useTranslate();

  const { data, isLoading } = useQuery({
    apiEndpoint: api.Endpoint.LengthGroupTotal,
  });

  const clonedData = _.cloneDeep(data);

  const { parsedData } = useBarChartParser({ data: clonedData });

  parsedData?.datasets.forEach((dataset) => {
    if (dataset.percentageRelativeValue) {
      dataset.data = dataset.percentageRelativeValue;
    }
  });

  function labelFormatter(ctx: any) {
    const dataset = ctx?.dataset as Dataset;
    const formattedValue = dataset.tooltipData?.[ctx.dataIndex];
    return `${formattedValue} (${dataset?.percentageData?.[ctx.dataIndex]})`;
  }

  return (
    <BaseCard
      isLoading={isLoading}
      header={
        <TextH5>{translate("LengthGroupTotalCompare_card_title")}</TextH5>
      }
    >
      <div className="relative">
        <BaseVerticalBarChart
          labelFormatter={labelFormatter}
          height={props.height}
          data={parsedData}
          stacked={false}
        />
      </div>
      <ExploreMoreCardFooter
        text="explore_ride_length_button"
        link={RoutePathEnum.RideLengthGroup}
        showExploreMoreButton={props.showExploreMoreButton}
      />
    </BaseCard>
  );
};
