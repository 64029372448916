import { api, time } from "@vuumly-common/common";
import { ChartData, ChartOptions, Plugin, TooltipItem } from "chart.js";
import Color from "color";
import { useQuery, useTheme, useTimeGranularity, useTranslate } from "hooks";
import _ from "lodash";
import { CardProps } from "..";
import { BaseLineChart } from "../../Charts";
import { TimeGranularityTabs } from "../../Tabs/TimeGranularityTabs";
import { TextH5 } from "../../Typography/Text";
import { BaseCard } from "../Base.card";

export const UtilizationTimeseriesLineChartPerformance = (props: CardProps) => {
  const { tabs, timeGranularity, changeGranularity, modifiedQueryParams } =
    useTimeGranularity();

  const translate = useTranslate();
  const theme = useTheme();

  const { data, isLoading } = useQuery({
    apiEndpoint: api.Endpoint.Utilization,
    params: modifiedQueryParams,
  });

  const clonedData = _.cloneDeep(data) as ChartData<"line">;

  if (clonedData?.datasets) {
    clonedData.datasets = clonedData?.datasets.filter(
      (dataset) => dataset.label === "operator_performance"
    );
  }

  clonedData?.datasets.forEach((dataset) => {
    const { label } = dataset;

    if (label === "operator_performance") {
      dataset.label = translate("Your utilization performance");
      dataset.parsing = {
        yAxisKey: "operator_performance",
      };
      dataset.backgroundColor = (item) => {
        if (item?.parsed?.y > 0) {
          return theme.positiveLineChartColor;
        }
        return theme.negativeLineChartColor;
      };

      dataset.borderWidth = 2;
      dataset.fill = {
        target: "origin",
        above: Color(theme.positiveLineChartColor).alpha(0.1).toString(),
        below: Color(theme.negativeLineChartColor).alpha(0.1).toString(),
      };
    }
  });

  const lePlugin: Plugin<"line"> = {
    id: "customLineColorPlugin",

    beforeDatasetsUpdate: (chart: any) => {
      const ctx = chart.ctx;
      const yAxis = chart.scales["y"];
      const yPos = yAxis.getPixelForValue(0);
      const gradientFill = ctx.createLinearGradient(0, 0, 0, chart.height);
      gradientFill.addColorStop(0, theme.positiveLineChartColor);
      gradientFill.addColorStop(
        yPos / chart.height,
        theme.positiveLineChartColor
      );
      gradientFill.addColorStop(
        yPos / chart.height,
        theme.negativeLineChartColor
      );
      gradientFill.addColorStop(1, theme.negativeLineChartColor);
      const datasets = chart.data.datasets;

      datasets.forEach((dataset: any) => {
        dataset.borderColor = gradientFill;
      });
    },
  };

  const options: ChartOptions<"line"> = {
    parsing: {
      xAxisKey: "local_time",
    },

    scales: {
      x: {
        type: "timeseries",
      },
      y: {
        grace: 0,
        min: -100,
        max: 100,
        title: {
          text: "Your utilization performance rate",
        },
        ticks: {
          callback: (value) => {
            return `${value}%`;
          },
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (item: TooltipItem<"line">) => {
            if (item.parsed.y > 0) {
              return `${item.dataset.label}: +${item.formattedValue}%`;
            }

            return `${item.dataset.label}: ${item.formattedValue}%`;
          },
        },
      },
    },
  };

  const Header = () => {
    return (
      <div className="flex justify-between w-full">
        <TextH5>
          {translate("Utilization performance over time (you vs. others)")}
        </TextH5>
        <TimeGranularityTabs
          tabs={tabs}
          defaultValue={timeGranularity || time.GranularityEnum.Day}
          onChange={changeGranularity}
        />
      </div>
    );
  };

  return (
    <BaseCard isLoading={isLoading} header={<Header />}>
      <BaseLineChart options={options} plugins={[lePlugin]} data={clonedData} />
    </BaseCard>
  );
};
