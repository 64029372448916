import { time } from "@vuumly-common/common";
import { useDayOfWeek, useTranslate } from "hooks";
import { useMemo } from "react";
import { BaseFilterOption, BaseFilterSelect } from "../BaseFilterSelect";
import { BaseOption } from "../BaseSelector";

export function DayOfWeekSelect() {
  const translate = useTranslate();

  const { selectedDaysOfWeek, setSelectedDaysOfWeek } = useDayOfWeek();

  const list = useMemo(
    () =>
      dayOfWeekDefault.map((item) => {
        return { ...item, selected: selectedDaysOfWeek.includes(item.value) };
      }),
    []
  );

  const onChange = (payload: BaseFilterOption<time.DayOfWeekEnum>[]) => {
    const selected = payload
      .filter((item) => item.selected)
      .map((item) => item.value);
    setSelectedDaysOfWeek(selected);
  };

  return (
    <BaseFilterSelect<time.DayOfWeekEnum>
      onChange={onChange}
      options={list}
      title={translate("day_of_week_filter_title")}
    />
  );
}

const def = {
  selected: false,
  disabled: false,
  hasMatch: false,
  subscribed: false,
};

const dayOfWeekDefault: BaseOption<time.DayOfWeekEnum>[] = [
  {
    label: time.DayOfWeekEnum.Monday,
    value: time.DayOfWeekEnum.Monday,
    id: time.DayOfWeekEnum.Monday,
    ...def,
  },
  {
    label: time.DayOfWeekEnum.Tuesday,
    value: time.DayOfWeekEnum.Tuesday,
    id: time.DayOfWeekEnum.Tuesday,
    ...def,
  },
  {
    label: time.DayOfWeekEnum.Wednesday,
    value: time.DayOfWeekEnum.Wednesday,
    id: time.DayOfWeekEnum.Wednesday,
    ...def,
  },
  {
    label: time.DayOfWeekEnum.Thursday,
    value: time.DayOfWeekEnum.Thursday,
    id: time.DayOfWeekEnum.Thursday,
    ...def,
  },
  {
    label: time.DayOfWeekEnum.Friday,
    value: time.DayOfWeekEnum.Friday,
    id: time.DayOfWeekEnum.Friday,
    ...def,
  },
  {
    label: time.DayOfWeekEnum.Saturday,
    value: time.DayOfWeekEnum.Saturday,
    id: time.DayOfWeekEnum.Saturday,
    ...def,
  },
  {
    label: time.DayOfWeekEnum.Sunday,
    value: time.DayOfWeekEnum.Sunday,
    id: time.DayOfWeekEnum.Sunday,
    ...def,
  },
];
