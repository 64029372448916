import { api, time } from "@vuumly-common/common";
import { ChartData, ChartOptions, TooltipItem } from "chart.js";
import Color from "color";
import { BaseCard, CardProps } from "components/Card";
import { useQuery, useTheme, useTimeGranularity } from "hooks";
import _ from "lodash";
import { useMemo } from "react";
import { translate } from "../../../../utils";
import { BaseLineChart } from "../../../Charts";
import { TimeGranularityTabs } from "../../../Tabs/TimeGranularityTabs";
import { TextH5 } from "../../../Typography/Text";

export const RideLengthMinutesTimeseriesOperatorShare = (props: CardProps) => {
  const { tabs, timeGranularity, changeGranularity, modifiedQueryParams } =
    useTimeGranularity();
  const theme = useTheme();

  const { data, isLoading } = useQuery({
    apiEndpoint: api.Endpoint.RideLengthMinutesTimeseriesOperatorShare,
    params: modifiedQueryParams,
  });

  const clonedData = useMemo(() => {
    if (isLoading || !data?.datasets) {
      return;
    }

    const clonedData = _.cloneDeep(data) as ChartData<"line">;

    if (clonedData.datasets && clonedData.datasets[0]) {
      const dataset = clonedData.datasets[0];

      dataset.label = translate("Your ride length market share");

      dataset.trendlineLinear = {
        colorMax: Color(theme.accentColor).alpha(0.5).rgb().string(),
        colorMin: Color(theme.accentColor).alpha(0.5).rgb().string(),
        lineStyle: "dashed",
        width: 2,
        projection: false,
      };
    }

    return clonedData;
  }, [data]);

  const options: ChartOptions<"line"> = {
    parsing: {
      xAxisKey: "date",
      yAxisKey: "operator_share",
    },

    scales: {
      x: {
        type: "timeseries",
      },
      y: {
        title: {
          text: "Your ride length market share",
        },
        ticks: {
          callback: (value) => {
            return `${value}%`;
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (item: TooltipItem<"line">) => {
            return `Your ride length market share: ${item.formattedValue}%`;
          },
        },
      },
    },
  };

  const Header = () => {
    return (
      <div className="flex justify-between w-full">
        <TextH5>{translate("Your market share over time")}</TextH5>
        <TimeGranularityTabs
          tabs={tabs}
          defaultValue={timeGranularity || time.GranularityEnum.Day}
          onChange={changeGranularity}
        />
      </div>
    );
  };

  return (
    <BaseCard isLoading={isLoading} header={<Header />}>
      <div>
        <BaseLineChart options={options} data={clonedData} />
      </div>
    </BaseCard>
  );
};
