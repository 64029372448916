export function useConstants() {
  return {
    ORIGIN_ID_KEY: "origin_id",
    DESTINATION_ID_KEY: "destination_id",
    LOCAL_STORAGE_USER_AUTH_KEY: "authorizationToken",

    TIME_GRANULARITY_MAX_RANGE_FOR_DAY_OPTION: 90,
    TIME_GRANULARITY_MIN_RANGE_FOR_MONTH_OPTION: 30,

    TIME_GRANULARITY_MAX_RANGE_FOR_WEEK_OPTION: 400,
    TIME_GRANULARITY_MIN_RANGE_FOR_WEEK_OPTION: 7,
  };
}
