import { api } from "@vuumly-common/common";
import { ChartData, ChartOptions, TooltipItem } from "chart.js";
import Color from "color";
import { useQuery, useTheme, useTranslate } from "hooks";
import _ from "lodash";
import { useMemo } from "react";
import { CardProps } from "../..";
import { BaseBarChart } from "../../../Charts";
import { TextH5 } from "../../../Typography/Text";
import { BaseCard } from "../../Base.card";

export const RidePerformanceDayOfWeekBarChart = (props: CardProps) => {
  const translate = useTranslate();
  const theme = useTheme();

  const { data, isLoading } = useQuery<ChartData<"bar", Record<any, number>[]>>(
    {
      apiEndpoint: api.Endpoint.RidesPerDayOfWeek,
    }
  );

  const clonedData = useMemo(() => {
    if (isLoading || !data?.datasets) {
      return;
    }
    const clonedData = _.cloneDeep(data);

    clonedData.labels = clonedData.datasets
      .flatMap((obj) =>
        obj.data
          .filter((item: any) => "dimension" in item)
          .map((item: any) => item.dimension)
      )
      .map((item: string) => translate("day_of_week_" + item));

    clonedData.labels = _.uniq(clonedData.labels);

    clonedData?.datasets.forEach((dataset) => {
      const { label } = dataset;

      if (label === "operator_performance") {
        if (dataset.data.length) {
          dataset.backgroundColor = dataset.data.map((data) => {
            if (data.operator_performance > 0) {
              return theme.postiveBarChartColor;
            }
            return theme.negativeBarChartColor;
          });

          dataset.hoverBackgroundColor = dataset.data.map((data) => {
            if (data.operator_performance > 0) {
              return theme.hoverPositiveBarChartColor;
            }
            return theme.hoverNegativeBarChartColor;
          });
        }

        dataset.label = translate("Your performance");
        dataset.barPercentage = 0.999;
      } else {
        dataset.hidden = true;
        dataset.label = "none";
      }
    });
    return clonedData;
  }, [data]);

  const options: ChartOptions<"bar"> = {
    parsing: {
      yAxisKey: "operator_performance",
      xAxisKey: "dimension",
    },

    scales: {
      y: {
        grace: "50%",
        title: {
          display: true,
          text: "Your performance",
        },
        ticks: {
          callback: (value: any) => {
            if (value > 0) {
              return `+${value}%`;
            }
            return `${value}%`;
          },
        },
        display: true,
      },
      x: {
        grid: {
          display: true,
        },
        title: {
          display: true,
          text: "Day of week",
        },
      },
    },

    plugins: {
      legend: {
        display: false,
        labels: {
          filter(item) {
            return item.text !== "none";
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (item: TooltipItem<"bar">) => {
            return `${item.dataset.label}: ${item.formattedValue}%`;
          },
        },
      },
      datalabels: {
        display: true,
        formatter: (item: any, _ctx) => {
          if (item.operator_performance > 0) {
            return "+" + item.operator_performance.toFixed(1) + "%";
          }
          return item.operator_performance.toFixed(1) + "%";
        },
      },
      // datalabels: {
      //   display: true,
      //   formatter: (item: number, _ctx) => {
      //     if (item > 0) {
      //       return "+" + item.toFixed(1) + "%";
      //     }
      //     return item.toFixed(1) + "%";
      //   },
      //   anchor: (item) => {
      //     const data = item.dataset.data[item.dataIndex];
      //     if (data && typeof data === "number" && data > 0) {
      //       return "end";
      //     }
      //     return "start";
      //   },
      //   align: (item) => {
      //     const data = item.dataset.data[item.dataIndex];
      //     if (data && typeof data === "number" && data > 0) {
      //       return "end";
      //     }
      //     return "start";
      //   },
      // },
    },
  };

  return (
    <BaseCard
      isLoading={isLoading}
      header={
        <TextH5>
          {translate("Ride performance by day of week (you vs. others)")}
        </TextH5>
      }
    >
      <BaseBarChart options={options} data={clonedData} />
    </BaseCard>
  );
};
