import { api } from "@vuumly-common/common";
import { ChartData, ChartOptions, TooltipItem } from "chart.js";
import Color from "color";
import { useQuery, useTheme, useTimeGranularity, useTranslate } from "hooks";
import _ from "lodash";
import { RoutePathEnum } from "../../../controllers/router";
import { BaseBarChart, BaseHorizontalBarChart } from "../../Charts";
import { TextH5 } from "../../Typography/Text";
import { BaseCard } from "../Base.card";
import { useMemo } from "react";

interface Props {
  href?: RoutePathEnum;
  includeOriginId?: boolean;
  disableLink?: boolean;
  horizontal?: boolean;
  chartHeight?: number;
}

export const UtilizationDistributionOperatorTimeOfDayBarChart = (
  props: Props
) => {
  const theme = useTheme();
  const translate = useTranslate();

  const { disableLink = true } = props;

  const { data, isLoading } = useQuery<ChartData<"bar", Record<any, number>[]>>(
    {
      apiEndpoint: api.Endpoint.UtilizationTimeOfDay,
    }
  );

  const clonedData = useMemo(() => {
    if (isLoading || !data?.datasets) {
      return;
    }
    const clonedData = _.cloneDeep(data);

    if (clonedData) {
      clonedData.labels = [];
    }

    clonedData?.datasets.forEach((dataset) => {
      const { label } = dataset;

      if (label === "operator_utilization_rate") {
        dataset.backgroundColor = dataset.data.map(() => {
          return Color(theme.accentColor).string();
        });

        dataset.hoverBackgroundColor = dataset.data.map(() => {
          return Color(theme.accentColorHover).string();
        });

        dataset.label = translate("Your utilization rate");
        dataset.parsing = {
          yAxisKey: "operator_utilization_rate",
        };
      } else if (label === "without_operator_overall_utilization_rate") {
        dataset.backgroundColor = dataset.data.map(() => {
          return Color(theme.otherOperatorBarColor).string();
        });

        dataset.hoverBackgroundColor = dataset.data.map(() => {
          return Color(theme.hoverOtherOperatorBarColor).string();
        });

        dataset.label = translate("Utilization rate for others");
        dataset.parsing = {
          yAxisKey: "without_operator_overall_utilization_rate",
        };
      } else {
        dataset.hidden = true;
        dataset.label = "none";
      }
      dataset.barPercentage = 0.999;
    });

    return clonedData;
  }, [data]);

  const options: ChartOptions<"bar"> = {
    parsing: {
      xAxisKey: "time_of_day",
      yAxisKey: "utilization_rate",
    },
    scales: {
      x: {
        title: {
          text: "Time of day",
          display: true,
        },
        grid: {
          display: false,
        },
        ticks: {
          callback: (idx) => {
            return translate(clonedData?.labels?.[idx as number] as string);
          },
        },
      },
      y: {
        title: {
          text: "Utilization rate",
        },
        ticks: {
          display: false,
          callback: (value) => {
            return `${value}%`;
          },
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },

    plugins: {
      tooltip: {
        callbacks: {
          title: (ctx) => {
            return `${translate(ctx[0].label)} (${translate(
              ctx[0].label + "_range"
            )})`;
          },
          label: (item: TooltipItem<"bar">) => {
            return `${item.dataset.label}: ${item.formattedValue}%`;
          },
        },
      },
      datalabels: {
        display: true,
        formatter: (item: Record<string, any>) => {
          if (item.without_operator_overall_utilization_rate) {
            return (
              item.without_operator_overall_utilization_rate.toFixed(1) + "%"
            );
          } else if (item.operator_utilization_rate) {
            return item.operator_utilization_rate.toFixed(1) + "%";
          }
          return "No data";
        },
      },
    },
  };

  const Header = () => {
    return (
      <div className="flex justify-between w-full">
        <TextH5>
          {translate("Utilization by time of day (others vs. you)")}
        </TextH5>
      </div>
    );
  };

  return (
    <BaseCard isLoading={isLoading} header={<Header />}>
      <BaseBarChart options={options} data={clonedData} />
    </BaseCard>
  );
};
