import { AreasPage } from "pages/Areas/Areas.page";
import { SignInPage } from "pages/Auth/SignInPage";
import { SignUpPage } from "pages/Auth/SignUpPage";
import { Route, Routes } from "react-router-dom";
import { RegisterPlugins } from "./components/Charts/plugins";
import {
  DashboardLayout,
  DebugRoute,
  ProtectedRoute,
} from "./components/Layouts";
import { UserContextProvider } from "./context";
import { RoutePathEnum } from "./controllers/router";
import Development from "./pages/Development/Development";
import { ApplicationWrapper } from "./pages/Settings/ApplicationWrapper";
import Subscribe from "./pages/Subscribe/SubscribePage";

import { QueryClientProvider } from "@tanstack/react-query";
import {
  AccidentPage,
  CheckEmailPage,
  DemoPage,
  FleetPage,
  FlowMapPage,
  GeohashMap,
  HeatmapPage,
  LivePage,
  MapTools,
  NotSubscribedPage,
  PathMap,
  PdfPreviewPage,
  ReportPage,
  RideLengthPage,
  RidesPage,
  TrafficMap,
  TransitPage,
  UtilizationPage,
  VerifyEmailPage,
} from "pages";
import QueryClient from "./context/QueryClient";
import Typography from "./pages/Debug/Typography";
import PageNotFound from "./pages/PageNotFound";
import VectorMapPage from "./pages/VectorMapPage";

RegisterPlugins();

export default function App() {
  return (
    <>
      <QueryClientProvider client={QueryClient}>
        <UserContextProvider>
          <ApplicationWrapper>
            <Routes>
              <Route path="/">
                <Route path={RoutePathEnum.SignUp} element={<SignUpPage />} />

                <Route path={RoutePathEnum.SignIn} element={<SignInPage />} />

                <Route path={RoutePathEnum.Demo} element={<DemoPage />} />

                <Route
                  path={RoutePathEnum.CheckEmail}
                  element={<CheckEmailPage />}
                />
                <Route
                  path={RoutePathEnum.VerifyEmail}
                  element={<VerifyEmailPage />}
                />
                <Route
                  path={RoutePathEnum.NotSubscribed}
                  element={<NotSubscribedPage />}
                />

                <Route path={RoutePathEnum.Subscribe} element={<Subscribe />} />

                <Route element={<DashboardLayout className="flex flex-col" />}>
                  <Route
                    path={RoutePathEnum.Flowmap}
                    element={<FlowMapPage />}
                  />
                  <Route
                    path={RoutePathEnum.Heatmap}
                    element={<HeatmapPage />}
                  />
                  <Route path={RoutePathEnum.LiveMap} element={<LivePage />} />
                  <Route path={RoutePathEnum.Hexmap} element={<GeohashMap />} />
                  <Route path={RoutePathEnum.PathMap} element={<PathMap />} />
                  <Route
                    path={RoutePathEnum.TrafficMap}
                    element={<TrafficMap />}
                  />
                  <Route
                    path={RoutePathEnum.AccidentMap}
                    element={<AccidentPage />}
                  />
                  <Route
                    path={RoutePathEnum.TransitMap}
                    element={<TransitPage />}
                  />

                  <Route
                    path={RoutePathEnum.VectorMap}
                    element={<VectorMapPage />}
                  />
                </Route>

                <Route
                  element={
                    <DashboardLayout className="max-w-7xl mb-32 mx-auto w-full p-6" />
                  }
                >
                  <Route index element={<RidesPage />} />

                  <Route
                    path={RoutePathEnum.Data}
                    element={<PdfPreviewPage />}
                  />
                  <Route path={RoutePathEnum.Areas} element={<AreasPage />}>
                    <Route
                      path={RoutePathEnum.AreasOriginDestination}
                      element={<></>}
                    />
                    <Route path={RoutePathEnum.AreasMap} element={<></>} />
                  </Route>

                  <Route path={RoutePathEnum.Rides} element={<RidesPage />}>
                    <Route
                      path={RoutePathEnum.RidesDayOfWeek}
                      element={<></>}
                    />
                    <Route
                      path={RoutePathEnum.RidesTimeOfDay}
                      element={<></>}
                    />
                    <Route
                      path={RoutePathEnum.RidesHourOfDay}
                      element={<></>}
                    />
                    <Route path={RoutePathEnum.RidesDevices} element={<></>} />
                  </Route>

                  <Route
                    path={RoutePathEnum.Utilization}
                    element={<UtilizationPage />}
                  >
                    <Route
                      path={RoutePathEnum.UtilizationTimeOfDay}
                      element={<></>}
                    />
                    <Route
                      path={RoutePathEnum.UtilizationHourOfDay}
                      element={<></>}
                    />
                  </Route>

                  <Route
                    path={RoutePathEnum.RideLength}
                    element={<RideLengthPage />}
                  >
                    <Route
                      path={RoutePathEnum.RideLengthGroup}
                      element={<></>}
                    />
                    <Route
                      path={RoutePathEnum.RideLengtMinutes}
                      element={<></>}
                    />
                    <Route
                      path={RoutePathEnum.RideLengtHours}
                      element={<></>}
                    />
                  </Route>

                  <Route path={RoutePathEnum.Fleet} element={<FleetPage />}>
                    <Route path={RoutePathEnum.FleetStandBy} element={<></>} />
                  </Route>

                  <Route path={RoutePathEnum.MapTools} element={<MapTools />} />

                  <Route path={RoutePathEnum.Report} element={<ReportPage />} />
                </Route>
              </Route>

              <Route element={<ProtectedRoute />}>
                <Route path="dev" element={<Development />} />
              </Route>

              <Route element={<DebugRoute />}>
                <Route path="typography" element={<Typography />} />
              </Route>

              {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </ApplicationWrapper>
        </UserContextProvider>
      </QueryClientProvider>
    </>
  );
}
