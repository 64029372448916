import {
  ColumnDef,
  SortingState,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { api } from "@vuumly-common/common";
import { BaseCard } from "components/Card/index";
import { BaseTable, DataTableColumnHeader } from "components/Tables";
import { TextH5 } from "components/Typography/Text";
import {
  useQuery,
  useTranslate,
  useUserContext
} from "hooks";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

type Item = {
  dimension: string;
  ride_count: number;
};

const emptyArray: any[] = [];

export const RideLengthMinutesTotalCompaniesTable = () => {
  const translate = useTranslate();
  const { locale } = useUserContext();
  const [searchParams] = useSearchParams();

  const [sorting, setSorting] = useState<SortingState>([
    { id: "ride_length_minutes", desc: true },
  ]);

  const params = new URLSearchParams(searchParams);

  params.set("data_structure", "flat");

  const { data, isLoading } = useQuery<Item[]>({
    apiEndpoint: api.Endpoint.RideLenghtTotalCompanies,
    params,
  });

  const columns: ColumnDef<Item>[] = [
    {
      accessorKey: "dimension",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title="Company"
        />
      ),
      enableSorting: false,
    },
    {
      accessorKey: "ride_length_minutes",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title="Total Ride length in minutes"
        />
      ),
      cell: ({ row }) => {
        const amount = parseFloat(row.getValue("ride_length_minutes"));
        const formatted = new Intl.NumberFormat(locale, {
          notation: "compact",
        }).format(amount);

        return formatted;
      },
      enableSorting: true,
    },
    {
      accessorKey: "ride_percentage",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title="% of all ride length"
        />
      ),
      cell: ({ row }) => {
        const value = parseFloat(row.getValue("ride_percentage"));
        return value + "%";
      },
      enableSorting: false,
    },
  ];

  const table = useReactTable({
    data: data || emptyArray,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
    },
  });

  return (
    <BaseCard
      isLoading={isLoading}
      header={<TextH5>{translate("Total ride count by company")}</TextH5>}
    >
      <div className="space-y-4">
        <BaseTable table={table} columns={columns} />
      </div>
    </BaseCard>
  );
};
