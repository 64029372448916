import {
  ColumnDef,
  SortingState,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { api } from "@vuumly-common/common";
import { BaseCard } from "components/Card";
import { BaseTable, DataTableColumnHeader } from "components/Tables";
import { TextH5 } from "components/Typography/Text";
import {
  useOperatorMode,
  useParseRideLength,
  useQuery,
  useTranslate,
  useUserContext
} from "hooks";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

type Item = {
  dimension: string;
  total_ride_count: number;
  ride_count: number;
  ride_percentage: number;
  ride_count_without_operator?: number;
  ride_count_operator?: number;
  ride_percentage_without_operator?: number;
  ride_percentage_operator?: number;
  total_ride_count_without_operator?: number;
  total_ride_count_operator?: number;
};

const emptyArray: any[] = [];

export const RidesDayOfWeekSummaryTableCard = () => {
  const translate = useTranslate();
  const { operatorMode } = useOperatorMode();
  const { locale } = useUserContext();
  const [searchParams] = useSearchParams();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState({
    ride_count: !operatorMode,
    ride_percentage: !operatorMode,
    ride_count_without_operator: operatorMode,
    ride_count_operator: operatorMode,
    ride_percentage_without_operator: operatorMode,
    ride_share_operator: operatorMode,
    dimension: true,
  });

  useEffect(() => {
    setColumnVisibility((prev) => ({
      ...prev,
      ride_count: !operatorMode,
      ride_percentage: !operatorMode,
      ride_count_without_operator: operatorMode,
      ride_count_operator: operatorMode,
      ride_percentage_without_operator: operatorMode,
      ride_share_operator: operatorMode,
    }));
  }, [operatorMode]);

  const params = new URLSearchParams(searchParams);

  params.set("data_structure", "flat");

  const { data, isLoading } = useQuery<Item[]>({
    apiEndpoint: api.Endpoint.RidesPerDayOfWeek,
    params,
  });

  const columns: ColumnDef<Item>[] = [
    {
      accessorKey: "dimension",
      header: ({ column }) => (
        <DataTableColumnHeader
          className="w-[150px]"
          column={column}
          title="Day of week"
        />
      ),
      cell: ({ row }) => {
        return translate("day_of_week_" + row.getValue("dimension"));
      },
      enableSorting: true,
    },
    {
      accessorKey: "ride_count",
      header: ({ column }) => (
        <DataTableColumnHeader
          className="w-[150px]"
          column={column}
          title="Ride count"
        />
      ),
      cell: ({ row }) => {
        const amount = parseFloat(row.getValue("ride_count"));
        const formatted = new Intl.NumberFormat(locale, {
          notation: "compact",
        }).format(amount);

        return formatted;
      },
      enableSorting: true,
    },
    {
      accessorKey: "ride_percentage",
      header: ({ column }) => (
        <DataTableColumnHeader
          className="w-[150px]"
          column={column}
          title="% of all rides"
        />
      ),
      cell: ({ row }) => {
        const value = parseFloat(row.getValue("ride_percentage"));
        return value + "%";
      },
      enableSorting: true,
    },

    {
      accessorKey: "ride_count_without_operator",
      header: ({ column }) => (
        <DataTableColumnHeader
          className="w-[200px]"
          column={column}
          title="Ride count (others)"
        />
      ),
      cell: ({ row }) => {
        const amount = parseFloat(row.getValue("ride_count_without_operator"));
        const formatted = new Intl.NumberFormat(locale, {
          notation: "compact",
        }).format(amount);

        return formatted;
      },
      enableSorting: true,
    },
    {
      accessorKey: "ride_percentage_without_operator",
      header: ({ column }) => (
        <DataTableColumnHeader
          className="w-[200px]"
          column={column}
          title="% of rides (others)"
        />
      ),
      cell: ({ row }) => {
        const value = parseFloat(
          row.getValue("ride_percentage_without_operator")
        );
        return value + "%";
      },
      enableSorting: true,
      enableHiding: operatorMode,
    },

    {
      accessorKey: "ride_count_operator",
      header: ({ column }) => (
        <DataTableColumnHeader
          className="w-[200px]"
          column={column}
          title="Your ride count"
        />
      ),
      cell: ({ row }) => {
        const amount = parseFloat(row.getValue("ride_count_operator"));
        const formatted = new Intl.NumberFormat(locale, {
          notation: "compact",
        }).format(amount);

        return formatted;
      },
      enableSorting: true,
    },
    {
      accessorKey: "ride_share_operator",
      header: ({ column }) => (
        <DataTableColumnHeader
          className="w-[200px]"
          column={column}
          title="Your market share"
        />
      ),
      cell: ({ row }) => {
        const value = parseFloat(row.getValue("ride_share_operator"));
        return value + "%";
      },
      enableSorting: true,
      enableHiding: operatorMode,
    },
  ];

  const table = useReactTable({
    data: data || emptyArray,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      columnVisibility,
    },
  });

  return (
    <BaseCard
      isLoading={isLoading}
      header={
        <TextH5>{translate("LengthGroupTotalCompare_card_title")}</TextH5>
      }
    >
      <div className="space-y-4">
        <BaseTable table={table} columns={columns} />
      </div>
    </BaseCard>
  );
};
