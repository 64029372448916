import { Countries, CountryEnum } from "@vuumly-common/common";
import { useSearchParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { SetParamsProps } from "./index";
import setDefaultOptions from "date-fns/setDefaultOptions";

function getCountryName(country: CountryEnum) {
  return Countries[country]?.name;
}

export function useCountry() {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedCountryValue = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedCountry = searchParams.getAll("countries[]") as CountryEnum[];
    return selectedCountry ? selectedCountry[0] : undefined;
  }, [searchParams]);

  const selectedCountryName = useMemo(() => {
    const name = selectedCountryValue
      ? getCountryName(selectedCountryValue)
      : undefined;
    return name;
  }, [searchParams]);

  const selectedCountryCode = useMemo(() => {
    return selectedCountryValue
      ? Countries[selectedCountryValue]?.country_code
      : undefined;
  }, [searchParams]);

  const selectedCountryLocale = useMemo(() => {
    return selectedCountryValue
      ? Countries[selectedCountryValue].locale
      : undefined;
  }, [searchParams]);

  const setSelectedCountry = (
    payload: CountryEnum,
    options?: SetParamsProps
  ) => {
    const searchParams = new URLSearchParams(window.location.search);

    searchParams.delete("cities[]");
    searchParams.delete("origin_id");
    searchParams.delete("companies[]");
    searchParams.set("countries[]", payload);

    setSearchParams(searchParams, options?.navigateOptions);
  };

  return {
    selectedCountryValue,
    selectedCountryName,
    selectedCountryCode,
    selectedCountryLocale,
    setSelectedCountry,
  };
}
