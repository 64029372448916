import {
  getCompanies,
  getCompaniesByCityAndDeviceTypeGroup,
  location,
} from "@vuumly-common/common";
import {
  useCompany,
  useCountry,
  useDeviceGroup,
  useDeviceType,
  useTranslate,
  useUserContext,
} from "hooks";
import { useMemo } from "react";
import { BaseFilterOption, BaseFilterSelect } from "../BaseFilterSelect";

export function CompanySelect() {
  const translate = useTranslate();
  const { subscribedCities } = useUserContext();
  const { isMicromobilityActive } = useDeviceType();

  const { setSelectedCompanies, selectedCompanies } = useCompany();
  const { selectedCountryValue } = useCountry();
  const { selectedDeviceGroup } = useDeviceGroup();

  const list = useMemo(() => {
    if (!selectedCountryValue) {
      return [];
    }

    const availableCities = location.cities.getCitiesByCountryFromArray(
      selectedCountryValue,
      subscribedCities
    );
    return getCompaniesByCityAndDeviceTypeGroup(
      availableCities,
      selectedDeviceGroup
    ).map((item) => {
      return {
        label: `Company ${String(item.id)}`,
        value: String(item.id),
        id: "company_" + String(item.id),
        selected: selectedCompanies.includes(String(item.id)),
      };
    });
  }, [isMicromobilityActive, selectedCountryValue]);

  const onChange = (payload: BaseFilterOption<string>[]) => {
    const selectedCompanies = payload
      .filter((item) => item.selected)
      .map((item) => item.value);

    setSelectedCompanies(selectedCompanies);
  };

  return (
    <BaseFilterSelect<string>
      onChange={onChange}
      options={list}
      title={translate("Company")}
    />
  );
}
