import { dayCountBetweenDates } from "@vuumly-common/common";
import format from "date-fns/format";
import { DateRange } from "react-day-picker";
import { useSearchParams } from "react-router-dom";
import { SetParamsProps, useDemoMode } from "./index";
import addDays from "date-fns/addDays";

export const useDateRange = () => {
  const [_searchParams, setSearchParams] = useSearchParams();
  const { demoCitySettings } = useDemoMode();

  const dateRangeMin = demoCitySettings
    ? demoCitySettings.dateRange.from
    : new Date(2022, 6, 1);

  const dateRangeMax = demoCitySettings
    ? demoCitySettings.dateRange.to
    : addDays(new Date(), -1);

  const searchParams = new URLSearchParams(window.location.search);
  const from = searchParams.get("start_date");
  const to = searchParams.get("end_date");

  const selectedDateRange: DateRange = {
    from: from ? new Date(from) : undefined,
    to: to ? new Date(to) : undefined,
  };

  function setSelectedDateRange(date: DateRange, options?: SetParamsProps) {
    if (date.from && date.to) {
      let params = options?.searchParams || searchParams;
      params.set("start_date", format(date.from, "yyyy-MM-dd"));
      params.set("end_date", format(date.to, "yyyy-MM-dd"));
      setSearchParams(params, options?.navigateOptions);
      return params;
    }
  }

  let selectedDaysCount = null;

  if (selectedDateRange.from && selectedDateRange.to) {
    selectedDaysCount = dayCountBetweenDates(
      selectedDateRange.from,
      selectedDateRange.to
    );
  }

  const rangeStartDayOfWeek =
    selectedDateRange.from?.getDay() !== undefined
      ? selectedDateRange.from?.getDay()
      : 99;

  const rangeEndDayOfWeek =
    selectedDateRange.to?.getDay() !== undefined
      ? selectedDateRange.to?.getDay()
      : 99;

  const weekStartsOn = 1; // Monday
  const weekEndsOn = 0; // Sunday

  const isStartingWithMonday = rangeStartDayOfWeek === weekStartsOn;
  const isEndingWithSunday = rangeEndDayOfWeek === weekEndsOn;

  const isFullWeekSelected = selectedDaysCount ? selectedDaysCount % 7 : false;

  return {
    selectedDateRange,
    setSelectedDateRange,
    selectedDaysCount,

    isFullWeekSelected,
    isStartingWithMonday,
    isEndingWithSunday,

    dateRangeMin,
    dateRangeMax,
  };
};
