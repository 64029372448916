import { api, time } from "@vuumly-common/common";
import { ChartData, ChartOptions, TooltipItem } from "chart.js";
import Color from "color";
import { useQuery, useTheme, useTimeGranularity, useTranslate } from "hooks";
import _ from "lodash";
import { CardProps } from "..";
import { RoutePathEnum } from "../../../controllers/router";
import { BaseLineChart } from "../../Charts";
import { TimeGranularityTabs } from "../../Tabs/TimeGranularityTabs";
import { TextH5 } from "../../Typography/Text";
import { BaseCard } from "../Base.card";

export const UtilizationTimeseriesLineChartCombinedCard = (
  props: CardProps
) => {
  const { tabs, timeGranularity, changeGranularity, modifiedQueryParams } =
    useTimeGranularity();

  const translate = useTranslate();
  const theme = useTheme();

  const { data, isLoading } = useQuery({
    apiEndpoint: api.Endpoint.Utilization,
    params: modifiedQueryParams,
  });

  const clonedData = _.cloneDeep(data) as ChartData<"line">;

  if (clonedData?.datasets) {
    clonedData.datasets = clonedData?.datasets.filter(
      (dataset) =>
        dataset.label === "without_operator_overall_utilization_rate" ||
        dataset.label === "operator_utilization_rate"
    );
  }

  clonedData?.datasets.forEach((dataset) => {
    const { label } = dataset;

    if (label === "without_operator_overall_utilization_rate") {
      dataset.label = translate("Utilization rate for others");
      dataset.parsing = {
        yAxisKey: "without_operator_overall_utilization_rate",
      };
      dataset.backgroundColor = theme.otherOperatorLineColor;
      dataset.borderColor = theme.otherOperatorLineColor;
      //dataset.borderWidth = 3;
      dataset.order = 2;

      dataset.trendlineLinear = {
        colorMax: Color(theme.otherOperatorLineColor).alpha(0.5).rgb().string(),
        colorMin: Color(theme.otherOperatorLineColor).alpha(0.5).rgb().string(),
        lineStyle: "dashed",
        width: 2,
        projection: false,
      };
    } else if (label === "operator_utilization_rate") {
      dataset.label = translate("Your utilization rate");
      dataset.parsing = {
        yAxisKey: "operator_utilization_rate",
      };
      // dataset.backgroundColor = theme.accentColor;
      // dataset.borderColor = theme.accentColor;
      // dataset.borderWidth = 3;
      dataset.order = 1;

      dataset.trendlineLinear = {
        colorMax: Color(theme.accentColor).alpha(0.3).rgb().string(),
        colorMin: Color(theme.accentColor).alpha(0.3).rgb().string(),
        lineStyle: "dashed",
        width: 2,
        projection: false,
      };
    }
  });

  const options: ChartOptions<"line"> = {
    parsing: {
      xAxisKey: "local_time",
    },

    scales: {
      x: {
        type: "timeseries",
      },
      y: {
        title: {
          text: "Utilization rate",
        },
        ticks: {
          callback: (value) => {
            return `${value}%`;
          },
        },
      },
    },

    plugins: {
      tooltip: {
        callbacks: {
          label: (item: TooltipItem<"line">) => {
            return `${item.dataset.label}: ${item.formattedValue}%`;
          },
        },
      },
    },
  };

  const Header = () => {
    return (
      <div className="flex justify-between w-full">
        <TextH5>{translate("Utilization over time (you vs. others)")}</TextH5>
        <TimeGranularityTabs
          tabs={tabs}
          defaultValue={timeGranularity || time.GranularityEnum.Day}
          onChange={changeGranularity}
        />
      </div>
    );
  };

  return (
    <BaseCard isLoading={isLoading} header={<Header />}>
      <BaseLineChart options={options} data={clonedData} />
    </BaseCard>
  );
};
