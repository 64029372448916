import { geo } from "@vuumly-common/common";
import { LayerPanel } from "./Layer.panel";
import { PanelSlider, SelectPanel } from ".";
import { BaseButton } from "../Button";
import { useTranslate } from "hooks";
import { Layers } from "controllers";
import classNames from "classnames";

export function calculateWeight(
  appearanceCount: number,
  maxCount: number,
  balanceLevel: number,
  balanceWeight: number
) {
  const weight =
    Math.pow(appearanceCount, balanceLevel) / Math.pow(maxCount, balanceLevel);
  return weight;
}

export interface Props {
  data: any;
  isLoading?: boolean;
  settings: Layers.Heatmap.Settings;
  setSettings: (settings: Layers.Heatmap.Settings) => void;
  weight?: number;
}

interface HeatmapPanelProps {
  settings: Layers.Heatmap.Settings;
  setSettings: (settings: Layers.Heatmap.Settings) => void;
  updateAvailable?: boolean;
  handleUpdate: () => void;
  resetFilter: () => void;
  isResetAvailable?: boolean;
}

export function HeatmapPanel(props: HeatmapPanelProps) {
  const {
    settings,
    setSettings,
    handleUpdate,
    updateAvailable,
    resetFilter,
    isResetAvailable,
  } = props;
  const translate = useTranslate();

  function getOptions() {
    const options = geo.GeohashType.map((type) => ({
      value: type.type,
      label: translate(type.name),
      id: type.type,
    }));
    return options;
  }

  return (
    <LayerPanel
      checked={true}
      value="heatmapLayerVisible"
      label="Heatmap"
      onChange={(val) => {}}
    >
      <div className="space-y-4">
        <PanelSlider
          label="heatmap_intensity_label"
          value={settings.weight}
          minValue={0.1}
          maxValue={1}
          step={0.1}
          onChange={(value) => {
            setSettings({
              ...settings,
              weight: value,
            });
          }}
        />

        <PanelSlider
          label="heatmap_min_ride_count"
          value={settings.heatmap_min_ride_count}
          minValue={5}
          maxValue={1000}
          step={5}
          onChange={(value) => {
            setSettings({
              ...settings,
              heatmap_min_ride_count: value,
            });
          }}
        />

        <SelectPanel
          label="geohash_cluster_size"
          value={settings.geohash_cluster_size}
          options={getOptions()}
          onChange={(value) => {
            setSettings({
              ...settings,
              geohash_cluster_size: value as geo.GeohashSize,
            });
          }}
        />
        <div className="flex space-x-4">
          <BaseButton
            variant="outline"
            onClick={resetFilter}
            disabled={!isResetAvailable}
          >
            {translate("reset_filter")}
          </BaseButton>
          <BaseButton
            className={classNames({
              "flex-1": true,
              "bg-primary": updateAvailable,
            })}
            onClick={handleUpdate}
            disabled={!updateAvailable}
          >
            {!updateAvailable
              ? translate("data_up_to_date")
              : translate("data_update_available")}
          </BaseButton>
        </div>
      </div>
    </LayerPanel>
  );
}
