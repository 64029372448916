import {
  FleetSizeNotRentedTimeseriesDeviceTypeCard,
  FleetSizeStandbyAverageDistinctPlace,
  FleetSizeTimeseriesCompanyCard,
  FleetSizeTimeseriesDeviceTypeCard,
  RidesTimeseriesDeviceBodyType,
  RidesTimeseriesDeviceSize,
  RidesTotalDeviceBodyType,
  RidesTotalDeviceModel,
  RidesTotalDeviceSize,
} from "components/Card";
import { DashboardHeader } from "components/Header";
import { useCountry, usePageTitle, useRouter } from "hooks";
import { RoutePathEnum } from "../../controllers/router";

import { useLocation } from "react-router-dom";
import { TextH3 } from "../../components/Typography/Text";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/shadcn/components/ui/accordion";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/shadcn/components/ui/tabs";

export const FleetPage = () => {
  const title = usePageTitle(RoutePathEnum.Fleet);
  const { selectedCountryName } = useCountry();
  const { goTo } = useRouter();
  const { pathname } = useLocation();

  return (
    <>
      <DashboardHeader title={title} />

      <Tabs onValueChange={goTo} value={pathname}>
        <TabsList className="mb-8">
          <TabsTrigger
            value={RoutePathEnum.Fleet}
          >{`Fleet size (${selectedCountryName})`}</TabsTrigger>
          <TabsTrigger value={RoutePathEnum.FleetStandBy}>
            Fleet size on standby
          </TabsTrigger>
        </TabsList>

        <TabsContent value={RoutePathEnum.Fleet}>
          <div className="flex flex-col space-y-12">
            <FleetSizeTimeseriesDeviceTypeCard />
            <FleetSizeTimeseriesCompanyCard />
          </div>
        </TabsContent>

        <TabsContent value={RoutePathEnum.FleetStandBy}>
          <div className="flex flex-col space-y-12">
            <FleetSizeNotRentedTimeseriesDeviceTypeCard />
            <FleetSizeStandbyAverageDistinctPlace />
          </div>
        </TabsContent>
      </Tabs>

      <div className="max-w-[600px] mx-auto mt-16">
        <TextH3 className="mb-4">FAQ</TextH3>
        <Accordion type="single" collapsible className="w-full">
          <AccordionItem value="item-1">
            <AccordionTrigger>
              How is the fleet size calculated?
            </AccordionTrigger>
            <AccordionContent>
              For any given date you see on the chart, we determine the fleet
              size by looking at a specific period: the 30 days leading up to
              and including that date. Within this 30-day window, we analyze our
              records to identify all the unique devices that were active. An
              active device is one that has been used.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-2">
            <AccordionTrigger>
              How is the average fleet size on standby calculated?
            </AccordionTrigger>
            <AccordionContent>
              For each date shown on the chart, we calculate the number of
              vehicles on standby by identifying those that are available for
              use but have not been rented out.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-3">
            <AccordionTrigger>
              Why fleet size is shown for whole country instead of the area or
              region?
            </AccordionTrigger>
            <AccordionContent>
              Due to the dynamic distribution of the fleet, we are unable to
              provide exact numbers for specific areas or regions. You can use{" "}
              <strong>Fleet size on standby</strong> to get the average fleet
              size on standby for the specific area or region.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-4">
            <AccordionTrigger>
              What is the difference between "fleet size" and "fleet size on
              standby"?
            </AccordionTrigger>
            <AccordionContent>
              The <strong>Fleet size</strong> section provides an overview of
              the total number of vehicles available in a carsharing company's
              fleet, though exact numbers for specific regions may be elusive
              due to data limitations. In contrast, the{" "}
              <strong>Fleet size on standby</strong> focuses on the average
              number of vehicles ready for rent but not currently in use,
              offering insights into regional availability and fleet
              utilization. This approach helps bridge the gap between the
              general fleet capacity and the immediate availability of vehicles
              to meet user demand.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-5">
            <AccordionTrigger>
              What is the precision of the calculations?
            </AccordionTrigger>
            <AccordionContent>
              <strong>Fleet size:</strong> Determining the exact fleet size for
              each company poses a challenge due to the lack of direct
              information on the total number of vehicles. Our approach relies
              on counting the number of active devices over the past 30 days as
              a proxy for fleet size. While this method offers a reliable
              estimate, it does not yield the precise number of vehicles in the
              fleet.
              <br />
              <strong>Fleet size on standby:</strong> This calculation is highly
              precise. It is based on the number of vehicles that are available
              for rent but have not been rented out, allowing for accurate
              measurement of standby fleet size.
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </>
  );
};
