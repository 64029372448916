import classNames from "classnames";
import { Button } from "components/shadcn/components/ui/button";
import { Calendar } from "components/shadcn/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/shadcn/components/ui/popover";
import { cn } from "components/shadcn/lib/utils";
import {
  addDays,
  addMonths,
  endOfQuarter,
  format,
  startOfQuarter,
} from "date-fns";
import { enGB } from "date-fns/locale";
import { CalendarIcon } from "lucide-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DateRange } from "react-day-picker";
import { toast } from "sonner";
import { DaterangeError } from "../../controllers";
import { isDateValid } from "../../controllers/validators/date.validator";
import {
  useDateRange,
  useDemoMode,
  useTranslate,
  useUserContext,
} from "../../hooks";
import { BaseButton } from "../Button";
import { BaseLabel } from "../Label";
import { TextMuted, TextSmall } from "../Typography/Text";
import { Input } from "../shadcn/components/ui/input";

interface IDateRangePicker {
  className?: string;
}

interface CalendarFooterProps {
  onApply: () => void;
  onCancel: () => void;
  disabled?: boolean;
}

interface DateRangeOption extends DateRange {
  label: string;
  currentSelectedDateRange?: DateRange;
  onClick?: (data: DateRange) => void;
}

interface DateRangeInputProps {
  dateRange?: DateRange;
  setDate: (date: DateRange) => void;
  dateRangeError: DaterangeError | null;
}

interface DateRangeInputProps {
  dateRange?: DateRange;
  setDate: (date: DateRange) => void;
  dateRangeError: DaterangeError | null;
}

interface CalendarSidebarProps {
  dateRange?: DateRange;
  onClick: (data: DateRange) => void;
}

interface DateInputProps {
  disabled?: boolean;
  date: string;
  onChange: (date: any) => void;
  error?: string | null;
  label: string;
  inputRef?: React.RefObject<HTMLInputElement>;
}

export const DateRangePicker = (props: IDateRangePicker) => {
  const {
    selectedDateRange,
    setSelectedDateRange,
    dateRangeMin,
    dateRangeMax,
  } = useDateRange();

  const { isDemoModeEnabled } = useDemoMode();

  const { locale } = useUserContext();
  const { className } = props;

  const [date, setDate] = React.useState<DateRange | undefined>(
    selectedDateRange
  );

  const translate = useTranslate();

  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const [dateRangeError, setDateRangeError] =
    React.useState<DaterangeError | null>(null);

  const [monthFrom, setMonthFrom] = useState<Date>(date?.from || new Date());
  const [monthTo, setMonthTo] = useState<Date>(date?.to || new Date());

  const onSelect = useCallback(
    (date?: DateRange) => {
      if (!date) {
        setDateRangeError(DaterangeError.Invalid);
        return;
      }

      if (!date.from) {
        setDateRangeError(DaterangeError.DateFromMissing);
        return;
      }

      if (!date.to) {
        setDateRangeError(DaterangeError.DateToMissing);
        return;
      }

      if (date.from > date.to) {
        // const newDate = { ...date, from: date.to };
        setDate(date);
        // setDateRange(newDate);
        setDateRangeError(DaterangeError.FromLargerThanTo);
        // setDateRangeError(CalendarError.InvalidRange);
        return;
      }

      // if (date.to < date.from) {
      //   const newDate = { ...date, from: date.to };
      //   setDate(newDate);
      //  // setDateRange(newDate);
      //   setDateRangeError(null);
      //   // setDateRangeError(CalendarError.InvalidRange);
      //   return;
      // }

      setDateRangeError(null);
      setDate(date);
      // setDateRange(date);
    },
    [date]
  );

  const handleSetDate = (date: DateRange) => {
    if (isDemoModeEnabled) {
      toast.error("Disabled in demo mode");
      return;
    }
    if (!date.to) return;
    setMonthFrom(date.from || date.to);
    setMonthTo(date.to);
    setDate(date);

    setSelectedDateRange(date);
    setIsPopoverOpen(false);
  };

  const onApply = () => {
    if (!date) return;
    setSelectedDateRange(date);
    setIsPopoverOpen(false);
  };

  const onCancel = () => {
    setDateRangeError(null);
    setDate(selectedDateRange);
    setIsPopoverOpen(false);
  };

  function setFromDay(day?: Date) {
    if (!day) return;
    onSelect({ from: day, to: date?.to });
  }

  function setToDay(day?: Date) {
    if (!day) return;
    onSelect({ from: date?.from, to: day });
  }

  function getPresetName() {
    const preset = dateRangeList.find((presetDate) => {
      const from =
        presetDate?.from?.getFullYear() === date?.from?.getFullYear() &&
        presetDate?.from?.getDate() === date?.from?.getDate() &&
        presetDate?.from?.getMonth() === date?.from?.getMonth();

      const to =
        presetDate?.to?.getFullYear() === date?.to?.getFullYear() &&
        presetDate?.to?.getDate() === date?.to?.getDate() &&
        presetDate?.to?.getMonth() === date?.to?.getMonth();

      return from && to;
    });

    if (preset) {
      return translate(preset.label);
    }
  }
  return (
    <div className={cn("grid gap-2", className)}>
      <Popover
        open={isPopoverOpen}
        onOpenChange={(isOpen) => {
          setIsPopoverOpen(isOpen);
          setDate(selectedDateRange);
          // trigger onChange when popover is closed
          // if (!isOpen && date) {
          //   setDateRange(date);
          // }
        }}
      >
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "justify-start text-left font-normal",
              !date && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {getPresetName() ? (
              getPresetName()
            ) : date?.from ? (
              date.to ? (
                <>
                  {format(date.from, "LLL dd, y")} -{" "}
                  {format(date.to, "LLL dd, y")}
                </>
              ) : (
                format(date.from, "LLL dd, y")
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0 shadow-2xl" align="end">
          <div>
            <div className="flex">
              <div className="border-r">
                <CalendarSidebar dateRange={date} onClick={handleSetDate} />
              </div>
              <div>
                <div className="flex">
                  <div className="bg-muted flex-1 py-2 text-center">
                    <BaseLabel htmlFor="start_date" label="Start date" />
                  </div>
                  <div className="bg-muted flex-1 py-2 text-center">
                    <BaseLabel htmlFor="end_date" label="End date" />
                  </div>
                </div>
                <div className="flex divide-x">
                  <div className="p-4 pb-0 flex-1">
                    <Calendar
                      locale={enGB}
                      initialFocus
                      mode="single"
                      weekStartsOn={1}
                      onMonthChange={setMonthFrom}
                      defaultMonth={date?.from}
                      fromDate={dateRangeMin}
                      toDate={dateRangeMax}
                      selected={date?.from}
                      month={monthFrom}
                      onSelect={setFromDay}
                      numberOfMonths={1}
                      showOutsideDays={true}
                      fixedWeeks={true}
                    />
                  </div>
                  <div className="p-4 pb-0 flex-1">
                    <Calendar
                      locale={enGB}
                      initialFocus
                      weekStartsOn={1}
                      mode="single"
                      onMonthChange={setMonthTo}
                      defaultMonth={date?.to}
                      fromDate={dateRangeMin}
                      toDate={dateRangeMax}
                      selected={date?.to}
                      month={monthTo}
                      onSelect={setToDay}
                      numberOfMonths={1}
                      showOutsideDays={true}
                      fixedWeeks={true}
                    />
                  </div>
                </div>
                {/* <DateRangeInput
                  dateRangeError={dateRangeError}
                  setDate={onSelect}
                  dateRange={date}
                /> */}
              </div>
            </div>
            <CalendarFooter
              disabled={dateRangeError ? true : false}
              onCancel={onCancel}
              onApply={onApply}
            />
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

const CalendarFooter = (props: CalendarFooterProps) => {
  const translate = useTranslate();
  const { isDemoModeEnabled } = useDemoMode();
  const { dateRangeMin, dateRangeMax } = useDateRange();
  return (
    <div className="border-t space-x-4 p-4 justify-end flex items-center">
      {/* show text that in demo mode available date range is from dateRangeMin to dateRangeMax */}
      {isDemoModeEnabled && (
        <TextSmall className="flex-1 text-warning">
          Demo mode availability: {format(dateRangeMin, "d MMMM yyyy")} through{" "}
          {format(dateRangeMax, "d MMMM yyyy")}
        </TextSmall>
      )}
      <BaseButton onClick={props.onCancel} variant="outline">
        {translate("daterange_button_cancel")}
      </BaseButton>
      <BaseButton disabled={props.disabled} onClick={props.onApply}>
        {translate("daterange_button_apply")}
      </BaseButton>
    </div>
  );
};

function ListItem(date: DateRangeOption) {
  const { currentSelectedDateRange } = date;
  const translate = useTranslate();

  function handleClick() {
    if (!date.onClick) return;
    date.onClick(date);
  }

  const from =
    currentSelectedDateRange?.from?.getFullYear() ===
      date.from?.getFullYear() &&
    currentSelectedDateRange?.from?.getDate() === date.from?.getDate() &&
    currentSelectedDateRange?.from?.getMonth() === date.from?.getMonth();

  const to =
    currentSelectedDateRange?.to?.getFullYear() === date.to?.getFullYear() &&
    currentSelectedDateRange?.to?.getDate() === date.to?.getDate() &&
    currentSelectedDateRange?.to?.getMonth() === date.to?.getMonth();

  return (
    <BaseButton
      className={cn("justify-start", from && to && "bg-muted")}
      variant="ghost"
      onClick={handleClick}
    >
      {translate(date.label)}
    </BaseButton>
  );
}

const CalendarSidebar = (props: CalendarSidebarProps) => {
  return (
    <div className="flex flex-col p-2 w-[200px]">
      {dateRangeList.map((date) => {
        return (
          <ListItem
            key={date.label}
            {...date}
            currentSelectedDateRange={props.dateRange}
            onClick={props.onClick}
          />
        );
      })}
    </div>
  );
};

function DateInput(props: DateInputProps) {
  const { date, onChange, error, label, inputRef, disabled } = props;
  const translate = useTranslate();
  const { isDemoModeEnabled } = useDemoMode();

  function onClick() {
    if (isDemoModeEnabled) {
      toast.error("Disabled in demo mode");
    }
  }

  return (
    <div className="relative">
      <div className="w-full inline-block" onClick={onClick}>
        {/* <BaseLabel htmlFor={label} label={translate(label)} /> */}
        <Input
          ref={inputRef}
          id={label}
          className={classNames({
            "border-red-500": error,
            "pointer-events-none": isDemoModeEnabled,
          })}
          type="text"
          defaultValue={date}
          onChange={onChange}
          disabled={disabled}
        />
        <TextMuted
          className={classNames({
            "text-red-500 text-xs visible": error,
            invisible: !error,
          })}
        >
          {translate(error || "error")}
        </TextMuted>
      </div>
    </div>
  );
}

const DateRangeInput = (props: DateRangeInputProps) => {
  const { locale } = useUserContext();
  const { dateRange, setDate } = props;
  const [dateFromError, setDateFromError] = React.useState<string | null>(null);
  const [dateToError, setDateToError] = React.useState<string | null>(null);
  const dateStartInput = useRef<HTMLInputElement>(null);
  const dateEndInput = useRef<HTMLInputElement>(null);
  const { dateRangeMax, dateRangeMin } = useDateRange();
  const { isDemoModeEnabled } = useDemoMode();

  useEffect(() => {
    if (!props.dateRangeError) {
      setDateFromError(null);
      setDateToError(null);
      return;
    }

    if (props.dateRangeError === DaterangeError.Invalid) {
      setDateFromError("daterange_invalid");
      setDateToError("daterange_invalid");
    } else if (props.dateRangeError === DaterangeError.DateFromMissing) {
      setDateFromError("daterange_date_from_missing");
    } else if (props.dateRangeError === DaterangeError.DateToMissing) {
      setDateToError("daterange_date_to_missing");
    } else if (props.dateRangeError === DaterangeError.InvalidRange) {
      setDateFromError("daterange_invalid_range");
      setDateToError("daterange_invalid_range");
    } else if (props.dateRangeError === DaterangeError.OutOfRange) {
      setDateFromError("daterange_out_of_range");
      setDateToError("daterange_out_of_range");
    } else if (props.dateRangeError === DaterangeError.FromLargerThanTo) {
      setDateFromError("Start date cannot be larger than end date");
    }
  }, [props.dateRangeError, props.dateRange]);

  useEffect(() => {
    if (!dateRange) return;
    const activeElement = document.activeElement;
    if (dateStartInput.current && dateStartInput.current !== activeElement) {
      dateStartInput.current.value = new Intl.DateTimeFormat(locale, {}).format(
        dateRange.from
      );
    }
    if (dateEndInput.current && dateEndInput.current !== activeElement) {
      dateEndInput.current.value = new Intl.DateTimeFormat(locale, {}).format(
        dateRange.to
      );
    }
  }, [dateRange?.from, dateRange?.to]);

  function startDateChanged(date: any) {
    const parsedDate = isDateValid(
      date.target.value,
      { from: dateRangeMin, to: dateRangeMax },
      locale
    );
    if (parsedDate === "outOfRange") {
      setDateFromError("daterange_out_of_range");
    } else if (parsedDate === "invalid") {
      setDateFromError("daterange_invalid");
    } else if (parsedDate) {
      setDate({ ...dateRange, from: parsedDate });
      setDateFromError(null);
    }
  }

  function endDateChanged(date: any) {
    const parsedDate = isDateValid(
      date.target.value,
      { from: dateRangeMin, to: dateRangeMax },
      locale
    );
    if (parsedDate === "outOfRange") {
      setDateToError("daterange_out_of_range");
    } else if (parsedDate === "invalid") {
      setDateToError("daterange_invalid");
    } else if (parsedDate && dateRange?.from) {
      setDate({ ...dateRange, to: parsedDate });
      setDateToError(null);
    }
  }

  if (!dateRange) return <>No daterange provided</>;

  const dateFrom = new Intl.DateTimeFormat(locale, {}).format(dateRange.from);
  const dateTo = new Intl.DateTimeFormat(locale, {}).format(dateRange.to);

  return (
    <div className="flex divide-x">
      <div className="p-4 pb-0 flex-1">
        <DateInput
          inputRef={dateStartInput}
          label="start_date"
          error={dateFromError}
          onChange={startDateChanged}
          date={dateFrom}
          disabled={isDemoModeEnabled}
        />
      </div>
      <div className="p-4 pb-0 flex-1">
        <DateInput
          inputRef={dateEndInput}
          label="end_date"
          error={dateToError}
          onChange={endDateChanged}
          date={dateTo}
          disabled={isDemoModeEnabled}
        />
      </div>
    </div>
  );
};

const dateRangeList: DateRangeOption[] = [
  {
    label: "last_7_days",
    from: addDays(new Date(), -7),
    to: addDays(new Date(), -1),
  },
  {
    label: "last_14_days",
    from: addDays(new Date(), -14),
    to: addDays(new Date(), -1),
  },
  {
    label: "last_28_days",
    from: addDays(new Date(), -28),
    to: addDays(new Date(), -1),
  },
  {
    label: "last_3_months",
    from: addDays(addMonths(new Date(), -3), +1),
    to: addDays(new Date(), -1),
  },
  {
    label: "last_6_months",
    from: addDays(addMonths(new Date(), -6), +1),
    to: addDays(new Date(), -1),
  },
  {
    label: "this_year",
    from: new Date(new Date().getFullYear(), 0, 1),
    to: addDays(new Date(), -1),
  },
  {
    label: "q1_2023",
    from: startOfQuarter(new Date(2023, 0, 3)),
    to: endOfQuarter(new Date(2023, 0, 3)),
  },
  {
    label: "q2_2023",
    from: startOfQuarter(new Date(2023, 3, 6)),
    to: endOfQuarter(new Date(2023, 3, 6)),
  },
  {
    label: "q3_2023",
    from: startOfQuarter(new Date(2023, 6, 9)),
    to: endOfQuarter(new Date(2023, 6, 9)),
  },
  {
    label: "q4_2023",
    from: startOfQuarter(new Date(2023, 9, 12)),
    to: endOfQuarter(new Date(2023, 9, 12)),
  },
];
