import { useTranslate } from "hooks";
import { BaseCheckbox } from "../Checkbox";

interface Props
  extends Omit<React.ComponentPropsWithoutRef<"div">, "onChange"> {
  label: string;
  value?: boolean;
  onChange: (value: boolean) => void;
}

export function PanelCheckbox(props: Props) {
  const translate = useTranslate();

  const { label, value, onChange } = props;
  return (
    <BaseCheckbox
      label={translate(label)}
      value={label}
      id={label}
      checked={value || false}
      onChange={(val) => onChange(val as boolean)}
    />
  );
}
