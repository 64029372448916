import { Check, ChevronsUpDown } from "lucide-react";
import { useState } from "react";
import { Button } from "../shadcn/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../shadcn/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../shadcn/components/ui/popover";
import { cn } from "../shadcn/lib/utils";

interface Item {
  label: string;
  value: string;
}

interface Props {
  onValueChanged: (value: string) => void;
  value?: string;
  list: Item[];
  placeholderSelect?: string;
  placeholderSearch?: string;
  placeholderEmpty?: string;
}

export function BaseCombobox(props: Props) {
  const {
    list,
    value,
    onValueChanged,
    placeholderEmpty = "Nothing found",
    placeholderSearch = "Search...",
    placeholderSelect = "Select...",
  } = props;

  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          disabled={list.length === 0}
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between"
        >
          {value
            ? list.find((item) => item.value === value)?.label
            : placeholderSelect}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[335px] p-0">
        <Command>
          <CommandInput placeholder={placeholderSearch} />
          <CommandEmpty>{placeholderEmpty}</CommandEmpty>
          <CommandGroup className=" h-[200px] overflow-auto">
            {list.map((item) => (
              <CommandItem
                key={item.value}
                value={item.label}
                onSelect={() => {
                  onValueChanged(item.value);
                  setOpen(false);
                }}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    value === item.value ? "opacity-100" : "opacity-0"
                  )}
                />
                {item.label}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
