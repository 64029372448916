import { useState, useEffect } from "react";
import { useMapContext } from "../../../context/MapContext";

export default function useMap() {
  const { mapInstance: map } = useMapContext();
  const [isMoving, setIsMoving] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);

  const handleMouseDown = () => {
    //console.log("mousedown");
    setIsMouseDown(true)
  }

  const handleMouseUp = () => {
    //console.log("mouseup");
    setIsMouseDown(false)
  }

  useEffect(() => {
    if (!map) return;

    const handleMoveStart = () => {
      setIsMoving(true);
    };

    map.on("movestart", handleMoveStart);

    const handleMoveEnd = () => {
      setIsMoving(false);
    };

    map.on("moveend", handleMoveEnd);

    return () => {
      console.log("unmount");
      map?.un("movestart", handleMoveStart);
      map?.un("moveend", handleMoveEnd);
    };
  }, [map]);

  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown)
    window.addEventListener("mouseup", handleMouseUp)

    return () => {
        window.removeEventListener("mousedown", handleMouseDown)
    }
  },[]);

  return { isMoving, isMouseDown };
}
