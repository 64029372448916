import { MapStylePanel } from "../Panel";
import { cn } from "../shadcn/lib/utils";

interface Props extends React.ComponentProps<"div"> {}

export function MapSidebar(props: Props) {
  return (
    <div
      className={cn(
        "absolute z-10 bg-card rounded-lg right-4 top-4 shadow-lg w-full",
        props.className
      )}
      style={{ width: 340, ...props.style }}
    >
      <div>{props.children}</div>
      <MapStylePanel />
    </div>
  );
}
