import { api } from "@vuumly-common/common";
import { BaseCard } from "components/Card/index";
import { TextH5 } from "components/Typography/Text";
import { useOperatorMode, useQuery, useTranslate, useUserContext } from "hooks";

import {
  ColumnDef,
  SortingState,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { BaseTable } from "../../../Tables";
import { DataTableColumnHeader } from "../../../Tables/DataTableColumnHeader";

const emptyArray: any[] = [];

export const RidesTotalDeviceBodyType = () => {
  const { locale } = useUserContext();
  const translate = useTranslate();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [searchParams] = useSearchParams();

  const params = new URLSearchParams(searchParams);

  params.set("data_structure", "flat");

  const { data, isLoading } = useQuery<[]>({
    apiEndpoint: api.Endpoint.RidesTotalDeviceBodyType,
    params,
  });

  const columns: ColumnDef<any>[] = [
    {
      accessorKey: "device_body_type",
      header: ({ column }) => (
        <DataTableColumnHeader
          className="w-[200px]"
          column={column}
          title="Vehicle type"
        />
      ),
      enableSorting: true,
      sortingFn: (a, b) => {
        const aa = a.getValue("device_body_type") as string;
        const bb = b.getValue("device_body_type") as string;
        if (aa === bb) return 0;
        if (aa === null || bb === null) return 1;
        return aa.localeCompare(bb);
      },
      cell: ({ row }) => {
        return translate(row.getValue("device_body_type")) || "other";
      },
    },
    {
      accessorKey: "vehicle_count",
      header: ({ column }) => (
        <DataTableColumnHeader
          className="w-[150px]"
          column={column}
          title="Fleet size"
        />
      ),
      cell: ({ row }) => {
        const amount = parseFloat(row.getValue("vehicle_count"));
        const formatted = new Intl.NumberFormat(locale, {
          notation: "compact",
        }).format(amount);

        return formatted;
      },
      enableSorting: true,
    },
    {
      accessorKey: "ride_count",
      header: ({ column }) => (
        <DataTableColumnHeader
          className="w-[150px]"
          column={column}
          title="Ride count"
        />
      ),
      cell: ({ row }) => {
        const amount = parseFloat(row.getValue("ride_count"));
        const formatted = new Intl.NumberFormat(locale, {
          notation: "compact",
        }).format(amount);

        return formatted;
      },
      enableSorting: true,
    },
    {
      accessorKey: "rides_per_vehicle_per_day",
      header: ({ column }) => (
        <DataTableColumnHeader
          className="w-[250px]"
          column={column}
          title="Ride count per vehicle (day)"
        />
      ),
      cell: ({ row }) => {
        const amount = parseFloat(row.getValue("rides_per_vehicle_per_day"));
        const formatted = new Intl.NumberFormat(locale, {
          notation: "compact",
        }).format(amount);

        return formatted;
      },
      enableSorting: true,
    },
  ];

  const table = useReactTable({
    data: data || emptyArray,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  return (
    <BaseCard
      isLoading={isLoading}
      header={<TextH5>Ride count by vehicle type</TextH5>}
    >
      <BaseTable columns={columns} table={table} />
    </BaseCard>
  );
};
