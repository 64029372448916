import { useTranslate } from "hooks";
import { TextH2 } from "../Typography/Text";
import { cn } from "../shadcn/lib/utils";

interface Props {
  title?: string;
  className?: string;
}

export const DashboardHeader = (props: Props) => {
  const { title, className } = props;
  const translate = useTranslate();

  return (
    <div id="page-header" className={cn(["mb-4", className])}>
      <TextH2>{title || translate("Dashboard")}</TextH2>
    </div>
  );
};
