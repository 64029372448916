import { useSearchParams } from "react-router-dom";
import { useSuburbAreas } from "./useSuburbAreas.hook";

export function useDistrict() {
  const [_searchParams, setSearchParams] = useSearchParams();
  const { list } = useSuburbAreas();

  const searchParams = new URLSearchParams(window.location.search);
  const selectedDistrict = searchParams.get("origin_id") as string;

  let selectedDistrictName = "district_name";

  if (selectedDistrict) {
    const district = list.find((item) => item.id === selectedDistrict);
    selectedDistrictName = district?.name || selectedDistrictName;
  }

  const setSelectedDistrict = (payload: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("origin_id", payload);
    setSearchParams(searchParams);
  };

  const removeSelectedDistrict = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("origin_id");
    setSearchParams(searchParams);
  };

  return {
    selectedDistrict,
    selectedDistrictName,
    setSelectedDistrict,
    removeSelectedDistrict,
  };
}
