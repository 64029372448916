import { BaseSlider } from "components/Slider";
import { useTranslate } from "hooks";
import { cn } from "../shadcn/lib/utils";
import { TextBody, TextMuted } from "../Typography/Text";
import { BaseLabel } from "../Label";

interface Props
  extends Omit<React.ComponentPropsWithoutRef<"div">, "onChange"> {
  label: string;
  value: number;
  minValue?: number;
  maxValue: number;
  step?: number;
  isHidden?: boolean;
  isDisabled?: boolean;
  onChange: (value: number) => void;
}

export function PanelSlider(props: Props) {
  const translate = useTranslate();

  const {
    label,
    value,
    minValue = 0,
    maxValue,
    step = 1,
    isHidden,
    onChange,
    isDisabled,
  } = props;
  return (
    <>
      {!isHidden && (
        <div className={cn("space-y-4", props.className)}>
          <div className="flex items-center">
            <BaseLabel
              htmlFor={String(value)!}
              label={translate(label)}
              className="flex-1"
            />
            <TextMuted className="tabular-nums">{value}</TextMuted>
          </div>
          <BaseSlider
            disabled={isDisabled}
            min={minValue}
            max={maxValue}
            step={step}
            value={[value]}
            defaultValue={[value]}
            onValueChange={(value: any) => {
              onChange(value[0]);
            }}
          />
        </div>
      )}
    </>
  );
}
