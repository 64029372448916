import { BaseOption } from "components/Select/BaseSelector";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/shadcn/components/ui/select";
import { LockIcon } from "lucide-react";
import { forwardRef } from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../shadcn/components/ui/tooltip";
import { toast } from "sonner";

export interface IBaseSelect<T extends string> {
  onChange: (val: T) => void;
  options: BaseOption<T>[];
  disabled?: boolean;
  value?: string;
}

function Base<T extends string>(
  props: IBaseSelect<T> &
    Omit<React.HTMLAttributes<HTMLSelectElement>, "onChange">,
  ref: React.ForwardedRef<HTMLSelectElement>
) {
  const { onChange, options, disabled, value, className, title, id } = props;

  return (
    <Select
      // open={true}
      disabled={disabled}
      value={value}
      onValueChange={(val) => {
        onChange(val as T);
      }}
    >
      <SelectTrigger id={id} className={className}>
        <SelectValue
          placeholder={props.placeholder ? props.placeholder : "Select..."}
        />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {options.map((option) =>
            option.tooltip ? (
              <div
                className="flex items-center"
                key={option.value as string}
                onClick={() => {
                  toast.error(option.tooltip);
                }}
              >
                <LockIcon
                  className="fill-muted-foreground text-background ml-1"
                  size={18}
                />
                <SelectItem
                  disabled={option.disabled}
                  value={option.value as string}
                >
                  <div className="-ml-5">{option.label}</div>
                </SelectItem>
              </div>
            ) : (
              <SelectItem
                key={option.value as string}
                disabled={option.disabled}
                value={option.value as string}
              >
                {option.label}
              </SelectItem>
            )
          )}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

export const BaseSelect = forwardRef(Base) as <T extends string>(
  props: IBaseSelect<T> &
    Omit<React.HTMLAttributes<HTMLSelectElement>, "onChange"> & {
      ref?: React.ForwardedRef<HTMLUListElement>;
    }
) => ReturnType<typeof Base>;

const TooltipItem = ({ text }: { text: string }) => {
  return (
    <TooltipProvider delayDuration={30}>
      <Tooltip>
        <TooltipTrigger asChild>
          <LockIcon
            className="fill-muted-foreground text-background ml-1"
            size={18}
          />
        </TooltipTrigger>
        <TooltipContent>{text}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
