import { useConstants } from "hooks";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutePathEnum } from "../controllers/router";

export function useRouter() {
  const { ORIGIN_ID_KEY, DESTINATION_ID_KEY } = useConstants();
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const goTo = (path: RoutePathEnum | string, opt?: { replace: boolean }) => {
    const searchParams = new URLSearchParams(window.location.search);
    window.scrollTo(0, 0);
    console.log("goTo", path, searchParams.toString());
    navigate(path + "?" + searchParams, { replace: opt?.replace });
  };

  const goToExternal = useCallback(
    (path: string | RoutePathEnum, options?: { target?: "_blank" }) => {
      console.log("goToExternal", path);
      window.open(path, options?.target || "_blank");
    },
    []
  );

  const getUrlParamsWithDestinationId = useCallback(() => {
    const newParams = new URLSearchParams(window.location.search);

    newParams.set(DESTINATION_ID_KEY, newParams.getAll(ORIGIN_ID_KEY)[0] || "");
    newParams.delete(ORIGIN_ID_KEY);
    return newParams;
  }, []);

  const getUrlParamsWithoutOriginId = useCallback(() => {
    const newParams = new URLSearchParams(window.location.search);
    newParams.delete(ORIGIN_ID_KEY);
    return newParams;
  }, []);

  return {
    pathname,
    goTo,
    goToExternal,
    getUrlParamsWithDestinationId,
    getUrlParamsWithoutOriginId,
  };
}
