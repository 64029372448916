import { TextH1, TextH2 } from "components/Typography/Text";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DeviceGroupEnum } from "@vuumly-common/common";
import { RoutePathEnum } from "../../controllers/router";
import { getFirebaseUserToken } from "../../controllers/firebase-app";
import { PageContentLayout } from "../../components/Layouts";
import { ISubscribeCardOption } from "./components";
import { SubscribeCard } from "./components/SubscribeCard";
import { useUserContext } from "../../hooks";

const items: ISubscribeCardOption[] = [
  {
    title: "Micromobility",

    price: 250,
    btnText: "Get Started",
    value: DeviceGroupEnum.Micromobility,
    options: [
      "Pick one of 7 cities",
      "Realtime data",
      "Available micrmobility companies",
      "Up to 3 team members",
    ],
    colorClass: "bg-blue-600",
    priceId: "price_1NWbL2LTt5LDsTFKxzCW31u3",
  },
  {
    title: "Carsharing",
    value: DeviceGroupEnum.Carsharing,
    price: 250,
    btnText: "Get Started",
    options: [
      "Pick one of 7 cities",
      "Realtime data",
      "Available carsharing companies",
      "Up to 3 team members",
    ],
    colorClass: "bg-purple-600",
    priceId: "price_1NWbL2LTt5LDsTFKxzCW31u3",
  },
  {
    title: "Combo",
    value: "combo",
    price: 450,
    btnText: "Get Started",
    options: [
      "Pick one of 7 cities",
      "Realtime data",
      "Available micromobility companies",
      "Available carsharing companies",
      "Up to 3 team members",
    ],
    colorClass: "bg-green-600",
    priceId: "price_1NWbLkLTt5LDsTFKCyqQEstJ",
  },
  {
    title: "Government",
    value: "government",
    price: 0,
    btnText: "Contact sales",
    options: [
      "Custom features",
      "Realtime data",
      "All available companies",
      "Payment via bank transfers",
      "Custom agreement",
    ],
    colorClass: "bg-orange-600",
    isCustom: true,
    priceId: "not_available",
  },
];

function useGeneratePaymentLink() {
  const [selectedProductValue, setSelectedProductValue] = useState<string>("");
  const [isGeneratingPaymentLink, setIsGeneratingPaymentLink] =
    useState<boolean>(false);

  const { isSigned } = useUserContext();
  const navigate = useNavigate();

  async function generatePaymentLink(priceId: string, productValue: string) {
    if (isSigned && isGeneratingPaymentLink === false) {
      setSelectedProductValue(productValue);
      setIsGeneratingPaymentLink(true);

      const token = await getFirebaseUserToken();
      // redirect to payment page

      const paymentLink = await fetch(
        process.env.REACT_APP_DATA_API_ENDPOINT + "/v1/generate-checkout-link",
        {
          headers: {
            token: token!,
            price_id: priceId,
          },
        }
      ).then((res) => res.json());

      if (paymentLink.data.checkout_link) {
        window.location.href = paymentLink.data.checkout_link;
      } else {
        alert("Something went wrong. Please contact us.");
      }
      setTimeout(() => {
        setIsGeneratingPaymentLink(false);
      }, 3000);
    } else {
      localStorage.setItem("subscribe_intent_product_price_id", priceId);
      localStorage.setItem(
        "subscribe_intent_product_product_value",
        productValue
      );
      navigate(RoutePathEnum.SignIn);
      setIsGeneratingPaymentLink(false);
    }
  }

  return { isGeneratingPaymentLink, generatePaymentLink, selectedProductValue };
}

export default function Subscribe() {
  const { selectedProductValue, isGeneratingPaymentLink, generatePaymentLink } =
    useGeneratePaymentLink();

  const { isSigned } = useUserContext();

  useEffect(() => {
    if (isSigned) {
      const product_price_id = localStorage.getItem(
        "subscribe_intent_product_price_id"
      );

      const product_value = localStorage.getItem(
        "subscribe_intent_product_product_value"
      );

      if (product_price_id && product_value) {
        localStorage.removeItem("subscribe_intent_product_price_id");
        localStorage.removeItem("subscribe_intent_product_product_value");
        generatePaymentLink(product_price_id, product_value);
      }
    }
  }, [isSigned]);

  return (
    <PageContentLayout>
      <div>
        <div className="text-center font-bold tracking-tight">
          <TextH1 className="mb-4 mt-8">Upgrade to membership now</TextH1>
          <TextH2 className="mb-20">
            to get unlimited access to everything on Kenflow
          </TextH2>
        </div>
        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {items.map((item) => (
            <SubscribeCard
              isInProgress={
                selectedProductValue === item.value && isGeneratingPaymentLink
              }
              key={item.value}
              onClick={generatePaymentLink}
              value={item}
            />
          ))}
        </div>
      </div>
    </PageContentLayout>
  );
}
