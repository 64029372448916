import { useMapContext } from "context/MapContext";
import OlMap from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import "ol/ol.css";
import { fromLonLat } from "ol/proj";
import XYZ from "ol/source/XYZ";
import { useEffect, useMemo, useRef } from "react";
import { getMapStyleByValue } from "../../pages/Distribution/MapStyles";
import {
  Fill,
  Icon,
  MVT,
  Stroke,
  Style,
  VectorTileLayer,
  VectorTileSource,
  Text,
  Layer,
  VectorLayer,
} from "../../utils/ol";
import { createMapboxStreetsV6Style } from "./mapboxV6style";

import { applyStyle } from "ol-mapbox-style";
import { useSuburbPanelLayer } from "../../pages/Distribution/Layers/SuburbPanel";

const mapboxAccessToken =
  "pk.eyJ1IjoidHVtc2FsdiIsImEiOiJjbGw3c2JvcHEwZjg3M2ttaTdvdXl3ZjN3In0.oVCOGRg9N3O5S5_Jew9xGA";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  tooltip?: React.ReactNode;
  isLoading?: boolean;
  showExpandButton?: boolean;
  center?: [number, number];
  isTooltipVisible?: boolean;
}

export function BaseVectorMap(props: Props) {
  const olMapRef = useRef<HTMLDivElement>(null);
  const {
    setMapInstance,
    mapStyle,
    lowDetailMapStyle,
    setCurrentZoom,
    hoveredFeatureOnMap,
  } = useMapContext();
  let map: OlMap | null = null;
  const mapRef = useRef<OlMap | null>(null);

  // useMapHover();

  const { layer } = useSuburbPanelLayer();

  useEffect(() => {

    // const mapInstance = olMapRef.current as unknown as OlMap | undefined;
   console.log(mapRef.current, layer, "mapInstance, layer")
    if (layer && mapRef.current) {
      console.log("adding suburb layer", layer);
     // layer.setZIndex(1);
      mapRef.current.addLayer(layer);
    }

    return () => {
      if (!layer || !mapRef.current) return;
      mapRef.current.removeLayer(layer);
    };
  }, [mapRef.current, layer]);

  const vectorTiles = new VectorTileLayer({
    declutter: true,
    // source: new VectorTileSource({
    //   attributions:
    //     '© <a href="https://www.mapbox.com/map-feedback/">Mapbox</a> ' +
    //     '© <a href="https://www.openstreetmap.org/copyright">' +
    //     "OpenStreetMap contributors</a>",
    //   format: new MVT(),
    //   url:
    //     "https://{a-d}.tiles.mapbox.com/v4/mapbox.mapbox-streets-v6/" +
    //     "{z}/{x}/{y}.vector.pbf?access_token=" +
    //     mapboxAccessToken,
    // }),
    // style: createMapboxStreetsV6Style(Style, Fill, Stroke, Icon, Text),
  });

  applyStyle(vectorTiles, "mapbox://styles/mapbox/streets-v12", {
    accessToken: mapboxAccessToken,
  }, {
    
  });

  const xyzLayer = useMemo(
    () =>
      new XYZ({
        tileSize: 512,
        //tilePixelRatio: 2,
        // default map style might be stored in localStorage
        //url: `https://api.mapbox.com/styles/v1/mapbox/streets-v12/tiles/512/{z}/{x}/{y}?access_token=${mapboxAccessToken}`,
        url: `https://api.mapbox.com/v4/mapbox.mapbox-streets-v8/1/0/0.mvt?access_token=${mapboxAccessToken}`,
        attributions:
          '© <a href="https://www.mapbox.com/map-feedback/">Mapbox</a>',
      }),
    []
  );

  useEffect(() => {
    console.log("map mounted");
    return () => {
      console.log("map unmounted");
    };
  }, []);

  useEffect(() => {
    if (!olMapRef.current) return;

    map = new OlMap({
      target: olMapRef.current,
      layers: [vectorTiles],
      view: new View({
        center: fromLonLat([24.1038478, 56.9470011]),
        zoom: 12,
      }),
    });

    mapRef.current = map;

    (window as any).mapRef = map;

    setMapInstance(map);

    return () => {
      if (!map) return;
      // map.removeInteraction(mouseWheelZoom);
      map.setTarget(undefined);
    };
  }, []);

  return (
    <div
      //id="vectorMap"
      ref={olMapRef}
      style={{
        height: "100%",
        width: "100%",
        opacity: 1,
      }}
    />
  );
}
