import { api } from "@vuumly-common/common";
import { ChartData, ChartOptions, TooltipItem } from "chart.js";
import Color from "color";
import { useOperatorMode, useQuery, useTheme, useTranslate } from "hooks";
import _ from "lodash";
import { useMemo } from "react";
import { CardProps } from "../..";
import { BaseBarChart } from "../../../Charts";
import { TextH5 } from "../../../Typography/Text";
import { BaseCard } from "../../Base.card";

export const RidesDayOfWeekBarChart = (props: CardProps) => {
  const translate = useTranslate();
  const theme = useTheme();
  const { operatorMode } = useOperatorMode();

  const { data, isLoading } = useQuery<ChartData<"bar", Record<any, number>[]>>(
    {
      apiEndpoint: api.Endpoint.RidesPerDayOfWeek,
    }
  );

  const clonedData = useMemo(() => {
    if (isLoading || !data?.datasets) {
      return;
    }
    const clonedData = _.cloneDeep(data);

    clonedData.labels = clonedData.datasets
      .flatMap((obj) =>
        obj.data
          .filter((item: any) => "dimension" in item)
          .map((item: any) => item.dimension)
      )
      .map((item: string) => translate("day_of_week_" + item));

    clonedData.labels = _.uniq(clonedData.labels);

    clonedData?.datasets.forEach((dataset) => {
      const { label } = dataset;

      if (label === "ride_percentage") {
        if (dataset.data.length) {
          dataset.backgroundColor = dataset.data.map(() => {
            return Color(
              !operatorMode ? theme.accentColor : theme.otherOperatorBarColor
            ).string();
          });

          dataset.hoverBackgroundColor = dataset.data.map(() => {
            return Color(
              !operatorMode
                ? theme.accentColorHover
                : theme.hoverOtherOperatorBarColor
            ).string();
          });
        }

        dataset.label = translate("Overall ride distribution");
        dataset.barPercentage = 0.999;
      } else {
        dataset.hidden = true;
        dataset.label = "none";
      }
    });
    return clonedData;
  }, [data, operatorMode]);

  const options: ChartOptions<"bar"> = {
    parsing: {
      yAxisKey: "ride_percentage",
      xAxisKey: "dimension",
    },

    scales: {
      y: {
        grace: "50%",
        title: {
          display: true,
          text: "Ride distribution",
        },
        border: {
          display: false,
        },
        ticks: {
          callback: (value: any) => {
            if (value > 0) {
              return `+${value}%`;
            }
            return `${value}%`;
          },
          display: false,
        },
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Day of week",
        },
      },
    },

    plugins: {
      legend: {
        labels: {
          filter(item) {
            return item.text !== "none";
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (item: TooltipItem<"bar">) => {
            return `${item.dataset.label}: ${item.formattedValue}%`;
          },
        },
      },
      datalabels: {
        display: true,
        formatter: (item: Record<string, any>) => item.ride_percentage + "%",
      },
    },
  };

  return (
    <BaseCard
      isLoading={isLoading}
      header={
        <TextH5>{translate("Overall ride distribution by day of week")}</TextH5>
      }
    >
      <BaseBarChart options={options} data={clonedData} />
    </BaseCard>
  );
};
