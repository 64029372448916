import { SliderProps } from "@radix-ui/react-slider";
import { Slider } from "components/shadcn/components/ui/slider";
import { forwardRef } from "react";

export const BaseSlider = forwardRef(function RefSlider(
  props: React.DOMAttributes<HTMLSpanElement> & SliderProps,
  ref
) {
  return <Slider {...props} />;
});
