import { useEffect, useRef } from "react";
import { HoveredFeature } from "../../../context";
import { useMapContext } from "../../../context/MapContext";
import { ol } from "../../../utils";
import {
  DeviceStandstillLayer,
  PopularityGridFeature,
  RealtimeDeviceFeature,
  RealtimeDeviceLayer,
  RouteHeatmapLayer,
  SuburbFeature,
  SuburbFeatureLayer
} from "../Layers";
import useMap from "./useMapMoving";

// const hoverStyle =  new ol.Style({
//   fill: new ol.Fill({ color: "rgba(0, 0, 255, 0.1)" }),
//   stroke: new ol.Stroke({ color: "white", width: 2 }),
//   zIndex: 5
// })

// function debounce(func: Function, delay: number) {
//   let timerId: any;

//   return function (...args: any) {
//     if (timerId) {
//       clearTimeout(timerId);
//     }

//     timerId = setTimeout(() => {
//       func(...args);
//     }, delay);
//   };
// }

// function createDebouncedChecker() {
//   let lastCallTimestamp = 0;

//   return function () {
//     const currentTimestamp = Date.now();

//     if (currentTimestamp - lastCallTimestamp >= 60) {
//       lastCallTimestamp = currentTimestamp;
//       return true;
//     }

//     lastCallTimestamp = currentTimestamp;
//     return false;
//   };
// }

// Create a debounced checker function
//const isIdle = createDebouncedChecker();

const areaHoverStyle = new ol.Style({
  fill: new ol.Fill({ color: "rgba(1, 106, 118, 0.35)" }),
  stroke: new ol.Stroke({ color: "rgba(1, 106, 118, 1)", width: 2 }),
  zIndex: 5,
});

const realtimeDeviceStyle = new ol.Style({
  image: new ol.Circle({
    radius: 12,
    fill: new ol.Fill({
      color: `rgba(255, 0, 255, 1)`,
    }),
  }),
});

interface Props {
  onClick?: (item: HoveredFeature | null) => void;
}

export function useMapHover(props?: Props) {
  const {
    mapInstance: map,
    setHoveredFeatureOnMap,
    hoveredFeatureOnMap,
  } = useMapContext();
  const { isMoving, isMouseDown } = useMap();
  const selectRef = useRef<ol.Select>();
  const selectedFeatureRef = useRef<HoveredFeature | null>(null);

  const lastEventRef = useRef<ol.MapBrowserEvent<any>>();

  useEffect(() => {
    if (!selectRef.current) return;
    if (isMoving || isMouseDown) {
      // selectRef.current.getFeatures().clear();
      // setHoveredFeatureOnMap(null);
      // selectRef.current.setActive(false);
    } else {
      // selectRef.current.setActive(true);

      // selectRef.current.changed();

      setTimeout(() => {
        //console.log("dispatch")

        if (map && lastEventRef.current) {
          //console.log("lastEvent", lastEventRef.current.type, lastEventRef.current.pixel)
          // var rect = map.getViewport().getBoundingClientRect();
          // let event = new PointerEvent('pointermove', {

          // });
          //map.getViewport().dispatchEvent(event);
          // var customEvent = new ol.MapBrowserEvent('pointermove', map, event, undefined, undefined);

          //map?.dispatchEvent(new BaseEvent('pointermove'))
          // selectRef.current?.dispatchEvent("select")
          lastEventRef.current.type = "pointermove_last";
          try {
            selectRef.current?.handleEvent(lastEventRef.current);
          } catch (e) {
            console.log("handleEvent Error", e);
          }
        }

        if (map) {
          let event = new PointerEvent("pointermove", {});

          var customEvent = new ol.MapBrowserEvent(
            "pointermove",
            map,
            event,
            undefined,
            undefined
          );
          // selectRef.current?.handleEvent(customEvent)
        }
      }, 30);
    }
  }, [isMoving, isMouseDown]);

  useEffect(() => {
    if (!map) return;

    // const debounceFn = debounce(() => {
    //   console.log("debouncing");
    //   if (selectRef.current && lastEventRef.current) {
    //     lastEventRef.current.type = "pointermove_debounce";
    //     selectRef.current.handleEvent(lastEventRef.current);
    //   }
    // }, 0);

    const translate = new ol.Translate({
      layers: () => {
        console.log("translate");
        return false;
      },
    });

    selectRef.current = new ol.Select({
      // addCondition: () => {
      //   console.log("addCondition")
      //   return false;
      // },
      //condition: ol.pointerMove,
      hitTolerance: 0,

      condition: (event) => {
        lastEventRef.current = event;
        if (event.type === "pointermove" || event.type === "pointermove_last") {
          return true;
        }
        return false;
        //console.log(isIdle())

        lastEventRef.current = event;
        if (event.type === "pointermove_last") {
          return true;
        }

        if (event.type === "pointermove_debounce") {
          return true;
        }
        // console.log("condition", event.pixel)
        // if (event.dragging || event.frameState?.animate) {
        //   console.log("condition", event.dragging , event.frameState?.animate)
        //   return false
        // }
        //return false
        // if (!isIdle()) {
        //   console.log("setting debounce");
        //   debounceFn();
        //   return false;
        // } else {
        //   return true;
        // }
        // debounceFn();
        return false;
        //return isIdle();
      },
      multi: true,
      layers(layer) {
        //return false;

        if (layer instanceof RouteHeatmapLayer) {
          return true;
        }
        if (layer instanceof RealtimeDeviceLayer) {
          return true;
        }
        if (layer instanceof DeviceStandstillLayer) {
          return false;
        }

        if (layer instanceof SuburbFeatureLayer) {
          return true;
        }

        return false;
        console.log("layer");
        // const zoom = map.getView().getZoom();
        if (
          layer instanceof RealtimeDeviceLayer
          // &&
          // zoom &&
          // zoom < realtimeCarsharingDevices.zoomSwitch
        ) {
          return false;
        }

        return true;
      },
      //style: false,
      style: (feature) => {
        if (feature instanceof SuburbFeature) {
          return areaHoverStyle;
        }

        if (feature instanceof RealtimeDeviceFeature) {
          return realtimeDeviceStyle;
        }

        if (feature instanceof PopularityGridFeature) {
          return areaHoverStyle;
        }

        // return undefined;
      },
    });

    const change = (e: ol.SelectEvent) => {
      const select = selectRef.current;
      if (!select) return;

      const feature = e.selected[0];
      const activeCount = select.getFeatures().getLength();

      const activeFeatures = select
        .getFeatures()
        .getArray()
        .filter((f) => f instanceof SuburbFeature);

      if (activeFeatures.length > 1) {
        select.getFeatures().clear();
        select.getFeatures().push(activeFeatures[0]);
        const layer = select.getLayer(activeFeatures[0]);

        selectedFeatureRef.current = { feature: activeFeatures[0], layer };
        setHoveredFeatureOnMap({ feature: activeFeatures[0], layer });
      } else {
        if (activeCount === 0) {
          selectedFeatureRef.current = null;
          setHoveredFeatureOnMap(null);
        }

        if (feature) {
          const layer = select.getLayer(feature);
          selectedFeatureRef.current = { feature, layer };
          setHoveredFeatureOnMap({ feature, layer });
        } else {
          /**
           * if there was no feature selected, but there was a feature deselected
           * then we need to set the hovered feature to the last selected feature
           */
          if (activeCount > 0) {
            const length = select.getFeatures().getLength();
            const prevFuture = select.getFeatures().getArray()[length - 1];
            const layer = select.getLayer(prevFuture);
            selectedFeatureRef.current = { feature: prevFuture, layer };
            setHoveredFeatureOnMap({ layer, feature: prevFuture });
          }
        }
      }
    };

    selectRef.current.on("select", change);

    map.addInteraction(selectRef.current);

    function handleClick(e: ol.MapBrowserEvent<any>) {
      if (props?.onClick) {
        props.onClick(selectedFeatureRef.current);
      }
    }

    map.on("click", handleClick);
    // map.addInteraction(translate);

    return () => {
      if (!selectRef.current) return;
      map.removeInteraction(selectRef.current);
      map.removeInteraction(translate);
      map.un("click", handleClick);
      selectRef.current.un("select", change);
    };
  }, [map]);

  return {
    selectedFeature: selectedFeatureRef.current,
  };

  // useEffect(() => {
  //   if (!map) return;
  //  // const map = map?.current;

  //   map.on("pointermove", function (e) {
  //     let pixel = false;
  //     // if (selected !== null) {
  //     //   selected.setStyle(undefined);
  //     //   selected = null;
  //     // }

  //     map.forEachFeatureAtPixel(
  //       e.pixel,
  //       function (feature, layer) {
  //         console.log("feature hovered")
  //         pixel = true;
  //         return true;
  //       }
  //     );

  //     if (pixel) return;

  //     console.log("nothing hovered")
  //   });
  // }, [map])
}
