import { DeviceGroupEnum } from "@vuumly-common/common";
import {
  useDeviceGroup,
  useDeviceType,
  useRouter,
  useTranslate
} from "hooks";
import { toast } from "sonner";
import { RoutePathEnum } from "../../controllers/router";
import { useDemoMode } from "../../hooks/useDemoMode";
import { SelectProps } from "../Select";
import { BaseTabs, IBaseTab } from "./BaseTabs";

export function DeviceTypeGroupTabs(props: SelectProps) {
  const { isDemoModeEnabled } = useDemoMode();
  const translate = useTranslate();
  const { goToExternal } = useRouter();

  const { selectedDeviceGroup, setSelectedDeviceGroup } = useDeviceGroup();

  const { isCarsharingSubscribed, isMicromobilitySubscribed } = useDeviceType();

  function handleSelect(val: string) {
    setSelectedDeviceGroup(val as DeviceGroupEnum, {
      navigateOptions: props.navigateOptions,
    });
  }

  const items: IBaseTab[] = [
    {
      value: DeviceGroupEnum.Micromobility,
      label: translate(DeviceGroupEnum.Micromobility),
      disabled: isDemoModeEnabled ? false : !isMicromobilitySubscribed,
    },
    {
      value: DeviceGroupEnum.Carsharing,
      label: translate(DeviceGroupEnum.Carsharing),
      disabled: isDemoModeEnabled ? false : !isCarsharingSubscribed,
    },
  ];

  function showNotification(item: IBaseTab) {
    if (item.disabled) {
      toast(translate("not_subscribed_to_device_type"), {
        action: {
          label: translate("contact_us"),
          onClick: () => goToExternal(RoutePathEnum.Contacts),
        },
      });
    }
  }

  return (
    <>
      <BaseTabs
        onChange={handleSelect}
        onClick={showNotification}
        items={items}
        defaultValue={selectedDeviceGroup}
      />
    </>
  );
}
