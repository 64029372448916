import { BaseButton, ButtonLink } from "components/Button";
import { TextH2 } from "components/Typography/Text";
import { RoutePathEnum } from "controllers/router";
import { usePageTitle, useRouter, useTranslate } from "hooks";
import { AuthWrapper } from "./AuthWrapper";

export function CheckEmailPage() {
  const { goToExternal } = useRouter();
  const translate = useTranslate();
  const pageTitle = usePageTitle(RoutePathEnum.CheckEmail);
  return (
    <AuthWrapper hideTerms>
      <div className="flex flex-col space-y-2 text-center">
        <TextH2>{pageTitle}</TextH2>
        <ButtonLink link={RoutePathEnum.SignIn}>
          {translate("signin_check_email_didnt_receive_email")}
        </ButtonLink>
        <BaseButton
          variant="link"
          onClick={() => goToExternal(RoutePathEnum.Contacts)}
        >
          {translate("signin_check_email_contact_support")}
        </BaseButton>
      </div>
    </AuthWrapper>
  );
}
