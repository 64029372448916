import { api } from "@vuumly-common/common";
import { ChartData, ChartOptions, TooltipItem } from "chart.js";
import Color from "color";
import { useQuery, useTheme, useTranslate } from "hooks";
import _ from "lodash";
import { useMemo } from "react";
import { BaseBarChart } from "../../Charts";
import { TextH5 } from "../../Typography/Text";
import { BaseCard } from "../Base.card";

export const UtilizationHourOfDayBarChartCard = () => {
  const theme = useTheme();
  const translate = useTranslate();

  const { data, isLoading } = useQuery<
    ChartData<"bar", { utilization_rate: number }[]>
  >({
    apiEndpoint: api.Endpoint.UtilizationHourOfDay,
  });

  const clonedData = useMemo(() => {
    if (isLoading || !data?.datasets) {
      return;
    }
    const clonedData = _.cloneDeep(data);

    if (clonedData) {
      clonedData.labels = [];
    }

    clonedData?.datasets.forEach((dataset) => {
      const { label } = dataset;

      if (label === "utilization_rate") {
        dataset.backgroundColor = dataset.data.map(() => {
          return Color(theme.accentColor).string();
        });

        dataset.hoverBackgroundColor = dataset.data.map(() => {
          return Color(theme.accentColorHover).string();
        });

        dataset.label = translate("Overall utilization rate");
        dataset.barPercentage = 0.999;
      } else {
        dataset.hidden = true;
        dataset.label = "none";
      }
    });

    return clonedData;
  }, [data]);

  const options: ChartOptions<"bar"> = {
    parsing: {
      xAxisKey: "hour_of_day",
      yAxisKey: "utilization_rate",
    },
    scales: {
      x: {
        title: {
          text: "Hour of day",
          display: true,
        },
        type: "time",
        time: {
          tooltipFormat: "HH:00",
          parser: "HH",
          unit: "hour",
          displayFormats: {
            hour: "HH",
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          text: "Utilization rate",
        },
        ticks: {
          display: false,
          callback: (value) => {
            return `${value}%`;
          },
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },

    plugins: {
      tooltip: {
        callbacks: {
          label: (item: TooltipItem<"bar">) => {
            return `${item.dataset.label}: ${item.formattedValue}%`;
          },
        },
      },
      datalabels: {
        display: true,
        formatter: (item: Record<string, any>) => {
          return item.utilization_rate.toFixed(1) + "%";
        },
      },
    },
  };

  return (
    <BaseCard
      isLoading={isLoading}
      header={<TextH5>{translate("Utilization by hour of day")}</TextH5>}
    >
      <div>
        <BaseBarChart options={options} data={clonedData} />
      </div>
    </BaseCard>
  );
};
