import { Document, Image, Page, StyleSheet, Text } from "@react-pdf/renderer";
import { DateRange } from "react-day-picker";
import { ExportMetadataProps } from "../../controllers/export/generateFileName";
import { ChartData } from "../../hooks";
import { PdfTable } from "../Tables/PdfTable";

export interface Props {
  img: string;
  data?: ChartData;
  wrap?: boolean;
  metadata?: ExportMetadataProps;
  dateRange: DateRange;
  translate: (value: string, params?: Record<string, string>) => string;
}

export const ExportTemplatePDF = (props: Props) => {
  // const state = useGlobalReducerState();
  const { img, data, wrap, metadata, translate, dateRange } = props;
  //const translate = useTranslate();

  return (
    <Document>
      <Page style={styles.body} orientation="landscape">
        <Text style={styles.website}>kenflow.io</Text>

        {metadata?.title && (
          <Text style={styles.title}>{translate(metadata?.title)}</Text>
        )}
        <Text style={styles.dateRange}>
          Date range: {dateRange?.from?.toLocaleDateString()} -{" "}
          {dateRange?.to?.toLocaleDateString()}
        </Text>
        <Image style={styles.image} src={img} />
        <PdfTable data={data} wrap={wrap} />
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    marginBottom: 4,
  },
  dateRange: {
    fontSize: 12,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  website: {
    fontSize: 12,
    marginBottom: 4,
    opacity: 0.5,
  },
  text: {
    marginVertical: 12,
    fontSize: 14,
    textAlign: "left",
    fontFamily: "sans-serif",
  },
  image: {
    marginVertical: 24,
    marginHorizontal: 0,
  },
});
