import { api, time } from "@vuumly-common/common";
import { ChartData, ChartOptions, TooltipItem } from "chart.js";
import {
  useQuery,
  useTheme,
  useTimeGranularity,
  useTranslate,
  useUserContext
} from "hooks";
import _ from "lodash";
import { RoutePathEnum } from "../../../controllers/router";
import { BaseLineChart } from "../../Charts";
import { TimeGranularityTabs } from "../../Tabs/TimeGranularityTabs";
import { TextH5 } from "../../Typography/Text";
import { BaseCard } from "../Base.card";
import { CardProps } from "../index";

export const UtilizationTimeseriesCompaniesLineChartCard = (
  props: CardProps
) => {
  const { disableLink = true } = props;
  const { getCompanyColors } = useTheme();
  const { tabs, timeGranularity, changeGranularity, modifiedQueryParams } =
    useTimeGranularity();

  const { operator_id } = useUserContext().userState;

  const translate = useTranslate();

  const { data, isLoading } = useQuery({
    apiEndpoint: api.Endpoint.UtilizationTimeseriesCompanies,
    params: modifiedQueryParams,
  });

  const clonedData = _.cloneDeep(data) as ChartData<"line">;

  clonedData?.datasets.forEach((dataset) => {
    if (dataset.dimensions?.company_id === operator_id) {
      dataset.order = 0;
    }
    dataset.label = `Company ${dataset.dimensions?.company_id}`;
    const colors = getCompanyColors(dataset.dimensions?.company_id);
    dataset.backgroundColor = colors.color;
    dataset.borderColor = colors.color;
  });

  const options: ChartOptions<"line"> = {
    parsing: {
      xAxisKey: "local_time",
      yAxisKey: "utilization_rate",
    },

    scales: {
      x: {
        type: "timeseries",
      },
      y: {
        title: {
          text: "Utilization rate",
        },
        ticks: {
          callback: (value) => {
            return `${value}%`;
          },
        },
      },
    },

    plugins: {
      tooltip: {
      //  axis: "x",	
        //includeInvisible: false,
    // mode: "nearest",
        callbacks: {
          label: (item: TooltipItem<"line">) => {
            const raw = item.raw as Record<string, any>;
            return `Company ${item.dataset.dimensions?.company_id}: ${raw.utilization_rate}%`;
          },
        },
      },
    },
  };

  const Header = () => {
    return (
      <div className="flex justify-between w-full">
        <TextH5>{translate("Utilization over time by company")}</TextH5>
        <TimeGranularityTabs
          tabs={tabs}
          defaultValue={timeGranularity || time.GranularityEnum.Day}
          onChange={changeGranularity}
        />
      </div>
    );
  };

  return (
    <BaseCard
      isLoading={isLoading}
      header={<Header />}
      href={disableLink ? undefined : RoutePathEnum.Rides}
    >
      <BaseLineChart options={options} data={clonedData} />
    </BaseCard>
  );
};
