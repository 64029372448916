import { useRef, useEffect, useMemo } from "react";
import OlMap from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import { fromLonLat } from "ol/proj";
import "ol/ol.css";
import XYZ from "ol/source/XYZ";
import { useMapContext } from "context/MapContext";
import { getMapStyleByValue } from "../../pages/Distribution/MapStyles";
import { useMapHover } from "../../pages/Distribution/hooks/useMapHover";
import BaseVirtualTooltip from "../Tooltip/BaseVirtualTooltip";
import { LoadingIndicator } from "../Progress";
import { BaseButton } from "../Button";
import { useCity, useRouter, useTranslate } from "../../hooks";
import { RoutePathEnum } from "../../controllers/router";
import MouseWheelZoom from "ol/interaction/MouseWheelZoom";
import { getCityCenter } from "@vuumly-common/common";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  tooltip?: React.ReactNode;
  isLoading?: boolean;
  showExpandButton?: boolean;
  center?: [number, number];
  isTooltipVisible?: boolean;
}

const ExpandMapOverlay = () => {
  const translate = useTranslate();
  const { goTo } = useRouter();
  return (
    <div className="absolute w-full h-full">
      <BaseButton
        variant={"outline"}
        className="absolute right-4 top-4 z-10"
        onClick={() => {
          goTo(RoutePathEnum.Heatmap);
        }}
      >
        {translate("expand_map")}
      </BaseButton>
    </div>
  );
};

export function BaseMap(props: Props) {
  const { tooltip, isLoading, showExpandButton, isTooltipVisible } = props;
  const { selectedCities } = useCity();
  const olMapRef = useRef<HTMLDivElement>(null);
  const {
    setMapInstance,
    mapStyle,
    lowDetailMapStyle,
    setCurrentZoom,
    hoveredFeatureOnMap,
  } = useMapContext();
  let map: OlMap | null = null;

  // useMapHover();

  const xyzLayer = useMemo(
    () =>
      new XYZ({
        tileSize: 512,
        //tilePixelRatio: 2,
        // default map style might be stored in localStorage
        url: getMapStyleByValue(mapStyle)?.url,
        attributions:
          '© <a href="https://www.mapbox.com/map-feedback/">Mapbox</a>',
      }),
    []
  );

  useEffect(() => {
    console.log("map mounted");
    return () => {
      console.log("map unmounted");
    };
  }, []);

  useEffect(() => {
    if (!olMapRef.current) return;

    map = new OlMap({
      target: olMapRef.current,
      layers: [new TileLayer({ source: xyzLayer })],
      view: new View({
        center: fromLonLat(
          props.center ||
            (getCityCenter(selectedCities[0]) as number[] | undefined) || [
              24.1038478, 56.9470011,
            ]
        ),
        zoom: 12,
      }),
    });

    (window as any).mapRef = map;

    setMapInstance(map);

    // hold ctrl to scroll

    // const defaultZoom = map
    //   .getInteractions()
    //   .getArray()
    //   .find((interaction) => interaction instanceof MouseWheelZoom);

    // if (defaultZoom) {
    //   map.removeInteraction(defaultZoom);
    // }

    // const mouseWheelZoom = new MouseWheelZoom({
    //   condition: (event) => {
    //     event.preventDefault();
    //     //return true;
    //     // Allow zoom only when the Ctrl key is pressed
    //     return event.originalEvent.ctrlKey;
    //   },
    // });

    // map.addInteraction(mouseWheelZoom);
    // mouseWheelZoom.changed();

    // map.on("moveend", () => {
    //   const zoom = map?.getView().getZoom();
    //   if (zoom) {
    //     setCurrentZoom(zoom);
    //   }
    // });

    return () => {
      if (!map) return;
      // map.removeInteraction(mouseWheelZoom);
      map.setTarget(undefined);
    };
  }, []);

  useEffect(() => {
    const style = getMapStyleByValue(mapStyle);
    xyzLayer.setUrls([lowDetailMapStyle ? style?.lowDetailurl : style?.url]);
    xyzLayer.changed();
  }, [mapStyle, lowDetailMapStyle]);

  return (
    <BaseVirtualTooltip
      tooltip={tooltip}
      isVisible={isTooltipVisible ? true : false}
    >
      {isLoading && (
        <div className="absolute top-1/2 left-1/2 z-1 -translate-x-1/2 -translate-y-1/2 z-10">
          <LoadingIndicator size={48} />
        </div>
      )}
      {!isLoading && showExpandButton && <ExpandMapOverlay />}
      <div
        id="map"
        ref={olMapRef}
        style={{
          height: "100%",
          width: "100%",
          opacity: isLoading ? 0.3 : 1,
        }}
      />
    </BaseVirtualTooltip>
  );
}
