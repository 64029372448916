import { DeviceEnum, DeviceGroupEnum, location } from "@vuumly-common/common";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { BaseOption } from "../components/Select/BaseSelector";
import { useCity, useCountry, useDeviceGroup, useUserContext } from "./index";

export const useDeviceType = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { selectedDeviceGroup } = useDeviceGroup();
  const { selectedCountryValue } = useCountry();
  const { selectedCities } = useCity();
  const { subscribedProducts: subscriptions } = useUserContext();

  const selectedDeviceTypes = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.getAll("device_types[]") as DeviceEnum[];
  }, [searchParams]);

  const setSelectedDeviceType = (payload: DeviceEnum[]) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("device_types[]");
    payload.forEach((item) => {
      searchParams.append("device_types[]", item);
    });

    setSearchParams(searchParams);
  };

  const removeSelectedDeviceType = (payload: DeviceEnum) => {
    const searchParams = new URLSearchParams(window.location.search);
    const deviceTypeParam = searchParams.getAll(
      "device_types[]"
    ) as DeviceEnum[];

    if (deviceTypeParam.includes(payload)) {
      searchParams.delete("device_types[]");
      deviceTypeParam.forEach((item) => {
        if (item !== payload) {
          searchParams.append("device_types[]", item);
        }
      });

      setSearchParams(searchParams);
    }
  };

  const isCarsharingSubscribed = useMemo(() => {
    if (!selectedCountryValue) {
      return false;
    }

    if (selectedCities.length > 0) {
      // Check if the user is subscribed to carsharing in the selected cities
      return subscriptions.some(
        (item) =>
          item.metadata.device_group === DeviceGroupEnum.Carsharing &&
          selectedCities.includes(item.metadata.city)
      );
    } else {
      // Check if the user is subscribed to carsharing in the available cities (country level)
      const availableCities = location.cities
        .getCitiesByCountry(selectedCountryValue)
        .map((city) => city.value);
      return subscriptions.some(
        (item) =>
          item.metadata.device_group === DeviceGroupEnum.Carsharing &&
          availableCities.includes(item.metadata.city)
      );
    }
  }, [subscriptions, selectedCities]);

  const isMicromobilitySubscribed = useMemo(() => {
    if (!selectedCountryValue) {
      return false;
    }

    if (selectedCities.length > 0) {
      // Check if the user is subscribed to carsharing in the selected cities
      return subscriptions.some(
        (item) =>
          item.metadata.device_group === DeviceGroupEnum.Micromobility &&
          selectedCities.includes(item.metadata.city)
      );
    } else {
      // Check if the user is subscribed to carsharing in the available cities (country level)
      const availableCities = location.cities
        .getCitiesByCountry(selectedCountryValue)
        .map((city) => city.value);
      return subscriptions.some(
        (item) =>
          item.metadata.device_group === DeviceGroupEnum.Micromobility &&
          availableCities.includes(item.metadata.city)
      );
    }
  }, [subscriptions, selectedCities]);

  const deviceTypeOptionList = useMemo(() => {
    let options =
      selectedDeviceGroup === DeviceGroupEnum.Micromobility
        ? deviceListMicromobilityDefault
        : deviceListCarsharingDefault;

    return options.map((item) => {
      return {
        ...item,
        selected: selectedDeviceTypes.includes(item.value),
      };
    });
  }, [selectedDeviceGroup]);

  const deviceTypeGroupOptionList = useMemo(() => {
    return deviceTypeGroupDefault.map((item) => {
      return {
        ...item,
        selected: selectedDeviceGroup?.includes(item.value),
      };
    });
  }, [selectedDeviceGroup]);

  const deviceTypes = useMemo(() => {
    return deviceTypeOptionList.map((item) => item.value);
  }, [deviceTypeOptionList]);

  const cityOptions = deviceTypes.map((device) => ({
    label: device,
    value: device,
    selected: selectedDeviceTypes.includes(device),
  }));

  const setDeviceTypeOptionList = (payload: BaseOption<DeviceEnum>[]) => {
    const selected = payload
      .filter((item) => item.selected)
      .map((item) => item.value);
    setSelectedDeviceType(selected);
  };

  const isCarsharingActive = selectedDeviceGroup === DeviceGroupEnum.Carsharing;
  const isMicromobilityActive =
    selectedDeviceGroup === DeviceGroupEnum.Micromobility;

  return {
    selectedDeviceTypes,
    deviceTypeOptionList,
    deviceTypeGroupOptionList,
    setDeviceTypeOptionList,
    setSelectedDeviceType,
    deviceTypes,
    cityOptions,
    isCarsharingActive,
    isMicromobilityActive,
    isCarsharingSubscribed,
    isMicromobilitySubscribed,
    removeSelectedDeviceType,
  };
};

const def = {
  selected: false,
  disabled: false,
  hasMatch: false,
  subscribed: false,
};

export const deviceTypeGroupDefault: BaseOption<DeviceGroupEnum>[] = [
  {
    label: DeviceGroupEnum.Micromobility,
    id: DeviceGroupEnum.Micromobility,
    value: DeviceGroupEnum.Micromobility,
    ...def,
  },
  {
    label: DeviceGroupEnum.Carsharing,
    id: DeviceGroupEnum.Carsharing,
    value: DeviceGroupEnum.Carsharing,
    ...def,
  },
];

export const deviceListCarsharingDefault: BaseOption<DeviceEnum>[] = [
  {
    label: DeviceEnum.Car,
    id: DeviceEnum.Car,
    value: DeviceEnum.Car,
    ...def,
  },
  {
    label: DeviceEnum.Cargo,
    id: DeviceEnum.Cargo,
    value: DeviceEnum.Cargo,
    ...def,
  },
];

export const deviceListMicromobilityDefault: BaseOption<DeviceEnum>[] = [
  {
    label: DeviceEnum.Scooter,
    id: DeviceEnum.Scooter,
    value: DeviceEnum.Scooter,
    ...def,
  },
  {
    label: DeviceEnum.Bike,
    id: DeviceEnum.Bike,
    value: DeviceEnum.Bike,
    ...def,
  },
  {
    label: DeviceEnum.EBike,
    id: DeviceEnum.EBike,
    value: DeviceEnum.EBike,
    ...def,
  },
  {
    label: DeviceEnum.Moped,
    id: DeviceEnum.Moped,
    value: DeviceEnum.Moped,
    ...def,
  },
];
