import classNames from "classnames";
import { BaseMap } from "components/Map";
import { useEffect } from "react";
import { HoveredFeature } from "../../context";
import { MapContextProvider, useMapContext } from "../../context/MapContext";
import { useSuburbPanelLayer } from "../../pages/Distribution/Layers/SuburbPanel";
import { useMapHover } from "../../pages/Distribution/hooks/useMapHover";
import { cn } from "../shadcn/lib/utils";

import { useCenterMap } from "../../hooks";

interface Props {
  onClick?: (item: HoveredFeature | null) => void;
  style?: React.CSSProperties;
  className?: string;
}

const Content = (props: Props) => {
  const { mapInstance, hoveredFeatureOnMap } = useMapContext();
  useMapHover({ onClick: handleClick });
  useCenterMap();

  const { layer, areaTooltip } = useSuburbPanelLayer();

  function handleClick(item: HoveredFeature | null) {
    props.onClick && props.onClick(item);
  }

  useEffect(() => {
    if (layer && mapInstance) {
      mapInstance?.addLayer(layer);
    }

    return () => {
      if (!layer) return;
      mapInstance?.removeLayer(layer);
    };
  }, [layer, mapInstance]);

  return (
    <BaseMap
      isTooltipVisible={!!hoveredFeatureOnMap}
      className={classNames({
        "flex flex-1 rounded-lg overflow-hidden": true,
        "cursor-pointer": hoveredFeatureOnMap,
      })}
      tooltip={areaTooltip}
    />
  );
};

export const SuburbMap = (props: Props) => {
  const { style, className } = props;
  return (
    <MapContextProvider>
      <div
        className={cn(["flex", className])}
        style={{ height: 500, ...style }}
      >
        <Content {...props} />
      </div>
    </MapContextProvider>
  );
};
