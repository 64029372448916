import { CityEnum } from "@vuumly-common/common";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { SetParamsProps } from "./index";

export const useCity = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedCities = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.getAll("cities[]") as CityEnum[];
  }, [searchParams]);

  const setSelectedCities = (payload: CityEnum[], options?: SetParamsProps) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("cities[]");
    searchParams.delete("origin_id");
    payload.forEach((item) => {
      searchParams.append("cities[]", item);
    });

    setSearchParams(searchParams, options?.navigateOptions);
  };

  const removeSelectedCity = (payload: CityEnum) => {
    const searchParams = new URLSearchParams(window.location.search);
    const cityParam = searchParams.getAll("cities[]") as CityEnum[];

    if (cityParam.includes(payload)) {
      searchParams.delete("cities[]");
      cityParam.forEach((item) => {
        if (item !== payload) {
          searchParams.append("cities[]", item);
        }
      });

      setSearchParams(searchParams);
    }
  };

  return { selectedCities, setSelectedCities, removeSelectedCity };
};
