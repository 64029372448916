import { api, time } from "@vuumly-common/common";
import { ChartData, ChartOptions, TooltipItem } from "chart.js";
import {
  useCountry,
  useQuery,
  useTheme,
  useTimeGranularity,
  useTranslate,
} from "hooks";
import _ from "lodash";
import { RoutePathEnum } from "../../../controllers/router";
import { BaseLineChart } from "../../Charts";
import { TimeGranularityTabs } from "../../Tabs/TimeGranularityTabs";
import { TextH5 } from "../../Typography/Text";
import { BaseCard } from "../Base.card";

interface Props {
  href?: RoutePathEnum;
  includeOriginId?: boolean;
  disableLink?: boolean;
  horizontal?: boolean;
  chartHeight?: number;
}

export const FleetSizeTimeseriesDeviceTypeCard = (props: Props) => {
  const { tabs, timeGranularity, changeGranularity, modifiedQueryParams } =
    useTimeGranularity();

  const translate = useTranslate();
  const { getDeviceTypeColors } = useTheme();
  const { selectedCountryName } = useCountry();

  const { disableLink = true } = props;

  const { data, isLoading } = useQuery({
    apiEndpoint: api.Endpoint.FleetSizeTimeseriesDeviceType,
    params: modifiedQueryParams,
    apiVersion: "v2",
  });

  const clonedData = _.cloneDeep(data) as ChartData<"line">;

  clonedData?.datasets.forEach((dataset) => {
    dataset.label = translate(dataset.dimensions?.device_type || "Device type");
    const colors = getDeviceTypeColors(dataset.dimensions?.device_type);
    dataset.backgroundColor = colors.color;
    dataset.borderColor = colors.color;
  });

  const options: ChartOptions<"line"> = {
    parsing: {
      xAxisKey: "date",
      yAxisKey: "device_count",
    },

    scales: {
      x: {
        type: "timeseries",
      },
      y: {
        title: {
          text: "Fleet size",
        },
      },
    },

    plugins: {
      tooltip: {
        itemSort: (a: TooltipItem<"line">, b: TooltipItem<"line">) => {
          return b.parsed.y - a.parsed.y;
        },
        callbacks: {
          label: (item: TooltipItem<"line">) => {
            return `${item.dataset.label}: ${item.formattedValue}`;
          },
        },
      },
    },
  };

  const Header = () => {
    return (
      <div className="flex justify-between w-full">
        <TextH5>
          {translate(`Fleet size over time (${selectedCountryName})`)}
        </TextH5>
        <TimeGranularityTabs
          tabs={tabs}
          defaultValue={timeGranularity || time.GranularityEnum.Day}
          onChange={changeGranularity}
        />
      </div>
    );
  };

  return (
    <BaseCard
      isLoading={isLoading}
      header={<Header />}
      href={disableLink ? undefined : RoutePathEnum.Rides}
    >
      <BaseLineChart options={options} data={clonedData} />
    </BaseCard>
  );
};
