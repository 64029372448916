import { BaseButton } from "components/Button";
import { Logo } from "components/Image/Logo";
import { MainNavigation } from "components/Navigation/MainNavigation";
import { UserNavigation } from "components/Navigation/UserNavigation";
import { RoutePathEnum } from "controllers/router";
import { memo } from "react";
import { Link } from "react-router-dom";
import { useUserContext } from "hooks";

const SignInNavigation = memo(() => {
  return (
    <div className="space-x-2">
      <Link to={RoutePathEnum.SignIn}>
        <BaseButton>Sign In</BaseButton>
      </Link>
    </div>
  );
});

const LogoMemo = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return (
    <Link to={`${RoutePathEnum.Rides}?${searchParams}`}>
      <Logo />
    </Link>
  );
};

export const MainHeader = () => {
  const { isSigned } = useUserContext();

  return (
    <div id="main-menu" className="flex px-6 py-3 items-center w-full border-b">
      <div className="cursor-pointer mr-16">
        <LogoMemo />
      </div>
      <div className="flex-1">
        <MainNavigation />
      </div>
      {isSigned ? <UserNavigation /> : <SignInNavigation />}
    </div>
  );
};
