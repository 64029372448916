import { zodResolver } from "@hookform/resolvers/zod";
import { BaseButton } from "components/Button";
import { BaseInput } from "components/Input/BaseInput";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/shadcn/components/ui/form";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { RoutePathEnum } from "../../controllers/router";
import { useRouter, useTranslate } from "../../hooks";
import { useAuth } from "../../hooks/useAuth.hook";

export function SignInForm() {
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const translate = useTranslate();
  const { signInWithEmail } = useAuth();
  const { goTo } = useRouter();

  const formSchema = z
    .object({
      email: z.string().email(),
      password: z.string().optional(), // Make password optional initially
    })
    .refine(
      (data) => {
        // Check if email ends with @vuumly.com
        if (data.email.endsWith("@vuumly.com")) {
          // If so, require password
          return !!data.password; // Ensure password is not empty
        }
        // If email does not end with @vuumly.com, validation passes regardless of password
        return true;
      },
      {
        message:
          "Password is required for email addresses ending with @vuumly.com",
        path: ["password"], // Indicate that this error message is specifically for the password field
      }
    );

  function onSuccess() {
    setLoading(false);
    goTo(RoutePathEnum.CheckEmail);
  }

  function onError(error: any) {
    setLoading(false);
    console.error(error);
  }

  function onSubmit(values: z.infer<typeof formSchema & {}>) {
    setLoading(true);

    if (values.email.includes("@vuumly.com")) {
      if (!values.password) {
        alert("Password is required");
        return;
      }
      const auth = getAuth();
      signInWithEmailAndPassword(auth, values.email, values.password).catch(
        (e) => {
          if (e.message.includes("invalid-login-credentials")) {
            alert("Invalid login credentials");
          } else if (e.message.includes("auth/too-many-requests")) {
            alert("Too many requests. Please try again later.");
          }
          setLoading(false);
          console.error(e);
        }
      );
    } else {
      signInWithEmail({ email: values.email, onSuccess, onError });
    }
  }

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  function emailChanged(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{translate("signin_email")}</FormLabel>
              <FormControl>
                <BaseInput
                  className="shadow-inner"
                  disabled={loading}
                  placeholder=""
                  {...field}
                  onChange={(e) => {
                    emailChanged(e);
                    field.onChange(e);
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {email.includes("@vuumly.com") && (
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <BaseInput type="password" placeholder="" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        <BaseButton isLoading={loading} className="w-full" type="submit">
          {translate("signin_button")}
        </BaseButton>
      </form>
    </Form>
  );
}
