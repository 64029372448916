import { DashboardHeader } from "../../components/Header";
import { DashboardContentWrapper } from "../../components/Wrapper";

import { MapContextProvider, useMapContext } from "../../context/MapContext";
import { BaseMap } from "components/Map";
import { useEffect, useRef, useState } from "react";
import { useMapHover } from "../Distribution/hooks/useMapHover";
import { BaseTooltip } from "../../components/Tooltip";
import { HoveredFeature } from "../../context";
import classNames from "classnames";
import { ol } from "../../utils";
import useMapLayer from "../Distribution/useMapLayer";
import { BaseSelect } from "../../components/Select";
import { RouteHeatmapLayer } from "../Distribution/Layers";
import { Deck, PathLayer } from "deck.gl";
import { Layer } from "ol/layer";
import { toLonLat } from "../../utils/ol";
import { MapSidebar } from "../../components/Sidebar";
import { PathStyleExtension } from "@deck.gl/extensions";
import { ScatterplotLayer } from "deck.gl";

interface Props {
  onClick?: (item: HoveredFeature | null) => void;
}

const DebugMap = (props: Props) => {
  const { mapInstance, hoveredFeatureOnMap } = useMapContext();
  useMapHover({ onClick: handleClick });

  function handleClick(item: HoveredFeature | null) {
    props.onClick && props.onClick(item);
  }

  function getTooltip() {
    //console.log(88, hoveredFeatureOnMap?.feature.getProperties())
    return (
      <BaseTooltip>
        {hoveredFeatureOnMap?.feature.getProperties().overlapCount}
      </BaseTooltip>
    );
  }

  return (
    <div>
      <div
        className={classNames({
          "h-full w-full flex-1 rounded-lg overflow-hidden": true,
          "cursor-pointer": hoveredFeatureOnMap,
        })}
      >
        <BaseMap
          center={[24.1128363, 56.9138477]}
          tooltip={getTooltip()}
          style={{ height: 800, width: `100%` }}
        />
      </div>
    </div>
  );
};

const TextArea = () => {
  const [routeData, setRouteData] = useState<any>();
  const [stopsData, setStopsData] = useState<any>();

  const { mapInstance, hoveredFeatureOnMap } = useMapContext();
  const [dataType, setDataType] = useState<
    "coordinates" | "feature_collection"
  >("coordinates");
  const { clearLayer, vectorSource, vectorLayer } = useMapLayer({
    layerInstance: RouteHeatmapLayer,
    zIndex: 3,
    // style: new ol.Style({
    //   stroke: new ol.Stroke({
    //     color: `red`,
    //     width: 6,
    //   }),
    // }),
  });

  useEffect(() => {
    fetch("/data/rigassatiksme.json")
      .then((response) => response.json())
      .then((rawData) => {
        setRouteData(
          rawData.features.filter((item: any) => item.properties.route_id)
        );
      });
  }, []);

  useEffect(() => {
    fetch("/data/rigassatiksme.json")
      .then((response) => response.json())
      .then((rawData) => {
        setStopsData(
          rawData.features.filter((item: any) => item.properties.stop_id)
        );
      });
  }, []);

  const deckRef = useRef<Deck | null>(null);
  const deckLayerRef = useRef<Layer | null>(null);

  useEffect(() => {
    if (!mapInstance || !routeData) return;

    const layer = new PathLayer({
      id: "PathLayer",
      data: routeData,
      getColor: (d: any) => {
        if (d.properties.route_id.includes("bus")) {
          return [255, 0, 0];
        } else if (d.properties.route_id.includes("trol")) {
          return [0, 0, 255];
        } else if (d.properties.route_id.includes("tram")) {
          return [0, 255, 0];
        }
        return [255, 255, 255];
      },
      getPath: (d: any) => {
        return d.geometry.coordinates;
      },
      getOffset: (d: any) => 0.1,
      getWidth: (d) => 3,
      //  extensions: [new PathStyleExtension({ dash: false, offset: true })],
      widthMinPixels: 3,
      widthScale: 2,
      opacity: 1,
      parameters: {
        depthMask: false,
        blend: false,
        // blendFunc: [GL.SRC_ALPHA, GL.ONE],
        // blendEquation: GL.FUNC_ADD
      },
      pickable: true,
    });

    const stopLayer = new ScatterplotLayer({
      id: "scatterplot-layer",
      data: stopsData,
      // pickable: true,
      opacity: 0.8,
      stroked: true,
      filled: true,
      radiusScale: 6,
      radiusMinPixels: 1,
      radiusMaxPixels: 10,
      lineWidthMinPixels: 1,
      getPosition: (d: any) => d.geometry.coordinates,
      // getRadius: (d: any) => Math.sqrt(d.exits),
      getRadius: (d: any) => 6,
      getFillColor: (d: any) => [255, 140, 0],
      getLineColor: (d: any) => [0, 0, 0],
    });

    const deckGL = new Deck({
      initialViewState: {
        longitude: 0,
        latitude: 0,
        zoom: 1,
      },
      // onViewStateChange: handleViewStateChange,
      controller: true,
      parent: document.getElementById("map") as HTMLElement,
      style: {
        mixBlendMode: "darken",
        top: 0,
        pointerEvents: "none",
        "z-index": 1,
      },
      layers: [layer, stopLayer],
    });

    deckRef.current = deckGL;

    const deckLayer = new Layer({
      render: function ({ size, viewState }): any {
        const [width, height] = size;
        const [longitude, latitude] = toLonLat(viewState.center);
        const zoom = viewState.zoom - 1;
        const bearing = (-viewState.rotation * 180) / Math.PI;
        const deckViewState = {
          bearing,
          longitude,
          latitude,
          zoom,
        };
        deckRef.current?.setProps({
          width,
          height,
          viewState: deckViewState,
        });
        deckRef.current?.redraw({});
      },
    });

    deckLayerRef.current = deckLayer;

    mapInstance?.addLayer(deckLayer);

    // mapInstance?.addLayer(deckLayer);

    return () => {
      // mapInstance?.removeLayer(deckLayer);
      deckRef.current = null;
      deckGL.finalize();
      if (!deckLayerRef.current) return;
      mapInstance?.removeLayer(deckLayer);
    };
  }, [mapInstance, routeData]);

  function handleChange(e: any) {
    // @ts-ignore
    // window.mapRef = mapInstance
    //console.log(e.target.value, mapInstance);
    clearLayer();

    try {
      if (dataType === "coordinates") {
        var lineString = new ol.LineString(JSON.parse(e.target.value));
        lineString.transform("EPSG:4326", "EPSG:3857");

        const lineFeature = new ol.Feature({
          geometry: lineString,
        });

        vectorSource.current?.addFeature(lineFeature);

        vectorSource.current?.addFeature(
          new ol.Feature({
            geometry: new ol.Point(ol.fromLonLat([24.1092773, 56.9132978])),
          })
        );
      } else if (dataType === "feature_collection") {
        const featureCollection = new ol.GeoJSON().readFeatures(
          JSON.parse(e.target.value),
          {
            // Assuming the source data is in 'EPSG:4326' and the map uses 'EPSG:3857'
            dataProjection: "EPSG:4326",
            featureProjection: "EPSG:3857",
          }
        );

        featureCollection.forEach((feature) => {
          console.log(feature.getProperties().overlapCount / 500);
          feature.setStyle(
            new ol.Style({
              stroke: new ol.Stroke({
                color: `rgba(255,0,0,  ${
                  feature.getProperties().overlapCount / 500
                })`,
                width: 6,
              }),
            })
          );
        });

        vectorSource.current?.addFeatures(featureCollection);
      }
    } catch (e) {
      console.log(e);
    }
  }

  function dataTypeChanged(value: any) {
    setDataType(value);
  }

  return (
    <div>
      <BaseSelect
        className="mb-4"
        onChange={dataTypeChanged}
        value={dataType}
        options={[
          { label: "Coordinates", value: "coordinates", id: "coordinates"},
          { label: "Feature collection", value: "feature_collection", id: "feature_collection"},
        ]}
      />
      <textarea
        onChange={handleChange}
        className="border w-full"
        name="postContent"
        rows={20}
      />
    </div>
  );
};

export function TransitPage() {
  return (
    <MapContextProvider>
      <DashboardContentWrapper className="mb-32">
        <DashboardHeader title="Public transit" />
        <div className="flex">
          <div className="w-full">
            <DebugMap />
          </div>
          <div className="hidden w-2/6">
            <MapSidebar className="relative right-0 top-0 w-full">
              <div className="p-4">
                <TextArea />
              </div>
            </MapSidebar>
          </div>
        </div>
      </DashboardContentWrapper>
    </MapContextProvider>
  );
}

type ViewState = {
  /** Longitude at map center */
  longitude: number;
  /** Latitude at map center */
  latitude: number;
  /** Map zoom level */
  zoom: number;
  /** Map rotation bearing in degrees counter-clockwise from north */
  bearing: number;
  /** Map angle in degrees at which the camera is looking at the ground */
  pitch: number;
  /** Dimensions in pixels applied on each side of the viewport for shifting the vanishing point. */
  padding: PaddingOptions;
};

type PaddingOptions = {
  top: number;
  bottom: number;
  left: number;
  right: number;
};
