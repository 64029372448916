import { Navigate, Outlet } from "react-router-dom";
import { RoutePathEnum } from "../../controllers/router";

interface Props {
  redirectPath?: string;
  children?: JSX.Element;
}

export function DebugRoute({
  redirectPath = RoutePathEnum.SignIn,
  children,
}: Props) {
  const isProtected = process.env.REACT_APP_ENV === "development";

  if (!isProtected) {
    return <Navigate to={redirectPath} replace />;
  }

  if (!children) {
    return <Outlet />;
  }

  return children;
}
