const mapboxAccessToken =
  "pk.eyJ1IjoidHVtc2FsdiIsImEiOiJjbGw3c2JvcHEwZjg3M2ttaTdvdXl3ZjN3In0.oVCOGRg9N3O5S5_Jew9xGA";

export type MapStyle = {
  url: string;
  lowDetailurl: string;
  value: string;
  label: string;
  id: string;
  default: boolean;
};

export const mapStyles: MapStyle[] = [
  {
    url: `https://api.mapbox.com/styles/v1/mapbox/streets-v12/tiles/512/{z}/{x}/{y}?access_token=${mapboxAccessToken}`,
    lowDetailurl: `https://api.mapbox.com/styles/v1/tumsalv/clo2zpxv700hd01pk34iugrtk/tiles/512/{z}/{x}/{y}?access_token=${mapboxAccessToken}`,
    value: "streets-v12",
    label: "Streets",
    id: "streets",
    default: false,
  },
  // {
  //   url: `https://api.mapbox.com/styles/v1/mapbox/light-v11/tiles/512/{z}/{x}/{y}?access_token=${mapboxAccessToken}`,
  //   lowDetailurl: `https://api.mapbox.com/styles/v1/tumsalv/clo62swip00qx01qm5wey4if0/tiles/512/{z}/{x}/{y}?access_token=${mapboxAccessToken}`,
  //   value: "navigation-night-v1",
  //   label: "Navigation Night",
  //   default: false,
  // },
  {
    url: `https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/512/{z}/{x}/{y}?access_token=${mapboxAccessToken}`,
    lowDetailurl: `https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/512/{z}/{x}/{y}?access_token=${mapboxAccessToken}`,
    value: "light-v10",
    label: "Light",
    id: "light",
    default: true,
  },
  {
    url: `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/tiles/512/{z}/{x}/{y}?access_token=${mapboxAccessToken}`,
    lowDetailurl: `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/tiles/512/{z}/{x}/{y}?access_token=${mapboxAccessToken}`,
    value: "satellite",
    label: "Satellite",
    id: "satellite",
    default: false,
  },
  // {
  //   url: `https://api.mapbox.com/styles/v1/mapbox/dark-v11/tiles/512/{z}/{x}/{y}?access_token=${mapboxAccessToken}`,
  //   lowDetailurl: `https://api.mapbox.com/styles/v1/mapbox/dark-v11/tiles/512/{z}/{x}/{y}?access_token=${mapboxAccessToken}`,
  //   value: "dark-v11",
  //   label: "Dark",
  //   default: false,
  // }
];

export const defaultMapStyle = mapStyles.find((style) => style.default)!;

export const getMapStyleByValue = (value: string) =>
  mapStyles.find((style) => style.value === value) || defaultMapStyle;
