import { BaseMap } from "components/Map";
import { MapContextProvider, useMapContext } from "context/MapContext";
import {
  useCenterMap,
  useFlowMapLayer,
  usePageTitle,
  useTranslate
} from "hooks";
import "ol/ol.css";
import { useEffect } from "react";
import { DashboardHeader } from "../../components/Header";
import { MapSidebar } from "../../components/Sidebar";
import { BaseTooltip } from "../../components/Tooltip";
import { TextBody, TextSmall } from "../../components/Typography/Text";
import { RoutePathEnum } from "../../controllers/router";

const Content = () => {
  useCenterMap();
  const { mapInstance } = useMapContext();
  const translate = useTranslate();
  const { layer, panel, tooltip, isLoading } = useFlowMapLayer();

  useEffect(() => {
    if (layer && mapInstance) {
      mapInstance?.addLayer(layer);

      // for some reason adding deckGL layer steals focus from map
      document.getElementById("map")?.focus();
    }

    return () => {
      if (layer) mapInstance?.removeLayer(layer);
    };
  }, [layer]);

  useEffect(() => {
    function setFocus() {
      document.getElementById("map")?.focus();
    }
    mapInstance?.on("pointermove", setFocus);

    return () => {
      mapInstance?.un("pointermove", setFocus);
    };
  });

  function getTooltip() {
    if (!tooltip) {
      return <></>;
    }

    if (tooltip.object?.type === "location") {
      return (
        <BaseTooltip>
          <div className="flex flex-col space-y-2">
            {/* <TextBody>{tooltip.object.name}</TextBody> */}
            <TextSmall>
              {translate("incomingCount")}:{" "}
              {tooltip.object.totals.incomingCount}
            </TextSmall>
            <TextSmall>
              {translate("outgoingCount")}:{" "}
              {tooltip.object.totals.outgoingCount}
            </TextSmall>
            <TextSmall>
              {translate("internalCount")}:{" "}
              {tooltip.object.totals.internalCount}
            </TextSmall>
          </div>
        </BaseTooltip>
      );
    }
    if (tooltip.object?.type === "flow") {
      return (
        <BaseTooltip>
          <div className="flex flex-col space-y-2">
            <TextBody>
              {translate("ride_count")}: {tooltip.object.count}
            </TextBody>
          </div>
        </BaseTooltip>
      );
    }

    return <></>;
  }

  const tooltipItem = getTooltip();

  return (
    <>
      {!isLoading && (
        <MapSidebar>
          <div>{panel}</div>
        </MapSidebar>
      )}
      <BaseMap
        isTooltipVisible={!!tooltipItem}
        isLoading={isLoading}
        tooltip={tooltipItem}
      />
    </>
  );
};

export function FlowMapPage() {
  const title = usePageTitle(RoutePathEnum.Flowmap);
  return (
    <>
      <div className="max-w-7xl mx-auto p-6 w-full">
        <DashboardHeader className="mb-0" title={title} />
      </div>
      <div className="flex flex-1 relative">
        <MapContextProvider>
          <Content />
        </MapContextProvider>
      </div>
    </>
  );
}
