import {
  api,
  time
} from "@vuumly-common/common";
import { ChartData, ChartOptions } from "chart.js";
import {
  useCountry,
  useQuery,
  useTheme,
  useTimeGranularity,
  useTranslate
} from "hooks";
import _ from "lodash";
import { CardProps } from "..";
import { RoutePathEnum } from "../../../controllers/router";
import { BaseLineChart } from "../../Charts";
import { TimeGranularityTabs } from "../../Tabs/TimeGranularityTabs";
import { TextH5 } from "../../Typography/Text";
import { BaseCard } from "../Base.card";

export const FleetSizeTimeseriesCompanyCard = (props: CardProps) => {
  const { tabs, timeGranularity, changeGranularity, modifiedQueryParams } =
    useTimeGranularity();

  const { getCompanyColors } = useTheme();
  const { selectedCountryName } = useCountry();

  const translate = useTranslate();
  const { disableLink = true } = props;

  const { data, isLoading } = useQuery({
    apiEndpoint: api.Endpoint.FleetSizeTimeseriesCompany,
    params: modifiedQueryParams,
    apiVersion: "v2",
  });

  const clonedData = _.cloneDeep(data) as ChartData<"line">;

  clonedData?.datasets.forEach((dataset) => {
    dataset.label = `Company ${dataset.dimensions?.company_id}`;
    const colors = getCompanyColors(dataset.dimensions?.company_id);
    dataset.backgroundColor = colors.color;
    dataset.borderColor = colors.color;
  });

  const options: ChartOptions<"line"> = {
    parsing: {
      xAxisKey: "date",
      yAxisKey: "device_count",
    },

    scales: {
      x: {
        type: "timeseries",
      },
      y: {
        title: {
          text: "Fleet size",
        },
      },
    },
  };

  const Header = () => {
    return (
      <div className="flex justify-between w-full">
        <TextH5>
          {translate(
            `Fleet size by company over time (${selectedCountryName})`
          )}
        </TextH5>
        <TimeGranularityTabs
          tabs={tabs}
          defaultValue={timeGranularity || time.GranularityEnum.Day}
          onChange={changeGranularity}
        />
      </div>
    );
  };

  return (
    <BaseCard
      isLoading={isLoading}
      header={<Header />}
      href={disableLink ? undefined : RoutePathEnum.Rides}
    >
      <div>
        <BaseLineChart options={options} data={clonedData} />
      </div>
    </BaseCard>
  );
};
