import { DeviceCategories, FilterSize } from "@vuumly-common/common";

const translations: Record<string, string> = {
  // device types
  car: "Car",
  scooter: "Scooter",
  bike: "Bike",
  e_bike: "eBike",
  moped: "Moped",
  cargo: "Cargo",
  bus: "Bus",
  trolleybus: "Trolleybus",
  tram: "Tram",

  combustion: "Combustion engine",
  electric: "Electric engine",

  // day of the week
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",

  minutes_short: "min.",

  car_rides: "Car Rides",
  "Ride count": "Ride count",

  riga: "Riga",
  vilnius: "Vilnius",
  tallinn: "Tallinn",
  warsaw: "Warsaw",

  none: "None",
  by_company: "By Company",
  by_device: "By Device",
  both: "Both",

  [DeviceCategories.Hatchbacks.tag]: "Hatchback",
  [DeviceCategories.Sedan.tag]: DeviceCategories.Sedan.name,
  [DeviceCategories.Suv.tag]: DeviceCategories.Suv.name,
  [DeviceCategories.Estates.tag]: DeviceCategories.Estates.name,
  [DeviceCategories.Coupe.tag]: DeviceCategories.Coupe.name,
  [DeviceCategories.Small.tag]: DeviceCategories.Small.name,
  [DeviceCategories.Crossover.tag]: DeviceCategories.Crossover.name,

  [FilterSize.Size_Small.tag]: FilterSize.Size_Small.name,
  [FilterSize.Size_Medium.tag]: FilterSize.Size_Medium.name,
  [FilterSize.Size_Large.tag]: FilterSize.Size_Large.name,

  micromobility: "Micromobility",
  carsharing: "Car Sharing",

  contact_us: "Contact us",

  // metric translations
  bike_ride_count_metric: "Bike",
  car_ride_count_metric: "Car",
  scooter_ride_count_metric: "Scooter",
  moped_ride_count_metric: "Moped",
  cargo_ride_count_metric: "Cargo",
  e_bike_ride_count_metric: "eBike",

  total_ride_count_metric: "Rides",
  date: "Date",

  day_of_week: "Day of week",
  day_of_week_1: "Monday",
  day_of_week_2: "Tuesday",
  day_of_week_3: "Wednesday",
  day_of_week_4: "Thursday",
  day_of_week_5: "Friday",
  day_of_week_6: "Saturday",
  day_of_week_7: "Sunday",
  rides_per_day_of_week: "Rides",

  time_of_day: "Time of day",
  rides_per_time_of_day: "Rides",

  morning: "Morning",
  afternoon: "Afternoon",
  evening: "Evening",
  night: "Night",

  morning_range: "06:00-12:00",
  afternoon_range: "12:00-18:00",
  evening_range: "18:00-22:00",
  night_range: "22:00-06:00",

  morning_metric: "Morning",
  afternoon_metric: "Afternoon",
  evening_metric: "Evening",
  night_metric: "Night",

  rides_total: "Total ride count",
  rides_avg_per_day: "Average rides per day",
  rides_length_average: "Average ride length",
  average_ride_length: "Average ride length",
  fleet_total: "Total fleet size",
  total: "Total",

  length_average: "Average ride length",
  length_median: "Median ride length",
  ride_count: "Ride count",
  ride_count_operator: "Your ride count",

  ride_origin: "Ride origin",
  rides_started_from_origin: "Rides",
  popularity_of_origins: "Popularity of origins",

  rides_origin_destination_card_ogirin_title:
    "Ride destinations from ${origin}",
  rides_origin_destination_card_title: "Popularity of ride destinations",
  ride_destination_origin_card_title: "Ride origins to ${destination}",

  start_suburb: "Starting location",
  ride_destination: "Ride destination",

  ride_length_between: "${above}-${below}",
  ride_length_above: "Above ${length}",
  ride_length_below: "Below ${length} min",

  ride_length_in_minutes: "Overall ride length in minutes",
  ride_length_operator_in_minutes: "Your ride length in minutes",

  area_popularity: "Area popularity",
  ride_count_scooter: "Scooter rides",
  ride_count_bike: "Bike rides",
  ride_count_car: "Car rides",
  ride_count_moped: "Moped rides",
  ride_count_cargo: "Cargo rides",
  ride_count_e_bike: "eBike rides",

  timegranularity_tab_day: "by Day",
  timegranularity_tab_week: "by Week",
  timegranularity_tab_month: "by Month",

  ride_history_card_title: "Ride history",

  // ride length page

  ride_length_page_title: "Ride length",
  ride_length_timeseries_card_title: "Rides by length over time",

  ride_count_under_30_min: "Ride count under 30 min",
  ride_count_under_60_min: "Ride count under 60 min",

  ride_count_under_10_min: "Ride count under 10 min",
  ride_count_under_15_min: "Ride count under 15 min",

  workdays: "Workdays",
  weekends: "Weekends",

  percentage_from_total_ride_count: "% from total ride count",

  ride_length_overtime_title: "Ride length over time",
  LengthGroupShareTimeseries_card_title:
    "Ride distribution by length over time",
  LengthGroupTotalCompare_card_title: "Ride distribution by length",
  LengthGroupTotalCard_title: "Rides by length",

  // menu
  length_median_menu_label: "Median ride length",

  // average ride length page
  length_median_page_title: "Median ride length",
  carsharing_rides: "Average ride length",
  carsharing_median: "Median ride length",
  micromobility_rides: "Average ride length",
  micromobility_median: "Median ride length",

  ride_length_avg_bike: "Bike average",
  ride_length_avg_car: "Car average",
  ride_length_avg_scooter: "Scooter average",
  ride_length_avg_moped: "Moped average",
  ride_length_avg_cargo: "Cargo average",
  ride_length_avg_e_bike: "eBike average",

  ride_length_median_bike: "Bike median",
  ride_length_median_car: "Car median",
  ride_length_median_scooter: "Scooter median",
  ride_length_median_moped: "Moped median",
  ride_length_median_cargo: "Cargo median",
  ride_length_median_e_bike: "eBike median",

  rides_per_time_of_day_card_title: "Rides per time of day",
  rides_per_day_of_week_card_title: "Ride distribution by day of week",

  length_average_time_of_day_card_title: "Average ride length by time of day",

  average_ride_length_by_day_of_week_card_title:
    "Average ride length by day of week",
  length_median_timesries_device_type_card_title:
    "Median ride length over time",

  average_ride_length_scooter: "Scooter",
  average_ride_length_bike: "Bike",
  average_ride_length_car: "Car",
  average_ride_length_moped: "Moped",
  average_ride_length_cargo: "Cargo",
  average_ride_length_e_bike: "eBike",

  "median_ride_length:car": "Car",
  "operator_metric:car": "Car (your)",
  "median_ride_length:scooter": "Scooter",
  "operator_metric:scooter": "Scooter (your)",
  "median_ride_length:bike": "Bike",
  "operator_metric:bike": "Bike (your)",
  "median_ride_length:moped": "Moped",
  "operator_metric:moped": "Moped (your)",
  "median_ride_length:cargo": "Cargo",
  "operator_metric:cargo": "Cargo (your)",
  "median_ride_length:e_bike": "eBike",
  "operator_metric:e_bike": "eBike (your)",

  length_median_length_origin_card_title: "Median ride length for origin",
  "length_median_length_origin_card_title&device_type=car":
    "Median ride length for origin (car)",
  "length_median_length_origin_card_title&device_type=cargo":
    "Median ride length for origin (cargo)",
  "length_median_length_origin_card_title&device_type=moped":
    "Median ride length for origin (moped)",
  "length_median_length_origin_card_title&device_type=scooter":
    "Median ride length for origin (scooter)",
  "length_median_length_origin_card_title&device_type=bike":
    "Median ride length for origin (bike)",
  "length_median_length_origin_card_title&device_type=e_bike":
    "Median ride length for origin (eBike)",
  length_median_device_type_group_title: "Median ride length by device type",

  operator_diff: "Your % Diff",
  operator_share: "Your share",

  your_distribution: "Your distribution",
  market_distribution: "Market distribution",

  // rides page

  rides_page_title: "Rides",

  dataset_size: "Used ride count",
  fethching_data: "Fetching data",

  your_ride_share_overtime_card_title:
    "Your ride market share over time with trendline",

  // filter
  active_filter_label: "Active filters:",
  device_type_filter: "Device type",
  ride_length_filter_label: "Ride length",
  day_of_week_filter_title: "Day of week",
  hour_of_day_filter_title: "Hour of day",
  operator_mode_filter_label: "Operator mode",
  filter_button_text: "Filter",
  filter_sheet_title: "Data filters",
  filter_apply_button_text: "Apply",

  // date range
  daterange_out_of_range: "Date out of range",
  daterange_invalid: "Invalid date",
  start_date: "Start date",
  end_date: "End date",
  last_7_days: "Last 7 days",
  last_14_days: "Last 14 days",
  last_28_days: "Last 28 days",
  last_30_days: "Last 30 days",
  last_90_days: "Last 90 days",
  last_180_days: "Last 180 days",
  last_3_months: "Last 3 months",
  last_6_months: "Last 6 months",
  this_year: "This year",

  daterange_button_apply: "Apply",
  daterange_button_cancel: "Cancel",

  q1_2023: "2023 Q1",
  q2_2023: "2023 Q2",
  q3_2023: "2023 Q3",
  q4_2023: "2023 Q4",

  // menu
  rides_menu_label: "Rides",
  utilziation_menu_label: "Utilization",
  data_main_menu_label: "Data",
  maps_main_menu_label: "Maps",
  flow_map_menu_label: "Flowmap",
  heatmap_menu_label: "Heatmap",
  hexmap_menu_label: "Hexmap",
  areas_menu_label: "Areas",
  ride_length_menu_label: "Ride length",
  logout_button: "Logout",

  // score card
  median_ride_length_score_card_title: "Median ride length",

  // homepage
  dashboard_page_title_with_area: "Dashboard for ${area}",
  total_ride_count_card_title: "Total ride count",
  average_rides_per_day_card_title: "Average rides per day",
  average_car_ride_length_card_title: "Average ride length",
  total_fleet_size_card_title: "Unique devices count",
  average_scooter_ride_length_card_title: "Average ride length (scooter)",
  expand_map: "Expand map",
  explore_areas_button: "Explore areas",
  explore_rides_button: "Explore ride data",
  explore_ride_length_button: "Explore ride length data",
  rides_overtime_card_title: "Ride count over time",
  neighborhood_list_card_title: "Neighborhoods",

  // rides page
  rides_page_title_with_area: "Rides in ${area}",
  total_rides_by_device_type_group_card_title: "Total ride count",

  // utilization page
  utilization_page_title: "Utilization",
  utilization_rate: "Utilization rate",
  without_operator_overall_utilization_rate:
    "Overall utilization rate (excluding you)",
  operator_utilization_rate: "Your utilization rate",

  // areas
  areas_page_title: "Areas",
  other_area: "Other",
  areas_page_title_with_area: "Dashboard for ${area}",

  // table
  rides_started: "Ride count",
  ride_percentage: "Ride percentage",
  ride_percentage_operator: "Your ride percentage",
  rides_started_operator: "Your rides started",
  rides_variance_operator: "Your variance",
  rides_relative_share_performance: "Relative share (%)",

  // signin page
  signin_page_title: "Sign in",
  signin_email: "Enter your business email:",
  signin_button: "Continue",
  signin_by_continuing: "By continuing, you agree to our",
  signin_terms_of_service: "Terms of Service",
  signin_privacy_policy: "Privacy Policy",
  signin_and: "and",

  // check email page
  signin_check_email_page_title: "Check your email",
  signin_check_email_didnt_receive_email: "Didn't receive an email? Try again",
  signin_check_email_contact_support: "Contact support",

  // verify email page
  verify_email_page_title: "Verifying email...",
  verify_email_verification_failed: "Something went wrong",
  verify_email_try_again: "Try again",

  // not subscribed page
  not_subscribed_page_title:
    "Unlock Premium Features: Elevate Your Experience with a Subscription! 🚀",
  not_subscribed_page_text:
    "Welcome! Ready to elevate your experience? Unlock premium features and exclusive insights by subscribing now. Your journey is about to get even better!",
  not_subscribed_page_button: "Subscribe",
  already_subscribed_text:
    "Already subscribed? But still see this page? Try refreshing the page or contact support.",
  already_subscribed_button: "Contact us",
  // map
  reset_filter: "Reset filter",

  geohash_size_medium: "Medium",
  geohash_size_small: "Small",
  geohash_size_large: "Large",
  geohash_size_xs: "Extra small",
  data_up_to_date: "Data up-to-date",
  data_update_available: "Update data",
  geohash_cluster_size: "Cluster size",

  // heatmap
  heatmap_page_title: "Heatmap",
  heatmap_intensity_label: "Intensity",
  heatmap_min_ride_count: "Min ride count",

  // hexmap
  geohashmap_page_title: "Geohash map",
  geohashmap_opacity_label: "Opacity",
  geohashmap_min_ride_count: "Min ride count",

  // flowmap
  flow_map_page_title: "Flowmap",
  opacity: "Opacity",
  maxTopFlowsDisplayNum: "Max top flows display num",
  fadeAmount: "Fade amount",
  fadeEnabled: "Fade enabled",
  clusteringEnabled: "Clustering enabled",
  incomingCount: "Incoming count",
  outgoingCount: "Outgoing count",
  internalCount: "Internal count",

  // fleet
  fleet_menu_label: "Fleet",
  fleet_page_title: "Fleet",
  fleet_size_timeseries_card_title:
    "Average fleet size available for rent over time",
  device_count_cargo: "Cargo",
  device_count_car: "Car",
  device_count_e_bike: "eBike",
  device_count_moped: "Moped",
  device_count_scooter: "Scooter",
  device_count_bike: "Bike",

  fleet_size_by_device_type_group_card_title: "Unique devices used for rent",

  scooter_avg_available_fleet_size_card_title: "Scooters",
  moped_avg_available_fleet_size_card_title: "Mopeds",
  bike_avg_available_fleet_size_card_title: "Bikes",
  car_avg_available_fleet_size_card_title: "Cars",
  cargo_avg_available_fleet_size_card_title: "Cargo vans",
  e_bike_avg_available_fleet_size_card_title: "eBikes",

  // export
  export_no_data: "No data to export",
  pdf_export_confirm_button: "Export",

  // notifications
  fleet_size_timeseries_filter_disabled_notification_message:
    "Only device type and date range filter apply to this chart",

  not_subscribed_to_device_type: "You are not subscribed to this device type.",

  notification_average_length_card_device_type:
    "This table is only available for Car or Scooter device types",

  no_data: "No data",

  hour_of_day_0: "00:00",
  hour_of_day_1: "01:00",
  hour_of_day_2: "02:00",
  hour_of_day_3: "03:00",
  hour_of_day_4: "04:00",
  hour_of_day_5: "05:00",
  hour_of_day_6: "06:00",
  hour_of_day_7: "07:00",
  hour_of_day_8: "08:00",
  hour_of_day_9: "09:00",
  hour_of_day_10: "10:00",
  hour_of_day_11: "11:00",
  hour_of_day_12: "12:00",

  hour_of_day_13: "13:00",
  hour_of_day_14: "14:00",
  hour_of_day_15: "15:00",
  hour_of_day_16: "16:00",
  hour_of_day_17: "17:00",
  hour_of_day_18: "18:00",
  hour_of_day_19: "19:00",
  hour_of_day_20: "20:00",
  hour_of_day_21: "21:00",
  hour_of_day_22: "22:00",
  hour_of_day_23: "23:00",

  // company id are added by generateCompanyIds method
};

(function generateCompanyIds() {
  let i = 1;
  while (i < 51) {
    translations[`_${i}`] = `Company ${i}`;
    i++;
  }
})();

const translate = (value: string): string => {
  return translations[value] ?? value;
};

export { translate };
