import { time } from "@vuumly-common/common";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export function useRideLength() {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedRideLength = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.getAll("ride_length[]") as time.RideLengthEnum[];
  }, [searchParams]);

  const setSelectedRideLength = (payload: string[]) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("ride_length[]");
    payload.forEach((item) => {
      searchParams.append("ride_length[]", item);
    });

    setSearchParams(searchParams);
  };

  const removeSelectedRideLength = (payload: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    const rideLengthParam = searchParams.getAll("ride_length[]") as string[];

    if (rideLengthParam.includes(payload)) {
      searchParams.delete("ride_length[]");
      rideLengthParam.forEach((item) => {
        if (item !== payload) {
          searchParams.append("ride_length[]", item);
        }
      });

      setSearchParams(searchParams);
    }
  };

  return {
    selectedRideLength,
    setSelectedRideLength,
    removeSelectedRideLength,
  };
}
