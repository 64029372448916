import { PageContentSpacer } from "components";
import {
  TextH1,
  TextH2,
  TextH3,
  TextH4,
  TextH5,
  TextLarge,
  TextParagraph,
  TextSmall,
  TextMuted,
} from "../../components/Typography/Text";

export default function Typography() {
  return (
    <div>
      <PageContentSpacer includeLeftSidebar={true}>
        <TextH1>Text heading H1</TextH1>
        <TextH2>Text heading H2</TextH2>
        <TextH3>Text heading H3</TextH3>
        <TextH4>Text heading H4</TextH4>
        <TextH5>Text heading H5</TextH5>
        <div>
          <TextLarge>Text Large</TextLarge>
        </div>
        <div>
          <TextSmall>Text small</TextSmall>
        </div>
        <TextParagraph>Text paragraph</TextParagraph>
        <TextMuted>Text muted</TextMuted>
      </PageContentSpacer>
    </div>
  );
}
