import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { SetParamsProps } from ".";

export function useOperatorMode() {
  const [searchParams, setSearchParams] = useSearchParams();

  const operatorMode = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const operatorId = searchParams.get("operator_id");
    return !!operatorId;
  }, [searchParams]);

  const disableOperatorMode = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("operator_id");

    setSearchParams(searchParams);
  };

  function setOperatorId(operatorId: number, options?: SetParamsProps) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("operator_id", String(operatorId));
    setSearchParams(searchParams, options?.navigateOptions);
    return searchParams;
  }

  return {
    operatorMode,
    setOperatorId,
    disableOperatorMode,
  };
}
