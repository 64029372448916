import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  useFloating,
  shift,
  getOverflowAncestors,
  offset,
} from "@floating-ui/react";
import { isVisible } from "@testing-library/user-event/dist/utils";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  isVisible: boolean;
  tooltip: React.ReactNode;
}

export default function BaseVirtualTooltip(props: Props) {
  const { children, isVisible, tooltip } = props;
  const [open, setOpen] = useState(false);
  const boundaryRef = useRef<HTMLDivElement | null>(null);
  const pointerTypeRef = useRef<"touch" | "mouse" | "pen" | null>(null);
  const { x, y, refs, update } = useFloating({
    placement: "bottom-start",
    strategy: "fixed",
    middleware: [
      offset({ mainAxis: 10, crossAxis: 10 }),
      shift({
        crossAxis: true,
        padding: 5,
        rootBoundary: "document",
      }),
    ],
  });

  const handleMouseMove = useCallback(
    ({ clientX, clientY }: any) => {
      refs.setReference({
        getBoundingClientRect() {
          return {
            width: 0,
            height: 0,
            x: clientX,
            y: clientY,
            left: clientX,
            top: clientY,
            right: clientX,
            bottom: clientY,
          };
        },
      });
    },
    [refs]
  );

  useEffect(() => {
    const boundary = boundaryRef.current;
    boundary!.addEventListener("pointermove", handleMouseMove);

    const parents = getOverflowAncestors(refs.floating.current!);
    parents.forEach((parent) => {
      parent.addEventListener("scroll", update);
    });

    function handleWindowScroll() {
      if (pointerTypeRef.current === "touch") {
        setOpen(false);
      }
    }

    window.addEventListener("scroll", handleWindowScroll);

    return () => {
      boundary!.removeEventListener("pointermove", handleMouseMove);
      window.removeEventListener("scroll", handleWindowScroll);
      parents.forEach((parent) => {
        parent.removeEventListener("scroll", update);
      });
    };
  }, [refs, update, handleMouseMove]);

  return (
    // <div className="relative overflow-hidden flex w-full">
    <div
      ref={boundaryRef}
      className="relative overflow-hidden w-full"
      onPointerDown={({ pointerType }) => {
        pointerTypeRef.current = pointerType;
      }}
      onPointerEnter={(event) => {
        handleMouseMove(event);
        setOpen(true);
      }}
      onMouseLeave={() => {
        setOpen(false);
      }}
    >
      <div
        ref={refs.setFloating}
        className="z-10"
        style={{
          height: props.style?.height,
          position: "absolute",
          top: y ?? 0,
          left: Math.round(x) ?? 0,
          transform: `scale(${open && isVisible ? "1" : "0"})`,
          opacity: open && isVisible ? "1" : "0",
          transition: "transform 0.2s ease, opacity 0.1s ease",
        }}
      >
        {tooltip}
      </div>
      {children}
    </div>
    // </div>
  );
}
