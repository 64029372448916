import { ChartOptions } from "chart.js";
import enGB from "date-fns/locale/en-GB";
import { useTheme, useUserContext } from "hooks";
import _ from "lodash";
import { useMemo } from "react";
import { Bar } from "react-chartjs-2";

const defaultData = {
  labels: [],
  datasets: [],
};

interface BaseHorizontalBarChartrProps {
  data?: any;
  hideDataLabels?: boolean;
  height?: number;
  stackedXAxis?: boolean;
  stackedYAxis?: boolean;
  paddingRight?: number;
  horizontal?: boolean;
  stacked?: boolean;
  options?: ChartOptions<"bar" | "line">;
}

const legendMargin = {
  id: "legendMargin",
  beforeInit: function (chart: any) {
    const fitValue = chart.legend.fit;

    chart.legend.fit = function () {
      fitValue.bind(chart.legend)();
      return (this.height += 10);
    };
  },
};

export const BaseBarChart = (props: BaseHorizontalBarChartrProps) => {
  const { charts } = useTheme();
  const { locale } = useUserContext();

  const {
    data,
    height = 423,
    paddingRight,
    horizontal = false,
    options = {},
  } = props;

  const usedData = data || defaultData;

  const baseOptions: ChartOptions<"bar"> = {
    animation: {
      duration: 0,
    },
    responsive: true,
    //locale,
    maintainAspectRatio: false,
    elements: {
      bar: {
        hoverBackgroundColor: charts.colors.hover,
        borderRadius: 4,
        backgroundColor: charts.colors.primary,
      },
      line: {
        tension: 0.3,
        borderWidth: 2,
      },
      point: {
        radius: 0,
        borderWidth: 8,
        hoverRadius: 8,
        borderColor: "white",
        hoverBorderWidth: 4,
        hoverBorderColor: "white",
        // there are additional settings at lower
      },
    },

    scales: {
      x: {
        time: {
          isoWeekday: true,
          tooltipFormat: "PPPP",
          minUnit: "day",
        },
        title: {
          color: "#9E9E9E",
        },
      },
      y: {
        title: {
          color: "#9E9E9E",
        },
      },
    },

    plugins: {
      legend: {
        labels: {
          filter(item) {
            return item.text !== "none";
          },
          usePointStyle: true,
        },
      },
      tooltip: {
        caretPadding: 10,
      },
    },
  };

  const verticalBarOptions: ChartOptions<"bar"> = {
    indexAxis: "x",

    hover: {
      mode: "index",
    },

    scales: {
      y: {
        border: {
          display: false,
        },
        grace: "10%",
        title: {
          display: true,
          padding: 10,
        },
        ticks: {
          padding: 10,
        },
      },
      x: {
        // max: 100,
        ticks: {
          padding: 10,
        },
        title: {
          padding: 10,
        },
      },
    },

    layout: {
      padding: {
        right: 20,
      },
    },

    plugins: {
      tooltip: {
        enabled: true,
        mode: "index",
        position: "average",
        displayColors: true,
        usePointStyle: true,
        intersect: false,
      },
      datalabels: {
        display: false,
        color: "black",
        anchor: "end",
        align: "end",
      },
    },
  };

  const mergedOptions = useMemo(() => {
    return _.merge(baseOptions, verticalBarOptions, options);
  }, [props]);

  return (
    <Bar
      //  ref={chart}
      width="100%"
      height={height}
      data={usedData}
      plugins={[legendMargin]}
      options={mergedOptions}
    />
  );
};
