import { createEmpty, extend } from "ol/extent";
import { useEffect } from "react";
import { useMapContext } from "../context/MapContext";
import { useSuburbPanelLayer } from "../pages/Distribution/Layers/SuburbPanel";

export function useCenterMap() {
  const { mapInstance } = useMapContext();
  const { suburbAreas, layer } = useSuburbPanelLayer();
  useEffect(() => {
    var ext = createEmpty();
    layer.getSource()?.forEachFeature((feature) => {
      var geometry = feature.getGeometry();

      if (geometry) {
        extend(ext, geometry.getExtent());
      }
    });
    if (suburbAreas.length && mapInstance) {
      mapInstance?.getView().fit(ext, { padding: [50, 350, 50, 50] });
    }
  }, [suburbAreas, mapInstance, layer]);
}
