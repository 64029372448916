import { cn } from "components/shadcn/lib/utils";
import { Link, To } from "react-router-dom";

export function TextH1(props: React.HTMLProps<HTMLDivElement>) {
  const { children, style, className } = props;
  return (
    <h1
      style={style}
      className={cn(
        "scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl",
        className
      )}
    >
      {children}
    </h1>
  );
}

export function TextH2(props: React.HTMLProps<HTMLDivElement>) {
  const { children, style, className } = props;
  return (
    <h2
      style={style}
      className={cn(
        "scroll-m-20 pb-2 text-3xl font-semibold tracking-tight transition-colors first:mt-0",
        className
      )}
    >
      {children}
    </h2>
  );
}

export function TextH3(props: React.HTMLProps<HTMLDivElement>) {
  const { children, style, className } = props;
  return (
    <h3
      style={style}
      className={cn(
        "scroll-m-20 text-2xl font-semibold tracking-tight",
        className
      )}
    >
      {children}
    </h3>
  );
}

export function TextH4(props: React.HTMLProps<HTMLDivElement>) {
  const { children, style, className } = props;
  return (
    <h4
      style={style}
      className={cn(
        "scroll-m-20 text-xl font-semibold tracking-tight",
        className
      )}
    >
      {children}
    </h4>
  );
}

export function TextH5(props: React.HTMLProps<HTMLDivElement>) {
  const { children, style, className } = props;
  return (
    <h5
      style={style}
      className={cn(
        "scroll-m-20 text-md font-semibold tracking-tight",
        className
      )}
    >
      {children}
    </h5>
  );
}

export function TextParagraph(props: React.HTMLProps<HTMLDivElement>) {
  const { children, style, className } = props;
  return (
    <p
      style={style}
      className={cn("leading-7 [&:not(:first-child)]:mt-6", className)}
    >
      {children}
    </p>
  );
}

export function TextLarge(props: React.HTMLProps<HTMLDivElement>) {
  const { children, style, className } = props;
  return (
    <span style={style} className={cn("text-lg font-semibold", className)}>
      {children}
    </span>
  );
}

export function TextBody(props: React.HTMLProps<HTMLDivElement>) {
  const { children, style, className } = props;
  return (
    <span
      style={style}
      className={cn("text-md font-medium leading-6", className)}
    >
      {children}
    </span>
  );
}

export function TextSmall(props: React.HTMLProps<HTMLDivElement>) {
  const { children, style, className } = props;
  return (
    <span
      style={style}
      className={cn("text-sm font-medium leading-none", className)}
    >
      {children}
    </span>
  );
}

export function TextMuted(props: React.HTMLProps<HTMLDivElement>) {
  const { children, style, className } = props;

  return (
    <span
      style={style}
      className={cn("text-sm text-muted-foreground", className)}
    >
      {children}
    </span>
  );
}

export function TextWarning(props: React.HTMLProps<HTMLDivElement>) {
  const { children, style, className } = props;

  return (
    <span
      style={style}
      className={cn("text-sm text-warning", className)}
    >
      {children}
    </span>
  );
}

export interface ITextLink extends React.HTMLAttributes<HTMLDivElement> {
  to: To;
  target?: React.HTMLAttributeAnchorTarget;
}

export function TextLink(props: ITextLink) {
  const { children, style, className, to, target } = props;
  return (
    <Link target={target} to={to}>
      <span style={style} className={cn("underline", className)}>
        {children}
      </span>
    </Link>
  );
}
