import { TextH2, TextMuted } from "components/Typography/Text";
import { BaseButton } from "../../components/Button";
import { RoutePathEnum } from "../../controllers/router";
import {
  usePageTitle,
  useRouter,
  useTranslate,
  useUserContext,
} from "../../hooks";
import { AuthWrapper } from "./AuthWrapper";

export function NotSubscribedPage() {
  const pageTitle = usePageTitle(RoutePathEnum.NotSubscribed);
  const { goToExternal } = useRouter();
  const translate = useTranslate();
  const { signOut } = useUserContext();

  return (
    <AuthWrapper hideTerms>
      <div className="flex flex-col mb-6">
        <TextH2>{pageTitle}</TextH2>
      </div>
      <div className="flex mb-24">
        <BaseButton onClick={() => goToExternal(RoutePathEnum.Contacts)}>
          {translate("not_subscribed_page_button")}
        </BaseButton>
      </div>
      <div>
        <TextMuted>{translate("already_subscribed_text")}</TextMuted>
      </div>
      <div>
        <BaseButton
          className="pl-0"
          variant="link"
          onClick={() => goToExternal(RoutePathEnum.Contacts)}
        >
          {translate("already_subscribed_button")}
        </BaseButton>
      </div>
      <div>
        <BaseButton variant="link" className="pl-0" onClick={signOut}>
          {translate("logout_button")}
        </BaseButton>
      </div>
    </AuthWrapper>
  );
}
