import { api, db, time } from "@vuumly-common/common";
import {
  ChartData,
  useParseRideLength,
  useTheme,
  useTranslate,
  useUserContext,
} from "hooks";
import _ from "lodash";

interface Props {
  data?: api.response.StandartResponse;
  timeGranularity?: time.GranularityEnum | null;
  minCountPercentage?: number;
  ignoreOperatorMode?: boolean;
  dashNonOperatorMetrics?: boolean;
  showTrendline?: boolean;
  fillColor?: boolean;
  showBorder?: boolean;
  calculatePercentageBetweenDatasets?: boolean;
  ignoreDatasetColor?: boolean;
  chartType?: "line" | "bar";
}

export function useTimeseriesParser(props: Props) {
  const {
    data,
    timeGranularity,
    minCountPercentage = 10,
    ignoreOperatorMode = false,
    dashNonOperatorMetrics = false,
    showTrendline = false,
    fillColor,
    showBorder = true,
    calculatePercentageBetweenDatasets = false,
    ignoreDatasetColor = false,
    chartType = "line",
  } = props;
  const { locale } = useUserContext();
  const parseRideLength = useParseRideLength();
  const { getDatasetColor } = useTheme();
  const translate = useTranslate();

  const parsedData: ChartData | undefined = _.cloneDeep(data);

  // remove operator metrics from dataset
  if (ignoreOperatorMode && parsedData) {
    const clonedData = parsedData.datasets.filter(
      (dataset) => dataset.usage_type !== db.models.ColumnType.OperatorMetrics
    );

    parsedData.datasets.length = 0;
    parsedData.datasets.push(...clonedData);
  }

  if (parsedData) {
    if (!parsedData.rawLabels) {
      parsedData.rawLabels = [...parsedData.labels];
      parsedData.shortLabels = [...parsedData.labels];
      parsedData.tableFormattedLabels = [...parsedData.labels];
      parsedData.formatted_label = translate(parsedData.label);

      parsedData.labels.forEach((label, index) => {
        if (parsedData.label_type === db.models.LabelTypeEnum.Date) {
          let longDate: string = label;
          let shortDate: string = label;
          let tableDate: string = new Date(shortDate).toLocaleDateString(
            locale
          );

          if (timeGranularity === time.GranularityEnum.Day) {
            longDate = new Date(longDate).toLocaleDateString(locale, {
              dateStyle: "full",
            });

            shortDate = new Date(shortDate).toLocaleDateString(locale, {
              dateStyle: "short",
            });
          } else if (timeGranularity === time.GranularityEnum.Week) {
            longDate = new Date(longDate).toLocaleDateString(locale, {
              dateStyle: "long",
            });

            shortDate = new Date(shortDate).toLocaleDateString(locale, {
              month: "short",
              day: "numeric",
              year: "numeric",
            });
          } else {
            longDate = new Date(longDate).toLocaleDateString(locale, {
              month: "long",
              year: "numeric",
            });

            shortDate = new Date(shortDate).toLocaleDateString(locale, {
              month: "long",
              year: "numeric",
            });
          }

          parsedData.labels[index] = longDate;
          parsedData.shortLabels![index] = shortDate;
          parsedData.tableFormattedLabels![index] = tableDate;
        } else if (
          parsedData.label_type === db.models.LabelTypeEnum.TimeOfDay
        ) {
          parsedData.labels[index] = translate(label);
        } else if (
          parsedData.label_type === db.models.LabelTypeEnum.HourOfDay
        ) {
          let hour = label.split("hour_of_day_")[1];
          if (hour.length === 1) {
            hour = "0" + hour;
          }

          const formatted = new Intl.DateTimeFormat(locale, {
            hour: "numeric",
            hour12: false,
          }).format(new Date(`2023-10-17T${hour}:00`));

          parsedData.labels[index] = formatted;
        } else if (
          parsedData.label_type === db.models.LabelTypeEnum.DayOfWeek
        ) {
          parsedData.labels[index] = translate(label);
          parsedData.shortLabels![index] = translate(label);
        } else if (
          parsedData.label_type === db.models.LabelTypeEnum.TimeLengthGroup
        ) {
          parsedData.labels[index] = parseRideLength(label);
        } else {
          parsedData.labels[index] = translate(label);
        }
      });
    }

    parsedData.totalValue = 0;

    parsedData.labels.forEach((label, dimensionIdx) => {
      parsedData.datasets.forEach((dataset) => {
        if (
          (dataset.data_type === db.models.DatasetTypeEnum.NumberArray ||
            dataset.data_type === db.models.DatasetTypeEnum.RideCountArray) &&
          dataset.data[dimensionIdx]
        ) {
          parsedData.totalValue += dataset.data[dimensionIdx];
        }
      });
    });

    parsedData.datasets.forEach((dataset, index) => {
      if (!dataset.parsed) {
        // Parse dataset label
        dataset.raw_label = dataset.label;
        dataset.formattedData = [];
        dataset.tableFormattedData = [];
        dataset.tooltipData = [];
        dataset.percentageData = [];
        dataset.percentageDataFormatted = [];

        dataset.totalValue = dataset.data.reduce((acc, cur) => acc + cur, 0);

        dataset.averageValue = dataset.totalValue
          ? Math.round(dataset.totalValue / dataset.data.length)
          : 0;

        if (dataset.averageValue) {
          dataset.averageValueFormatted = new Intl.NumberFormat(locale, {
            notation: "compact",
          }).format(dataset.averageValue);
        }

        if (dataset.label.includes("ride_length_group")) {
          dataset.label = parseRideLength(dataset.id);
        } else {
          dataset.label = translate(dataset.label);
        }
        // Parse total value for certain dimension/date
        if (
          dataset.data_type === db.models.DatasetTypeEnum.NumberArray ||
          dataset.data_type === db.models.DatasetTypeEnum.RideCountArray ||
          dataset.data_type === db.models.DatasetTypeEnum.FleetSizeArray
        ) {
          dataset.totalDatasetValue = 0;

          for (let item of dataset.data) {
            dataset.totalDatasetValue += item;

            const formattedValue = new Intl.NumberFormat(locale, {
              notation: "compact",
            }).format(item);

            dataset.formattedData.push(formattedValue);

            const formattedTable = new Intl.NumberFormat(locale, {
              notation: "standard",
            }).format(item);
            dataset.tableFormattedData.push(formattedTable);

            const formattedTooltip = `${translate(
              dataset.label
            )}: ${formattedValue}`;

            dataset.tooltipData.push(formattedTooltip);

            const percentage = (100 / (parsedData.totalValue || 100)) * item;

            dataset.percentageData.push(percentage);
            dataset.percentageDataFormatted.push(percentage.toFixed(1) + "%");
          }
        } else if (
          dataset.data_type === db.models.DatasetTypeEnum.TimeLengthArray
        ) {
          for (let item of dataset.data) {
            const formattedValue = item + " " + translate("minutes_short");

            dataset.formattedData.push(formattedValue);

            const formattedTooltip = `${translate(
              dataset.label
            )}: ${formattedValue}`;

            dataset.tooltipData.push(formattedTooltip);

            const formattedTable = new Intl.NumberFormat(locale, {
              notation: "standard",
            }).format(item);
            dataset.tableFormattedData.push(formattedTable);
          }
        } else if (
          dataset.data_type === db.models.DatasetTypeEnum.RidePercentageArray
        ) {
          for (let item of dataset.data) {
            const formattedValue = item.toFixed(2) + "%";

            dataset.formattedData.push(formattedValue);

            const formattedTooltip = `${translate(
              dataset.label
            )}: ${formattedValue}`;

            dataset.tooltipData.push(formattedTooltip);
          }
        }
      }

      // set style for dataset
      if (chartType === "line") {
        dataset.pointStyle = "circle";
        dataset.borderDash = [];
      }

      if (dashNonOperatorMetrics) {
        if (dataset.usage_type !== db.models.ColumnType.OperatorMetrics) {
          dataset.borderDash = [5, 5];
        } else {
          dataset.pointStyle = "rectRot";
        }
      }

      const datasetColor = getDatasetColor(dataset, {
        ignoreOperatorMode,
        ignoreDatasetColor,
        chartType: chartType,
      });

      dataset.borderColor = datasetColor;
      dataset.hoverBorderColor = datasetColor;

      if (showBorder) {
        dataset.borderWidth = 2;
      } else {
        dataset.borderWidth = 0;
      }

      dataset.hoverBackgroundColor = datasetColor;
      dataset.backgroundColor = datasetColor;

      if (fillColor) {
        dataset.backgroundColor = datasetColor;
      }

      dataset.pointRadius = 0;
      dataset.pointHoverRadius = 5;

      dataset.parsed = true;
      dataset.tension = 0.3;

      if (showTrendline) {
        // dataset.trendlineLinear = {
        //   colorMax: "red",
        //   colorMin: "red",
        //   lineStyle: "dashed",
        //   width: 2,
        //   projection: false,
        // };
      }
    });

    parsedData.datasets.forEach((dataset) => {
      if (
        dataset.data_type === db.models.DatasetTypeEnum.NumberArray ||
        dataset.data_type === db.models.DatasetTypeEnum.RideCountArray
      ) {
        dataset.totalDatasetValuePercentage =
          (100 / (parsedData.totalValue || 100)) *
          (dataset.totalDatasetValue || 0);

        dataset.hidden =
          dataset.totalDatasetValuePercentage < minCountPercentage;
      }
    });

    if (calculatePercentageBetweenDatasets && parsedData.datasets.length > 1) {
      parsedData.totalIndexValue = [];
      parsedData.totalOperatorIndexValue = [];

      parsedData.datasets[0].data.forEach((value, index) => {
        // calculate total index value (sum of all datasets values for current index
        const totalIndexValue = parsedData.datasets
          .filter(
            (dataset) => dataset.usage_type === db.models.ColumnType.Metrics
          )
          .reduce((acc, cur) => {
            return acc + cur.data[index];
          }, 0 as number);

        parsedData.totalIndexValue?.push(totalIndexValue);

        // calculate total operator index value (sum of all datasets values for current index
        const totalOperatorIndexValue = parsedData.datasets
          .filter(
            (dataset) =>
              dataset.usage_type === db.models.ColumnType.OperatorMetrics
          )
          .reduce((acc, cur) => {
            return acc + cur.data[index];
          }, 0 as number);

        parsedData.totalOperatorIndexValue?.push(totalOperatorIndexValue);
      });
    }

    parsedData.time_granularity = timeGranularity || time.GranularityEnum.None;
  }

  return { parsedData };
}
