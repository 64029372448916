import { useSearchParams } from "react-router-dom";
import { SetParamsProps, useCountry, useDeviceGroup } from "./index";
import {
  DeviceGroupEnum,
  TCity,
  getCitiesByCountry,
  location,
} from "@vuumly-common/common";

export function useDemoMode() {
  const [_searchParams, setSearchParams] = useSearchParams();
  const { selectedDeviceGroup } = useDeviceGroup();

  const { selectedCountryValue } = useCountry();
  let demoCitySettings: location.DeviceGroupCityDemoSettings | undefined;

  const searchParams = new URLSearchParams(window.location.search);
  const isDemoModeEnabled = searchParams.get("demo_mode") === "true";

  if (isDemoModeEnabled && selectedCountryValue) {
    const cities = getCitiesByCountry(selectedCountryValue);

    const citySettings = cities.find(
      (city) => city.country === selectedCountryValue && city.demoSettings
    );

    if (
      citySettings &&
      citySettings.demoSettings &&
      selectedDeviceGroup !== DeviceGroupEnum.All
    ) {
      demoCitySettings = citySettings.demoSettings[selectedDeviceGroup];
    }
  }

  function setDemoMode(value: boolean, options?: SetParamsProps) {
    const searchParams =
      options?.searchParams || new URLSearchParams(window.location.search);
    const currentState = searchParams.get("demo_mode");
    if (currentState === String(value)) {
      return;
    }
    searchParams.set("demo_mode", String(value));
    setSearchParams(searchParams, options?.navigateOptions);
  }

  return {
    demoCitySettings,
    isDemoModeEnabled,
    setDemoMode,
  };
}
