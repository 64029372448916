import { Layers } from "controllers";
import { useState } from "react";

interface Props {
  settings?: Layers.Hexmap.Settings;
}

export const useHexmapPanelSettings = (props?: Props) => {
  const [settings, setSettings] = useState<Layers.Hexmap.Settings>(
    props?.settings || getDefaults()
  );

  // i need a way to get good defaults
  function getDefaults() {
    return Layers.Hexmap.defaultSettings;
  }

  return {
    settings,
    setSettings,
    getDefaults,
  };
};
