import { LayoutDefaults } from "../Layouts";
import React from "react";

interface Props {
  includeLeftSidebar?: boolean;
  children: React.ReactNode;
}

export default function PageContentSpacer(props: Props) {
  return (
    <div
      className="p-6 h-full"
      style={{
        marginLeft: props.includeLeftSidebar
          ? LayoutDefaults.leftSidebarWidth
          : undefined,
      }}
    >
      <div className="w-full space-y-4">{props.children}</div>
    </div>
  );
}
