import { api } from "@vuumly-common/common";
import { ChartData, ChartOptions, TooltipItem } from "chart.js";
import Color from "color";
import { useQuery, useTheme, useTranslate } from "hooks";
import _ from "lodash";
import { useMemo } from "react";
import { CardProps } from "..";
import { BaseBarChart } from "../../Charts";
import { TextH5 } from "../../Typography/Text";
import { BaseCard } from "../Base.card";

export const UtilizationTimeOfDayBarChartCard = (props: CardProps) => {
  const theme = useTheme();
  const translate = useTranslate();

  const { data, isLoading } = useQuery<
    ChartData<"bar", { utilization_rate: number }[]>
  >({
    apiEndpoint: api.Endpoint.UtilizationTimeOfDay,
  });

  const clonedData = useMemo(() => {
    if (isLoading || !data?.datasets) {
      return;
    }
    const clonedData = _.cloneDeep(data);

    if (clonedData) {
      clonedData.labels = [];
    }

    clonedData?.datasets.forEach((dataset) => {
      const { label } = dataset;

      if (label === "utilization_rate") {
        dataset.backgroundColor = dataset.data.map((value) => {
          return Color(theme.accentColor).string();
        });

        dataset.hoverBackgroundColor = dataset.data.map((value) => {
          return Color(theme.accentColorHover).string();
        });

        dataset.label = translate("Overall utilization rate");
      } else {
        dataset.hidden = true;
        dataset.label = "none";
      }
    });

    return clonedData;
  }, [data]);

  const options: ChartOptions<"bar"> = {
    parsing: {
      xAxisKey: "time_of_day",
      yAxisKey: "utilization_rate",
    },
    scales: {
      x: {
        title: {
          text: "Time of day",
          display: true,
        },
        grid: {
          display: false,
        },
        ticks: {
          callback: (idx) => {
            return translate(clonedData?.labels?.[idx as number] as string);
          },
        },
        offset: true,
      },
      y: {
        title: {
          text: "Utilization rate",
        },
        ticks: {
          display: false,
          callback: (value) => {
            return `${value}%`;
          },
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },

    plugins: {
      tooltip: {
        callbacks: {
          title: (ctx) => {
            return `${translate(ctx[0].label)} (${translate(
              ctx[0].label + "_range"
            )})`;
          },
          label: (item: TooltipItem<"bar">) => {
            return `${item.dataset.label}: ${item.formattedValue}%`;
          },
        },
      },
      datalabels: {
        display: true,
        formatter: (item: Record<string, any>) => {
          return item.utilization_rate.toFixed(1) + "%";
        },
      },
    },
  };

  return (
    <BaseCard
      isLoading={isLoading}
      header={
        <TextH5>{translate("Vehicle utilization by time of day")}</TextH5>
      }
    >
      <BaseBarChart options={options} data={clonedData} />
    </BaseCard>
  );
};
