import "ol/ol.css";
import { MapContextProvider, useMapContext } from "context/MapContext";
import { DashboardLayout, LayoutDefaults } from "../../components/Layouts";
import { BaseMap } from "components/Map";
import { useEffect, useState } from "react";
import { DashboardHeader } from "../../components/Header";
import { MapSidebar } from "../../components/Sidebar";
import { useHeatmapPanelLayer } from "hooks/layers/useHeatmapPanelLayer.hook";
import { Layers, usePageTitle } from "hooks";
import { RoutePathEnum } from "../../controllers/router";
import { DashboardContentWrapper } from "../../components/Wrapper";
import { usePopularityGridQuery } from "../../hooks/layers";

const Content = () => {
  const { mapInstance } = useMapContext();
  const [data, setData] = useState<any>();

  const { settings, setSettings, ready } = Layers.useHeatmapPanelSettings();

  // const { isLoading, data } = usePopularityGridQuery({
  //   ...settings,
  //   isEnable: ready,
  // });

  useEffect(() => {
    const data = fetch("/data/accidents.json")
      .then((response) => response.json())
      .then((rawData) => {
        const values = rawData.map((item: any) => {
          return `${item.lat},${item.lon}`;
        });
        const parsed = {
          data: {
            datasets: [
              {
                data: new Array(values.length).fill(1),
                label: "ride_count",
                id: "ride_count",
                usage_type: "metrics",
                data_type: "number_array",
              },
              {
                data: values,
                label: "ride_count",
                id: "ride_count",
                usage_type: "metrics",
                data_type: "number_array",
              },
            ],
            id: "rides_grid_response",
            label: "geohash",
            label_type: "geohash",
            labels: values,
            minimum_ride_count: 0,
          },
        };
        setData(parsed);
      });
  }, []);

  const { panel } = useHeatmapPanelLayer({
    data,
    weight: 0.1,
    isLoading: !data || !ready,
    setSettings,
    settings: settings,
  });

  useEffect(() => {
    function setFocus() {
      document.getElementById("map")?.focus();
    }
    mapInstance?.on("pointermove", setFocus);

    return () => {
      mapInstance?.un("pointermove", setFocus);
    };
  }, []);

  return (
    <div className="flex">
      <div className="flex-1 relative">
        {ready && (
          <MapSidebar>
            <div>{panel}</div>
          </MapSidebar>
        )}
        <BaseMap
          isLoading={!ready}
          tooltip={<></>}
          style={{
            height: `calc(100vh - ${
              (document.getElementById("main-menu")?.offsetHeight ||
                LayoutDefaults.headerHeight) +
              (document.getElementById("page-layout")?.offsetHeight || 0)
            }px)`,
          }}
        />
      </div>
    </div>
  );
};

export function AccidentPage() {
  return (
    <div>
      <DashboardContentWrapper className="mb-0">
        <DashboardHeader title="Accident heatmap" />
      </DashboardContentWrapper>
      <MapContextProvider>
        <Content />
      </MapContextProvider>
    </div>
  );
}
