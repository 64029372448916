import {
  LengthSummaryTableCard,
  RideLengthHour,
  RideLengthHourCompanies,
  RideLengthMinutesTimeseries,
  RideLengthMinutesTimeseriesCompanies,
  RideLengthMinutesTimeseriesOperatorShare,
  RideLengthMinutesTotal,
  RideLengthMinutesTotalCompaniesTable,
  RideLengthTimeseriesCard,
  RidesPerLenghtGroupBarChartCard,
  RidesPerLenghtGroupBarChartCombinedCard,
  RidesPerLenghtGroupBarChartDifferenceCard,
  RidesPerLengthGroupBarChartOperatorCard,
} from "components/Card";
import { DashboardHeader } from "components/Header";
import { useDeviceType, useOperatorMode, usePageTitle, useRouter } from "hooks";
import { useLocation } from "react-router-dom";
import { toast } from "sonner";
import { BaseAlert } from "../../components/Alert/BaseAlert";
import { LengthGroupCompanies } from "../../components/Card/Length/LengthGroupCompanies";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/shadcn/components/ui/tabs";
import { RoutePathEnum } from "../../controllers/router";

const MICROMOBILITY_ERROR_MESSAGE =
  "Ride length by hour is not available for micromobility.";

export const RideLengthPage = () => {
  const title = usePageTitle(RoutePathEnum.RideLengthGroup);
  const { operatorMode } = useOperatorMode();
  const { pathname } = useLocation();
  const { goTo } = useRouter();

  const { isMicromobilityActive } = useDeviceType();

  function handleVehicleModels() {
    if (isMicromobilityActive) {
      toast.error(MICROMOBILITY_ERROR_MESSAGE);
    }
  }

  return (
    <>
      <DashboardHeader title={title} />

      <Tabs onValueChange={goTo} value={pathname}>
        <TabsList className="mb-8">
          <TabsTrigger value={RoutePathEnum.RideLength}>
            Ride length by group
          </TabsTrigger>
          <TabsTrigger value={RoutePathEnum.RideLengtMinutes}>
            Ride length in minutes
          </TabsTrigger>
          <div onClick={handleVehicleModels}>
            <TabsTrigger
              disabled={isMicromobilityActive}
              value={RoutePathEnum.RideLengtHours}
            >
              Ride length by hours
            </TabsTrigger>
          </div>
        </TabsList>

        <TabsContent value={RoutePathEnum.RideLength}>
          <div className="flex flex-col space-y-12">
            <RideLengthTimeseriesCard />

            {operatorMode && <RidesPerLengthGroupBarChartOperatorCard />}

            {operatorMode && <RidesPerLenghtGroupBarChartCombinedCard />}

            {operatorMode && <RidesPerLenghtGroupBarChartDifferenceCard />}

            <RidesPerLenghtGroupBarChartCard />

            <LengthGroupCompanies />

            <LengthSummaryTableCard />
          </div>
        </TabsContent>

        <TabsContent value={RoutePathEnum.RideLengtMinutes}>
          <div className="flex flex-col space-y-12">
            <RideLengthMinutesTimeseries />
            <RideLengthMinutesTotal />
            {operatorMode && <RideLengthMinutesTimeseriesOperatorShare />}
            <RideLengthMinutesTimeseriesCompanies />
            <RideLengthMinutesTotalCompaniesTable />
          </div>
        </TabsContent>

        <TabsContent value={RoutePathEnum.RideLengtHours}>
          <div className="flex flex-col space-y-12">
            {isMicromobilityActive ? (
              <>
                <BaseAlert
                  className="mb-8"
                  title={MICROMOBILITY_ERROR_MESSAGE}
                />
              </>
            ) : (
              <>
                <RideLengthHour />
                <RideLengthHourCompanies />
              </>
            )}
          </div>
        </TabsContent>
      </Tabs>
    </>
  );
};
