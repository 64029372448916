import { useState } from "react";
import { BaseCheckbox } from "../Checkbox";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../shadcn/components/ui/accordion";
import { TextH5, TextMuted } from "../Typography/Text";
import { useTranslate } from "../../hooks";
import { toast } from "sonner";
import { LockIcon } from "lucide-react";

export type BaseFilterOption<T extends string> = {
  label: string;
  value: T;
  disabled?: boolean;
  selected?: boolean;
  tooltip?: string;
  subscribed?: boolean;
  hasMatch?: boolean;
  id: string;
  // active: boolean;
};

export interface SelectorProps<T extends string> {
  options: BaseFilterOption<T>[];
  onChange: (value: BaseFilterOption<T>[]) => void;
  title: string;
}

export function BaseFilterSelect<T extends string>({
  // selectedOptions,
  onChange,
  title,
  options,
}: // tooltip,
SelectorProps<T>) {
  const [open, setOpen] = useState(false);

  const handleCheckboxChange = (value: T) => {
    const newDevices = options.map((o) => {
      if (o.value === value) {
        o.selected = !o.selected;
      }
      return o;
    });

    onChange(newDevices);
  };

  type OptionProps = {
    option: BaseFilterOption<T>;
    selected: boolean;
    onChange: (event: any) => void;
  };

  const OptionCheckbox = ({ option, selected, onChange }: OptionProps) => {
    return option.disabled ? (
      <div
        className="tooltip flex cursor-pointer"
        onClick={() => {
          toast.error(option.tooltip);
        }}
      >
        <LockIcon
          className="fill-muted-foreground text-background mr-1"
          size={18}
        />
        <TextMuted>{option.label}</TextMuted>
      </div>
    ) : (
      <>
        <BaseCheckbox
          value={option.value as string}
          disabled={option.disabled}
          checked={selected}
          id={option.id}
          label={option.label}
          onChange={(val: any) => {
            onChange(option.value);
          }}
        />
      </>
    );
  };

  function Header() {
    const translate = useTranslate();
    return (
      <div style={{ maxWidth: "90%" }}>
        <TextH5>{translate(title)}</TextH5>
      </div>
    );
  }

  const Options = () => {
    return (
      <div className="space-y-2">
        <Header />
        {options.map((option) => (
          <OptionCheckbox
            option={option}
            key={String(option.value)}
            selected={option.selected ?? false}
            onChange={handleCheckboxChange}
          />
        ))}
      </div>
    );
  };

  return <Options />;
}
