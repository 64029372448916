import { DeviceGroupEnum } from "@vuumly-common/common";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { SetParamsProps } from "./index";

export function useDeviceGroup() {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedDeviceGroup = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("device_group") as DeviceGroupEnum;
  }, [searchParams]);

  const setSelectedDeviceGroup = (
    payload: DeviceGroupEnum,
    options?: SetParamsProps
  ) => {
    const searchParams = new URLSearchParams(window.location.search);

    searchParams.delete("device_types[]");
    searchParams.delete("ride_length[]");
    searchParams.delete("engine_type[]");

    searchParams.set("device_group", payload);

    setSearchParams(searchParams, options?.navigateOptions);
  };

  return {
    selectedDeviceGroup,
    setSelectedDeviceGroup,
  };
}
