import { useMapContext } from "context/MapContext";
import { useEffect, useMemo, useRef } from "react";
import { LayerPanel } from "../../../components/Panel/Layer.panel";
import { useDistrict, useSuburbAreas } from "../../../hooks";
import { ol } from "../../../utils";
import { SuburbFeature, SuburbFeatureLayer } from "./index";
import { BaseTooltip } from "../../../components/Tooltip";

const styleDefault = new ol.Style({
  fill: new ol.Fill({
    color: `rgba(66, 85, 108, 0.2)`,
  }),
  stroke: new ol.Stroke({ color: `rgba(66, 85, 108, 0.50)`, width: 1 }),
});

interface Props {
  zIndex?: number;
}

export function useSuburbPanelLayer(props?: Props) {
  const {
    suburbAreaLayerVisible,
    setSuburbAreaLayerVisible,
    hoveredFeatureOnMap,
    mapInstance,
  } = useMapContext();

  const { selectedDistrict } = useDistrict();

  const { isLoading: loading, list: suburbAreas } = useSuburbAreas();

  const style = useRef<ol.Style>(styleDefault);

  const vectorSource = useRef(new ol.VectorSource());

  const vectorLayer = useRef(
    new SuburbFeatureLayer({
      style: style.current,
      source: vectorSource.current,
      zIndex: props?.zIndex,
    })
  );

  // useEffect(() => {
  //   if (vectorLayer.current && mapInstance) {
  //     console.log("adding suburb layer");
  //     mapInstance?.addLayer(vectorLayer.current);
  //   }

  //   return () => {
  //     if (!vectorLayer.current) return;
  //     mapInstance?.removeLayer(vectorLayer.current);
  //   };
  // }, [vectorLayer.current, mapInstance]);

  useMemo(() => {
    if (!loading && suburbAreas && suburbAreaLayerVisible) {
      // clearLayer();
      console.log("rendering suburb areas");
      const features: ol.Feature[] = [];

      suburbAreas.forEach((item) => {
        if (selectedDistrict && item.id !== selectedDistrict) return;

        if ((item.geo_json.type as any) === "MultiPolygon") {
          features.push(
            new SuburbFeature({
              geometry: new ol.MultiPolygon(
                item.geo_json.coordinates.map((item) =>
                  item.map((item) =>
                    item.map((item) => ol.fromLonLat(item as any))
                  )
                )
              ),
              properties: {
                ...item,
              },
            })
          );
        } else {
          features.push(
            new SuburbFeature({
              geometry: new ol.Polygon(
                item.geo_json.coordinates.map((item) =>
                  item.map((item) => ol.fromLonLat(item))
                )
              ),
              properties: {
                ...item,
              },
            })
          );
        }
      });
      vectorSource.current.clear();
      vectorSource.current.addFeatures(features);
    }
  }, [suburbAreaLayerVisible, loading, suburbAreas, selectedDistrict]);

  function getTooltip() {
    return (
      <BaseTooltip>
        {hoveredFeatureOnMap?.feature.getProperties().properties.name}
      </BaseTooltip>
    );
  }

  return {
    suburbAreas,
    areaTooltip: getTooltip(),
    layer: vectorLayer.current,
    panel: (
      <>
        <LayerPanel
          checked={suburbAreaLayerVisible}
          value="suburbAreaLayerVisible"
          label="Suburb areas"
          onChange={(val) => setSuburbAreaLayerVisible(val as boolean)}
        />
      </>
    ),
  };
}
