import { useUserContext } from "./useUserContext.hook";

export function useFormatNumber() {
  const { locale } = useUserContext();

  function formatShort(value: number) {
    return new Intl.NumberFormat(locale, {
      notation: "compact",
    }).format(value);
  }

  return { formatShort };
}
