import { api } from "@vuumly-common/common";
import { ChartData, ChartOptions, TooltipItem } from "chart.js";
import Color from "color";
import { useParseRideLength, useQuery, useTheme, useTranslate } from "hooks";
import _ from "lodash";
import { useMemo } from "react";
import { CardProps } from "..";
import { BaseBarChart } from "../../Charts";
import { TextH5 } from "../../Typography/Text";
import { BaseCard } from "../Base.card";

export const RidesPerLenghtGroupBarChartDifferenceCard = (props: CardProps) => {
  const translate = useTranslate();
  const parseRideLength = useParseRideLength();
  const theme = useTheme();

  const { data, isLoading } = useQuery<ChartData<"bar", Record<any, number>[]>>(
    {
      apiEndpoint: api.Endpoint.LengthGroupTotal,
    }
  );

  const clonedData = useMemo(() => {
    if (isLoading || !data?.datasets) {
      return;
    }
    const clonedData = _.cloneDeep(data);

    clonedData.labels = clonedData.datasets.flatMap(
      (obj) =>
        obj.data
          .filter((item: any) => "dimension" in item) // Filter out objects without 'x'
          .map((item: any) => item.dimension) // Extract 'x' values
    );

    clonedData.labels = _.uniq(clonedData.labels);

    clonedData?.datasets.forEach((dataset) => {
      const { label } = dataset;

      if (label === "operator_performance") {
        if (dataset.data.length) {
          dataset.backgroundColor = dataset.data.map((data) => {
            if (data.operator_performance > 0) {
              return theme.postiveBarChartColor;
            }
            return theme.negativeBarChartColor;
          });

          dataset.hoverBackgroundColor = dataset.data.map((data) => {
            if (data.operator_performance > 0) {
              return theme.hoverPositiveBarChartColor;
            }
            return theme.hoverNegativeBarChartColor;
          });
        }

        dataset.label = translate("Your performance");
        dataset.barPercentage = 0.999;
        dataset.parsing = {
          yAxisKey: "operator_performance",
        };
      } else {
        dataset.hidden = true;
        dataset.label = "none";
      }
    });

    return clonedData;
  }, [data]);

  const options: ChartOptions<"bar"> = {
    parsing: {
      xAxisKey: "dimension",
    },

    scales: {
      x: {
        title: {
          text: "Ride length",
          display: true,
        },
        grid: {
          display: false,
        },
        ticks: {
          callback: (idx) => {
            return parseRideLength(
              clonedData?.labels?.[idx as number] as string
            );
          },
        },
        offset: true,
      },
      y: {
        title: {
          text: "Your performance",
        },
        ticks: {
          display: true,
          callback: (value) => {
            return `${value}%`;
          },
        },
        border: {
          display: false,
        },
        grid: {
          display: true,
        },
      },
    },

    plugins: {
      legend: {
        display: false,
        labels: {
          filter(item) {
            return item.text !== "none";
          },
        },
      },
      tooltip: {
        callbacks: {
          title: (ctx) => {
            return `${parseRideLength(ctx[0].label)}`;
          },
          label: (item: TooltipItem<"bar">) => {
            return `${item.dataset.label}: ${item.formattedValue}%`;
          },
        },
      },
      datalabels: {
        display: true,
        formatter: (item: Record<string, any>) =>
          item.operator_performance + "%",
      },
    },
  };

  return (
    <BaseCard
      isLoading={isLoading}
      header={
        <TextH5>
          {translate("Your performance compared to the market by ride length")}
        </TextH5>
      }
    >
      <BaseBarChart options={options} data={clonedData} />
    </BaseCard>
  );
};
