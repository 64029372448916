import { DashboardHeader } from "../../components/Header";
import { DashboardContentWrapper } from "../../components/Wrapper";

import { MapContextProvider, useMapContext } from "../../context/MapContext";
import { BaseMap } from "components/Map";
import { useEffect, useRef, useState } from "react";
import { useSuburbPanelLayer } from "../Distribution/Layers/SuburbPanel";
import { useMapHover } from "../Distribution/hooks/useMapHover";
import { BaseTooltip } from "../../components/Tooltip";
import { HoveredFeature } from "../../context";
import classNames from "classnames";
import { ol } from "../../utils";
import useMapLayer from "../Distribution/useMapLayer";
import { BaseSelect } from "../../components/Select";
import { RouteHeatmapLayer } from "../Distribution/Layers";
import { Deck, PathLayer } from "deck.gl";
import { Layer } from "ol/layer";
import { toLonLat } from "../../utils/ol";
import GL from "@luma.gl/constants";
import { MapSidebar } from "../../components/Sidebar";

interface Props {
  onClick?: (item: HoveredFeature | null) => void;
}

const DebugMap = (props: Props) => {
  const { mapInstance, hoveredFeatureOnMap } = useMapContext();
  useMapHover({ onClick: handleClick });

  function handleClick(item: HoveredFeature | null) {
    props.onClick && props.onClick(item);
  }

  // useEffect(() => {
  //   if (layer && mapInstance) {
  //     mapInstance?.addLayer(layer);
  //   }

  //   return () => {
  //     if (!layer) return;
  //     mapInstance?.removeLayer(layer);
  //   };
  // }, [layer, mapInstance]);

  function getTooltip() {
    //console.log(88, hoveredFeatureOnMap?.feature.getProperties())
    return (
      <BaseTooltip>
        {hoveredFeatureOnMap?.feature.getProperties().overlapCount}
      </BaseTooltip>
    );
  }

  return (
    <div>
      <div
        className={classNames({
          "h-full w-full flex-1 rounded-lg overflow-hidden": true,
          "cursor-pointer": hoveredFeatureOnMap,
        })}
      >
        <BaseMap
          center={[24.1128363, 56.9138477]}
          tooltip={getTooltip()}
          style={{ height: 800, width: `100%` }}
        />
      </div>
    </div>
  );
};

const TextArea = () => {
  const [data, setData] = useState<any>();

  const { mapInstance, hoveredFeatureOnMap } = useMapContext();
  const [dataType, setDataType] = useState<
    "coordinates" | "feature_collection"
  >("coordinates");
  const { clearLayer, vectorSource, vectorLayer } = useMapLayer({
    layerInstance: RouteHeatmapLayer,
    zIndex: 3,
    // style: new ol.Style({
    //   stroke: new ol.Stroke({
    //     color: `red`,
    //     width: 6,
    //   }),
    // }),
  });

  useEffect(() => {
    const data = fetch("/data/routes.ndjson")
      .then((response) => response.text())
      .then((text) => {
        const lines = text.split("\n");
        const data = lines
          .filter((e) => e)
          .map((line) => {
            //  console.log(line)
            return JSON.parse(line);
          });
        setData(data);
        // Process the text
      });
  }, []);

  const deckRef = useRef<Deck | null>(null);
  const deckLayerRef = useRef<Layer | null>(null);

  // const handleViewStateChange = ({ viewState }: any) => {
  //   setViewState(viewState);
  // };

  useEffect(() => {
    if (!mapInstance || !data) return;

    const layer = new PathLayer({
      id: "PathLayer",
      data: data,
      getColor: (d: any) => {
        const hex = d.color;
        // convert to RGB
        return [255, 0, 0];
      },
      getPath: (d: any) => d,
      getWidth: (d) => 5,
      widthMinPixels: 2,
      widthScale: 2,
      opacity: 0.0001,
      parameters: {
        //depthMask: false,
        //   blend: true,
        // blendFunc: [GL.SRC_ALPHA, GL.ONE],
        // blendEquation: GL.FUNC_ADD
      },
      pickable: true,
    });

    const deckGL = new Deck({
      initialViewState: {
        longitude: 0,
        latitude: 0,
        zoom: 1,
      },
      // onViewStateChange: handleViewStateChange,
      controller: true,
      parent: document.getElementById("map") as HTMLElement,
      style: {
        mixBlendMode: "darken",
        top: 0,
        pointerEvents: "none",
        "z-index": 1,
      },
      layers: [layer],
    });

    deckRef.current = deckGL;

    const deckLayer = new Layer({
      render: function ({ size, viewState }): any {
        const [width, height] = size;
        const [longitude, latitude] = toLonLat(viewState.center);
        const zoom = viewState.zoom - 1;
        const bearing = (-viewState.rotation * 180) / Math.PI;
        const deckViewState = {
          bearing,
          longitude,
          latitude,
          zoom,
        };
        deckRef.current?.setProps({
          width,
          height,
          viewState: deckViewState,
        });
        deckRef.current?.redraw({});
      },
    });

    deckLayerRef.current = deckLayer;

    mapInstance?.addLayer(deckLayer);

    // mapInstance?.addLayer(deckLayer);

    return () => {
      // mapInstance?.removeLayer(deckLayer);
      deckRef.current = null;
      deckGL.finalize();
      if (!deckLayerRef.current) return;
      mapInstance?.removeLayer(deckLayer);
    };
  }, [mapInstance, data]);

  function handleChange(e: any) {
    // @ts-ignore
    // window.mapRef = mapInstance
    //console.log(e.target.value, mapInstance);
    clearLayer();

    try {
      if (dataType === "coordinates") {
        var lineString = new ol.LineString(JSON.parse(e.target.value));
        lineString.transform("EPSG:4326", "EPSG:3857");

        const lineFeature = new ol.Feature({
          geometry: lineString,
        });

        vectorSource.current?.addFeature(lineFeature);

        vectorSource.current?.addFeature(
          new ol.Feature({
            geometry: new ol.Point(ol.fromLonLat([24.1092773, 56.9132978])),
          })
        );
      } else if (dataType === "feature_collection") {
        const featureCollection = new ol.GeoJSON().readFeatures(
          JSON.parse(e.target.value),
          {
            // Assuming the source data is in 'EPSG:4326' and the map uses 'EPSG:3857'
            dataProjection: "EPSG:4326",
            featureProjection: "EPSG:3857",
          }
        );

        featureCollection.forEach((feature) => {
          console.log(feature.getProperties().overlapCount / 500);
          feature.setStyle(
            new ol.Style({
              stroke: new ol.Stroke({
                color: `rgba(255,0,0,  ${
                  feature.getProperties().overlapCount / 500
                })`,
                width: 6,
              }),
            })
          );
        });

        vectorSource.current?.addFeatures(featureCollection);
      }
    } catch (e) {
      console.log(e);
    }
  }

  function dataTypeChanged(value: any) {
    setDataType(value);
  }

  return (
    <div>
      <BaseSelect
        className="mb-4"
        onChange={dataTypeChanged}
        value={dataType}
        options={[
          { label: "Coordinates", value: "coordinates", id: "coordinates" },
          {
            label: "Feature collection",
            value: "feature_collection",
            id: "feature_collection",
          },
        ]}
      />
      <textarea
        onChange={handleChange}
        className="border w-full"
        name="postContent"
        rows={20}
      />
    </div>
  );
};

export function PathMap() {
  return (
    <MapContextProvider>
      <DashboardContentWrapper className="mb-32">
        <DashboardHeader title="Path map" />
        <div className="flex">
          <div className="w-full">
            <DebugMap />
          </div>
          <div className="hidden w-2/6">
            <MapSidebar className="relative right-0 top-0 w-full">
              <div className="p-4">
                <TextArea />
              </div>
            </MapSidebar>
          </div>
        </div>
      </DashboardContentWrapper>
    </MapContextProvider>
  );
}

// const data = [
//   [
//     [-122.3535851, 37.9360513],
//     [-122.3179784, 37.9249513],
//     [-122.300284, 37.902646],
//     [-122.2843653, 37.8735039],
//     [-122.269058, 37.8694562],
//     [-122.2709185, 37.85301],
//     [-122.2689342, 37.8283973],
//     [-122.2707195, 37.8080566],
//     [-122.2718706, 37.804996],
//     [-122.2948251, 37.8064628],
//     [-122.3971496, 37.794745],
//     [-122.4030149, 37.7905282],
//     [-122.4084631, 37.7862646],
//     [-122.4157833, 37.7803439],
//     [-122.4219476, 37.7653052],
//     [-122.4206096, 37.7528545],
//     [-122.4359052, 37.7340058],
//     [-122.448995, 37.723273],
//     [-122.471192, 37.7067871],
//     [-122.4672607, 37.6842548],
//     [-122.4449822, 37.6635925],
//     [-122.4169649, 37.6372339],
//     [-122.3876274, 37.5993171],
//   ],
//   [
//     [-122.353165, 37.936887],
//     [-122.317269, 37.925655],
//     [-122.299272, 37.903059],
//     [-122.283451, 37.87404],
//     [-122.268045, 37.869867],
//     [-122.26978, 37.853024],
//     [-122.2677961, 37.8284091],
//     [-122.2695925, 37.8079322],
//     [-122.2721546, 37.8035508],
//     [-122.2666476, 37.7964139],
//     [-122.2253433, 37.7739122],
//     [-122.1985085, 37.7530763],
//     [-122.1625282, 37.7216747],
//     [-122.1280105, 37.6961822],
//     [-122.0888196, 37.669805],
//     [-122.0584803, 37.6342836],
//     [-122.0187396, 37.5906334],
//     [-121.9772135, 37.5567286],
//   ],

//   [
//     [-121.945154, 38.018914],
//     [-122.024597, 38.003275],
//     [-122.029095, 37.973737],
//     [-122.056013, 37.928403],
//     [-122.067423, 37.905628],
//     [-122.123801, 37.893394],
//     [-122.183791, 37.878361],
//     [-122.251793, 37.844601],
//     [-122.2666934, 37.8282587],
//     [-122.2684655, 37.8078078],
//     [-122.2715151, 37.80322],
//     [-122.2947035, 37.8055689],
//     [-122.3969458, 37.7938605],
//     [-122.402211, 37.7898921],
//     [-122.40766, 37.7856278],
//     [-122.4147696, 37.779936],
//     [-122.4208208, 37.7651836],
//     [-122.4195378, 37.7525542],
//     [-122.4349986, 37.7334634],
//     [-122.4482045, 37.722627],
//     [-122.4701365, 37.7064541],
//     [-122.466233, 37.684638],
//     [-122.444116, 37.664174],
//     [-122.416038, 37.637753],
//     [-122.392612, 37.616035],
//     [-122.38666, 37.599787],
//   ],

//   [
//     [-121.900367, 37.701695],
//     [-121.928099, 37.699759],
//     [-122.075567, 37.690754],
//     [-122.1266292, 37.6975918],
//     [-122.160905, 37.7229335],
//     [-122.1968609, 37.7543157],
//     [-122.2239172, 37.7753131],
//     [-122.2653405, 37.7978803],
//     [-122.294582, 37.804675],
//     [-122.396742, 37.792976],
//     [-122.401407, 37.789256],
//     [-122.406857, 37.784991],
//     [-122.413756, 37.779528],
//     [-122.419694, 37.765062],
//     [-122.418466, 37.752254],
//     [-122.434092, 37.732921],
//     [-122.447414, 37.721981],
//     [-122.469081, 37.706121],
//   ],
//   [
//     [-121.9764, 37.557355],
//     [-122.017867, 37.591208],
//     [-122.057551, 37.6348],
//     [-122.087967, 37.670399],
//     [-122.1272512, 37.696851],
//     [-122.161717, 37.7223045],
//     [-122.1976851, 37.7536963],
//     [-122.2246308, 37.7746129],
//     [-122.2658775, 37.7970877],
//     [-122.2945333, 37.8037768],
//     [-122.3965382, 37.7920915],
//     [-122.4006031, 37.7886199],
//     [-122.406054, 37.7843542],
//     [-122.4127424, 37.77912],
//     [-122.4185672, 37.7649404],
//     [-122.4173942, 37.7519538],
//     [-122.4331854, 37.7323786],
//     [-122.4466235, 37.721335],
//     [-122.4683093, 37.705461],
//   ],
// ];

type ViewState = {
  /** Longitude at map center */
  longitude: number;
  /** Latitude at map center */
  latitude: number;
  /** Map zoom level */
  zoom: number;
  /** Map rotation bearing in degrees counter-clockwise from north */
  bearing: number;
  /** Map angle in degrees at which the camera is looking at the ground */
  pitch: number;
  /** Dimensions in pixels applied on each side of the viewport for shifting the vanishing point. */
  padding: PaddingOptions;
};

type PaddingOptions = {
  top: number;
  bottom: number;
  left: number;
  right: number;
};
