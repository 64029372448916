import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export function useCompany() {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedCompanies = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.getAll("companies[]");
  }, [searchParams]);

  const setSelectedCompanies = useCallback(
    (payload: string[]) => {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete("companies[]");
      payload.forEach((item) => {
        searchParams.append("companies[]", item);
      });
      setSearchParams(searchParams);
    },
    [searchParams]
  );

  const removeSelectedCompany = useCallback(
    (payload: string) => {
      const searchParams = new URLSearchParams(window.location.search);
      const selectedCompanies = searchParams.getAll("companies[]");

      if (selectedCompanies.includes(payload)) {
        searchParams.delete("companies[]");
        selectedCompanies.forEach((item) => {
          if (item !== payload) {
            searchParams.append("companies[]", item);
          }
        });
        setSearchParams(searchParams);
      }
    },
    [searchParams]
  );

  return {
    selectedCompanies,
    setSelectedCompanies,
    removeSelectedCompany,
  };
}
