import { Navigate, Outlet } from "react-router-dom";
import { RoutePathEnum } from "../../controllers/router";
import { useUserContext } from "../../hooks";

interface ProtectedRoute {
  redirectPath?: string;
  children?: JSX.Element;
}

export function ProtectedRoute({
  redirectPath = RoutePathEnum.SignIn,
  children,
}: ProtectedRoute) {
  const { isSigned } = useUserContext();

  if (!isSigned) {
    return <Navigate to={redirectPath} replace />;
  }

  if (!children) {
    return <Outlet />;
  }

  return children;
}
