import { ChartType, TooltipPositionerFunction } from "chart.js";

export const tooltipPositioner: TooltipPositionerFunction<ChartType> =
  function (elements, eventPosition) {
    const { x, y } = eventPosition;
    const { ctx, chartArea } = this.chart;
    const { left, right, top, bottom } = chartArea;

    if (x >= left && x <= right) {
      ctx.save();
      ctx.lineWidth = 1;
      ctx.setLineDash([3, 3]);
      ctx.strokeStyle = "#FF4949";

      // draw vertical line
      ctx.beginPath();
      ctx.moveTo(x, top);
      ctx.lineTo(x, bottom);
      ctx.stroke();

      ctx.restore();
    }
    return eventPosition;
  };

export const cursor: TooltipPositionerFunction<ChartType> = function (
  elements,
  eventPosition
) {
  return eventPosition;
};
