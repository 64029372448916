import { useDemoMode, useRouter, useUserContext } from "../../hooks";

import {
  CityEnum,
  CountryEnum,
  DeviceGroupEnum,
  getCitiesByCountry,
} from "@vuumly-common/common";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut as signOutFirebase,
} from "firebase/auth";
import { useEffect, useLayoutEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SplashLoading } from "../../components/Layouts";
import { Toaster } from "../../components/shadcn/components/ui/sonner";
import { RoutePathEnum } from "../../controllers/router";

interface IApplicationWrapperProps {
  children: JSX.Element;
}

export function ApplicationWrapper(props: IApplicationWrapperProps) {
  const { goTo, pathname } = useRouter();
  const [_searchParams, setSearchParams] = useSearchParams();
  const { children } = props;
  const [isAppReady, setIsAppReady] = useState(false);
  const { isDemoModeEnabled } = useDemoMode();

  const {
    isSigned,
    isAuthInit,
    isProfileInit,
    isSubscribed,
    subscribedProducts,
    subscribedCities,
    subscribedCountries,
    isOperator,
    operatorId,
  } = useUserContext();

  useEffect(() => {
    if (isProfileInit) {
      const searchParams = new URLSearchParams(window.location.search);

      // init country

      const countries = searchParams.getAll("countries[]") as CountryEnum[];

      if (countries.length === 0 && subscribedCountries.length > 0) {
        searchParams.set("countries[]", subscribedCountries[0]);
        setSearchParams(searchParams, { replace: true });
      }

      if (countries.length && !subscribedCountries.includes(countries[0])) {
        searchParams.delete("countries[]");
        searchParams.delete("cities[]");
        searchParams.set("countries[]", subscribedCountries[0]);
        setSearchParams(searchParams, { replace: true });
      }

      const cities = searchParams.getAll("cities[]") as CityEnum[];
      const selectedCountry = searchParams.get(
        "countries[]"
      ) as CountryEnum | null;

      if (cities.length && selectedCountry) {
        const citiesInCountry = getCitiesByCountry(selectedCountry).map(
          (item) => item.value
        );

        const activeCities = cities
          .filter((item) => citiesInCountry.includes(item))
          .filter((item) => subscribedCities.includes(item));
        searchParams.delete("cities[]");

        activeCities.forEach((city) => {
          searchParams.append("cities[]", city);
        });

        setSearchParams(searchParams, { replace: true });
      }

      // init daterange

      const startDateParam = searchParams.get("start_date");
      const endDateParam = searchParams.get("end_date");

      if (startDateParam && endDateParam) {
        searchParams.set(
          "start_date",
          format(new Date(startDateParam), "yyyy-MM-dd")
        );
        searchParams.set(
          "end_date",
          format(new Date(endDateParam), "yyyy-MM-dd")
        );
        setSearchParams(searchParams, { replace: true });
      } else {
        const today = addDays(new Date(), -1);
        const lastMonth = new Date(addDays(new Date(), -28));

        searchParams.set("start_date", format(lastMonth, "yyyy-MM-dd"));
        searchParams.set("end_date", format(today, "yyyy-MM-dd"));

        setSearchParams(searchParams, { replace: true });
      }

      // init device group

      const deviceGroup = searchParams.get("device_group") as DeviceGroupEnum;

      const subscribedDeviceGroupsInCity = subscribedProducts
        .filter((item) => item.metadata.city === subscribedCities[0])
        .map((item) => item.metadata.device_group);

      if (deviceGroup && subscribedDeviceGroupsInCity.includes(deviceGroup)) {
        searchParams.set("device_group", deviceGroup);
        setSearchParams(searchParams, { replace: true });
      } else if (
        (!deviceGroup && subscribedDeviceGroupsInCity.length > 0) ||
        (deviceGroup && !subscribedDeviceGroupsInCity.includes(deviceGroup))
      ) {
        searchParams.delete("device_types[]");
        searchParams.delete("ride_length[]");
        searchParams.set("device_group", subscribedDeviceGroupsInCity[0]);
        setSearchParams(searchParams, { replace: true });
      }

      // init operator mode
      if (!isOperator) {
        searchParams.delete("operator_id");
        setSearchParams(searchParams, { replace: true });
      } else if (operatorId) {
        searchParams.set("operator_id", String(operatorId));
        setSearchParams(searchParams, { replace: true });
      }

      if (searchParams.get("device_group") === DeviceGroupEnum.Micromobility) {
        searchParams.delete("engine_type[]");
        setSearchParams(searchParams, { replace: true });
      }

      setIsAppReady(true);
    }
  }, [isProfileInit]);

  useLayoutEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    let user_id = searchParams.get("demo_user_id");

    if (user_id && !user_id.includes("@")) {
      user_id = `${user_id}@vuumly.com`;
    }

    if (!isSigned && user_id) {
      searchParams.delete("demo_user_id");
      setSearchParams(searchParams, { replace: true });

      const token = user_id.split("@")[0];
      const auth = getAuth();

      signOutFirebase(auth).then(() => {
        if (!user_id) return;
        signInWithEmailAndPassword(auth, user_id, token);
      });
    }

    if (pathname !== RoutePathEnum.VerifyEmail && searchParams.has("oobCode")) {
      searchParams.delete("mode");
      searchParams.delete("oobCode");
      searchParams.delete("apiKey");
      searchParams.delete("lang");
      setSearchParams(searchParams, { replace: true });
    }

    if (isSigned && searchParams.has("demo_mode")) {
      searchParams.delete("demo_mode");
      setSearchParams(searchParams, { replace: true });
    }

    if (
      !isDemoModeEnabled &&
      isAuthInit &&
      !isSigned &&
      pathname !== RoutePathEnum.SignIn &&
      pathname !== RoutePathEnum.SignUp &&
      pathname !== RoutePathEnum.VerifyEmail &&
      pathname !== RoutePathEnum.NotSubscribed &&
      pathname !== RoutePathEnum.CheckEmail &&
      pathname !== RoutePathEnum.Demo
    ) {
      goTo(RoutePathEnum.SignIn);
    } else if (
      isSigned &&
      isSubscribed &&
      (pathname === RoutePathEnum.SignIn ||
        pathname === RoutePathEnum.SignUp ||
        pathname === RoutePathEnum.VerifyEmail ||
        pathname === RoutePathEnum.CheckEmail)
    ) {
      console.log("goTo Home", pathname);
      goTo(RoutePathEnum.Home);
    } else if (!isSigned && pathname === RoutePathEnum.NotSubscribed) {
      goTo(RoutePathEnum.SignIn);
    } else if (isSigned && isProfileInit && !isSubscribed) {
      goTo(RoutePathEnum.NotSubscribed);
    }
  }, [isSigned, isAuthInit, isSubscribed, isProfileInit, pathname]);

  const showSplash = !isAuthInit || (isSigned && !isProfileInit && isAppReady);

  if (!isAppReady && isSigned) {
    return <SplashLoading />;
  }

  if (showSplash) {
    return <SplashLoading />;
  }

  return (
    <>
      {children}
      <Toaster />
    </>
  );
}
