export function PageContentLayout(props: React.HtmlHTMLAttributes<HTMLDivElement>) {
  const { children } = props;

  return (
    <div className="p-12">
      <div className="md:max-w-7xl mx-auto">
        {children}
      </div>
    </div>
  );
}
