import { LoadingIndicator } from "components/Progress";
import { Button } from "components/shadcn/components/ui/button";
import React, { forwardRef } from "react";
import { ButtonVariants } from "./index";

export const BaseButton = forwardRef(function RefButton(
  props: React.ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: ButtonVariants;
    isLoading?: boolean;
  },
  ref
) {
  const purged = { ...props };
  delete purged.isLoading;
  
  const { variant, children, isLoading } = props;

  return (
    <Button variant={variant} {...purged}>
      {isLoading && (
        <div className="mr-2">
          <LoadingIndicator color="white" />
        </div>
      )}
      {children}
    </Button>
  );
});
