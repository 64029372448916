import { DeviceGroupEnum, time } from "@vuumly-common/common";
import { RideLengthEnum } from "@vuumly-common/common/dist/time";
import {
  useDeviceGroup,
  useParseRideLength,
  useRideLength,
  useTranslate,
} from "hooks";
import { useMemo } from "react";
import { BaseFilterOption, BaseFilterSelect } from "../BaseFilterSelect";
import { BaseOption } from "../BaseSelector";

export function RideLengthSelect() {
  const parseRideLength = useParseRideLength();
  const translate = useTranslate();

  const { selectedDeviceGroup } = useDeviceGroup();
  const { selectedRideLength, setSelectedRideLength } = useRideLength();

  const list = useMemo(() => {
    let options =
      selectedDeviceGroup === DeviceGroupEnum.Micromobility
        ? rideLengtMicromobilityDefault
        : rideLengthCarsharingDefault;

    return options.map((item) => {
      return {
        ...item,
        label: parseRideLength(item.value),
        selected: selectedRideLength.includes(item.value),
      };
    });
  }, [selectedDeviceGroup]);

  const onChange = (payload: BaseFilterOption<RideLengthEnum>[]) => {
    const selected = payload
      .filter((item) => item.selected)
      .map((item) => item.value);
    setSelectedRideLength(selected);
  };

  return (
    <BaseFilterSelect<time.RideLengthEnum>
      title={translate("ride_length_filter_label")}
      options={list}
      onChange={onChange}
    />
  );
}

const def = {
  selected: false,
  disabled: false,
  hasMatch: false,
  subscribed: false,
};

export const rideLengthCarsharingDefault: BaseOption<time.RideLengthEnum>[] = [
  {
    label: time.RideLengthEnum.Bellow_30Min,
    value: time.RideLengthEnum.Bellow_30Min,
    id: time.RideLengthEnum.Bellow_30Min,
    ...def,
  },
  {
    label: time.RideLengthEnum.Between_30Min_And_1Hour,
    value: time.RideLengthEnum.Between_30Min_And_1Hour,
    id: time.RideLengthEnum.Between_30Min_And_1Hour,
    ...def,
  },
  {
    label: time.RideLengthEnum.Between_1_And_2Hour,
    value: time.RideLengthEnum.Between_1_And_2Hour,
    id: time.RideLengthEnum.Between_1_And_2Hour,
    ...def,
  },
  {
    label: time.RideLengthEnum.Between_2Hour_And_1Day,
    value: time.RideLengthEnum.Between_2Hour_And_1Day,
    id: time.RideLengthEnum.Between_2Hour_And_1Day,
    ...def,
  },
  {
    label: time.RideLengthEnum.Between_1_And_3Day,
    value: time.RideLengthEnum.Between_1_And_3Day,
    id: time.RideLengthEnum.Between_1_And_3Day,
    ...def,
  },
  {
    label: time.RideLengthEnum.Above3Day,
    value: time.RideLengthEnum.Above3Day,
    id: time.RideLengthEnum.Above3Day,
    ...def,
  },
];

export const rideLengtMicromobilityDefault: BaseOption<time.RideLengthEnum>[] =
  [
    {
      label: time.RideLengthEnum.Bellow_5Min,
      value: time.RideLengthEnum.Bellow_5Min,
      id: time.RideLengthEnum.Bellow_5Min,
      ...def,
    },
    {
      label: time.RideLengthEnum.Between_5Min_And_10Min,
      value: time.RideLengthEnum.Between_5Min_And_10Min,
      id: time.RideLengthEnum.Between_5Min_And_10Min,
      ...def,
    },
    {
      label: time.RideLengthEnum.Between_10Min_And_15Min,
      value: time.RideLengthEnum.Between_10Min_And_15Min,
      id: time.RideLengthEnum.Between_10Min_And_15Min,
      ...def,
    },
    {
      label: time.RideLengthEnum.Between_15Min_And_20Min,
      value: time.RideLengthEnum.Between_15Min_And_20Min,
      id: time.RideLengthEnum.Between_15Min_And_20Min,
      ...def,
    },
    {
      label: time.RideLengthEnum.Between_20Min_And_30Min,
      value: time.RideLengthEnum.Between_20Min_And_30Min,
      id: time.RideLengthEnum.Between_20Min_And_30Min,
      ...def,
    },
    {
      label: time.RideLengthEnum.Above_2Hour,
      value: time.RideLengthEnum.Above_2Hour,
      id: time.RideLengthEnum.Above_2Hour,
      ...def,
    },
  ];
