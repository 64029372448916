import { time } from "@vuumly-common/common";
import { toast } from "sonner";
import { useConstants, useDateRange } from "../../hooks";
import { BaseTabs, IBaseTab } from "./BaseTabs";

interface ITimeGranularityTabs {
  onChange: (value: time.GranularityEnum) => void;
  defaultValue: time.GranularityEnum;
  tabs?: IBaseTab[];
}

export function TimeGranularityTabs(props: ITimeGranularityTabs) {
  const {
    TIME_GRANULARITY_MIN_RANGE_FOR_MONTH_OPTION,
    TIME_GRANULARITY_MAX_RANGE_FOR_DAY_OPTION,
    TIME_GRANULARITY_MAX_RANGE_FOR_WEEK_OPTION,
    TIME_GRANULARITY_MIN_RANGE_FOR_WEEK_OPTION,
  } = useConstants();
  const { selectedDaysCount } = useDateRange();
  const { onChange, defaultValue, tabs } = props;

  const items: IBaseTab[] = tabs || [
    { value: time.GranularityEnum.Month, label: "By month" },
    { value: time.GranularityEnum.Week, label: "By week" },
    { value: time.GranularityEnum.Day, label: "By day" },
  ];

  function onClick(item: IBaseTab) {
    if (selectedDaysCount === null) return;
    if (item.disabled) {
      if (
        item.value === time.GranularityEnum.Month &&
        selectedDaysCount < TIME_GRANULARITY_MIN_RANGE_FOR_MONTH_OPTION
      ) {
        toast.error(
          `The selected date range is too short for this chart. Please select a date range greater than ${TIME_GRANULARITY_MIN_RANGE_FOR_MONTH_OPTION} days to activate the option.`
        );
      } else if (
        item.value === time.GranularityEnum.Day &&
        selectedDaysCount > TIME_GRANULARITY_MAX_RANGE_FOR_DAY_OPTION
      ) {
        toast.error(
          `Selected date range is too long for this chart. Please select a date range less than ${TIME_GRANULARITY_MAX_RANGE_FOR_DAY_OPTION} days to activate the option.`
        );
      } else if (
        item.value === time.GranularityEnum.Week &&
        TIME_GRANULARITY_MAX_RANGE_FOR_WEEK_OPTION < selectedDaysCount
      ) {
        toast.error(
          `The selected date range is too long for this chart. Please select a date range less than ${TIME_GRANULARITY_MAX_RANGE_FOR_WEEK_OPTION} days to activate the option.`
        );
      } else if (
        item.value === time.GranularityEnum.Week &&
        TIME_GRANULARITY_MIN_RANGE_FOR_WEEK_OPTION > selectedDaysCount
      ) {
        toast.error(
          `The selected date range is too short for this chart. Please select a date range greater than ${TIME_GRANULARITY_MIN_RANGE_FOR_WEEK_OPTION} days to activate the option.`
        );
      }
    }
  }

  return (
    <div className="flex items-end flex-col">
      <BaseTabs
        onClick={onClick}
        onChange={(val) => onChange(val as time.GranularityEnum)}
        items={items}
        defaultValue={defaultValue}
      />
    </div>
  );
}
