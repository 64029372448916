import isWithinInterval from "date-fns/isWithinInterval";
import lv from "date-fns/locale/lv";
import parse from "date-fns/parse";
import { DateRange } from "react-day-picker";

export enum DaterangeError {
  Invalid = "daterange_invalid",
  OutOfRange = "daterange_out_of_range",
  InvalidRange = "daterange_invalid_range",
  DateFromMissing = "daterange_date_from_missing",
  DateToMissing = "daterange_date_to_missing",
  FromLargerThanTo = "daterange_from_larger_than_to",
}

export type DateValidationErrors = "invalid" | "outOfRange";

export function isDateValid(
  date: string,
  dateRange: DateRange,
  locale?: string
): Date | DateValidationErrors {
  const maxRange = dateRange;

  try {
    const patterns = ["dd.MM.yyyy", "dd.MM.yyyy.", "dd-MM-yyyy", "yyyy-MM-dd"];
    let parsedDate;

    for (let pattern of patterns) {
      parsedDate = parse(date, pattern, new Date(), {
        locale: lv,
      });

      if (
        parsedDate.toString() !== "Invalid Date" &&
        !isNaN(new Date(parsedDate).getTime())
      ) {
        break;
      }
    }

    if (parsedDate && parsedDate?.toString() !== "Invalid Date") {
      const isWithinRange = isWithinInterval(parsedDate, {
        start: maxRange.from!,
        end: maxRange.to!,
      });

      if (isWithinRange) {
        return parsedDate;
      } else {
        return "outOfRange";
      }
    }

    //    / console.log(6666, parsedDate);
  } catch (e) {
    console.error(e);
  }

  return "invalid";
}
