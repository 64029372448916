import { Label } from "components/shadcn/components/ui/label";
import { cn } from "../shadcn/lib/utils";
import { useTranslate } from "hooks";

interface IBaseLabel extends React.ComponentPropsWithoutRef<"label"> {
  htmlFor: string;
  label: string;
}

export function BaseLabel(props: IBaseLabel) {
  const { htmlFor, label, className } = props;
  const translate = useTranslate();
  return (
    <Label
      className={cn("cursor-pointer select-none", className)}
      htmlFor={htmlFor}
    >
      {translate(label)}
    </Label>
  );
}
