import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from "@radix-ui/react-icons";
import { Table } from "@tanstack/react-table";
import { Button } from "../shadcn/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../shadcn/components/ui/select";

export interface DataTablePaginationProps<TData> {
  table: Table<TData>;
  isSelectedRowCountVisible?: boolean;
  isRowCountPerPageVisible?: boolean;
  signleArrows?: boolean;
  enabled?: boolean;
}

const RowCountPerPage = ({ table }: { table: Table<any> }) => {
  return (
    <div className="flex items-center space-x-2">
      <p className="text-[13px] text-muted-foreground">Rows per page</p>
      <Select
        value={`${table.getState().pagination.pageSize}`}
        onValueChange={(value) => {
          table.setPageSize(Number(value));
        }}
      >
        <SelectTrigger className="h-8 w-[70px]">
          <SelectValue placeholder={table.getState().pagination.pageSize} />
        </SelectTrigger>
        <SelectContent side="top">
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <SelectItem key={pageSize} value={`${pageSize}`}>
              {pageSize}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

const SelectedRowCount = ({ table }: { table: Table<any> }) => {
  return (
    <>
      {table.getFilteredSelectedRowModel().rows.length} of{" "}
      {table.getFilteredRowModel().rows.length} row(s) selected.
    </>
  );
};

export function DataTablePagination<TData>(
  props: DataTablePaginationProps<TData>
) {
  const {
    table,
    isSelectedRowCountVisible,
    isRowCountPerPageVisible = true,
    signleArrows = false,
  } = props;

  return (
    <div className="flex items-center justify-between px-2">
      <div className="flex-1 text-[13px] text-muted-foreground">
        {isSelectedRowCountVisible && <SelectedRowCount table={table} />}
      </div>
      <div className="flex items-center space-x-6 lg:space-x-8">
        {isRowCountPerPageVisible && <RowCountPerPage table={table} />}
        <div className="flex w-[100px] items-center justify-center text-[13px] text-muted-foreground">
          Page {table.getState().pagination.pageIndex + 1} of{" "}
          {table.getPageCount()}
        </div>
        <div className="flex items-center space-x-2">
          {!signleArrows && (
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              <span className="sr-only">Go to first page</span>
              <DoubleArrowLeftIcon className="h-4 w-4" />
            </Button>
          )}
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <span className="sr-only">Go to previous page</span>
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <span className="sr-only">Go to next page</span>
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
          {!signleArrows && (
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              <span className="sr-only">Go to last page</span>
              <DoubleArrowRightIcon className="h-4 w-4" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
