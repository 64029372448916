import { DeviceEnum, getCityByValue } from "@vuumly-common/common";
import {
  useCity,
  useCompany,
  useDayOfWeek,
  useDeviceType,
  useDistrict,
  useEngineType,
  useFilterHelper,
  useHourOfDay,
  useOperatorMode,
  useParseRideLength,
  useRideLength,
  useTranslate,
} from "hooks";
import { BaseButton } from "../Button";
import { TextMuted } from "../Typography/Text";
import { BaseBadge } from "../Badge";

export function ActiveFilters() {
  const { selectedDistrict, selectedDistrictName, removeSelectedDistrict } =
    useDistrict();
  const { selectedDaysOfWeek, removeSelectedDayOfWeek } = useDayOfWeek();
  const { selectedHourOfDay, removeSelectedHourOfDay } = useHourOfDay();
  const { selectedRideLength, removeSelectedRideLength } = useRideLength();
  const { selectedDeviceTypes, removeSelectedDeviceType } = useDeviceType();
  const { selectedCompanies, removeSelectedCompany } = useCompany();
  const { selectedCities, removeSelectedCity } = useCity();
  const { resetAllSelections } = useFilterHelper();
  const { operatorMode, disableOperatorMode } = useOperatorMode();
  const { selectedEngineTypes, removeSelectedEngineType } = useEngineType();

  const translate = useTranslate();
  const parseRideLength = useParseRideLength();

  const hasActiveFilters =
    selectedDistrict ||
    selectedEngineTypes.length > 0 ||
    selectedDaysOfWeek.length > 0 ||
    selectedHourOfDay.length > 0 ||
    selectedRideLength.length > 0 ||
    selectedDeviceTypes.length > 0 ||
    selectedCompanies.length > 0 ||
    selectedCities.length > 0;

  if (!hasActiveFilters) {
    return <></>;
  }

  return (
    <div className="flex space-x-4 items-center">
      <TextMuted>{translate("active_filter_label")}</TextMuted>
      <div className="flex gap-2 flex-row flex-wrap items-center">
        {selectedCities.map((item) => (
          <BaseBadge
            onClear={removeSelectedCity}
            label={getCityByValue(item)?.name || item}
            id={item}
            key={item}
          />
        ))}
        {selectedDistrict && (
          <BaseBadge
            onClear={removeSelectedDistrict}
            label={selectedDistrictName}
            id={selectedDistrict}
          />
        )}
        {selectedDeviceTypes.map((item) => (
          <BaseBadge
            onClear={removeSelectedDeviceType}
            label={translate(item)}
            id={item as DeviceEnum}
            key={item}
          />
        ))}
        {selectedEngineTypes.map((item) => (
          <BaseBadge
            onClear={removeSelectedEngineType}
            label={translate(item)}
            id={item}
            key={item}
          />
        ))}
        {selectedDaysOfWeek.map((item) => (
          <BaseBadge
            onClear={removeSelectedDayOfWeek}
            label={translate(item)}
            id={item}
            key={item}
          />
        ))}
        {selectedHourOfDay.map((item) => (
          <BaseBadge
            onClear={removeSelectedHourOfDay}
            label={translate(item)}
            id={item}
            key={item}
          />
        ))}
        {selectedRideLength.map((item) => (
          <BaseBadge
            onClear={removeSelectedRideLength}
            label={parseRideLength(item)}
            id={item}
            key={item}
          />
        ))}
        {selectedCompanies.map((item) => (
          <BaseBadge
            onClear={removeSelectedCompany}
            label={`Company ${item}`}
            id={item}
            key={item}
          />
        ))}
        <BaseButton variant="link" onClick={resetAllSelections}>
          Clear filter
        </BaseButton>
      </div>
    </div>
  );
}
