import { DeviceGroupEnum, location } from "@vuumly-common/common";
import { TextH2, TextH5, TextMuted } from "components/Typography/Text";
import { HelpCircleIcon } from "lucide-react";
import { useLayoutEffect, useState } from "react";
import { BaseCheckbox } from "../../components";
import { BaseButton } from "../../components/Button";
import { BaseCheckboxState } from "../../components/Checkbox/BaseCheckbox";
import { CitySelect, CountrySelect } from "../../components/Select";
import { DeviceTypeGroupTabs } from "../../components/Tabs";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/shadcn/components/ui/tooltip";
import {
  useCity,
  useCountry,
  useDateRange,
  useDeviceGroup,
  useOperatorMode,
  useRouter,
  useUserContext,
} from "../../hooks";
import { useDemoMode } from "../../hooks/useDemoMode";
import { AuthWrapper } from "./AuthWrapper";
import { RoutePathEnum } from "../../controllers/router";
import { useSearchParams } from "react-router-dom";

export function DemoPage() {
  const { setDemoMode } = useDemoMode();
  const { selectedCities } = useCity();
  const { selectedDeviceGroup } = useDeviceGroup();
  const { selectedCountryValue } = useCountry();
  const { setOperatorId } = useOperatorMode();
  const { setSelectedDateRange } = useDateRange();
  const { goTo } = useRouter();
  const [_searchParams] = useSearchParams();

  const [operatorMode, setOperatorMode] = useState(false);

  const { signOut, isSigned } = useUserContext();

  if (isSigned) {
    signOut();
  }

  function handleSubmit() {
    if (!selectedCountryValue) {
      alert("Please select country");
      return;
    }

    if (!selectedCities || selectedCities.length === 0) {
      alert("Please select city");
      return;
    }

    if (!selectedDeviceGroup) {
      alert("Please select device type");
      return;
    }

    const city = location.cities.getCityByValue(selectedCities[0]);

    if (
      city?.demoSettings &&
      selectedDeviceGroup !== DeviceGroupEnum.All &&
      city.demoSettings[selectedDeviceGroup]
    ) {
      const searchParams = setOperatorId(
        city.demoSettings[selectedDeviceGroup].operatorId,
        {
          navigateOptions: { replace: true },
        }
      );

      setSelectedDateRange(city.demoSettings[selectedDeviceGroup].dateRange, {
        navigateOptions: { replace: true },
        searchParams,
      });
    }

    goTo(RoutePathEnum.Rides);

    // set operator id
  }

  useLayoutEffect(() => {
    setDemoMode(true, {
      navigateOptions: { replace: true },
      searchParams: new URLSearchParams(),
    });
  }, []);

  function handleOperatorMode(value: BaseCheckboxState) {
    if (value !== "indeterminate") setOperatorMode(value);
  }

  return (
    <AuthWrapper>
      <div className="flex flex-col space-y-2 ">
        <TextH2>Demo setup</TextH2>
        <TextMuted>
          Before we start please configure your demo settings
        </TextMuted>
        {/* {demoState.isEnabled && <TextH2>Demo mode enabled</TextH2>} */}
        <div className="space-y-8">
          <CountrySelect navigateOptions={{ replace: true }} />
          <div>
            <CitySelect navigateOptions={{ replace: true }} />

            {!selectedCountryValue && (
              <TextMuted className="block pt-2">Select country first</TextMuted>
            )}
          </div>

          <div>
            <TextH5 className="mb-2">Device type</TextH5>
            <DeviceTypeGroupTabs navigateOptions={{ replace: true }} />
          </div>

          <div className="flex">
            <BaseCheckbox
              id="operator_mode"
              checked={operatorMode}
              value="operator_mode"
              onChange={handleOperatorMode}
              label="operator_mode_filter_label"
            />

            <TooltipProvider delayDuration={30}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <HelpCircleIcon
                    className="fill-muted-foreground text-background ml-1"
                    size={18}
                  />
                </TooltipTrigger>
                <TooltipContent>
                  <p className="w-[320px]">
                    Compare and analyze the performance of mobility providers in
                    detail. Gain insights into market share, trip patterns, and
                    more for a specific operator.
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>

          <BaseButton className="w-full" onClick={handleSubmit}>
            Start demo
          </BaseButton>
        </div>
      </div>
    </AuthWrapper>
  );
}
