import { time } from "@vuumly-common/common";
import { useMemo } from "react";
import { useHourOfDay } from "hooks";
import { translate } from "utils";
import { BaseFilterOption, BaseFilterSelect } from "../BaseFilterSelect";
import { BaseOption } from "../BaseSelector";

export function HourOfDaySelect() {
  const { selectedHourOfDay, setSelectedHourOfDay } = useHourOfDay();

  const list = useMemo(
    () =>
      defaultList.map((item) => {
        return { ...item, selected: selectedHourOfDay.includes(item.value) };
      }),
    []
  );

  const onChange = (payload: BaseFilterOption<time.HourOfDayEnum>[]) => {
    const selected = payload
      .filter((item) => item.selected)
      .map((item) => item.value);
    setSelectedHourOfDay(selected);
  };

  return (
    <BaseFilterSelect<time.HourOfDayEnum>
      onChange={onChange}
      options={list}
      title={translate("hour_of_day_filter_title")}
    />
  );
}

export const defaultList: BaseOption<time.HourOfDayEnum>[] = Object.values(
  time.HourOfDayEnum
).map((value) => ({
  label: value,
  value,
  id: value,
  selected: false,
  disabled: false,
  hasMatch: false,
  subscribed: false,
}));
