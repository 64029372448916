import { RoutePathEnum } from "../controllers/router";
import { useTranslate } from "./useTranslate.hook";

export function usePageTitle(path: RoutePathEnum) {
  const translate = useTranslate();
  let pageTitle = "Kenflow.io | Mobility data dashboard";

  if (path === RoutePathEnum.Rides) {
    pageTitle = translate("rides_page_title");
  } else if (path === RoutePathEnum.Areas) {
    pageTitle = translate("Neighborhoods");
  } else if (path === RoutePathEnum.Report) {
    pageTitle = translate("Reports");
  } else if (path === RoutePathEnum.LengthMedian) {
    pageTitle = translate("length_median_page_title");
  } else if (path === RoutePathEnum.RideLengthGroup) {
    pageTitle = translate("ride_length_page_title");
  } else if (path === RoutePathEnum.Fleet) {
    pageTitle = translate("fleet_page_title");
  } else if (path === RoutePathEnum.Utilization) {
    pageTitle = translate("utilization_page_title");
  }

  // maps
  else if (path === RoutePathEnum.Hexmap) {
    pageTitle = translate("geohashmap_page_title");
  } else if (path === RoutePathEnum.Flowmap) {
    pageTitle = translate("flow_map_page_title");
  } else if (path === RoutePathEnum.Heatmap) {
    pageTitle = translate("heatmap_page_title");
  } else if (path === RoutePathEnum.LiveMap) {
    pageTitle = translate("Live map");
  }

  // auth
  else if (path === RoutePathEnum.SignIn) {
    pageTitle = translate("signin_page_title");
  } else if (path === RoutePathEnum.VerifyEmail) {
    pageTitle = translate("verify_email_page_title");
  } else if (path === RoutePathEnum.CheckEmail) {
    pageTitle = translate("signin_check_email_page_title");
  } else if (path === RoutePathEnum.NotSubscribed) {
    pageTitle = translate("not_subscribed_page_title");
  }

  window.document.title = pageTitle + " | Kenflow.io";
  return pageTitle;
}
