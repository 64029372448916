import { useEffect, useRef } from "react";
import { useMapContext } from "context/MapContext";
import { ol } from "../../utils";
import Heatmap from "ol/layer/Heatmap";
import { Point } from "../../utils/ol";

interface Props {
  zIndex?: number;
  weight?: number;
}

const minZoom = 11;
const maxZoom = 16;
const minBlur = 1;
const maxBlur = 16;

function calculateBlur(
  zoom: number,
  minZoom: number,
  maxZoom: number,
  minBlur: number,
  maxBlur: number
) {
  const exponent = 0.5;
  const zoomFactor =
    1 - Math.pow((maxZoom - zoom) / (maxZoom - minZoom), exponent);
  const scaledBlur = minBlur + zoomFactor * (maxBlur - minBlur);
  return Math.min(Math.max(scaledBlur, minBlur), maxBlur);
}

export function useHeatmapLayer(props?: Props) {
  const { weight = 0, zIndex } = props ?? {};
  const mapContext = useMapContext();
  const mapRef = mapContext.mapInstance;

  const vectorSource = useRef(new ol.VectorSource<Point>());

  const vectorLayer = useRef(
    new Heatmap({
      source: vectorSource.current,
      zIndex: 2,
      weight: (feature) => {
        return feature.getProperties().weight;
      },
    })
  );

  useEffect(() => {
    if (!mapRef) return;

    const setZoom = () => {
      const heatmap = vectorLayer.current;

      const zoom = mapRef.getView().getZoom() ?? 1;

      if (zoom >= maxZoom) {
        heatmap.setBlur(16 - 2 * weight);
        heatmap.setRadius(16 + 2 * weight);
        return;
      }

      const blur = calculateBlur(zoom, minZoom, maxZoom, minBlur, maxBlur);
      heatmap.setBlur(blur + 2 - 2 * weight);
      heatmap.setRadius(blur + 2 * weight);
    };

    setZoom();

    mapRef.getView().on("change:resolution", setZoom);

    return () => {
      mapRef.getView().un("change:resolution", setZoom);
    };
  }, [mapRef, weight]);

  useEffect(() => {
    if (mapRef) {
      console.log("adding heatmap layer");
      mapRef.addLayer(vectorLayer.current);
    }
    return () => {
      if (mapRef) {
        mapRef.removeLayer(vectorLayer.current);
      }
    };
  }, [mapRef]);

  const clearLayer = () => {
    if (mapRef) {
      vectorLayer.current.getSource()?.clear();
      showLayer();
    }
  };

  const hideLayer = () => {
    if (mapRef) {
      vectorLayer.current.setVisible(false);
    }
  };

  const showLayer = () => {
    if (mapRef) {
      vectorLayer.current.setVisible(true);
    }
  };

  return {
    clearLayer,
    hideLayer,
    showLayer,
    vectorSource,
    vectorLayer,
  };
}
