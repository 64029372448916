import { SignInForm } from "components/Form";
import { TextH2 } from "components/Typography/Text";
import { AuthWrapper } from "./AuthWrapper";
import { usePageTitle } from "../../hooks";
import { RoutePathEnum } from "../../controllers/router";

export function SignInPage() {
  const pageTitle = usePageTitle(RoutePathEnum.SignIn);
  return (
    <AuthWrapper>
      <div className="flex flex-col space-y-2 ">
        <TextH2>{pageTitle}</TextH2>
        {/* <TextMuted>
          Not a member?
          <TextLink
            className="ml-1 no-underline font-semibold"
            to={RoutePathEnum.SignUp}
          >
            Create an account
          </TextLink>{" "}
        </TextMuted> */}
      </div>
      <SignInForm />
    </AuthWrapper>
  );
}
