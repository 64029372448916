import { useEffect } from "react";
import { each } from "chart.js/helpers";
import { Chart } from "chart.js";

export function usePrint() {
  useEffect(() => {
    function setPrinting(printing: boolean) {
      each(Chart.instances, (chart) => {
        (chart as any)._printing = printing;
        chart.resize();
        chart.update();
      });
    }

    // https://stackoverflow.com/a/11060206/8837887
    function beforePrint() {
      setPrinting(true);
    }
    function afterPrint() {
      setPrinting(false);
    }

    (function () {
      if (window.matchMedia) {
        var mediaQueryList = window.matchMedia("print");
        mediaQueryList.addListener(function (mql) {
          if (mql.matches) {
            beforePrint();
          } else {
            afterPrint();
          }
        });
      }

      window.onbeforeprint = beforePrint;
      window.onafterprint = afterPrint;
    })();
  }, []);
}
