import { Link, useSearchParams } from "react-router-dom";
import { BaseButton } from "../components/Button";
import { Logo } from "../components/Image/Logo";
import { TextH3 } from "../components/Typography/Text";

export default function PageNotFound() {
  const [searchParams] = useSearchParams();
  return (
    <div className="h-full flex justify-center items-center">
      <div className="absolute left-0 top-0 m-8">
        <Logo />
      </div>
      <div className="space-y-8 items-center flex flex-col text-center mx-16">
        <TextH3>Sorry, we couldn't find the page you requested.</TextH3>
        <Link to={`/?` + searchParams}>
          <BaseButton>Go to homepage</BaseButton>
        </Link>
      </div>
    </div>
  );
}
