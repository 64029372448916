import { ChartData, ChartOptions } from "chart.js";
import enGB from "date-fns/locale/en-GB";
import { useTheme, useUserContext } from "hooks";
import _ from "lodash";
import { useMemo } from "react";
import { Line } from "react-chartjs-2";
const defaultData = {
  labels: [],
  datasets: [],
};

interface BaseLineChartProps {
  data?: ChartData<"line">;
  height?: number;
  options?: ChartOptions<"bar" | "line">;
  plugins?: any[];
}

const legendMargin = {
  id: "legendMargin",
  beforeInit: function (chart: any) {
    const fitValue = chart.legend.fit;

    chart.legend.fit = function () {
      fitValue.bind(chart.legend)();
      return (this.height += 10);
    };
  },
};

export const BaseLineChart = (props: BaseLineChartProps) => {
  const { charts, accentColor } = useTheme();
  const { locale } = useUserContext();

  const { data, height = 423, options = {} } = props;

  const usedData: ChartData<"line"> = data || defaultData;

  const baseOptions: ChartOptions<"line"> = {
    animation: {
      duration: 0,
    },
    responsive: true,

    backgroundColor: accentColor,
    borderColor: accentColor,

    locale,
    maintainAspectRatio: false,

    elements: {
      bar: {
        hoverBackgroundColor: charts.colors.hover,
        borderRadius: 4,
        backgroundColor: charts.colors.primary,
      },
      line: {
        tension: 0.3,
        borderWidth: 2,
        backgroundColor: charts.colors.primary,
        borderColor: charts.colors.primary,
      },
      point: {
        radius: 0,
        borderWidth: 8,
        hoverRadius: 8,
        borderColor: "white",
        hoverBorderWidth: 4,
        hoverBorderColor: "white",
        // there are additional settings at lower
      },
    },

    scales: {
      x: {
        title: {
          color: "#9E9E9E",
        },
      },
      y: {
        min: 0,
        title: {
          color: "#9E9E9E",
        },
      },
    },

    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
        },
      },
      tooltip: {
        caretPadding: 10,
      },
    },
  };

  const baseLineOptions: ChartOptions<"line"> = {
    interaction: {
      intersect: false,
    },

    hover: {
      mode: "nearest",
      axis: "x",
    },

    elements: {
      line: {
        tension: 0.3,
        borderWidth: 2,
      },
    },

    datasets: {
      line: {
        pointBorderColor: "transparent",
      },
    },
    scales: {
      x: {
        time: {
          isoWeekday: true,
          tooltipFormat: "PPPP",
          minUnit: "day",
        },

        stacked: false,
        display: true,

        grid: {
          display: true,
        },
        border: {
          display: false,
        },

        ticks: {
          padding: 10,
        },
      },
      y: {
        display: true,
        grace: "50%",
        beginAtZero: true,

        title: {
          padding: 10,
          display: true,
        },

        ticks: {
          padding: 10,
          callback: (value: number | string) => {
            if (
              typeof value === "number" &&
              Number.isInteger(value) &&
              value > 1000
            ) {
              return new Intl.NumberFormat(locale, {
                notation: "compact",
              }).format(value);
            }

            return value;
          },
        },
      },
    },

    plugins: {
      tooltip: {
        enabled: true,
        mode: "nearest",
        axis: "x",
        position: "average",
        displayColors: true,
        usePointStyle: true,
        intersect: false,
      },
      datalabels: {
        display: false,
      },
    },
  };

  const mergedOptions = useMemo(() => {
    return _.merge(baseOptions, baseLineOptions, options);
  }, [props]);

  return (
    <Line
      //   ref={chart}
      width="100%"
      height={height}
      data={usedData}
      plugins={[legendMargin, ...(props.plugins || [])]}
      options={mergedOptions}
    />
  );
};
