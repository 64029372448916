import { BaseButton } from "components/Button";
import {
  Card,
  CardContent,
  CardHeader,
} from "components/shadcn/components/ui/card";
import {
  TextH2,
  TextH3,
  TextH4,
  TextH5,
  TextMuted,
  TextParagraph,
  TextSmall,
} from "components/Typography/Text";
import { translate } from "../../../utils";
import { ISubscribeCard } from "./index";

export const SubscribeCard = (props: ISubscribeCard) => {
  const { onClick, value, isInProgress } = props;
  const {
    title,
    description,
    price,
    btnText,
    options,
    colorClass,
    isCustom,
    priceId,
    value: productValue,
  } = value;
  return (
    <Card>
      <CardHeader>
        <TextH3>{title}</TextH3>
      </CardHeader>
      <CardContent>
        <p className=" text-gray-400">{description}</p>
        {/* <hr className="-mx-6 mb-6" /> */}
        <TextH2 className="mb-2">{isCustom ? "Custom " : `€${price}`}</TextH2>
        <TextMuted className="mb-1">
          {isCustom
            ? "for organization with advanced needs"
            : "per city per month"}
        </TextMuted>
        <TextH5 className="mb-8">
          {isCustom ? "billed yearly" : `billed €${price * 3} quarterly`}
        </TextH5>
        <BaseButton
          className="mb-6 w-full"
          onClick={() => onClick(priceId, productValue)}
        >
          {btnText}
        </BaseButton>
        <div className="text-gray-500">
          {options.map((c) => (
            <TextMuted className="flex mb-1.5" key={c}>
              <div className="w-4 mr-1">✓</div>
              <div>{c}</div>
            </TextMuted>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};
