import { db } from "@vuumly-common/common";
import { ChartData } from "../../hooks";

interface parseResponseDataI {
  response: { data?: ChartData };
}

export const defaultChartData: ChartData = {
  id: "default",
  label: "no data",
  label_type: db.models.LabelTypeEnum.Date,
  labels: [],
  datasets: [
    {
      label: "",
      raw_label: "",
      data: [],
      id: "",
      data_type: db.models.DatasetTypeEnum.NumberArray,
      usage_type: db.models.ColumnType.Metrics,
    },
  ],
};

export const parseResponseData = ({
  response,
}: parseResponseDataI): ChartData => response.data || defaultChartData;
