import { useEffect, useRef } from "react";
import { useMapContext } from "context/MapContext";
import { hslToRgb } from "@vuumly-common/common";
import { ol } from "../../utils";
import { RealtimeDeviceLayer } from "./Layers";

interface Props<T> {
  zIndex?: number;
  layerInstance?: new () => T;
  style?: ol.Style;
}

export default function useMapLayer<
  T extends ol.VectorLayer<ol.VectorSource<ol.Geometry>>
>(props?: Props<T>) {
  const Layer = props?.layerInstance || ol.VectorLayer;
  const mapContext = useMapContext();
  const mapRef = mapContext.mapInstance;
  const color = useRef(hslToRgb(Math.round(Math.random() * 360), 85, 40));
  const style = useRef(
    props?.style ||
      new ol.Style({
        image: new ol.Circle({
          radius: 10,
          stroke: new ol.Stroke({
            color: `rgba(${color.current.toString()}, 1)`,
            width: 2,
          }),
          fill: new ol.Fill({
            color: `rgba(${color.current.toString()}, 0.8)`,
          }),
        }),
      })
  );

  (window as any).mapRef = mapRef;

  const vectorSource = useRef(new ol.VectorSource());

  const vectorLayer = useRef(
    new Layer({
      style: style.current,
      source: vectorSource.current,
      zIndex: props?.zIndex,
      //renderBuffer: 1000
    })
  );

  const setCenter = () => {
    if (mapRef) {
      mapRef.getView().setCenter(ol.fromLonLat([33.3194634, 35.1922276]));
    }
  };

  useEffect(() => {
    if (mapRef) {
      console.log("adding layer to map");
      mapRef.addLayer(vectorLayer.current);
    }
    return () => {
      if (mapRef) {
        console.log("removing layer from map");
        mapRef.removeLayer(vectorLayer.current);
      }
    };
  }, [mapRef]);

  const addMarker = () => {
    if (mapRef) {
      const center = mapRef.getView().getCenter();
      if (center) {
        vectorLayer.current.getSource()?.addFeature(
          new ol.Feature({
            geometry: new ol.Point(center),
          })
        );
      }
    }
  };

  const clearLayer = () => {
    if (mapRef) {
      //console.log("clearing layer")
      vectorLayer.current.getSource()?.clear();
      showLayer();
    }
  };

  const hideLayer = () => {
    if (mapRef) {
      vectorLayer.current.setVisible(false);
    }
  };

  const showLayer = () => {
    if (mapRef) {
      vectorLayer.current.setVisible(true);
    }
  };

  return {
    setCenter,
    addMarker,
    clearLayer,
    hideLayer,
    showLayer,
    vectorSource,
    vectorLayer,
  };
}
