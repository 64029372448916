import UseAnimations from "react-useanimations";
import loading from "react-useanimations/lib/loading";

interface Props {
  color?: string;
  size?: number;
}
export function LoadingIndicator(props: Props) {
  const { color, size } = props;
  return <UseAnimations size={size} strokeColor={color} animation={loading} />;
}
