import { translate as t } from "../utils";

export const useTranslate = () => {
  function translate(value?: string, params?: Record<string, string>): string {
    if (!value) return "translation missing";
    if (params) {
      let template = t(value);
      Object.keys(params).forEach((key) => {
        template = template.replace(`\${${key}}`, params[key]);
      });
      return template;
    }

    return t(value);
  }

  return translate;
};
