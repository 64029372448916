import { api } from "@vuumly-common/common";
import { BaseCard } from "components/Card/index";
import { TextH5 } from "components/Typography/Text";
import { useDateRange, useQuery, useTranslate, useUserContext } from "hooks";
import { useSearchParams } from "react-router-dom";

type Item = {
  dimension: string;
  ride_length_minutes: number;
};

export const RideLengthMinutesTotal = () => {
  const translate = useTranslate();
  const [searchParams] = useSearchParams();
  const { locale } = useUserContext();
  const { selectedDaysCount } = useDateRange();

  const params = new URLSearchParams(searchParams);

  params.set("data_structure", "flat");

  const { data, isLoading } = useQuery<Item[]>({
    apiEndpoint: api.Endpoint.RideLenghtTotalCompanies,
    params,
  });

  const total = data?.reduce((acc, item) => acc + item.ride_length_minutes, 0) || 0;

  const formattedTotal = Intl.NumberFormat(locale, {
    notation: "compact",
  }).format(total || 0);

  const avgRidesPerDay = total / (selectedDaysCount || 0);

  const avgPerDayFormatted = Intl.NumberFormat(locale, {
    notation: "compact",
  }).format(avgRidesPerDay);

  return (
    <div className="flex space-x-4">
      <BaseCard
        isLoading={isLoading}
        header={<TextH5>Total ride length in minutes</TextH5>}
      >
        <div className="text-xl">{formattedTotal}</div>
      </BaseCard>
      <BaseCard
        isLoading={isLoading}
        header={<TextH5>Average ride length in minutes per day</TextH5>}
      >
        <div className="text-xl">{avgPerDayFormatted}</div>
      </BaseCard>
    </div>
  );
};
