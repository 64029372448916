import { api, time } from "@vuumly-common/common";
import { ChartData, ChartOptions } from "chart.js";
import { BaseCard, CardProps } from "components/Card";
import { useQuery, useTheme, useTimeGranularity, useTranslate } from "hooks";
import _ from "lodash";
import { useMemo } from "react";
import { BaseLineChart } from "../../../Charts";
import { TimeGranularityTabs } from "../../../Tabs/TimeGranularityTabs";
import { TextH5 } from "../../../Typography/Text";

export const RidesTimeseriesDeviceSize = (props: CardProps) => {
  const { tabs, timeGranularity, changeGranularity, modifiedQueryParams } =
    useTimeGranularity();
  const { getDeviceSizeColor } = useTheme();
  const translate = useTranslate();

  const { data, isLoading } = useQuery({
    apiEndpoint: api.Endpoint.RidesTimeseriesDeviceSize,
    params: modifiedQueryParams,
  });

  const clonedData = useMemo(() => {
    if (isLoading || !data?.datasets) {
      return;
    }

    const clonedData = _.cloneDeep(data) as ChartData<"line">;

    clonedData?.datasets.forEach((dataset) => {
      const { label } = dataset;
      dataset.label = translate(label);
      if (label) {
        dataset.backgroundColor = getDeviceSizeColor(label).color;
        dataset.borderColor = dataset.backgroundColor;
      }
    });

    return clonedData;
  }, [data]);

  const options: ChartOptions<"line"> = {
    parsing: {
      xAxisKey: "date",
      yAxisKey: "ride_count",
    },

    scales: {
      x: {
        type: "timeseries",
      },
      y: {
        border: {
          display: false,
        },
        title: {
          text: "Ride count",
        },
      },
    },
  };

  const Header = () => {
    return (
      <div className="flex justify-between w-full">
        <TextH5>Ride count by vehicle size over time</TextH5>
        <TimeGranularityTabs
          tabs={tabs}
          defaultValue={timeGranularity || time.GranularityEnum.Day}
          onChange={changeGranularity}
        />
      </div>
    );
  };

  return (
    <BaseCard isLoading={isLoading} header={<Header />}>
      <BaseLineChart options={options} data={clonedData} />
    </BaseCard>
  );
};
