import { useSearchParams } from "react-router-dom";

export function useEngineType() {
  const [_searchParams, setSearchParams] = useSearchParams();

  const searchParams = new URLSearchParams(window.location.search);

  const selectedEngineTypes = searchParams.getAll("engine_type[]");

  function setSelectedEngineType(payload: string[]) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("engine_type[]");
    payload.forEach((item) => {
      searchParams.append("engine_type[]", item);
    });
    setSearchParams(searchParams);
  }

  const removeSelectedEngineType = (payload: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedEngineTypes = searchParams.getAll("engine_type[]");

    if (selectedEngineTypes.includes(payload)) {
      searchParams.delete("engine_type[]");
      selectedEngineTypes.forEach((item) => {
        if (item !== payload) {
          searchParams.append("engine_type[]", item);
        }
      });
      setSearchParams(searchParams);
    }
  };

  return {
    selectedEngineTypes,
    setSelectedEngineType,
    removeSelectedEngineType,
  };
}
