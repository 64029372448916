import classNames from "classnames";
import {
  Card,
  CardContent,
  CardHeader,
} from "components/shadcn/components/ui/card";
import { RoutePathEnum } from "../../controllers/router";
import { useRouter } from "../../hooks/useRouter.hook";
import { LoadingIndicator } from "../Progress";
import { cn } from "../shadcn/lib/utils";
import { ErrorBoundary } from "react-error-boundary";
import BaseVirtualTooltip from "../Tooltip/BaseVirtualTooltip";

interface Props extends React.ComponentProps<"div"> {
  href?: RoutePathEnum | string;
  isLoading?: boolean;
  header?: JSX.Element;
  tooltip?: JSX.Element;
}

export const BaseCard = (props: Props) => {
  const { isLoading, children, header, href, onClick } = props;
  const { goTo } = useRouter();

  const handleClick = (e: any) => {
    if (onClick) {
      onClick(e);
      return;
    }
    if (href) {
      goTo(href);
    }
  };

  return (
    <Card
      className={cn(
        classNames({ "flex-1": true, "cursor-pointer": href || onClick }),
        props.className
      )}
      onClick={handleClick}
    >
      <div className="relative w-full">
        {isLoading && (
          <div className="absolute top-1/2 left-1/2 z-1 -translate-x-1/2 -translate-y-1/2">
            <LoadingIndicator />
          </div>
        )}
        <div className={classNames({ "opacity-20": isLoading })}>
          {header && <CardHeader>{header}</CardHeader>}
          {/* <BaseVirtualTooltip tooltip={props.tooltip} isVisible={!!props.tooltip}> */}
            <CardContent>{children}</CardContent>
          {/* </BaseVirtualTooltip> */}
        </div>
      </div>
    </Card>
  );
};
