import { Countries, CountryEnum, location } from "@vuumly-common/common";
import { useCountry, useUserContext, useDemoMode, useDistrict } from "hooks";
import { useMemo } from "react";
import { TextH5 } from "../../Typography/Text";
import { BaseSelect } from "../BaseSelect";
import { BaseOption } from "../BaseSelector";
import { SelectProps } from "../index";
import { useSearchParams } from "react-router-dom";

export function CountrySelect(props: SelectProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { subscribedCountries } = useUserContext();
  const { selectedCountryValue, setSelectedCountry } = useCountry();
  const { removeSelectedDistrict} = useDistrict();
  const { isDemoModeEnabled } = useDemoMode();

  function handleSelect(val: CountryEnum) {
    setSelectedCountry(val, { navigateOptions: props.navigateOptions });
  }

  const list = useMemo(
    () =>
      defaultAreas.map((item) => {
        const isSubscribed = subscribedCountries.includes(item.value);

        const isDisabled = isDemoModeEnabled
          ? !location.countries.isDemoAvailable(item.value)
          : !isSubscribed;

        let tooltip = "";

        if (isDemoModeEnabled && isDisabled) {
          tooltip = "Available in full version";
        }

        if (!isDemoModeEnabled && !isSubscribed) {
          tooltip = "Not subscribed";
        }

        return {
          ...item,
          selected: selectedCountryValue === item.value,
          subscribed: subscribedCountries.includes(item.value),
          tooltip: tooltip,
          disabled: isDisabled,
        };
      }),
    [isDemoModeEnabled]
  );

  return (
    <div>
      <TextH5 className="mb-2">Country</TextH5>
      <BaseSelect
        id="countries"
        value={selectedCountryValue}
        options={list}
        onChange={handleSelect}
      />
    </div>
  );
}

const def = {
  selected: false,
  disabled: false,
  hasMatch: false,
  subscribed: false,
};

const defaultAreas: BaseOption<CountryEnum>[] = Object.values(Countries).map(
  (item) => {
    return {
      label: item.name,
      value: item.value,
      id: item.value,
      ...def,
    };
  }
);
