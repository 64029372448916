import { api } from "@vuumly-common/common";
import { ChartData, ChartOptions, TooltipItem } from "chart.js";
import Color from "color";
import { useOperatorMode, useQuery, useTheme, useTranslate } from "hooks";
import _ from "lodash";
import { useMemo } from "react";
import { CardProps } from "../..";
import { BaseBarChart } from "../../../Charts";
import { TextH5 } from "../../../Typography/Text";
import { BaseCard } from "../../Base.card";

export const RideLengthHour = (props: CardProps) => {
  const translate = useTranslate();
  const theme = useTheme();
  const { operatorMode } = useOperatorMode();

  const { data, isLoading } = useQuery<ChartData<"bar", Record<any, number>[]>>(
    {
      apiEndpoint: api.Endpoint.RideLengthHour,
    }
  );

  const clonedData = useMemo(() => {
    if (isLoading || !data?.datasets) {
      return;
    }
    const clonedData = _.cloneDeep(data);

    clonedData.labels = clonedData.datasets.flatMap(
      (obj) =>
        obj.data
          .filter((item: any) => "ride_length_hour" in item) // Filter out objects without 'x'
          .map((item: any) => item.ride_length_hour) // Extract 'x' values
    );

    clonedData.labels = _.uniq(clonedData.labels);

    clonedData?.datasets.forEach((dataset) => {
      const { label } = dataset;

      if (label === "ride_percentage") {
        dataset.label = translate("Ride distribution");
        if (dataset.data.length) {
          dataset.backgroundColor = dataset.data.map(() => {
            return Color(
              !operatorMode ? theme.accentColor : theme.otherOperatorBarColor
            ).string();
          });

          dataset.hoverBackgroundColor = dataset.data.map(() => {
            return Color(
              !operatorMode
                ? theme.accentColorHover
                : theme.hoverOtherOperatorBarColor
            ).string();
          });
        }
        dataset.parsing = {
          yAxisKey: "ride_percentage",
        };
      } else {
        dataset.hidden = true;
        dataset.label = "none";
      }
      dataset.barPercentage = 0.999;
    });

    return clonedData;
  }, [data, operatorMode]);

  const options: ChartOptions<"bar"> = {
    parsing: {
      xAxisKey: "ride_length_hour",
    },
    scales: {
      x: {
        title: {
          text: "Ride length in hours",
          display: true,
        },
        grid: {
          display: false,
        },
        ticks: {
          callback: (value, idx) => {
            if (value === 0) {
              return "Bellow 1h";
            }
            if (
              clonedData &&
              clonedData.labels &&
              clonedData.labels.length - 1 === idx
            ) {
              return `Above ${Number(value)}h`;
            }
            return `${value}-${Number(value) + 1}h`;
          },
        },
      },
      y: {
        title: {
          text: "Ride distribution",
        },
        ticks: {
          display: false,
          callback: (value) => {
            return `${value}%`;
          },
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },

    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          title: (item) => {
            const idx = item[0].dataIndex;
            if (idx === 0) {
              return `Rides bellow 1h`;
            }
            if (idx === 24) {
              return `Rides above 24h`;
            }

            return `Rides between ${idx}h and ${idx + 1}h`;
          },
          label: (item: TooltipItem<"bar">) => {
            return `${item.dataset.label}: ${item.formattedValue}%`;
          },
        },
      },
      datalabels: {
        display: true,
        formatter: (item: Record<string, any>) => item.ride_percentage + "%",
      },
    },
  };

  return (
    <BaseCard
      isLoading={isLoading}
      header={<TextH5>Ride distribution by ride length in hours</TextH5>}
    >
      <BaseBarChart options={options} data={clonedData} />
    </BaseCard>
  );
};
