import { api } from "@vuumly-common/common";
import { BaseCard } from "components/Card/index";
import { TextH5 } from "components/Typography/Text";
import {
  useConstants,
  useOperatorMode,
  useQuery,
  useTranslate,
  useUserContext,
} from "hooks";

import {
  ColumnDef,
  SortingState,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { BaseTable } from "../../Tables";
import { DataTableColumnHeader } from "../../Tables/DataTableColumnHeader";
import { cn } from "../../shadcn/lib/utils";

interface Props {
  showExploreMoreButton?: boolean;
  enablePagination?: boolean;
  hidePercentage?: boolean;
  ignoreOriginIdFilter?: boolean;
}

type Item = {
  start_loc_admin_1_name: string;
  start_loc_admin_2_name?: string;
  ride_count: number;
  total_ride_count: number;
  ride_percentage: number;
  ride_count_without_operator?: number;
  ride_count_operator?: number;
  ride_percentage_without_operator?: number;
  ride_percentage_operator?: number;
  total_ride_count_without_operator?: number;
  total_ride_count_operator?: number;
  deviation_percentage?: number;
};

const emptyArray: any[] = [];

export const RideOriginCard = (props: Props) => {
  const { ORIGIN_ID_KEY, DESTINATION_ID_KEY } = useConstants();
  const { ignoreOriginIdFilter } = props;
  const translate = useTranslate();
  const { operatorMode } = useOperatorMode();
  const { locale } = useUserContext();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState({
    start_loc_admin_1_name: true,
    start_loc_admin_2_name: true,
    ride_count: true,
    ride_percentage: true,
    ride_percentage_without_operator: false,
    ride_count_operator: false,
    ride_percentage_operator: operatorMode,
    deviation_percentage: operatorMode,
  });

  useEffect(() => {
    setColumnVisibility((prev) => ({
      ...prev,
      ride_percentage_operator: operatorMode,
      deviation_percentage: operatorMode,
    }));
  }, [operatorMode]);

  const { data, isLoading } = useQuery<Item[]>({
    apiEndpoint: api.Endpoint.RidesCountForSuburb,
    ignoreParams: ignoreOriginIdFilter
      ? [ORIGIN_ID_KEY, DESTINATION_ID_KEY]
      : [],
  });

  const columns: ColumnDef<Item>[] = [
    {
      accessorKey: "start_loc_admin_1_name",
      header: ({ column }) => (
        <DataTableColumnHeader
          className="w-[125px]"
          column={column}
          title="Ride origin"
        />
      ),
      enableSorting: true,
      sortingFn: (a, b) => {
        const aa = a.getValue("start_loc_admin_1_name") as string;
        const bb = b.getValue("start_loc_admin_1_name") as string;
        if (aa === bb) return 0;
        if (aa === null || bb === null) return 1;
        return aa.localeCompare(bb);
      },
      cell: ({ row }) => {
        return row.getValue("start_loc_admin_1_name");
      },
    },
    {
      accessorKey: "start_loc_admin_2_name",
      header: ({ column }) => (
        <DataTableColumnHeader
          className="w-[150px]"
          column={column}
          title="Ride origin"
        />
      ),
      enableSorting: true,
      sortingFn: (a, b) => {
        const aa = (a.getValue("start_loc_admin_2_name") ||
          a.getValue("start_loc_admin_1_name")) as string;
        const bb = (b.getValue("start_loc_admin_2_name") ||
          b.getValue("start_loc_admin_1_name")) as string;
        if (aa === bb) return 0;
        if (aa === null || bb === null) return 1;
        return aa.localeCompare(bb);
      },
      cell: ({ row }) => {
        return (
          row.getValue("start_loc_admin_2_name") || "All districts/suburbs"
        );
      },
    },
    {
      accessorKey: "ride_count",
      header: ({ column }) => (
        <DataTableColumnHeader
          className="w-[125px]"
          column={column}
          title="Ride count"
        />
      ),
      cell: ({ row }) => {
        const amount = parseFloat(row.getValue("ride_count"));
        const formatted = new Intl.NumberFormat(locale, {
          notation: "compact",
        }).format(amount);

        return formatted;
      },
      enableSorting: true,
    },

    {
      accessorKey: "ride_percentage",
      header: ({ column }) => (
        <DataTableColumnHeader
          className="w-[125px]"
          column={column}
          title="% of all rides"
        />
      ),
      cell: ({ row }) => {
        const value = parseFloat(row.getValue("ride_percentage"));
        return value + "%";
      },
      enableSorting: true,
    },
    {
      accessorKey: "ride_percentage_without_operator",
      header: ({ column }) => (
        <DataTableColumnHeader
          className="w-[125px]"
          column={column}
          title="ride_percentage_without_operator"
        />
      ),
      enableSorting: true,
    },
    {
      accessorKey: "ride_count_operator",
      header: ({ column }) => (
        <DataTableColumnHeader
          className="w-[125px]"
          column={column}
          title="Your ride count"
        />
      ),
      cell: ({ row }) => {
        const amount = parseFloat(row.getValue("ride_count_operator"));
        const formatted = new Intl.NumberFormat(locale, {
          notation: "compact",
        }).format(amount);

        return formatted;
      },
      enableSorting: true,
    },
    {
      accessorKey: "ride_percentage_operator",
      header: ({ column }) => (
        <DataTableColumnHeader
          className="w-[150px]"
          column={column}
          title="% of all your rides"
        />
      ),
      cell: ({ row }) => {
        const value = parseFloat(row.getValue("ride_percentage_operator"));
        return value + "%";
      },
      enableSorting: true,
      enableHiding: operatorMode,
    },
    {
      accessorKey: "deviation_percentage",
      header: ({ column }) => (
        <DataTableColumnHeader
          className="w-[150px]"
          column={column}
          title="Your performance"
        />
      ),
      cell: ({ row }) => {
        const amount = parseFloat(row.getValue("deviation_percentage"));
        const formatted = amount > 0 ? `+${amount}%` : `${amount}%`;
        return (
          <div className={cn(amount > 0 ? "text-green-600" : "text-red-600")}>
            {formatted}
          </div>
        );
      },
      enableSorting: true,
    },
  ];

  const table = useReactTable({
    data: data || emptyArray,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      columnVisibility,
    },
  });

  return (
    <BaseCard
      isLoading={isLoading}
      header={<TextH5>Ride count by district/suburb</TextH5>}
    >
      <BaseTable
        columnVisibility={columnVisibility}
        columns={columns}
        table={table}
        paginationProps={{
          enabled: true,
          isRowCountPerPageVisible: false,
          table,
        }}
      />
    </BaseCard>
  );
};
