import { api } from "@vuumly-common/common";
import {
  useDeviceType,
  useParseRideLength,
  useQuery,
  useSerializeNumber,
  useTranslate,
} from "hooks";
import { TextH2, TextH5, TextMuted } from "../Typography/Text";
import { BaseCard } from "./index";

interface StatProps {
  title: string;
  total: number;
  value: number;
}

interface BaseLayoutProps {
  total_workday?: number;
  total_weekend?: number;
  workdays?: number;
  weekends?: number;
  total?: number;
}

function Stat({ title, total, value }: StatProps) {
  const percentage = Math.round((100 / total) * value);

  return (
    <div className="flex">
      <div className="flex-1 mr-4">
        <div className="w-full mt-1 h-1.5 rounded-lg overflow-hidden bg-muted">
          {value && (
            <div
              className="h-1.5 bg-chart rounded-lg"
              style={{
                width: `${percentage}%`,
              }}
            ></div>
          )}
        </div>
        <div>
          <TextMuted>{title}</TextMuted>
        </div>
      </div>
      <div className="w-16">
        <TextH2>{value ? percentage : 0}%</TextH2>
      </div>
    </div>
  );
}

export const RideLengthPercentileCard = () => {
  const translate = useTranslate();

  const parseRideLength = useParseRideLength();
  const { serialize } = useSerializeNumber();

  const { isCarsharingActive, isMicromobilityActive } = useDeviceType();

  const { data, isLoading } = useQuery<api.response.Length>({
    apiEndpoint: api.Endpoint.Length,
  });

  function getBaseLayout({
    workdays = 0,
    weekends = 0,
    total_workday = 0,
    total_weekend = 0,
    total: value = 0,
  }: BaseLayoutProps) {
    return (
      <div className="flex flex-col space-y-4">
        <TextH2 className="pb-0">{serialize(value)}</TextH2>
        {/* <Stat title={translate("percentage_from_total_ride_count")} value={workdays} total={total} /> */}
        <Stat
          title={translate("workdays")}
          value={workdays}
          total={total_workday}
        />
        <Stat
          title={translate("weekends")}
          value={weekends}
          total={total_weekend}
        />
      </div>
    );
  }

  function getLayoutFirst() {
    if (isCarsharingActive && !isMicromobilityActive) {
      return getBaseLayout({
        workdays: data?.[0].carsharing_workdays_rides_under_30,
        weekends: data?.[0].carsharing_weekends_rides_under_30,
        total_workday: data?.[0].carsharing_workdays_total_rides,
        total_weekend: data?.[0].carsharing_weekends_total_rides,
        total: data?.[0].carsharing_total_rides_under_30,
      });
    }

    if (!isCarsharingActive && isMicromobilityActive) {
      return getBaseLayout({
        workdays: data?.[0].micromobility_workdays_rides_under_10,
        weekends: data?.[0].micromobility_weekends_rides_under_10,
        total_workday: data?.[0].micromobility_workdays_total_rides,
        total_weekend: data?.[0].micromobility_weekends_total_rides,
        total: data?.[0].micromobility_total_rides_under_10,
      });
    }

    return <></>;
  }

  function getLayoutSecond() {
    if (isCarsharingActive && !isMicromobilityActive) {
      return getBaseLayout({
        workdays: data?.[0].carsharing_workdays_rides_under_60,
        weekends: data?.[0].carsharing_weekends_rides_under_60,
        total_workday: data?.[0].carsharing_workdays_total_rides,
        total_weekend: data?.[0].carsharing_weekends_total_rides,
        total: data?.[0].carsharing_total_rides_under_60,
      });
    }

    if (!isCarsharingActive && isMicromobilityActive) {
      return getBaseLayout({
        workdays: data?.[0].micromobility_workdays_rides_under_15,
        weekends: data?.[0].micromobility_weekends_rides_under_15,
        total_workday: data?.[0].micromobility_workdays_total_rides,
        total_weekend: data?.[0].micromobility_weekends_total_rides,
        total: data?.[0].micromobility_total_rides_under_15,
      });
    }

    return <></>;
  }

  // function getLayoutFirst() {
  //   if (isCarsharingActive && !isMicromobilityActive) {
  //     return getBaseLayout({
  //       workdays: data?.[0].carsharing_total_rides_under_30,
  //       weekends: data?.[0].carsharing_weekends_rides_under_30,
  //       total: data?.[0].carsharing_total_rides,
  //       value: data?.[0].carsharing_total_rides_under_30,
  //     });
  //   }

  //   if (!isCarsharingActive && isMicromobilityActive) {
  //     return getBaseLayout({
  //       workdays: data?.[0].micromobility_workdays_rides_under_10,
  //       weekends: data?.[0].micromobility_weekends_rides_under_10,
  //       total: data?.[0].micromobility_total_rides,
  //       value: data?.[0].micromobility_total_rides_under_10,
  //     });
  //   }

  //   return <></>;
  // }

  // function getLayoutSecond() {
  //   if (isCarsharingActive && !isMicromobilityActive) {
  //     return getBaseLayout({
  //       workdays: data?.[0].carsharing_total_rides_under_60,
  //       weekends: data?.[0].carsharing_weekends_rides_under_60,
  //       total: data?.[0].carsharing_total_rides,
  //       value: data?.[0].carsharing_total_rides_under_60,
  //     });
  //   }

  //   if (!isCarsharingActive && isMicromobilityActive) {
  //     return getBaseLayout({
  //       workdays: data?.[0].micromobility_workdays_rides_under_15,
  //       weekends: data?.[0].micromobility_weekends_rides_under_15,
  //       total: data?.[0].micromobility_total_rides_under_15,
  //       value: data?.[0].micromobility_total_rides_under_15,
  //     });
  //   }

  //   return <></>;
  // }

  function titleUnderFirst() {
    if (isCarsharingActive && !isMicromobilityActive) {
      return translate("ride_count_under_30_min");
    }
    if (!isCarsharingActive && isMicromobilityActive) {
      return translate("ride_count_under_10_min");
    }
  }

  function titleUnderSecond() {
    if (isCarsharingActive && !isMicromobilityActive) {
      return translate("ride_count_under_60_min");
    }
    if (!isCarsharingActive && isMicromobilityActive) {
      return translate("ride_count_under_15_min");
    }
  }

  return (
    <div className="space-y-4 flex flex-col h-full">
      <BaseCard
        className="flex"
        isLoading={isLoading}
        header={<TextH5>{titleUnderFirst()}</TextH5>}
      >
        {getLayoutFirst()}
      </BaseCard>
      <BaseCard
        className="flex"
        isLoading={isLoading}
        header={<TextH5>{titleUnderSecond()}</TextH5>}
      >
        {getLayoutSecond()}
      </BaseCard>
    </div>
  );
};
