import { api, time } from "@vuumly-common/common";
import { ChartData, ChartOptions } from "chart.js";
import Color from "color";
import { BaseCard } from "components/Card";
import {
  useOperatorMode,
  useQuery,
  useTheme,
  useTimeGranularity,
  useUserContext,
} from "hooks";
import _ from "lodash";
import { useMemo } from "react";
import { translate } from "../../../../utils";
import { BaseLineChart } from "../../../Charts";
import { TimeGranularityTabs } from "../../../Tabs/TimeGranularityTabs";
import { TextH5 } from "../../../Typography/Text";

export const RideLengthMinutesTimeseries = () => {
  const { tabs, timeGranularity, changeGranularity, modifiedQueryParams } =
    useTimeGranularity();
  const theme = useTheme();
  const { operatorMode } = useOperatorMode();
  const { locale } = useUserContext();

  const { data, isLoading } = useQuery({
    apiEndpoint: api.Endpoint.RideLengthMinutesTimeseries,
    params: modifiedQueryParams,
  });

  const clonedData = useMemo(() => {
    if (isLoading || !data?.datasets) {
      return;
    }

    const clonedData = _.cloneDeep(data) as ChartData<"line">;

    if (clonedData?.datasets) {
      clonedData.datasets = clonedData?.datasets.filter(
        (dataset) => dataset.label === "ride_length_minutes"
      );
    }

    clonedData?.datasets.forEach((dataset) => {
      const { label } = dataset;

      if (label === "ride_length_minutes") {
        dataset.label = translate("Overall ride length in minutes");

        dataset.parsing = {
          yAxisKey: "ride_length_minutes",
        };

        dataset.backgroundColor = operatorMode
          ? theme.otherOperatorLineColor
          : theme.accentColor;
        dataset.borderColor = dataset.backgroundColor;

        dataset.trendlineLinear = {
          colorMax: Color(dataset.backgroundColor).alpha(0.5).rgb().string(),
          colorMin: Color(dataset.backgroundColor).alpha(0.5).rgb().string(),
          lineStyle: "dashed",
          width: 2,
          projection: false,
        };
      }
    });

    return clonedData;
  }, [data]);

  const options: ChartOptions<"line"> = {
    parsing: {
      xAxisKey: "date",
    },

    scales: {
      x: {
        type: "timeseries",
      },
      y: {
        title: {
          text: "Overall ride length in minutes",
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context) => {
            return (
              "Ride length: " +
              Intl.NumberFormat(locale, {
                notation: "compact",
              }).format(context.parsed.y) + " minutes"
            );
          },
        },
      },
    },
  };

  const Header = () => {
    return (
      <div className="flex justify-between w-full">
        <TextH5>Overall ride length in minutes over time</TextH5>
        <TimeGranularityTabs
          tabs={tabs}
          defaultValue={timeGranularity || time.GranularityEnum.Day}
          onChange={changeGranularity}
        />
      </div>
    );
  };

  return (
    <BaseCard isLoading={isLoading} header={<Header />}>
      <BaseLineChart options={options} data={clonedData} />
    </BaseCard>
  );
};
