import { api } from "@vuumly-common/common";
import { ChartData, ChartOptions, TooltipItem } from "chart.js";
import Color from "color";
import { useQuery, useTheme, useTranslate } from "hooks";
import _ from "lodash";
import { useMemo } from "react";
import { CardProps } from "../..";
import { BaseBarChart } from "../../../Charts";
import { TextH5 } from "../../../Typography/Text";
import { BaseCard } from "../../Base.card";

export const RideDistributionOperatorDayOfWeekBarChart = (props: CardProps) => {
  const translate = useTranslate();
  const theme = useTheme();

  const { data, isLoading } = useQuery<ChartData<"bar", Record<any, number>[]>>(
    {
      apiEndpoint: api.Endpoint.RidesPerDayOfWeek,
    }
  );

  const clonedData = useMemo(() => {
    if (isLoading || !data?.datasets) {
      return;
    }
    const clonedData = _.cloneDeep(data);

    clonedData.labels = clonedData.datasets
      .flatMap(
        (obj) =>
          obj.data
            .filter((item: any) => "dimension" in item) // Filter out objects without 'x'
            .map((item: any) => item.dimension) // Extract 'x' values
      )
      .map((item: string) => translate("day_of_week_" + item));

    clonedData.labels = _.uniq(clonedData.labels);

    clonedData?.datasets.forEach((dataset) => {
      const { label } = dataset;

      if (label === "ride_percentage_without_operator") {
        if (dataset.data.length) {
          dataset.backgroundColor = dataset.data.map(() => {
            return Color(theme.otherOperatorBarColor).string();
          });

          dataset.hoverBackgroundColor = dataset.data.map(() => {
            return Color(theme.hoverOtherOperatorBarColor).string();
          });
        }

        dataset.label = translate("Ride distribution (other operators)");
        dataset.barPercentage = 0.999;
        dataset.parsing = {
          yAxisKey: "ride_percentage_without_operator",
        };
      } else if (label === "ride_percentage_operator") {
        if (dataset.data.length) {
          dataset.backgroundColor = dataset.data.map(() => {
            return Color(theme.accentColor).string();
          });

          dataset.hoverBackgroundColor = dataset.data.map(() => {
            return Color(theme.accentColorHover).string();
          });
        }

        dataset.label = translate("Your ride distribution");
        dataset.barPercentage = 0.999;
        dataset.parsing = {
          yAxisKey: "ride_percentage_operator",
        };
      } else {
        dataset.hidden = true;
        dataset.label = "none";
      }
    });

    return clonedData;
  }, [data]);

  const options: ChartOptions<"bar"> = {
    parsing: {
      xAxisKey: "dimension",
      yAxisKey: "ride_count",
    },

    scales: {
      y: {
        title: {
          text: "Ride distribution",
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        stacked: false,
        ticks: {
          display: false,
          callback: (value) => {
            return `${value}%`;
          },
        },
      },
      x: {
        stacked: false,
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Day of week",
        },
      },
    },

    plugins: {
      legend: {
        labels: {
          filter(item) {
            return item.text !== "none";
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (item: TooltipItem<"bar">) => {
            return `${item.dataset.label}: ${item.formattedValue}%`;
          },
        },
      },
      datalabels: {
        display: true,
        formatter: (item: any, _ctx) => {
          return (
            (item.ride_percentage_operator ||
              item.ride_percentage_without_operator) + "%"
          );
        },
      },
    },
  };

  return (
    <BaseCard
      isLoading={isLoading}
      header={
        <TextH5>{translate("Ride distribution by day of the week")}</TextH5>
      }
    >
      <BaseBarChart options={options} data={clonedData} />
    </BaseCard>
  );
};
