// https://stackoverflow.com/questions/56373850/how-can-i-create-a-table-using-the-react-pdf-library-for-generation-pdf-report
import {
  Page,
  Document,
  StyleSheet,
  View,
  Text,
  Svg,
  Line,
  Font,
} from "@react-pdf/renderer";
import { ChartData } from "../../hooks";

Font.register({
  family: "Inter",
  fonts: [
    {
      src: "/fonts/Inter-Regular.ttf",
      fontStyle: "normal",
      fontWeight: "normal",
    },
    {
      src: "/fonts/Inter-Bold.ttf",
      fontStyle: "normal",
      fontWeight: "bold",
    },
  ],
});

const sampleData = {
  id: "5df3180a09ea16dc4b95f910",
  items: [
    {
      id: "1",
      desc: "desc1",
      xyz: 5,
    },
    {
      id: "2",
      desc: "desc2",
      xyz: 6,
    },
  ],
};

interface PdfTableProps {
  items: PdfTableRow[];
}


interface PdfTableRow {
  [key: string]: any;
  id: string;
}

export const PdfTable = ({
  data,
  wrap,
}: {
  data?: ChartData;
  wrap?: boolean;
}) => {
  const styles = StyleSheet.create({
    page: {
      fontSize: 11,
      flexDirection: "column",
    },
  });

  const cols: PdfTableRow[] = [];

  if (data) {
    data.tableFormattedLabels?.forEach((label, idx) => {
      const row: PdfTableRow = {
        id: label,
        [data.formatted_label || data.label]: label,
      };

      data.datasets.forEach((item) => {
        row[item.label] = item.tableFormattedData?.[idx];
      });
      cols.push(row);
    });
  }

  if (wrap) {
    return (
      <Document>
        <Page orientation="landscape" size="A4" style={styles.page}>
          <ItemsTable items={cols} />
        </Page>
      </Document>
    );
  }

  return <ItemsTable items={cols} />;
};

const ItemsTable = ({ items }: PdfTableProps) => {
  const styles = StyleSheet.create({
    tableContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
  });

  return (
    <View style={styles.tableContainer}>
      <TableHeader items={items} />
      <TableRow items={items} />
      {/*<TableFooter items={data.items} />*/}
    </View>
  );
};

const TableHeader = ({ items }: PdfTableProps) => {
  const styles = StyleSheet.create({
    row: {
      flexDirection: "row",
      alignItems: "center",
      fontFamily: "Inter",
      fontWeight: "bold",
      paddingBottom: 4,
      borderBottom: 1,
      borderBottomColor: "lightgray",
      width: "100%",
      display: "flex",
    },
    xyz: {
      fontSize: 10,
      width: "20%",
    },
  });
  if (items.length === 0) return null;

  return (
    <View style={styles.row}>
      {Object.keys(items[0])
        .filter((e) => e !== "id")
        .map((key: string) => (
          <Text style={styles.xyz} key={key}>
            {key}
          </Text>
        ))}
    </View>
  );
};

const TableRow = ({ items }: { items: any[] }) => {
  const styles = StyleSheet.create({
    row: {
      flexDirection: "row",
      alignItems: "center",
      fontFamily: "Inter",
      paddingVertical: 4,
      fontSize: 10,
      borderBottom: 1,
      borderBottomColor: "lightgray",
      width: "100%",
    },
    xyz: {
      fontSize: 10,
      width: "20%",
    },
  });

  const rows = items.map((item: { id: string; [key: string]: any }) => {
    return (
      <View style={styles.row} key={item.id}>
        {Object.keys(item)
          .filter((e) => e !== "id")
          .map((key: string) => (
            <Text style={styles.xyz} key={key}>
              {item[key]}
            </Text>
          ))}
      </View>
    );
  });
  return <>{rows}</>;
};
