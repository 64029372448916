import { api } from "@vuumly-common/common";
import { ChartData, ChartOptions, TooltipItem } from "chart.js";
import Color from "color";
import { useQuery, useTheme, useTranslate } from "hooks";
import _ from "lodash";
import { useMemo } from "react";
import { CardProps } from "../..";
import { BaseBarChart } from "../../../Charts";
import { TextH5 } from "../../../Typography/Text";
import { BaseCard } from "../../Base.card";

export const RideMarketSharePerHourOfDayBarChart = (props: CardProps) => {
  const translate = useTranslate();
  const theme = useTheme();

  const { data, isLoading } = useQuery<ChartData<"bar", Record<any, number>[]>>(
    {
      apiEndpoint: api.Endpoint.RidesPerHourOfDay,
    }
  );

  const clonedData = useMemo(() => {
    if (isLoading || !data?.datasets) {
      return;
    }
    const clonedData = _.cloneDeep(data);

    clonedData?.datasets.forEach((dataset) => {
      const { label } = dataset;

      if (label === "ride_share_operator") {
        if (dataset.data.length) {
          dataset.backgroundColor = dataset.data.map(() => {
            return Color(theme.accentColor).string();
          });

          dataset.hoverBackgroundColor = dataset.data.map(() => {
            return Color(theme.accentColorHover).string();
          });
        }
        dataset.label = translate("Your market share");
        dataset.barPercentage = 0.999;
        // dataset.categoryPercentage = 1;
      } else {
        dataset.hidden = true;
        dataset.label = "none";
      }
    });

    return clonedData;
  }, [data]);

  const options: ChartOptions<"bar"> = {
    parsing: {
      yAxisKey: "ride_share_operator",
      xAxisKey: "dimension",
    },
    scales: {
      x: {
        title: {
          text: "Hour of day",
          display: true,
        },
        type: "time",
        time: {
          tooltipFormat: "HH:00",
          parser: "HH",
          unit: "hour",
          displayFormats: {
            hour: "HH",
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          text: "Your market share",
        },
        ticks: {
          display: false,
          callback: (value) => {
            return `${value}%`;
          },
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },

    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: (item: TooltipItem<"bar">) => {
            return `${item.dataset.label}: ${item.formattedValue}%`;
          },
        },
      },
      datalabels: {
        display: true,
        formatter: (item: any, _ctx) => {
          return item.ride_share_operator + "%";
        },
      },
    },
  };

  return (
    <BaseCard
      isLoading={isLoading}
      header={
        <TextH5>{translate("Your ride market share by hour of day")}</TextH5>
      }
    >
      <BaseBarChart options={options} data={clonedData} />
    </BaseCard>
  );
};
