import { useQuery as tanstackQuery } from "@tanstack/react-query";
import { api } from "@vuumly-common/common";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { useDemoMode, useUserContext } from "../index";
import { useMemo } from "react";

export interface Props {
  apiEndpoint: api.Endpoint;
  params?: string | URLSearchParams | null;
  ignoreParams?: string[];
  apiVersion?: string;
}

export function useQuery<T = api.response.StandartResponse>(props: Props) {
  const { apiEndpoint, params, ignoreParams, apiVersion = "v1" } = props;
  const [searchParams] = useSearchParams();
  const { isSigned } = useUserContext();
  const { isDemoModeEnabled } = useDemoMode();

  const queryParams = useMemo(() => {
    let val = searchParams;

    if (params) {
      if (typeof params === "string") {
        val = new URLSearchParams(params);
      } else {
        val = params;
      }
    }

    if (ignoreParams && ignoreParams.length > 0) {
      ignoreParams.forEach((param) => val.delete(param));
    }

    return val.toString();
  }, [searchParams, params, ignoreParams]);

  const { data, isLoading, error } = tanstackQuery<api.ApiResponse<T>>({
    staleTime: Infinity,
    
    retry: false,

    enabled:
      (isSigned || isDemoModeEnabled) &&
      ((params !== null && !!params?.toString()) ||
        (params === undefined && !!searchParams.toString())),
    queryKey: [apiVersion, apiEndpoint, queryParams],

    queryFn: () => {
      const url =
        `${process.env.REACT_APP_DATA_API_ENDPOINT}/${apiVersion}/${apiEndpoint}?` +
        (params ? params?.toString() : searchParams.toString());

      return axios(url, {
        headers: { authorization: localStorage.getItem("authorizationToken") },
      }).then((res) => res.data);
    },
  });

  return { error, isLoading, data: data?.data };
}
