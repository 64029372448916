import { time } from "@vuumly-common/common";
import { useState } from "react";
import { IBaseTab } from "../components/Tabs/BaseTabs";
import { useConstants, useDateRange, useTranslate } from "./index";

export function useTimeGranularity() {
  const {
    TIME_GRANULARITY_MAX_RANGE_FOR_DAY_OPTION,
    TIME_GRANULARITY_MIN_RANGE_FOR_MONTH_OPTION,
    TIME_GRANULARITY_MIN_RANGE_FOR_WEEK_OPTION,
    TIME_GRANULARITY_MAX_RANGE_FOR_WEEK_OPTION,
  } = useConstants();

  const { selectedDaysCount } = useDateRange();
  const translate = useTranslate();

  const [timeGranularity, setTimeGranularity] = useState<time.GranularityEnum>(
    time.GranularityEnum.Day
  );

  if (selectedDaysCount) {
    if (
      timeGranularity === time.GranularityEnum.Day &&
      selectedDaysCount > TIME_GRANULARITY_MAX_RANGE_FOR_DAY_OPTION
    ) {
      setTimeGranularity(time.GranularityEnum.Week);
    }

    if (
      timeGranularity === time.GranularityEnum.Week &&
      selectedDaysCount < TIME_GRANULARITY_MIN_RANGE_FOR_WEEK_OPTION
    ) {
      setTimeGranularity(time.GranularityEnum.Day);
    }

    if (
      (timeGranularity === time.GranularityEnum.Week ||
        timeGranularity === time.GranularityEnum.Day) &&
      selectedDaysCount > TIME_GRANULARITY_MAX_RANGE_FOR_WEEK_OPTION
    ) {
      setTimeGranularity(time.GranularityEnum.Month);
    }

    if (
      timeGranularity === time.GranularityEnum.Month &&
      selectedDaysCount < TIME_GRANULARITY_MIN_RANGE_FOR_MONTH_OPTION
    ) {
      setTimeGranularity(time.GranularityEnum.Week);
    }
  }

  const dayTab: IBaseTab = {
    label: translate("timegranularity_tab_day"),
    value: time.GranularityEnum.Day,
    disabled: false,
  };
  const weekTab: IBaseTab = {
    label: translate("timegranularity_tab_week"),
    value: time.GranularityEnum.Week,
    disabled: false,
  };
  const monthTab: IBaseTab = {
    label: translate("timegranularity_tab_month"),
    value: time.GranularityEnum.Month,
    disabled: false,
  };

  if (selectedDaysCount) {
    if (selectedDaysCount < TIME_GRANULARITY_MIN_RANGE_FOR_WEEK_OPTION) {
      weekTab.disabled = true;
    }

    if (selectedDaysCount < TIME_GRANULARITY_MIN_RANGE_FOR_MONTH_OPTION) {
      monthTab.disabled = true;
    }

    if (selectedDaysCount > TIME_GRANULARITY_MAX_RANGE_FOR_DAY_OPTION) {
      dayTab.disabled = true;
    }

    if (selectedDaysCount > TIME_GRANULARITY_MAX_RANGE_FOR_WEEK_OPTION) {
      weekTab.disabled = true;
    }
  }

  const tabs = [dayTab, weekTab, monthTab];

  const changeGranularity = (value: time.GranularityEnum) => {
    const newParams = new URLSearchParams(window.location.search);
    newParams.set("time_granularity", value);
    setTimeGranularity(value);
  };

  const modifiedQueryParams = new URLSearchParams(window.location.search);

  if (timeGranularity) {
    modifiedQueryParams.set("time_granularity", timeGranularity);
  }

  return {
    tabs,
    timeGranularity,
    changeGranularity,
    modifiedQueryParams,
  };
}
