import { BaseLabel } from "components/Label";
import { Checkbox } from "components/shadcn/components/ui/checkbox";

export type BaseCheckboxState = boolean | "indeterminate";

interface IBaseCheckbox {
  onChange?: (val: BaseCheckboxState) => void;
  checked: boolean;
  disabled?: boolean;
  value: string;
  label: string;
  id: string;
}

export function BaseCheckbox(
  props: IBaseCheckbox &
    Omit<React.HTMLAttributes<HTMLInputElement>, "onChange">
) {
  const { onChange, checked, disabled, value, label, id } = props;
  return (
    <div className="flex items-center space-x-2">
      <Checkbox
        onCheckedChange={onChange}
        id={id}
        checked={checked}
        disabled={disabled}
      />
      <BaseLabel htmlFor={id} label={label} />
    </div>
  );
}
