export interface Color {
  hex: string;
  rgba: string;
}

interface ColorsI {
    Primary: Color;
    Blue: Color;
    Green: Color;
    Red: Color;
    Purple: Color;
    Orange: Color;
    Black: Color;

    Car: Color;
    Cargo: Color;
    Bike: Color;
    EBike: Color;
    Moped: Color;
    Scooter: Color;
  // Add more colors here if needed
}

export const Colors: ColorsI = {
  Primary: { hex: "#006874", rgba: "rgba(0, 119, 194, 1)" },
  Blue: { hex: "#0000FF", rgba: "rgb(0, 0, 255, 1)" },
  Green: { hex: "#008000", rgba: "rgb(0, 128, 0, 1)" },
  Red: { hex: "#FF0000", rgba: "rgb(255, 0, 0, 1)" },
  Purple: { hex: "#800080", rgba: "rgb(128, 0, 128, 1)" },
  Orange: { hex: "#FFA500", rgba: "rgb(255, 165, 0, 1)" },
  Black: { hex: "#000000", rgba: "rgb(0, 0, 0, 1)" },

  Car: { hex: "#264653", rgba: "rgba(0, 119, 194, 1)" },
  Cargo: { hex: "#2a9d8f", rgba: "rgba(0, 119, 194, 1)" },
  Bike: { hex: "#e9c46a ", rgba: "rgba(0, 119, 194, 1)" },
  EBike: { hex: "#f4a261 ", rgba: "rgba(0, 119, 194, 1)" },
  Moped: { hex: "#E09EB9 ", rgba: "rgba(0, 119, 194, 1)" },
  Scooter: { hex: "#e76f51", rgba: "rgba(0, 119, 194, 1)" },
  // Add more colors here if needed
};
