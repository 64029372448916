import { useEngineType, useTranslate } from "hooks";
import { useMemo } from "react";
import { BaseFilterOption, BaseFilterSelect } from "../BaseFilterSelect";

export function EngineTypeSelect() {
  const translate = useTranslate();
  const { setSelectedEngineType, selectedEngineTypes } = useEngineType();

  const list = useMemo(() => {
    return engineTypeDefault.map((item) => {
      item.selected = selectedEngineTypes.includes(item.value);
      return item;
    });
  }, [selectedEngineTypes]);

  const onChange = (payload: BaseFilterOption<string>[]) => {
    const engineTypes = payload
      .filter((item) => item.selected)
      .map((item) => item.value);

    setSelectedEngineType(engineTypes);
  };

  return (
    <BaseFilterSelect<string>
      onChange={onChange}
      options={list}
      title={translate("Engine type")}
    />
  );
}

const def = {
  selected: false,
  disabled: false,
  hasMatch: false,
  subscribed: false,
};

export const engineTypeDefault: BaseFilterOption<string>[] = [
  {
    label: "Combustion",
    value: "combustion",
    id: "combustion",
    ...def,
  },
  {
    label: "Electric",
    value: "electric",
    id: "electric",
    ...def,
  },
];
