import { time } from "@vuumly-common/common";
import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export function useHourOfDay() {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedHourOfDay = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.getAll("hour_of_day[]") as time.HourOfDayEnum[];
  }, [searchParams]);

  const setSelectedHourOfDay = (payload: string[]) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("hour_of_day[]");
    payload.forEach((item) => {
      searchParams.append("hour_of_day[]", item);
    });

    setSearchParams(searchParams);
  };

  const removeSelectedHourOfDay = (payload: time.HourOfDayEnum) => {
    const searchParams = new URLSearchParams(window.location.search);
    const hourOfDayParam = searchParams.getAll(
      "hour_of_day[]"
    ) as time.HourOfDayEnum[];

    if (hourOfDayParam.includes(payload)) {
      searchParams.delete("hour_of_day[]");
      hourOfDayParam.forEach((item) => {
        if (item !== payload) {
          searchParams.append("hour_of_day[]", item);
        }
      });

      setSearchParams(searchParams);
    }
  };

  return {
    selectedHourOfDay,
    setSelectedHourOfDay,
    removeSelectedHourOfDay,
  };
}
