import { useSearchParams } from "react-router-dom";

export function useFilterHelper() {
  const [searchParams, setSearchParams] = useSearchParams();

  function resetAllSelections() {
    searchParams.delete("cities[]");
    searchParams.delete("companies[]");
    searchParams.delete("device_types[]");
    searchParams.delete("origin_id");
    searchParams.delete("hour_of_day[]");
    searchParams.delete("day_of_week[]");
    searchParams.delete("ride_length[]");
    searchParams.delete("engine_type[]");
    setSearchParams(searchParams);
  }
  return {
    resetAllSelections,
  };
}
