import { usePageTitle } from "hooks";
import { DashboardHeader } from "../Header";
import { cn } from "../shadcn/lib/utils";
import { RoutePathEnum } from "controllers/router";

export const DashboardContentWrapper = (
  props: React.ComponentPropsWithoutRef<"div">
) => {
  return (
    <div
      id="page-layout"
      {...props}
      className={cn("max-w-7xl mx-auto", props.className)}
    >
      <div className="p-6">{props.children}</div>
    </div>
  );
};
