import { X } from "lucide-react";
import { TextSmall } from "../Typography/Text";
import classnames from "classnames";
import { cn } from "../shadcn/lib/utils";

interface Props<T> {
  label: string;
  id: T;
  onClick?: () => void;
  onClear?: (items: T) => void;
  className?: string;
}

export function BaseBadge<T extends string>(props: Props<T>) {
  const { label, id, onClear, onClick, className } = props;

  return (
    <div
      className={cn(
        "flex bg-foreground text-background rounded-md items-center p-1 hover:bg-primary/90",
        { "cursor-pointer": onClick, "cursor-default": !onClick },
        className
      )}
      onClick={onClick}
    >
      <TextSmall className="whitespace-nowrap">{label}</TextSmall>
      {onClear && (
        <div
          className="cursor-pointer hover:opacity-70"
          onClick={() => onClear(id)}
        >
          <X size={16} />
        </div>
      )}
    </div>
  );
}
