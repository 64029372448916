import { useTranslate } from "./useTranslate.hook";

export function useParseRideLength() {
  const translate = useTranslate();

  function parse(data: string): string {
    const structure = data.split("_").map((item) => Number(item));
    if (!structure[0]) {
      return translate("ride_length_below", { length: `${structure[1]}` });
    }

    if (!structure[1]) {
      if (structure[0] >= 120) {
        return translate("ride_length_above", {
          length: `${structure[0] / 60} h`,
        });
      }

      return translate("ride_length_above", { length: `${structure[0]}` });
    }

    const str = { above: "", below: "" };

    if (structure[0] > 120) {
      str.above = `${structure[0] / 60}`;
    } else {
      str.above = `${structure[0]}`;
    }

    if (structure[1] > 120) {
      str.below = structure[1] / 60 + " h";
      str.above = `${structure[0] / 60}`;
    } else {
      str.below = structure[1] + " min";
    }

    return translate("ride_length_between", str);
  }

  return parse;
}
