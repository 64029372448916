import {
  CityEnum,
  CountryEnum,
  DeviceGroupEnum,
  getCountriesByCities,
  subscriptions,
  time,
} from "@vuumly-common/common";
import {
  getAuth,
  onAuthStateChanged,
  onIdTokenChanged,
  signOut as signOutFirebase,
} from "firebase/auth";
import { getDatabase, onValue, ref } from "firebase/database";
import _ from "lodash";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { DateRange } from "react-day-picker";
import { firebaseApp } from "../../controllers/firebase-app";
import { UserState } from "./index";

const DEFAULT_LOCALE = "en-EN";

export interface UserContextProps {
  locale: string;
  userState: UserState;
  setUserState: (value: UserState) => void;

  isSubscribed: boolean;
  setIsSubscribed: (value: boolean) => void;

  isSigned: boolean;
  setIsSigned: (value: boolean) => void;

  /**
   * User auth state has been loaded. This will be true if user is signed in or not
   */
  isAuthInit: boolean;

  /**
   * User profile settings has been loaded. This will be true only for signed in users
   */
  isProfileInit: boolean;

  email?: string | null;
  displayName?: string | null;

  subscribedCities: CityEnum[];
  subscribedCountries: CountryEnum[];

  emailVerified: boolean;

  subscribedProducts: subscriptions.Model[];

  isOperator: boolean;
  operatorId?: string;

  signOut: () => void;

  // demoState: DemoState;
  // setDemoState: (value: DemoState) => void;

  //isDemoStateEnabled: boolean;
}

interface UserStoreModel {
  name?: string;
  operator_id?: number;
  operator_type?: DeviceGroupEnum;
  isAdmin?: boolean;
  subscriptions?: Record<
    string,
    {
      metadata: {
        city: CityEnum;
        device_group: DeviceGroupEnum;
        is_operating: boolean;
      };
      status: subscriptions.StatusEnum;
    }
  >;
}

const defaultDemoDaterange: DateRange = {
  from: new Date("2023-07-01"),
  to: new Date("2023-07-30"),
};

// interface DemoState {
//   isEnabled: boolean;
//   selectedCity: CityEnum;
//   selectedCountry: CountryEnum;
//   availableDateRange: DateRange;
//   deviceTypeGroup: DeviceGroupEnum;
// }

// const defaultDemoState: DemoState = {
//   isEnabled: false,
//   selectedCity: CityEnum.Riga,
//   selectedCountry: CountryEnum.Latvia,
//   availableDateRange:
//     Countries[CountryEnum.Latvia].demoDateRange || defaultDemoDaterange,
//   deviceTypeGroup: DeviceGroupEnum.Carsharing,
// };

export const UserContext = createContext<UserContextProps>({
  locale: DEFAULT_LOCALE,

  userState: {
    isSubscribed: false,
    isSigned: false,
    isAuthInit: false,
    isProfileInit: false,
    emailVerified: false,
  },
  setUserState: () => {},

  isSubscribed: false,
  setIsSubscribed: () => {},

  isSigned: false,
  setIsSigned: () => {},

  isAuthInit: false,
  isProfileInit: false,
  emailVerified: false,

  subscribedCities: [],
  subscribedCountries: [],

  subscribedProducts: [],

  isOperator: false,
  operatorId: undefined,

  signOut: () => {},

  // demoState: defaultDemoState,
  // setDemoState: () => {},

  //isDemoStateEnabled: false,
});

interface UserContextProviderProps {
  children: React.ReactNode;
}

const defaultUserState: UserState = {
  isSubscribed: false,
  isSigned: false,
  isAuthInit: false,
  isProfileInit: false,
  emailVerified: false,
  locale: DEFAULT_LOCALE,
  weekStartsOn: time.DayOfWeekEnum.Monday,
};

export const UserContextProvider: React.FC<UserContextProviderProps> = ({
  children,
}) => {
  // const [demoState, setDemoState] = useState<DemoState>(defaultDemoState);

  const [userState, setUserState] = useState<UserState>(defaultUserState);

  const setIsSubscribed = useCallback((value: boolean) => {
    setUserState((userState) => ({ ...userState, isSubscribed: value }));
  }, []);

  const setIsSigned = useCallback((value: boolean) => {
    setUserState((userState) => ({ ...userState, isSigned: value }));
  }, []);

  const {
    isSubscribed,
    isSigned,
    isAuthInit,
    uid,
    isProfileInit,
    email,
    displayName,
    subscribed_products,
    emailVerified,
  } = userState;

  const subscribedCities = useMemo(() => {
    if (subscribed_products) {
      return _.uniq(
        subscribed_products.map((product) => product.metadata.city as CityEnum)
      );
    }
    return [];
  }, [subscribed_products]);

  const subscribedCountries = useMemo(() => {
    if (subscribed_products) {
      const subscribedCities = _.uniq(
        subscribed_products.map((product) => product.metadata.city as CityEnum)
      );
      return getCountriesByCities(subscribedCities);
    }
    return [];
  }, [subscribed_products]);

  useEffect(() => {
    const auth = getAuth(firebaseApp);

    // watch token changes
    onIdTokenChanged(auth, async (user) => {
      if (user) {
        const newToken = await user.getIdToken();
        localStorage.setItem("authorizationToken", newToken);
      }
    });

    // watch auth state
    onAuthStateChanged(auth, async (user) => {
      const updatedCredentials: Record<string, any> = {};

      if (user) {
        const userToken = await auth.currentUser?.getIdToken();

        if (userToken) {
          localStorage.setItem("authorizationToken", userToken);
        } else {
          localStorage.removeItem("authorizationToken");
        }

        updatedCredentials.uid = user.uid;
        updatedCredentials.email = user.email;
        updatedCredentials.displayName = user.displayName;
        updatedCredentials.emailVerified = user.emailVerified;
        updatedCredentials.isSigned = true;
      } else {
        updatedCredentials.isSigned = false;
      }

      setUserState(
        (state): UserState => ({
          ...state,
          ...updatedCredentials,
          isAuthInit: true,
        })
      );
    });
  }, []);

  // load user settings
  useEffect(() => {
    if (uid && isSigned) {
      console.log("connecting to DB", userState.email);
      const db = getDatabase(firebaseApp);

      const starCountRef = ref(db, "users/" + uid);

      const updatedSettings: UserState = {
        ...userState,
      };

      onValue(starCountRef, (snapshot) => {
        const data: UserStoreModel = snapshot.val();
        // console.log("DB data", data);
        if (data) {
          updatedSettings.isAdmin = data.isAdmin || false;

          if (data.subscriptions) {
            // if has any active subscriptions
            updatedSettings.isSubscribed = true;
            updatedSettings.operator_id = data.operator_id
              ? String(data.operator_id)
              : undefined;
            updatedSettings.operator_type = data.operator_type;
            updatedSettings.subscribed_products = Object.values(
              data.subscriptions
            ).map((subscription) => {
              return {
                cancel_at_period_end: false,
                created: new Date().getTime(),
                current_period_end: new Date().getTime(),
                current_period_start: new Date().getTime(),
                product_id: "string",
                subscription_id: "string",
                metadata: subscription.metadata,
                status: subscription.status,
              };
            });
          }

          if (data.name) {
            updatedSettings.displayName = data.name;
          }
        }

        setUserState(
          (state): UserState => ({
            ...state,
            ...updatedSettings,
            isProfileInit: true,
          })
        );
      });
    }
  }, [isSigned]);

  const signOut = useCallback(() => {
    const auth = getAuth();
    setUserState(defaultUserState);
    signOutFirebase(auth);
  }, []);

  return (
    <UserContext.Provider
      value={{
        // demoState,
        // setDemoState,
        // isDemoStateEnabled: demoState.isEnabled,

        locale: userState.locale || DEFAULT_LOCALE,
        userState,
        setUserState,
        setIsSubscribed,
        isSubscribed,
        isSigned,
        isOperator: userState.operator_id !== undefined,
        operatorId: userState.operator_id,
        setIsSigned,
        isAuthInit,
        isProfileInit,
        email,
        displayName,
        subscribedCities,
        subscribedCountries,
        emailVerified,
        subscribedProducts: userState.subscribed_products || [],
        signOut,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
