import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import { APP_ENDPOINT, RoutePathEnum } from "../controllers/router";

const actionCodeSettings = {
  url: `${APP_ENDPOINT}${RoutePathEnum.VerifyEmail}`,
  handleCodeInApp: true,
};

interface SignInWithEmailProps {
  email: string;
  onSuccess: () => void;
  onError: (error: any) => void;
}

export function useAuth() {
  function signInWithEmail(props: SignInWithEmailProps) {
    const { email, onSuccess, onError } = props;
    const auth = getAuth();
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }

        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", email);
        // ...
      })
      .catch((error) => {
        if (onError) {
          onError(error);
        }
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
      });
  }

  return { signInWithEmail };
}
