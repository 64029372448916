import {
  Cities,
  CityEnum,
  getCitiesByCountry,
  location,
} from "@vuumly-common/common";
import {
  useCity,
  useCountry,
  useDemoMode,
  useTranslate,
  useUserContext,
} from "hooks";
import { useMemo } from "react";
import { BaseFilterOption, BaseFilterSelect } from "../BaseFilterSelect";
import { BaseOption } from "../BaseSelector";
import { SelectProps } from "../index";

export function CitySelect(props: SelectProps) {
  const translate = useTranslate();
  const { isDemoModeEnabled } = useDemoMode();
  const { subscribedCities } = useUserContext();
  const { selectedCountryValue } = useCountry();
  const { selectedCities, setSelectedCities } = useCity();

  const list = useMemo(() => {
    if (!selectedCountryValue) {
      return [];
    }
    const cities = getCitiesByCountry(selectedCountryValue);

    return cities.map((item) => {
      const isAvailableForDemo = location.cities.isDemoAvailable(item.value);

      const isSubscribed = subscribedCities.includes(item.value);

      return {
        label: item.name,
        value: item.value,
        id: item.value,
        selected: selectedCities.includes(item.value),
        subscribed: subscribedCities.includes(item.value),
        tooltip: isDemoModeEnabled
          ? "Available in full version"
          : isSubscribed
          ? ""
          : "Not subscribed",
        disabled: isDemoModeEnabled
          ? !isAvailableForDemo
          : !subscribedCities.includes(item.value),
      };
    });
  }, [selectedCountryValue, isDemoModeEnabled]);

  const onChange = (payload: BaseFilterOption<CityEnum>[]) => {
    const selectedCities = payload
      .filter((item) => item.selected)
      .map((item) => item.value);
    setSelectedCities(selectedCities, {
      navigateOptions: props.navigateOptions,
    });
  };

  return (
    <BaseFilterSelect<CityEnum>
      onChange={onChange}
      options={list}
      title={translate("City/Municipality")}
    />
  );
}

const def = {
  selected: false,
  disabled: false,
  hasMatch: false,
  subscribed: false,
};

const defaultAreas: BaseOption<CityEnum>[] = Object.values(Cities).map(
  (item) => {
    return {
      label: item.name,
      value: item.value,
      id: item.value,
      ...def,
    };
  }
);
