import { RoutePathEnum } from "../../controllers/router";
import { useRouter } from "../../hooks";
import { BaseBadge } from "./BaseBadge";

export function DemoModeBadge() {
  const { goToExternal } = useRouter();
  return (
    <div className="flex items-center">
      <BaseBadge
        className="bg-emerald-600 hover:bg-emerald-700"
        onClick={() => goToExternal(RoutePathEnum.Contacts)}
        id="demo_mode_badge"
        label="Demo mode - Request Full Access!"
      />
    </div>
  );
}
