import { api } from "@vuumly-common/common";
import { useQuery, useTranslate } from "hooks";
import _ from "lodash";
import { useMemo } from "react";
import { CardProps } from "..";
import { TextH5, TextMuted, TextSmall } from "../../Typography/Text";
import { BaseCard } from "../Base.card";

interface Data {
  company_id: string;
  distinct_place: string;
  device_count: number;
}

export const FleetSizeStandbyAverageDistinctPlace = (props: CardProps) => {
  const translate = useTranslate();

  const { data, isLoading } = useQuery({
    apiEndpoint: api.Endpoint.FleetSizeStandbyAverageDistinctPlace,
  });

  const clonedData = useMemo(() => {
    if (isLoading || !data) {
      return {
        columns: [],
        list: [],
      };
    }

    const list: Record<string, Record<string, number | string>> = {};

    const clonedData = _.cloneDeep(data) as unknown as Data[];

    const columns = _.uniq(clonedData.map((item) => item.company_id));

    clonedData.forEach((item) => {
      const { distinct_place, device_count, company_id } = item;
      if (!list[distinct_place]) {
        list[distinct_place] = { place: distinct_place };
      }
      if (!list[distinct_place][company_id]) {
        list[distinct_place][company_id] = device_count;
      }
    });
    return { columns, list: Object.values(list) };
  }, [data]);

  const Header = () => {
    return (
      <div className="flex justify-between w-full">
        <TextH5>{translate("Average fleet size on standby by area")}</TextH5>
      </div>
    );
  };

  return (
    <BaseCard isLoading={isLoading} header={<Header />}>
      <div className="h-[420px] overflow-y-scroll pr-4">
        <TableHeader columns={clonedData.columns} />
        <div className="">
          {clonedData.list.map((item, index) => {
            return (
              <Item key={index} item={item} columns={clonedData.columns} />
            );
          })}
        </div>
      </div>
    </BaseCard>
  );
};

const TableHeader = ({ columns }: { columns: string[] }) => {
  return (
    <div className="flex rounded-sm sticky top-0 bg-slate-100 py-2 pl-2">
      <TextMuted style={{ width: `${(100 / (columns.length + 2)) * 2}%` }}>
        Area
      </TextMuted>
      {columns.map((column, index) => {
        return (
          <TextMuted
            key={index}
            style={{ width: `${(100 / (columns.length + 2)) * 1}%` }}
          >
            Company {column}
          </TextMuted>
        );
      })}
    </div>
  );
};

const Item = ({
  item,
  columns,
}: {
  item: Record<string, string | number>;
  columns: string[];
}) => {
  const { place } = item;

  return (
    <div className="flex pl-2 border-b py-4 hover:bg-slate-50">
      <TextSmall style={{ width: `${(100 / (columns.length + 2)) * 2}%` }}>
        {place}
      </TextSmall>
      {columns.map((column, index) => {
        return (
          <TextSmall
            key={index}
            style={{ width: `${(100 / (columns.length + 2)) * 1}%` }}
          >
            {item[column] || 0}
          </TextSmall>
        );
      })}
    </div>
  );
};
