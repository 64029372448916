import { time } from "@vuumly-common/common";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export function useDayOfWeek() {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedDaysOfWeek = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.getAll("day_of_week[]") as time.DayOfWeekEnum[];
  }, [searchParams]);

  const setSelectedDaysOfWeek = (payload: string[]) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("day_of_week[]");
    payload.forEach((item) => {
      searchParams.append("day_of_week[]", item);
    });

    setSearchParams(searchParams);
  };

  const removeSelectedDayOfWeek = (payload: time.DayOfWeekEnum) => {
    const searchParams = new URLSearchParams(window.location.search);
    const dayOfWeekParam = searchParams.getAll(
      "day_of_week[]"
    ) as time.DayOfWeekEnum[];

    if (dayOfWeekParam.includes(payload)) {
      searchParams.delete("day_of_week[]");
      dayOfWeekParam.forEach((item) => {
        if (item !== payload) {
          searchParams.append("day_of_week[]", item);
        }
      });

      setSearchParams(searchParams);
    }
  };
  return { selectedDaysOfWeek, setSelectedDaysOfWeek, removeSelectedDayOfWeek };
}
