export enum RoutePathEnum {
  Home = "/",
  Faq = "/faq",
  Dev = "/dev",
  SignIn = "/signin",
  CheckEmail = "/check-email",
  VerifyEmail = "/auth/verify-email",
  NotSubscribed = "/auth/not-subscribed",
  SignUp = "/signup",
  Admin = "/admin",
  Subscribe = "/subscribe",
  Settings = "/settings",
  Distribution = "/distribution",

  Areas = "/areas",
  AreasOriginDestination = "/areas/origin-destination",
  AreasMap = "/areas/map",

  Area = "/area",
  Data = "/data",
  Flowmap = "/map/flow",
  Heatmap = "/map/heatmap",
  Hexmap = "/map/hex",
  Typography = "/typography",
  DayOfWeek = "/day-of-week",
  LengthMedian = "/length/median",

  RideLength = "/ride-length",
  RideLengthGroup = "/ride-length/group",
  RideLengtMinutes = "/ride-length/minutes",
  RideLengtHours = "/ride-length/hours",

  Rides = "/rides",
  RidesDayOfWeek = "/rides/day-of-week",
  RidesTimeOfDay = "/rides/time-of-day",
  RidesHourOfDay = "/rides/hour-of-day",
  RidesDevices = "/rides/devices",

  Report = "/report",

  Fleet = "/fleet",
  FleetStandBy = "/fleet/stanby",

  PathMap = "/debug/paths",
  TrafficMap = "/debug/traffic",
  AccidentMap = "/debug/accidents",
  TransitMap = "/debug/transit",
  MapTools = "/debug/maptools",

  Utilization = "/utilization",
  UtilizationTimeOfDay = "/utilization/time-of-day",
  UtilizationHourOfDay = "/utilization/hour-of-day",

  LiveMap = "/map/live",
  VectorMap = "/map/vector",

  Terms = "https://kenflow.io/end-user-license-agreement-eula",
  Privacy = "https://kenflow.io/privacy-policy",
  Contacts = "https://kenflow.io/contacts",

  Demo = "/demo",
}

const apiEndpoint = process.env.REACT_APP_DATA_API_ENDPOINT;
export const APP_ENDPOINT = process.env.REACT_APP_HOMPAGE_URL;

export const ApiPathEnum = {
  CancelSubscription: `${apiEndpoint}/v1/cancel-subscription`,
  ActivateSubscription: `${apiEndpoint}/v1/activate-subscription`,
  GenerateCheckoutLink: `${apiEndpoint}/v1/generate-checkout-link`,
  SubscriptionList: `${apiEndpoint}/v1/subscriptions`,
};
