import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { PickingType, FlowmapLayerPickingInfo } from "@flowmap.gl/layers";
import { Layer } from "ol/layer";
import { toLonLat } from "ol/proj";
import { Deck } from "deck.gl";
import { useMapContext } from "context/MapContext";
import { LayerPanel, PanelSlider, SelectPanel } from "components/Panel";
import { GeohashLayer } from "@deck.gl/geo-layers";
import { geo } from "@vuumly-common/common";
import { BaseButton } from "../../components/Button";
import { useTranslate } from "../useTranslate.hook";
import { HexagonLayer } from "deck.gl";
import { Layers } from "../../controllers";

interface Props {
  data: any;
  isLoading?: boolean;
  settings: Layers.Hexmap.Settings;
  setSettings: (settings: Layers.Hexmap.Settings) => void;
}

interface PanelProps {
  settings: Layers.Hexmap.Settings;
  setSettings: (settings: Layers.Hexmap.Settings) => void;
  updateAvailable?: boolean;
  handleUpdate: () => void;
}

function Panel(props: PanelProps) {
  const { settings, setSettings, handleUpdate, updateAvailable } = props;

  const translate = useTranslate();

  function getOptions() {
    const options = geo.GeohashType.map((type) => ({
      value: type.type,
      label: translate(type.name),
      id: type.type,
    }));
    return options;
  }

  return (
    <LayerPanel
      checked={true}
      value="heatmapLayerVisible"
      label="Heatmap"
      onChange={(val) => {}}
    >
      <div className="space-y-4">
        <PanelSlider
          label="geohashmap_opacity_label"
          value={settings.opacity}
          minValue={0.1}
          maxValue={1}
          step={0.1}
          onChange={(value) => {
            setSettings({
              ...settings,
              opacity: value,
            });
          }}
        />

        <PanelSlider
          label="geohashmap_min_ride_count"
          value={settings.heatmap_min_ride_count}
          minValue={5}
          maxValue={1000}
          step={5}
          onChange={(value) => {
            setSettings({
              ...settings,
              heatmap_min_ride_count: value,
            });
          }}
        />

        <SelectPanel
          label="geohash_cluster_size"
          value={settings.geohash_cluster_size}
          options={getOptions()}
          onChange={(value) => {
            setSettings({
              ...settings,
              geohash_cluster_size: value as geo.GeohashSize,
            });
          }}
        />
        <BaseButton
          className="w-full"
          onClick={handleUpdate}
          disabled={!updateAvailable}
        >
          {!updateAvailable
            ? translate("data_up_to_date")
            : translate("data_update_available")}
        </BaseButton>
      </div>
    </LayerPanel>
  );
}

export function useGeohashMapLayer(props: Props) {
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const { settings, setSettings, data, isLoading } = props;
  const [preFetchSettings, setPreFetchSettings] =
    useState<Layers.Hexmap.Settings>(Layers.Hexmap.defaultSettings);

  const [viewState, setViewState] = useState<ViewState>();
  const [tooltip, setTooltip] = useState<any | undefined>();

  const { mapInstance } = useMapContext();

  const deckRef = useRef<Deck | null>(null);
  const deckLayerRef = useRef<Layer | null>(null);
  const layersRef = useRef<any[]>([]);

  const handleViewStateChange = ({ viewState }: any) => {
    setViewState(viewState);
    setTooltip(undefined);
  };

  function handleUpdate() {
    setSettings(preFetchSettings);
    setUpdateAvailable(false);
  }

  useEffect(() => {
    if (preFetchSettings.opacity !== settings.opacity) {
      setSettings({ ...settings, opacity: preFetchSettings.opacity });
    }
    if (
      preFetchSettings.heatmap_min_ride_count !==
        settings.heatmap_min_ride_count ||
      preFetchSettings.geohash_cluster_size !== settings.geohash_cluster_size
    ) {
      setUpdateAvailable(true);
    } else {
      setUpdateAvailable(false);
    }
  }, [preFetchSettings]);

  useMemo(() => {
    if (data) {
      const layers: any[] = [];
      const max = Math.max(...data.datasets[0].data);
      const min = Math.min(...data.datasets[0].data);

      const parsedData = data.labels.map((d: string, idx: number) => {
        const val = data.datasets[0].data[idx];
        const normalized = (val - min) / (max - min);
        return {
          geohash: d,
          value: normalized,
          count: data.datasets[0].data[idx],
        };
      });

      layers.push(
        new GeohashLayer({
          opacity: settings.opacity,
          id: "geohash-layer",
          data: parsedData,
          pickable: true,
          wireframe: false,
          filled: true,
          extruded: false,
          //elevationScale: 1000,
          getGeohash: (d: any) => d.geohash,
          getFillColor: (d: any) => [
            d.value * 1000,
            (1 - d.value) * 128,
            (1 - d.value) * 128,
          ],
          getElevation: (d: any) => d.value,
          onHover: (info: any) => {
            setTooltip(info);
          },
        })
      );

      layersRef.current = layers;
    }
  }, [data, settings]);

  useEffect(() => {
    if (!data || !mapInstance) return;

    const deckGL = new Deck({
      initialViewState: {
        longitude: 0,
        latitude: 0,
        zoom: 1,
      },
      onViewStateChange: handleViewStateChange,
      controller: true,
      parent: document.getElementById("map") as HTMLElement,
      style: {
        // mixBlendMode: "darken",
        top: 0,
        pointerEvents: "none",
        "z-index": 1,
      },
      layers: layersRef.current,
    });

    deckRef.current = deckGL;

    const deckLayer = new Layer({
      render: function ({ size, viewState }): any {
        const [width, height] = size;
        const [longitude, latitude] = toLonLat(viewState.center);
        const zoom = viewState.zoom - 1;
        const bearing = (-viewState.rotation * 180) / Math.PI;
        const deckViewState = {
          bearing,
          longitude,
          latitude,
          zoom,
        };
        deckRef.current?.setProps({
          width,
          height,
          viewState: deckViewState,
        });
        deckRef.current?.redraw({});
      },
    });

    deckLayerRef.current = deckLayer;

    return () => {
      deckRef.current = null;
      deckGL.finalize();
    };
  }, [mapInstance, data]);

  useEffect(() => {
    if (!deckRef.current) return;

    deckRef.current.setProps({
      layers: layersRef.current,
    });
  }, [layersRef.current]);

  return {
    tooltip,
    layer: deckLayerRef.current,
    panel: (
      <Panel
        handleUpdate={handleUpdate}
        updateAvailable={updateAvailable}
        setSettings={setPreFetchSettings}
        settings={preFetchSettings}
      />
    ),
  };
}

function getTooltipState(
  info: FlowmapLayerPickingInfo<LocationDatum, FlowDatum> | undefined
): TooltipState | undefined {
  if (!info) return undefined;
  const { x, y, object } = info;
  const position = { left: x, top: y };
  switch (object?.type) {
    case PickingType.LOCATION:
      return {
        position,
        content: (
          <>
            <div>{object.name}</div>
            <div>Incoming trips: {object.totals.incomingCount}</div>
            <div>Outgoing trips: {object.totals.outgoingCount}</div>
            <div>Internal or round trips: {object.totals.internalCount}</div>
          </>
        ),
      };
    case PickingType.FLOW:
      return {
        position,
        content: (
          <>
            <div>
              {object.origin.id} → {object.dest.id}
            </div>
            <div>{object.count}</div>
          </>
        ),
      };
  }
  return undefined;
}

export type LocationDatum = {
  id: string;
  name: string;
  lon: number;
  lat: number;
};
export type FlowDatum = {
  origin: string;
  dest: string;
  count: number;
};
export type LoadedData = {
  locations: LocationDatum[];
  flows: FlowDatum[];
};

type TooltipState = {
  position: { left: number; top: number };
  content: ReactNode;
};

type ViewState = {
  /** Longitude at map center */
  longitude: number;
  /** Latitude at map center */
  latitude: number;
  /** Map zoom level */
  zoom: number;
  /** Map rotation bearing in degrees counter-clockwise from north */
  bearing: number;
  /** Map angle in degrees at which the camera is looking at the ground */
  pitch: number;
  /** Dimensions in pixels applied on each side of the viewport for shifting the vanishing point. */
  padding: PaddingOptions;
};

type PaddingOptions = {
  top: number;
  bottom: number;
  left: number;
  right: number;
};
