import { api } from "@vuumly-common/common";
import { useDataParser, useQuery, useTranslate, useUserContext } from "hooks";
import { TextMuted } from "../components/Typography/Text";

export function useDatasetSize() {
  const translate = useTranslate();
  const { locale } = useUserContext();

  const { data, isLoading } = useQuery({
    apiEndpoint: api.Endpoint.RidesTotal,
  });

  const { parsedData } = useDataParser({ data });

  function getComponent() {
    if (isLoading) {
      return <TextMuted>{translate("fethching_data")}...</TextMuted>;
    }

    if (!parsedData) {
      return <TextMuted>{translate("dataset_size")}: 0</TextMuted>;
    }

    const formatted = new Intl.NumberFormat(locale, {
      notation: "compact",
    }).format(parsedData?.datasets[0].data[0] || 0);

    return (
      <TextMuted>
        {translate("dataset_size")}: {formatted}
      </TextMuted>
    );
  }

  return {
    isLoading,
    totalCount: parsedData?.totalValue,
    component: getComponent(),
  };
}
