import { useDemoMode, usePrint, useUserContext } from "hooks";
import React from "react";
import { Outlet } from "react-router-dom";
import { DateRangePicker } from "../DatePicker/DateRagePicker";
import { ActiveFilters } from "../Filter/ActiveFilters";
import { FilterSheet } from "../Filter/Filter";
import { MainHeader } from "../Header";
import { DeviceTypeGroupTabs } from "../Tabs";
import { cn } from "../shadcn/lib/utils";
import { DemoModeBadge } from "../Badge";

export function DashboardLayout(props: React.ComponentPropsWithoutRef<"div">) {
  usePrint();
  const { isOperator } = useUserContext();
  const { isDemoModeEnabled } = useDemoMode();

  return (
    <div className="flex flex-col min-h-full">
      <div className="sticky top-0 z-50 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/80 min-w-[1200px]">
        <MainHeader />
        <div className="flex px-6 py-2 w-full border-b">
          <div className="flex space-x-6 flex-1">
            {isDemoModeEnabled && <DemoModeBadge />}
            {!isOperator && !isDemoModeEnabled && <DeviceTypeGroupTabs />}
            <ActiveFilters />
          </div>
          <div className="relative flex space-x-4">
            <DateRangePicker />
            <FilterSheet />
          </div>
        </div>
      </div>

      <div
        id="page-layout"
        {...props}
        className={cn("min-w-[1200px] flex-1", props.className)}
      >
        <Outlet />
      </div>
    </div>
  );
}
