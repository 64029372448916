import { PageContentLayout } from "components/Layouts";
import { TextH1 } from "components/Typography/Text";

export function Development() {
  return (
    <PageContentLayout>
      <TextH1>Development page</TextH1>
    </PageContentLayout>
  );
}

export default Development;
