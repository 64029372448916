import { api } from "@vuumly-common/common";
import { ChartData, ChartOptions, TooltipItem } from "chart.js";
import Color from "color";
import { useQuery, useTheme, useTranslate } from "hooks";
import _ from "lodash";
import { useMemo } from "react";
import { CardProps } from "../..";
import { BaseBarChart } from "../../../Charts";
import { TextH5 } from "../../../Typography/Text";
import { BaseCard } from "../../Base.card";

export const RideDistributionOperatorPerHourOfDayBarChart = (props: CardProps) => {
  const translate = useTranslate();
  const theme = useTheme();

  const { data, isLoading } = useQuery<ChartData<"bar", Record<any, number>[]>>(
    {
      apiEndpoint: api.Endpoint.RidesPerHourOfDay,
    }
  );

  const clonedData = useMemo(() => {
    if (isLoading || !data?.datasets) {
      return;
    }
    const clonedData = _.cloneDeep(data);

    clonedData?.datasets.forEach((dataset) => {
      const { label } = dataset;

      if (label === "ride_percentage_operator") {
        dataset.label = translate("Your ride distribution");
        if (dataset.data.length) {
          dataset.backgroundColor = dataset.data.map(() => {
            return Color(theme.accentColor).string();
          });

          dataset.hoverBackgroundColor = dataset.data.map(() => {
            return Color(theme.accentColorHover).string();
          });
        }
        dataset.parsing = {
          yAxisKey: "ride_percentage_operator",
        };
      } else if (label === "ride_percentage_without_operator") {
        dataset.label = translate("Ride distribution for others");
        if (dataset.data.length) {
          dataset.backgroundColor = dataset.data.map(() => {
            return Color(theme.otherOperatorBarColor).string();
          });

          dataset.hoverBackgroundColor = dataset.data.map(() => {
            return Color(theme.hoverOtherOperatorBarColor).string();
          });
        }
        dataset.parsing = {
          yAxisKey: "ride_percentage_without_operator",
        };
      } else {
        dataset.hidden = true;
        dataset.label = "none";
      }
      dataset.barPercentage = 0.999;
    });

    return clonedData;
  }, [data]);

  const options: ChartOptions<"bar"> = {
    parsing: {
      yAxisKey: "ride_share_operator",
      xAxisKey: "dimension",
    },
    scales: {
      x: {
        title: {
          text: "Hour of day",
          display: true,
        },
        type: "time",
        time: {
          tooltipFormat: "HH:00",
          parser: "HH",
          unit: "hour",
          displayFormats: {
            hour: "HH",
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          text: "Ride distribution",
        },
        ticks: {
          display: true,
          callback: (value) => {
            return `${value}%`;
          },
        },
        border: {
          display: false,
        },
        grid: {
          display: true,
        },
      },
    },

    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: (item: TooltipItem<"bar">) => {
            return `${item.dataset.label}: ${item.formattedValue}%`;
          },
        },
      },
      // datalabels: {
      //   display: true,
      //   formatter: (item: Record<string, any>) =>
      //     (item.ride_percentage_without_operator ||
      //       item.ride_percentage_operator) + "%",
      // },
    },
  };

  return (
    <BaseCard
      isLoading={isLoading}
      header={<TextH5>{translate("Ride distribution by hour of day")}</TextH5>}
    >
      <BaseBarChart options={options} data={clonedData} />
    </BaseCard>
  );
};
