import { SignUpForm } from "components/Form";
import { TextH2, TextLink, TextMuted } from "components/Typography/Text";
import { RoutePathEnum } from "controllers/router";
import { AuthWrapper } from "./AuthWrapper";

export function SignUpPage() {
  return (
    <AuthWrapper>
      <div className="flex flex-col space-y-2">
        <TextH2>Create an account</TextH2>
        <TextMuted>
          Alread a member?
          <TextLink
            className="ml-1 no-underline font-semibold"
            to={RoutePathEnum.SignIn}
          >
            Sign In
          </TextLink>{" "}
        </TextMuted>
      </div>
      <SignUpForm />
    </AuthWrapper>
  );
}
