import { useCountry, useDistrict, useSuburbAreas, useUserContext } from "hooks";
import { BaseButton } from "../../Button";
import { BaseCombobox } from "../../Combobox/BaseCombobox";
import { TextH5 } from "../../Typography/Text";
import { cn } from "../../shadcn/lib/utils";

export function DistrictSelect() {
  const { selectedCountryLocale } = useCountry();
  const { selectedDistrict, setSelectedDistrict, removeSelectedDistrict } =
    useDistrict();

  const { list: areaList } = useSuburbAreas();

  const list = areaList
    .filter(
      (item) => item.admin_level === "suburb" || item.admin_level === "district"
    )
    .sort((a, b) => a.name.localeCompare(b.name, selectedCountryLocale))
    .map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

  return (
    <div>
      <div className="flex items-center">
        <TextH5>Neighborhood/District</TextH5>

        <BaseButton
          className={cn("ml-2", {
            "disabled:opacity-0": !selectedDistrict,
          })}
          disabled={!selectedDistrict}
          variant="link"
          onClick={removeSelectedDistrict}
        >
          Reset
        </BaseButton>
      </div>

      <div>
        <BaseCombobox
          value={selectedDistrict}
          list={list}
          onValueChanged={(value) => {
            const val = value.toUpperCase();
            if (val === selectedDistrict) {
              return removeSelectedDistrict();
            }
            setSelectedDistrict(val);
          }}
        />
      </div>
    </div>
  );
}
