import { ObjectWithGeometry } from "ol/Feature";
import { ol } from "../../../utils";
import { Geometry } from "ol/geom";
import { api } from "@vuumly-common/common";

export class RealtimeDeviceFeature extends ol.Feature {}
export class DeviceStandstillFeature extends ol.Feature {}

interface PopularityGridExtended {
  max_value: number;
  weight: number;
  of_total: string;
  newWeight: number;
}

export type PopularityGrid = api.models.PopularityGrid & PopularityGridExtended;

export class CustomFeature<Props> extends ol.Feature {
  constructor(
    args: ObjectWithGeometry<Geometry> & {
      properties: Props;
    }
  ) {
    super(args);
  }

  getProperties() {
    return super.getProperties() as { geometry: Geometry; properties: Props };
  }
}

export class SuburbFeature extends CustomFeature<api.models.SuburbAreas> {}
export class PopularityGridFeature extends CustomFeature<
  api.models.PopularityGrid & PopularityGridExtended
> {}

export class SuburbFeatureLayer extends ol.VectorLayer<
  ol.VectorSource<ol.Geometry>
> {}
export class RealtimeDeviceLayer extends ol.VectorLayer<
  ol.VectorSource<ol.Geometry>
> {}
export class DeviceStandstillLayer extends ol.VectorLayer<
  ol.VectorSource<ol.Geometry>
> {}

export class RouteHeatmapLayer extends ol.VectorLayer<
  ol.VectorSource<ol.Geometry>
> {}

export const realtimeCarsharingDevices = {
  zoomSwitch: 14,
  zoomToHide: 10,
};
