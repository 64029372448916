import { PDFViewer } from "@react-pdf/renderer";
import { api } from "@vuumly-common/common";
import { PageContentSpacer } from "components";
import { useState } from "react";
import { SplashLoading } from "../../components/Layouts";
import { ExportTemplatePDF } from "../../components/Pdf";
import { BaseSelect } from "../../components/Select";
import { BaseOption } from "../../components/Select/BaseSelector";
import {
  useDateRange,
  useQuery,
  useTimeGranularity,
  useTranslate
} from "../../hooks";
import { useTimeseriesParser } from "../../hooks/v1";

const apiSelect: BaseOption<api.Endpoint>[] = [
  {
    label: "Rides",
    value: api.Endpoint.RidesTimeseries,
    id: "rides",
  },
];

export function PdfPreviewPage() {
  const { selectedDateRange } = useDateRange();
  const translate = useTranslate();
  const [selectedApi, setSelectedApi] = useState<api.Endpoint>(
    api.Endpoint.RidesTimeseries
  );

  const { tabs, timeGranularity, changeGranularity, modifiedQueryParams } =
    useTimeGranularity();

  const { data, isLoading } = useQuery({
    apiEndpoint: selectedApi,
    params: modifiedQueryParams,
  });

  const { parsedData } = useTimeseriesParser({ data, timeGranularity });

  if (!data && isLoading) {
    return <SplashLoading />;
  }

  return (
    <div>
      <div className="w-[400px] m-6">
        <BaseSelect
          value={selectedApi}
          onChange={setSelectedApi}
          options={apiSelect}
        />
      </div>

      <PageContentSpacer>
        <PDFViewer width="100%" height="1000px">
          <ExportTemplatePDF
            dateRange={selectedDateRange}
            translate={translate}
            metadata={{ title: "Rides" }}
            img="/chart/chart.png"
            data={parsedData}
          />
        </PDFViewer>
      </PageContentSpacer>
    </div>
  );
}
