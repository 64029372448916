import { api } from "@vuumly-common/common";
import { ChartOptions } from "chart.js";
import { translate } from "../../utils";
import { BaseChart, BaseChartConfig } from "./BaseChart";
import { calculatePercentageFromTotal, calculateTotal } from "./ChartOptions";

const config = { seperateOperators: false }

const options: ChartOptions<"bar"> = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: "index" as "index",
    axis: "x" as "x",
    intersect: false,
  },
  elements: {
    point: {
      radius: 0,
    },
    line: {
      borderWidth: 2,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      stacked: true,
      title: {
        display: true,
        text: translate("Ride count"),
      },
    },
    x: {
      stacked: true,
    },
  },
  plugins: {
    legend: {
      display: true,
      labels: {
        usePointStyle: false,
      },
    },
    tooltip: {
      position: "tooltipPositioner" as "average",
      // itemSort(a, b) {
      //   return b.datasetIndex - a.datasetIndex;
      // },
      callbacks: {
        label: calculatePercentageFromTotal,
        footer: calculateTotal,
      },
    },
  },
};

const RidesPerDayTrend = ({config}: {config?: BaseChartConfig}) => {
  return (
    <BaseChart
      title="Rides per hour"
      type="bar"
      apiEndpoint={api.Endpoint.RidesPerHourPerOperator}
      options={options}
      config={config}
      tableDataGenerator={(data) => { return { data: [], columns: [] }}}
    />
  );
};

export default RidesPerDayTrend;
