// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAlojxIY5zBAQCioxM8Tf3dGB-gUsUL1U8",
  authDomain: "kenflow-dashboard.firebaseapp.com",
  databaseURL:
    "https://kenflow-dashboard-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "kenflow-dashboard",
  storageBucket: "kenflow-dashboard.appspot.com",
  messagingSenderId: "782949039982",
  appId: "1:782949039982:web:db7497766250a53f54cb7f",
  measurementId: "G-CFLZY60EPD",
  // apiKey: "AIzaSyCz7mXGZVzcdcSqKEvx9p3ZKLls7Ex61FY",
  // authDomain: "vuumly.firebaseapp.com",
  // projectId: "vuumly",
  // storageBucket: "vuumly.appspot.com",
  // messagingSenderId: "172206855548",
  // appId: "1:172206855548:web:a4bc680b1fa14eb61580f9",
  // measurementId: "G-9PD4ZYR9F3",
  // databaseURL: "https://vuumly-default-rtdb.europe-west1.firebasedatabase.app/"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
//const analytics = getAnalytics(firebaseApp);
const analytics = getAnalytics(firebaseApp);

const FirebaseAuthInstance = getAuth(firebaseApp);

export async function getFirebaseUserToken() {
  return await FirebaseAuthInstance.currentUser?.getIdToken();
}

export { firebaseApp, FirebaseAuthInstance };
