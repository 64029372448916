import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../shadcn/components/ui/table";

import {
  ColumnDef,
  Table as TanstackTable,
  flexRender,
} from "@tanstack/react-table";
import { cn } from "../shadcn/lib/utils";
import { DataTablePagination, DataTablePaginationProps } from "./Pagination";

export interface ReactTable {
  data: { [key: string]: any }[];
  columns: ColumnDef<{ [key: string]: any }, any>[];
  // enablePagination?: boolean;
  // isSelectedRowCountVisible?: boolean;
  // isRowCountPerPageVisible?: boolean;
}

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data?: TData[];
  columnVisibility?: Record<string, boolean>;
  paginationProps?: DataTablePaginationProps<TData>;
  // enablePagination?: boolean;
  // isRowCountPerPageVisible?: boolean;
  table: TanstackTable<TData>;
}

export function BaseTable<TData, TValue>({
  columns,
  paginationProps,
  table,
}: DataTableProps<TData, TValue>) {
  return (
    <div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const sticky = header.column.columnDef.meta?.sticky;
                  return (
                    <TableHead
                      className={cn({
                        "sticky right-0 bg-background": sticky,
                      })}
                      key={header.id}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => {
                    const sticky = cell.column.columnDef.meta?.sticky;
                    return (
                      <TableCell
                        className={cn({
                          "sticky right-0 bg-background": sticky,
                        })}
                        key={cell.id}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {paginationProps?.enabled && (
        <div className="mt-4">
          <DataTablePagination {...paginationProps} />
        </div>
      )}
    </div>
  );
}
