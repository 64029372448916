import { Devices, TDevice, api } from "@vuumly-common/common";
import { BaseCard } from "components/Card/index";
import { TextH5 } from "components/Typography/Text";
import { useQuery, useTranslate, useUserContext } from "hooks";

import {
  ColumnDef,
  SortingState,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { BaseTable } from "../../../Tables";
import { DataTableColumnHeader } from "../../../Tables/DataTableColumnHeader";

const emptyArray: any[] = [];

const DeviceMap: Record<string, TDevice> = {};

Object.values(Devices).forEach((device) => {
  DeviceMap[device.tag] = device;
});

export const RidesTotalDeviceModel = () => {
  const { locale } = useUserContext();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [searchParams] = useSearchParams();

  const translate = useTranslate();

  const params = new URLSearchParams(searchParams);

  params.set("data_structure", "flat");

  const { data, isLoading } = useQuery<[]>({
    apiEndpoint: api.Endpoint.RidesTotalDeviceModel,
    params,
  });

  const columns: ColumnDef<any>[] = [
    {
      accessorKey: "device_model",
      header: ({ column }) => (
        <DataTableColumnHeader
          className="w-[200px]"
          column={column}
          title="Vehicle model"
        />
      ),
      enableSorting: true,
      sortingFn: (a, b) => {
        const aa = a.getValue("device_model") as string;
        const bb = b.getValue("device_model") as string;
        if (aa === bb) return 0;
        if (aa === null || bb === null) return 1;
        return aa.localeCompare(bb);
      },
      cell: ({ row }) => {
        const model = row.getValue("device_model") as string | undefined;
        return model ? DeviceMap[model].name : "other";
      },
    },
    {
      accessorKey: "device_body_type",
      header: ({ column }) => (
        <DataTableColumnHeader
          className="w-[200px]"
          column={column}
          title="Vehicle type"
        />
      ),
      enableSorting: true,
      sortingFn: (a, b) => {
        const aa = a.getValue("device_body_type") as string;
        const bb = b.getValue("device_body_type") as string;
        if (aa === bb) return 0;
        if (aa === null || bb === null) return 1;
        return aa.localeCompare(bb);
      },
      cell: ({ row }) => {
        const model = row.getValue("device_body_type") as string | undefined;
        return translate(model);
      },
    },

    {
      accessorKey: "vehicle_count",
      header: ({ column }) => (
        <DataTableColumnHeader
          className="w-[150px]"
          column={column}
          title="Fleet size"
        />
      ),
      cell: ({ row }) => {
        const amount = parseFloat(row.getValue("vehicle_count"));
        const formatted = new Intl.NumberFormat(locale, {
          notation: "compact",
        }).format(amount);

        return formatted;
      },
      enableSorting: true,
    },
    {
      accessorKey: "ride_count",
      header: ({ column }) => (
        <DataTableColumnHeader
          className="w-[150px]"
          column={column}
          title="Ride count"
        />
      ),
      cell: ({ row }) => {
        const amount = parseFloat(row.getValue("ride_count"));
        const formatted = new Intl.NumberFormat(locale, {
          notation: "compact",
        }).format(amount);

        return formatted;
      },
      enableSorting: true,
    },
    {
      accessorKey: "rides_per_vehicle_per_day",
      header: ({ column }) => (
        <DataTableColumnHeader
          className="w-[250px]"
          column={column}
          title="Ride count per vehicle (day)"
        />
      ),
      cell: ({ row }) => {
        const amount = parseFloat(row.getValue("rides_per_vehicle_per_day"));
        const formatted = new Intl.NumberFormat(locale, {
          notation: "compact",
        }).format(amount);

        return formatted;
      },
      enableSorting: true,
    },
  ];

  const table = useReactTable({
    data: data || emptyArray,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
    },
  });

  return (
    <BaseCard
      isLoading={isLoading}
      header={<TextH5>Ride count by vehicle model</TextH5>}
    >
      <BaseTable
        columns={columns}
        table={table}
        paginationProps={{
          enabled: true,
          isRowCountPerPageVisible: false,
          table,
        }}
      />
    </BaseCard>
  );
};
