import { ReactNode, useEffect, useRef, useState } from "react";
import { FlowmapLayer } from "@flowmap.gl/layers";
import { PickingType, FlowmapLayerPickingInfo } from "@flowmap.gl/layers";
import { Layer } from "ol/layer";
import { toLonLat } from "ol/proj";
import { Deck } from "deck.gl";
import { useMapContext } from "context/MapContext";
import { LayerPanel, PanelCheckbox, PanelSlider } from "components/Panel";
import { useQuery } from "../useQuery/useQuery.hook";
import { api } from "@vuumly-common/common";

interface Settings {
  fadeAmount: number;
  maxTopFlowsDisplayNum: number;
  clusteringEnabled: boolean;
  opacity: number;
  exclude_airports: boolean;
  fadeEnabled?: boolean;
}

const defaultSettings: Settings = {
  fadeAmount: 25,
  maxTopFlowsDisplayNum: 250,
  clusteringEnabled: true,
  exclude_airports: true,
  opacity: 1,
  fadeEnabled: true,
};

interface PanelProps {
  settings: Settings;
  setSettings: (settings: Settings) => void;
}

function Panel(props: PanelProps) {
  const { settings, setSettings } = props;

  return (
    <LayerPanel
      checked={true}
      value="panel_title"
      label="panel_title"
      onChange={(value) => console.log("panel clicked")}
    >
      <div className="space-y-6 flex flex-col">
        {/* <PanelCheckbox
          label="clusteringEnabled"
          value={settings.clusteringEnabled}
          onChange={(value) => {
            setSettings({ ...settings, clusteringEnabled: value });
          }}
        /> */}

        <PanelSlider
          label="maxTopFlowsDisplayNum"
          value={settings.maxTopFlowsDisplayNum}
          minValue={0}
          maxValue={1000}
          step={1}
          onChange={(value) => {
            setSettings({ ...settings, maxTopFlowsDisplayNum: value });
          }}
        />

        <PanelSlider
          label="fadeAmount"
          value={settings.fadeAmount}
          minValue={0}
          maxValue={100}
          step={1}
          onChange={(value) => {
            setSettings({ ...settings, fadeAmount: value });
          }}
          isDisabled={!settings.fadeEnabled}
        />

        <PanelSlider
          label="opacity"
          value={settings.opacity}
          minValue={0}
          maxValue={1}
          step={0.01}
          onChange={(value) => {
            setSettings({ ...settings, opacity: value });
          }}
        />

        <PanelCheckbox
          label="Exclude Airports"
          value={settings.exclude_airports}
          onChange={(value) => {
            setSettings({ ...settings, exclude_airports: value });
          }}
        />
      </div>
    </LayerPanel>
  );
}

export function useFlowMapLayer() {
  const [settings, setSettings] = useState<Settings>(defaultSettings);
  const [viewState, setViewState] = useState<ViewState>();
  const [tooltip, setTooltip] = useState<
    FlowmapLayerPickingInfo<LocationDatum, FlowDatum> | undefined
  >();

  const searchParams = new URLSearchParams(window.location.search);

  if (settings.exclude_airports) {
    searchParams.set("exclude_airports", "true");
  }

  const { data, isLoading } = useQuery<any>({
    apiEndpoint: api.Endpoint.FlowMapCluster,
    params: searchParams,
  });

  const { mapInstance } = useMapContext();

  const deckRef = useRef<Deck | null>(null);
  const deckLayerRef = useRef<Layer | null>(null);

  const handleViewStateChange = ({ viewState }: any) => {
    setViewState(viewState);
    setTooltip(undefined);
  };

  const layers: any[] = [];

  if (data) {
    layers.push(
      new FlowmapLayer<LocationDatum, FlowDatum>({
        id: "my-flowmap-layer",
        data,
        opacity: settings.opacity,
        pickable: true,
        darkMode: false,
        // colorScheme: config.colorScheme,
        fadeAmount: settings.fadeAmount,
        fadeEnabled: settings.fadeEnabled,
        fadeOpacityEnabled: true,
        // locationsEnabled: config.locationsEnabled,
        // locationTotalsEnabled: config.locationTotalsEnabled,
        // locationLabelsEnabled: config.locationLabelsEnabled,
        // animationEnabled: config.animationEnabled,
        clusteringEnabled: true,
        clusteringMethod: "HCA",
        clusteringAuto: true,
        //clusteringLevel: 5,
        adaptiveScalesEnabled: true,
        // highlightColor: config.highlightColor,
        maxTopFlowsDisplayNum: settings.maxTopFlowsDisplayNum,
        getLocationId: (loc) => loc.id,
        getLocationLat: (loc) => loc.lat,
        getLocationLon: (loc) => loc.lon,
        getFlowOriginId: (flow) => flow.origin,
        getLocationName: (loc) => loc.name,
        getFlowDestId: (flow) => flow.dest,
        getFlowMagnitude: (flow) => flow.count,

        onHover: (info) => {
          //console.log(info)
          setTooltip(info);
        },
        // onClick: (info) =>
        //   console.log("clicked", info.object?.type, info.object, info),
      })
    );
  }

  useEffect(() => {
    if (!data || data.locations === undefined || !mapInstance) return;

    const deckGL = new Deck({
      initialViewState: {
        longitude: 0,
        latitude: 0,
        zoom: 1,
      },
      onViewStateChange: handleViewStateChange,
      controller: true,
      parent: document.getElementById("map") as HTMLElement,
      style: {
        mixBlendMode: "normal",
        top: 0,
        pointerEvents: "none",
        "z-index": 1,
      },
      layers,
    });

    deckRef.current = deckGL;

    const deckLayer = new Layer({
      render: function ({ size, viewState }): any {
        const [width, height] = size;
        const [longitude, latitude] = toLonLat(viewState.center);
        const zoom = viewState.zoom - 1;
        const bearing = (-viewState.rotation * 180) / Math.PI;
        const deckViewState = {
          bearing,
          longitude,
          latitude,
          zoom,
        };
        deckRef.current?.setProps({
          width,
          height,
          viewState: deckViewState,
        });
        deckRef.current?.redraw({});
      },
    });

    deckLayerRef.current = deckLayer;

    // mapInstance?.addLayer(deckLayer);

    return () => {
      // mapInstance?.removeLayer(deckLayer);
      deckRef.current = null;
      deckGL.finalize();
    };
  }, [mapInstance, data]);

  useEffect(() => {
    if (!deckRef.current) return;
    deckRef.current.setProps({
      layers,
    });
  }, [layers]);

  return {
    isLoading,
    tooltip,
    layer: deckLayerRef.current,
    panel: <Panel setSettings={setSettings} settings={settings} />,
  };
}

export type LocationDatum = {
  id: string;
  name: string;
  lon: number;
  lat: number;
};
export type FlowDatum = {
  origin: string;
  dest: string;
  count: number;
};
export type LoadedData = {
  locations: LocationDatum[];
  flows: FlowDatum[];
};

type ViewState = {
  /** Longitude at map center */
  longitude: number;
  /** Latitude at map center */
  latitude: number;
  /** Map zoom level */
  zoom: number;
  /** Map rotation bearing in degrees counter-clockwise from north */
  bearing: number;
  /** Map angle in degrees at which the camera is looking at the ground */
  pitch: number;
  /** Dimensions in pixels applied on each side of the viewport for shifting the vanishing point. */
  padding: PaddingOptions;
};

type PaddingOptions = {
  top: number;
  bottom: number;
  left: number;
  right: number;
};
