import { useQuery } from "@tanstack/react-query";
import { api } from "@vuumly-common/common";
import axios from "axios";
import { useCity, useConstants, useCountry, useDemoMode } from "hooks";

export const useSuburbAreas = () => {
  const { selectedCities } = useCity();
  const { selectedCountryValue } = useCountry();
  const { LOCAL_STORAGE_USER_AUTH_KEY } = useConstants();
  const { isDemoModeEnabled } = useDemoMode();

  const { data, isLoading } = useQuery<
    api.ApiResponse<api.response.SuburbAreas>
  >({
    staleTime: Infinity,
    queryKey: [
      api.Endpoint.SuburbAreas,
      selectedCountryValue,
      selectedCities.toString(),
    ],
    queryFn: () => {
      const params = new URLSearchParams();

      for (const city of selectedCities) {
        params.append("cities[]", city);
      }

      if (isDemoModeEnabled) {
        params.set("demo_mode", "true");
      }

      params.set("countries[]", selectedCountryValue || "");
      params.set("admin_level", "2");

      const url = `${process.env.REACT_APP_DATA_API_ENDPOINT}/v1/${
        api.Endpoint.SuburbAreas
      }?${params.toString()}`;

      return axios(url, {
        headers: {
          authorization: localStorage.getItem(LOCAL_STORAGE_USER_AUTH_KEY),
        },
      }).then((res) => res.data);
    },
  });

  return {
    list: data?.data || [],
    isLoading,
  };
};
