import classNames from "classnames";
import { cn } from "components/shadcn/lib/utils";
import { RoutePathEnum } from "controllers/router";
import { useRouter, useTranslate } from "hooks";
import { memo } from "react";
import { Link, useSearchParams } from "react-router-dom";

interface MainMenuProps {
  path: RoutePathEnum;
  label: string;
  debug?: boolean;
}

const mainMenu: MainMenuProps[] = [
  // {
  //   path: RoutePathEnum.Home,
  //   label: "Dashboard",
  // },
  // {
  //   path: RoutePathEnum.Settings,
  //   label: "Settings",
  // },
  // {
  //   path: RoutePathEnum.Distribution,
  //   label: "Distribution",
  // },
  // {
  //   path: RoutePathEnum.Report,
  //   label: "Reports",
  // },
  {
    path: RoutePathEnum.Rides,
    label: "rides_menu_label",
  },
  {
    path: RoutePathEnum.Utilization,
    label: "utilziation_menu_label",
  },
  {
    path: RoutePathEnum.RideLength,
    label: "ride_length_menu_label",
  },
  {
    path: RoutePathEnum.Fleet,
    label: "Fleet",
  },
  {
    path: RoutePathEnum.Areas,
    label: "Neighborhoods",
  },
  // {
  //   path: RoutePathEnum.Data,
  //   label: "Data",
  // },

  // {
  //   path: RoutePathEnum.LengthMedian,
  //   label: "length_median_menu_label",
  // },

  {
    path: RoutePathEnum.Flowmap,
    label: "flow_map_menu_label",
  },
  {
    path: RoutePathEnum.Heatmap,
    label: "heatmap_menu_label",
  },
  {
    path: RoutePathEnum.LiveMap,
    label: "Live map",
  },

  // {
  //   path: RoutePathEnum.VectorMap,
  //   label: "Vector map",
  // },
  // {
  //   path: RoutePathEnum.Hexmap,
  //   label: "hexmap_menu_label",
  // },

  // {
  //   path: RoutePathEnum.PathMap,
  //   label: "Paths",
  //   debug: true,
  // },
  // {
  //   path: RoutePathEnum.TrafficMap,
  //   label: "Traffic",
  //   debug: true,
  // },
  // {
  //   path: RoutePathEnum.AccidentMap,
  //   label: "Accidents",
  //   debug: true,
  // },
  // {
  //   path: RoutePathEnum.TransitMap,
  //   label: "Transit",
  //   debug: true,
  // },
  // {
  //   path: RoutePathEnum.MapTools,
  //   label: "Map Tools",
  //   debug: false,
  // },
];

const MenuItem = (item: MainMenuProps) => {
  const translate = useTranslate();
  const { pathname } = useRouter();
  const [searchParams] = useSearchParams();

  const firstLevelPath = "/" + pathname.split("/")[1];

  return (
    <Link
      key={item.path}
      to={item.path + "?" + searchParams}
      onClick={() => window.scrollTo(0, 0)}
      className={classNames({
        "text-sm  transition-colors text-muted-foreground hover:text-primary p-2 font-medium":
          true,
        "text-primary font-semibold":
          firstLevelPath == item.path ||
          pathname === item.path ||
          (item.path === RoutePathEnum.Rides && pathname == "/"),
      })}
    >
      {translate(item.label)}
    </Link>
  );
};

export const MainNavigation = memo(function MainNavigation() {
  return (
    <nav className={cn("flex items-center space-x-4 lg:space-x-6")}>
      {mainMenu
        .filter((item) => {
          if (item.debug) {
            return process.env.REACT_APP_ENV === "development";
          }
          return true;
        })
        .map((item) => {
          return <MenuItem key={item.path} {...item} />;
        })}
    </nav>
  );
});
