import { useEffect, useState } from "react";
import { Layers } from "controllers";
import { useDatasetSize } from "hooks";

interface Props {
  settings?: Layers.Heatmap.Settings;
}

export const useHeatmapPanelSettings = (props?: Props) => {
  const { totalCount, isLoading } = useDatasetSize();

  const [ready, setReady] = useState(false);
  const [settings, setSettings] = useState<Layers.Heatmap.Settings>(
    Layers.Heatmap.defaultSettings
  );

  useEffect(() => {
    if (isLoading && !totalCount) {
      setReady(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (totalCount || totalCount === 0) {
      setReady(true);
    }
  }, [totalCount]);

  // i need a way to get good defaults
  function getDefaults() {
    if (!totalCount) return Layers.Heatmap.defaultSettings;

    const newSettings = { ...Layers.Heatmap.defaultSettings };
    if (totalCount > 100000) {
      newSettings.heatmap_min_ride_count = 5;
    }
    return newSettings;
  }

  return {
    ready,
    hasData: !!totalCount,
    totalCount,
    isLoading,
    settings,
    setSettings,
    getDefaults,
  };
};
