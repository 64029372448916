import { api, time } from "@vuumly-common/common";
import { ChartData, ChartOptions, TooltipItem } from "chart.js";
import {
  useFormatNumber,
  useQuery,
  useTheme,
  useTimeGranularity,
  useTranslate,
} from "hooks";
import _ from "lodash";
import { useMemo } from "react";
import { RoutePathEnum } from "../../../../controllers/router";
import { BaseBarChart } from "../../../Charts";
import { TimeGranularityTabs } from "../../../Tabs/TimeGranularityTabs";
import { TextH5 } from "../../../Typography/Text";
import { BaseCard } from "../../Base.card";
import { CardProps } from "../../index";

export const RidesTimeseriesCompanies = (props: CardProps) => {
  const { disableLink = true } = props;
  const { formatShort } = useFormatNumber();
  const { getCompanyColors } = useTheme();
  const { tabs, timeGranularity, changeGranularity, modifiedQueryParams } =
    useTimeGranularity();

  const translate = useTranslate();

  const { data, isLoading } = useQuery({
    apiEndpoint: api.Endpoint.RidesTimeseriesCompanies,
    params: modifiedQueryParams,
  });

  const clonedData = useMemo(() => {
    if (isLoading || !data?.datasets) {
      return;
    }
    const parsedData = _.cloneDeep(data) as ChartData<"bar", Record<any, any>>;

    parsedData.labels = parsedData.datasets.flatMap((obj) =>
      obj.data
        .filter((item: any) => "date" in item)
        .map((item: any) => item.date)
    );

    parsedData.labels = _.uniq(parsedData.labels).sort();

    parsedData.datasets.forEach((dataset) => {
      const dates = dataset.data.map((item: any) => item.date);

      dataset.data =
        parsedData.labels?.map((item: any) => {
          if (!dates.includes(item)) {
            return { date: item, ride_count: 0 };
          } else {
            return dataset.data.find((data: any) => data.date === item);
          }
        }) || [];

      dataset.label = `Company ${dataset.dimensions?.company_id}`;

      dataset.order = -dataset.data.reduce(
        (acc: number, item: any) => item.ride_count + acc,
        0
      );

      const colors = getCompanyColors(dataset.dimensions?.company_id);

      dataset.backgroundColor = colors.color;
      dataset.hoverBackgroundColor = colors.color;

      dataset.borderColor = "transparent";
      dataset.borderWidth = {
        top: 2,
      };
    });

    return parsedData;
  }, [data]);

  const options: ChartOptions<"line"> = {
    parsing: {
      xAxisKey: "date",
      yAxisKey: "ride_count",
    },

    elements: {
      bar: {
        borderRadius: 0,
      },
    },

    scales: {
      x: {
        type: "timeseries",
        stacked: true,
      },
      y: {
        max: 100,
        title: {
          text: "Market share",
        },
        stacked: true,

        ticks: {
          callback: (value) => {
            return `${value}%`;
          },
        },
      },
    },

    plugins: {
      stacked100: { enable: true },
      tooltip: {
        itemSort: (a: any, b: any) => {
          return b.dataset.order - a.dataset.order;
        },
        callbacks: {
          label: (item: TooltipItem<"line">) => {
            const raw = item.raw as Record<string, any>;
            return `Company ${
              item.dataset.dimensions?.company_id
            }: ${formatShort(raw.ride_count)} (${item.formattedValue}%)`;
          },
        },
      },
    },
  };

  const Header = () => {
    return (
      <div className="flex justify-between w-full">
        <TextH5>{translate("Market share over time by company")}</TextH5>
        <TimeGranularityTabs
          tabs={tabs}
          defaultValue={timeGranularity || time.GranularityEnum.Day}
          onChange={changeGranularity}
        />
      </div>
    );
  };

  return (
    <BaseCard
      isLoading={isLoading}
      header={<Header />}
      href={disableLink ? undefined : RoutePathEnum.Rides}
    >
      <BaseBarChart options={options} data={clonedData} />
    </BaseCard>
  );
};
