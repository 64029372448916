import { api, db } from "@vuumly-common/common";
import { BaseTimeseriesChart } from "components/Card";
import { Dataset, useQuery, useTimeGranularity } from "hooks";
import { useTimeseriesParser } from "hooks/v1";
import { RoutePathEnum } from "../../../controllers/router";

interface Props {
  showExploreMoreButton?: boolean;
}

export const RidesTimeseriesCard = (props: Props) => {
  const { tabs, timeGranularity, changeGranularity, modifiedQueryParams } =
    useTimeGranularity();

  const { data, isLoading } = useQuery({
    apiEndpoint: api.Endpoint.RidesTimeseries,
    params: modifiedQueryParams,
  });

  const { parsedData } = useTimeseriesParser({
    data,
    timeGranularity,
    minCountPercentage: 0,
    showTrendline: true,
  });

  if (parsedData?.datasets) {
    parsedData.datasets[0].label = "Total ride count";
  }

  if (parsedData?.datasets) {
    parsedData.datasets = parsedData.datasets.filter((dataset) => {
      return dataset.usage_type === db.models.ColumnType.Metrics;
    });
  }

  const primaryDataset = parsedData?.datasets?.find(
    (e) =>
      e.usage_type === db.models.ColumnType.Metrics &&
      e.data_type === db.models.DatasetTypeEnum.RideCountArray
  );

  function labelFormatter(ctx: any) {
    const dataset = ctx?.dataset as Dataset;
    const formattedValue = dataset.tooltipData?.[ctx.dataIndex];
    if (dataset?.usage_type === db.models.ColumnType.OperatorMetrics) {
      const operatorRideCount = dataset.data?.[ctx.dataIndex];
      const rideCountOther = primaryDataset?.data?.[ctx.dataIndex];
      const percentage = (operatorRideCount / rideCountOther) * 100;
      return `${formattedValue} (${percentage.toFixed(1)}%)`;
    }

    return formattedValue;
  }

  return (
    <BaseTimeseriesChart
      title="Total ride count over time with trendline"
      isLoading={isLoading}
      tabs={tabs}
      timeGranularity={timeGranularity}
      changeGranularity={changeGranularity}
      parsedData={parsedData}
      data={data}
      beginAtZero={false}
      labelFormatter={labelFormatter}
      showExploreMoreButton={props.showExploreMoreButton}
      showMoreLink={RoutePathEnum.Rides}
      showMoreText="explore_rides_button"
      yAxesTitle="Total ride count"
    />
  );
};
