import { TooltipModel, TooltipItem, ChartType } from "chart.js";
import { Dataset } from "../../hooks";

function calculatePercentageFromTotal<T extends ChartType>(
  this: TooltipModel<T>,
  item: TooltipItem<T>
) {
  let percentage: string | number = "NaN";

  const itemIdx = item.dataIndex;
  const dataset = item.dataset as unknown as Dataset;
  try {
    const total = dataset.total![itemIdx];
    const value = dataset.data![itemIdx];
    percentage = ((100 / total) * value).toFixed(1);
  } catch (e) {
    throw Error("Tootltip percantage calculation failed");
  }
  return `${dataset.label} ${item.formattedValue} (${percentage}%)`;
}

function calculateTotal<T extends ChartType>(
  this: TooltipModel<T>,
  items: TooltipItem<T>[]
) {
  const itemIdx = items[0].dataIndex;
  const dataset = items[0].dataset as unknown as Dataset;
  const total = dataset.total?.[itemIdx];
  return "Total: " + new Intl.NumberFormat().format(total ?? 0);
}

export { calculatePercentageFromTotal, calculateTotal };
