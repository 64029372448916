import { cn } from "components/shadcn/lib/utils";
import { ReactComponent as KenflowLogo } from "../../assets/kenflow-vuumly-logo.svg";

export function Logo(props: React.HtmlHTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cn(props.className)} {...props}>
      <KenflowLogo />
    </div>
  );
}
