import { DeviceEnum } from "@vuumly-common/common";
import { useDeviceType, useTranslate } from "hooks";
import { BaseFilterSelect } from "../BaseFilterSelect";

export function DeviceTypeSelectBasedOnGroup() {
  const translate = useTranslate();
  const { deviceTypeOptionList, setDeviceTypeOptionList } = useDeviceType();

  return (
    <>
      <BaseFilterSelect<DeviceEnum>
        title={translate("device_type_filter")}
        options={deviceTypeOptionList}
        onChange={setDeviceTypeOptionList}
      />
    </>
  );
}
