import { useCallback } from "react";
import { RoutePathEnum } from "../../controllers/router";
import { BaseButton } from "./BaseButton";
import { useRouter } from "../../hooks";
import { ButtonVariants } from "./index";

interface Props extends React.HtmlHTMLAttributes<HTMLButtonElement> {
  link: RoutePathEnum;
  variant?: ButtonVariants;
}

export function ButtonLink(props: Props) {
  const { goTo } = useRouter();

  const handleClick = useCallback(() => {
    return goTo(props.link);
  }, [props.link]);

  return <BaseButton onClick={handleClick} variant="link" {...props} />;
}
