import { Point } from "@vuumly-common/common";
import { Layers } from "controllers";
import { useDeviceType } from "hooks";
import { useEffect, useMemo, useState } from "react";
import { ol } from "utils";
import {
  HeatmapPanel,
  Props,
  calculateWeight,
} from "../../components/Panel/HeatmapPanel";
import { useHeatmapLayer } from "../../pages/Distribution/useHeatmapLayer";
import { useMapHover } from "../../pages/Distribution/hooks/useMapHover";

export const useHeatmapPanelLayer = (props: Props) => {
  const { settings, setSettings, data, isLoading } = props;
  const { isCarsharingActive } = useDeviceType();
  const [preFetchSettings, setPreFetchSettings] =
    useState<Layers.Heatmap.Settings>(settings);

  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [isResetAvailable, setIsResetAvailable] = useState(false);

  const { vectorSource, vectorLayer } = useHeatmapLayer({
    zIndex: 1,
    weight: preFetchSettings.weight,
  });

  useEffect(() => {
    const defaultSettings = Layers.Heatmap.defaultSettings;
    if (
      preFetchSettings.heatmap_min_ride_count !==
        defaultSettings.heatmap_min_ride_count ||
      preFetchSettings.geohash_cluster_size !==
        defaultSettings.geohash_cluster_size ||
      preFetchSettings.weight !== defaultSettings.weight
    ) {
      setIsResetAvailable(true);
    } else {
      setIsResetAvailable(false);
    }
  }, [preFetchSettings]);

  useEffect(() => {
    if (
      preFetchSettings.heatmap_min_ride_count !==
        settings.heatmap_min_ride_count ||
      preFetchSettings.geohash_cluster_size !== settings.geohash_cluster_size
    ) {
      setPreFetchSettings(settings);
    }
  }, [isCarsharingActive]);

  function handleUpdate() {
    setSettings(preFetchSettings);
    setUpdateAvailable(false);
  }

  function handleReset() {
    setPreFetchSettings(Layers.Heatmap.defaultSettings);
    setSettings(Layers.Heatmap.defaultSettings);
  }

  useEffect(() => {
    if (isLoading) return;
    if (
      preFetchSettings.heatmap_min_ride_count !==
        settings.heatmap_min_ride_count ||
      preFetchSettings.geohash_cluster_size !== settings.geohash_cluster_size
    ) {
      setPreFetchSettings(settings);
    }
  }, [isLoading]);

  useEffect(() => {
    // console.log(settings, preFetchSettings);
    if (preFetchSettings.weight !== settings.weight) {
      console.log(settings, preFetchSettings);
      setSettings({ ...settings, weight: preFetchSettings.weight });
      // return;
    }
    if (
      preFetchSettings.heatmap_min_ride_count !==
        settings.heatmap_min_ride_count ||
      preFetchSettings.geohash_cluster_size !== settings.geohash_cluster_size
    ) {
      setUpdateAvailable(true);
    } else {
      setUpdateAvailable(false);
    }
  }, [preFetchSettings]);

  function regenerateHeatmap() {
    console.log("generating heatmap");
    const features: ol.Feature<ol.Point>[] = [];
    vectorLayer.current?.unrender();
    vectorSource.current?.clear();

    const max = data.data.datasets[0].data[3];
    const max_0 = data.data.datasets[0].data[0];
    const max_w = 100 / max;
    let total = 0;

    const rideCount = data.data.datasets[0].data;
    const locations = data.data.datasets[1].data;

    data.data?.labels.forEach((label: any, idx: any) => {
      const stringPoint = locations[idx];
      const appearance_count = rideCount[idx];

      const w = calculateWeight(appearance_count, max_0, 0.5, 1);

      const location = stringPoint.split(",");
      const point: Point = {
        lat: Number(location[0]),
        lng: Number(location[1]),
      };
      const feature = new ol.Feature({
        geometry: new ol.Point(ol.fromLonLat([point.lng, point.lat])),
      });

      feature.setProperties({ weight: props?.weight || w, appearance_count });

      features.push(feature);
    });

    vectorSource.current?.addFeatures(features);
  }

  useMemo(() => {
    if (data && data.data && !isLoading) {
      regenerateHeatmap();
    }
  }, [data, isLoading]);

  return {
    //layer: vectorLayer.current,
    panel: (
      <HeatmapPanel
        handleUpdate={handleUpdate}
        updateAvailable={updateAvailable}
        setSettings={setPreFetchSettings}
        settings={preFetchSettings}
        isResetAvailable={isResetAvailable}
        resetFilter={handleReset}
      />
    ),
  };
};
