import {
  RideDistributionOperatorDayOfWeekBarChart,
  RideDistributionOperatorPerHourOfDayBarChart,
  RideDistributionOperatorTimeOfDayBarChart,
  RideMarketShareDayOfWeekBarChart,
  RideMarketSharePerHourOfDayBarChart,
  RideMarketShareTimeOfDayBarChart,
  RidePerformanceDayOfWeekBarChart,
  RidePerformancePerHourOfDayBarChart,
  RidesDayOfWeekBarChart,
  RidesDayOfWeekSummaryTableCard,
  RidesHourOfDaySummaryTable,
  RidesPerHourOfDayBarCharts,
  RidesPerVehicleCompanies,
  RidesTimeOfDayBarChart,
  RidesTimeOfDaySummaryTable,
  RidesTimeseries,
  RidesTimeseriesCombined,
  RidesTimeseriesCompanies,
  RidesTimeseriesDeviceBodyType,
  RidesTimeseriesDeviceSize,
  RidesTotalDeviceBodyType,
  RidesTotalDeviceModel,
  RidesTotalDeviceSize,
} from "components/Card";
import { DashboardHeader } from "components/Header";
import { RoutePathEnum } from "controllers/router";
import {
  useDateRange,
  useDeviceType,
  useOperatorMode,
  usePageTitle,
  useRouter,
} from "hooks";
import { memo } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "sonner";
import { BaseAlert, WarningAlert } from "../../components/Alert/BaseAlert";
import { RidesTotalCard } from "../../components/Card/Rides/Overall/RidesTotalCard";
import { RidesTotalCompaniesTableCard } from "../../components/Card/Rides/Overall/RidesTotalCompaniesTableCard";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/shadcn/components/ui/tabs";

const MICROMOBILITY_ERROR_MESSAGE = `Vehicle models are not available for micromobility.`;

export const RidesPage = memo(() => {
  const { operatorMode } = useOperatorMode();
  const title = usePageTitle(RoutePathEnum.Rides);
  const { pathname } = useLocation();
  const { goTo } = useRouter();
  const { isFullWeekSelected } = useDateRange();
  const { isMicromobilityActive } = useDeviceType();

  function handleVehicleModels() {
    if (isMicromobilityActive) {
      toast.error(MICROMOBILITY_ERROR_MESSAGE);
    }
  }

  return (
    <>
      <DashboardHeader title={title} />

      <Tabs
        onValueChange={goTo}
        value={pathname === "/" ? RoutePathEnum.Rides : pathname}
      >
        <TabsList className="mb-8">
          <TabsTrigger value={RoutePathEnum.Rides}>Overall</TabsTrigger>
          <TabsTrigger value={RoutePathEnum.RidesDayOfWeek}>
            Day of Week
          </TabsTrigger>
          <TabsTrigger value={RoutePathEnum.RidesTimeOfDay}>
            Time of Day
          </TabsTrigger>
          <TabsTrigger value={RoutePathEnum.RidesHourOfDay}>
            Hour of Day
          </TabsTrigger>
          <div onClick={handleVehicleModels}>
            <TabsTrigger
              disabled={isMicromobilityActive}
              value={RoutePathEnum.RidesDevices}
            >
              Vehicle models
            </TabsTrigger>
          </div>
        </TabsList>

        <TabsContent value={RoutePathEnum.Rides}>
          <div className="flex flex-col space-y-12">
            <RidesTimeseries />
            <RidesTotalCard />
            {operatorMode && <RidesTimeseriesCombined />}
            <RidesTimeseriesCompanies />
            <RidesTotalCompaniesTableCard />
          </div>
        </TabsContent>

        <TabsContent value={RoutePathEnum.RidesDayOfWeek}>
          <div className="flex flex-col space-y-12">
            {!isFullWeekSelected && (
              <WarningAlert
                className="mb-8"
                title="Notice regarding selected date range"
                description="Your selection does not include full weeks. For the most precise data analytics, we recommend selecting periods that span whole weeks, such as 7, 14, 21 days, etc. This ensures that the insights and analyses are based on complete data sets without partial-week biases."
              />
            )}
            {operatorMode && <RideMarketShareDayOfWeekBarChart />}
            {operatorMode && <RideDistributionOperatorDayOfWeekBarChart />}
            {operatorMode && <RidePerformanceDayOfWeekBarChart />}
            <RidesDayOfWeekBarChart />
            <RidesDayOfWeekSummaryTableCard />
          </div>
        </TabsContent>

        <TabsContent value={RoutePathEnum.RidesTimeOfDay}>
          <div className="flex flex-col space-y-12">
            {operatorMode && <RideMarketShareTimeOfDayBarChart />}
            {operatorMode && <RideDistributionOperatorTimeOfDayBarChart />}
            <RidesTimeOfDayBarChart />
            <RidesTimeOfDaySummaryTable />
          </div>
        </TabsContent>

        <TabsContent value={RoutePathEnum.RidesHourOfDay}>
          <div className="flex flex-col space-y-12">
            {operatorMode && <RideMarketSharePerHourOfDayBarChart />}
            {operatorMode && <RideDistributionOperatorPerHourOfDayBarChart />}
            {operatorMode && <RidePerformancePerHourOfDayBarChart />}
            <RidesPerHourOfDayBarCharts />
            <RidesHourOfDaySummaryTable />
          </div>
        </TabsContent>

        <TabsContent value={RoutePathEnum.RidesDevices}>
          <div className="flex flex-col space-y-12">
            {isMicromobilityActive ? (
              <>
                <BaseAlert
                  className="mb-8"
                  title={MICROMOBILITY_ERROR_MESSAGE}
                />
              </>
            ) : (
              <>
                <RidesTimeseriesDeviceBodyType />
                <RidesTimeseriesDeviceSize />
                <RidesTotalDeviceModel />
                <RidesTotalDeviceBodyType />
                <RidesTotalDeviceSize />
                <RidesPerVehicleCompanies />
              </>
            )}
          </div>
        </TabsContent>
      </Tabs>
    </>
  );
});
