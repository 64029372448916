import classnames from "classnames";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "components/shadcn/components/ui/alert";
import { AlertCircle } from "lucide-react";
import { cn } from "../shadcn/lib/utils";

interface IBaseAlert extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  description?: string;
  variant?: "default" | "destructive" | "warning" | null | undefined;
  icon?: React.ReactNode;
  footer?: React.ReactNode;
}

interface IErrorAlert extends Omit<IBaseAlert, "variant"> {}

export function BaseAlert(props: IBaseAlert) {
  const { title, description, variant, icon, className, footer } = props;
  return (
    <Alert className={className} variant={variant}>
      {icon}
      <AlertTitle
        className={classnames({
          "mb-0": !description,
        })}
      >
        {title}
      </AlertTitle>
      {description && <AlertDescription>{description}</AlertDescription>}
      {footer}
    </Alert>
  );
}

export function ErrorAlert({ className, ...rest }: IErrorAlert) {
  return (
    <BaseAlert
      icon={<AlertCircle className="h-4 w-4" />}
      variant="destructive"
      className={cn("bg-white", className)}
      {...rest}
    />
  );
}

export function WarningAlert({ className, ...rest }: IErrorAlert) {
  return (
    <BaseAlert
      icon={<AlertCircle className="h-4 w-4" />}
      variant="warning"
      className={cn("bg-card", className)}
      {...rest}
    />
  );
}
