import { useTranslate } from "hooks";
import { BaseSelect } from "../Select";
import { BaseOption } from "../Select/BaseSelector";
import { BaseLabel } from "../Label";

interface Props
  extends Omit<React.ComponentPropsWithoutRef<"div">, "onChange"> {
  label: string;
  value?: string;
  onChange: (value: string) => void;
  options: BaseOption<string>[];
}

export function SelectPanel(props: Props) {
  const translate = useTranslate();

  const { label, value, options, onChange } = props;

  return (
    <div className="space-y-2">
      <BaseLabel htmlFor={value!} label={translate(label)} />
      <BaseSelect
        id={value}
        value={value}
        options={options}
        onChange={onChange}
      />
    </div>
  );
}
