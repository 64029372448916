import { BaseMap } from "components/Map";
import { MapContextProvider, useMapContext } from "context/MapContext";
import { Layers, useCenterMap, usePageTitle } from "hooks";
import { useHeatmapPanelLayer } from "hooks/layers/useHeatmapPanelLayer.hook";
import "ol/ol.css";
import { useEffect } from "react";
import { DashboardHeader } from "../../components/Header";
import { MapSidebar } from "../../components/Sidebar";
import { RoutePathEnum } from "../../controllers/router";
import { usePopularityGridQuery } from "../../hooks/layers/usePopularityGridQuery.hook";
import { useSuburbPanelLayer } from "../Distribution/Layers/SuburbPanel";
import { useMapHover } from "../Distribution/hooks/useMapHover";

const Content = () => {
  useCenterMap();
  useMapHover();
  const { mapInstance, hoveredFeatureOnMap } = useMapContext();

  const { settings, setSettings, ready } = Layers.useHeatmapPanelSettings();

  const { isLoading, data } = usePopularityGridQuery({
    ...settings,
    isEnable: ready,
  });

  const { layer, areaTooltip } = useSuburbPanelLayer({
    zIndex: 1,
  });

  const { panel } = useHeatmapPanelLayer({
    data,
    isLoading: !data || isLoading || !ready,
    setSettings,
    settings: settings,
  });

  useEffect(() => {
    function setFocus() {
      document.getElementById("map")?.focus();
    }
    // mapInstance?.on("pointermove", setFocus);
    mapInstance?.addLayer(layer);
    return () => {
      // mapInstance?.un("pointermove", setFocus);
      mapInstance?.removeLayer(layer);
    };
  }, [mapInstance]);

  return (
    <>
      {!isLoading && ready && (
        <MapSidebar>
          <div>{panel}</div>
        </MapSidebar>
      )}
      <BaseMap
        isTooltipVisible={!!hoveredFeatureOnMap}
        tooltip={areaTooltip}
        isLoading={isLoading || !ready}
      />
    </>
  );
};

export function HeatmapPage() {
  const title = usePageTitle(RoutePathEnum.Heatmap);
  return (
    <>
      <div className="max-w-7xl mx-auto p-6 w-full">
        <DashboardHeader className="mb-0" title={title} />
      </div>
      <div className="flex flex-1 relative">
        <MapContextProvider>
          <Content />
        </MapContextProvider>
      </div>
    </>
  );
}
