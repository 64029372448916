import {
  UtilizationDistributionOperatorTimeOfDayBarChart,
  UtilizationHourOfDayBarChartCard,
  UtilizationHourOfDayBarChartCombined,
  UtilizationTimeOfDayBarChartCard,
  UtilizationTimeseriesCompaniesLineChartCard,
  UtilizationTimeseriesLineChartCard,
  UtilizationTimeseriesLineChartCombinedCard,
  UtilizationTimeseriesLineChartPerformance,
} from "components/Card";
import { DashboardHeader } from "components/Header";
import { RoutePathEnum } from "controllers/router";
import {
  useCountry,
  useDeviceType,
  useOperatorMode,
  usePageTitle,
  useRouter,
} from "hooks";
import { useLocation } from "react-router-dom";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/shadcn/components/ui/tabs";

import { BaseAlert } from "../../components/Alert/BaseAlert";
import { TextH3 } from "../../components/Typography/Text";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/shadcn/components/ui/accordion";

export const UtilizationPage = () => {
  const { operatorMode } = useOperatorMode();
  const title = usePageTitle(RoutePathEnum.Utilization);
  const { goTo } = useRouter();
  const { pathname } = useLocation();

  const { isMicromobilityActive } = useDeviceType();

  const { selectedCountryName } = useCountry();

  if (isMicromobilityActive) {
    return (
      <>
        <DashboardHeader title={`${title} (${selectedCountryName})`} />

        <BaseAlert
          className="mb-8"
          title="Notice regarding fleet utilization for micromobility"
          description="Due the dynamic nature of micromobility fleets, the utilization rate is not available at this time. We are working to provide this data in the future. Thank you for your understanding."
        />
      </>
    );
  }

  return (
    <>
      <DashboardHeader title={`${title} (${selectedCountryName})`} />

      <Tabs onValueChange={goTo} value={pathname}>
        <TabsList className="mb-8">
          <TabsTrigger value={RoutePathEnum.Utilization}>Overall</TabsTrigger>
          <TabsTrigger value={RoutePathEnum.UtilizationTimeOfDay}>
            Time of Day
          </TabsTrigger>
          <TabsTrigger value={RoutePathEnum.UtilizationHourOfDay}>
            Hour of Day
          </TabsTrigger>
        </TabsList>

        <TabsContent value={RoutePathEnum.Utilization}>
          <div className="flex flex-col space-y-12">
            <UtilizationTimeseriesLineChartCard />
            {operatorMode && <UtilizationTimeseriesLineChartCombinedCard />}
            {operatorMode && <UtilizationTimeseriesLineChartPerformance />}
            <UtilizationTimeseriesCompaniesLineChartCard />
          </div>
        </TabsContent>

        <TabsContent value={RoutePathEnum.UtilizationTimeOfDay}>
          <div className="flex flex-col space-y-12">
            <UtilizationTimeOfDayBarChartCard />
            {operatorMode && (
              <UtilizationDistributionOperatorTimeOfDayBarChart />
            )}
          </div>
        </TabsContent>

        <TabsContent value={RoutePathEnum.UtilizationHourOfDay}>
          <div className="flex flex-col space-y-12">
            <UtilizationHourOfDayBarChartCard />
            {operatorMode && <UtilizationHourOfDayBarChartCombined />}
          </div>
        </TabsContent>
      </Tabs>

      <div className="max-w-[600px] mx-auto mt-16">
        <TextH3 className="mb-4">FAQ</TextH3>
        <Accordion type="single" collapsible className="w-full">
          <AccordionItem value="item-1">
            <AccordionTrigger>
              Why is the fleet utilization rate shown for the whole country
              instead of by area or region?
            </AccordionTrigger>
            <AccordionContent>
              The fleet utilization rate is presented on a country-wide basis
              because specific area or regional data is challenging to quantify
              accurately, due to the dynamic nature of the fleet's distribution.
              Vehicles often move across different areas, making it difficult to
              allocate them to a single region at any given time. We appreciate
              your understanding as we aim to provide the most comprehensive and
              understandable metrics under these constraints.
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </>
  );
};
