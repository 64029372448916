import { BaseCheckbox } from "components";
import { IBaseCheckbox } from "components/Checkbox";
import { cn } from "components/shadcn/lib/utils";

export function LayerPanel(
  props: {
    loading?: boolean;
    onChange: (val: boolean) => void;
  } & IBaseCheckbox &
    React.HTMLAttributes<HTMLDivElement>
) {
  const { checked, loading, onChange, children, value, label } = props;
  return (
    <div {...props} className={cn("border-b p-4", props.className)}>
      <div className="">
        {/* <BaseCheckbox
          value={value}
          disabled={false}
          checked={checked || false}
          label={loading ? "Fetching..." : label}
          onChange={(state) => onChange(state === true)}
        /> */}
      </div>
      <>{checked && children && <div className="my-4">{children}</div>}</>
    </div>
  );
}
