import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import OlMap from "ol/Map";
import { defaultMapStyle } from "pages/Distribution/MapStyles";
import { CompanyEnum } from "@vuumly-common/common";
import { HoveredFeature } from "./index";


interface Props {
  children: ReactNode;
}

interface PersistentProps {
  mapStyle: string;
  lowDetailMapStyle: boolean;
  dataAutoUpdate: boolean;
  realtimeCarsharingDevicesLayerVisible: boolean;
  popularityGridLayerVisible: boolean;
  popularityGridLayerIntensity: number;
  standstillDevicesLayerVisible: boolean;
  standstillDevicesLayerCompany: CompanyEnum[];
  standstillDevicesMinTimeLayer: number;
  suburbAreaLayerVisible: boolean;
  
}

interface MapContextProps extends PersistentProps {
  mapInstance: OlMap | null;
  currentZoom: number;
  setCurrentZoom: (zoom: number) => void;
  setDataAutoUpdate: (autoUpdate: boolean) => void;
  setMapInstance: (map: OlMap) => void;
  setMapStyle: (style: string) => void;
  setLowDetailMapStyle: (style: boolean) => void;
  setRealtimeCarsharingDevicesLayerVisible: (visible: boolean) => void;
  setPopularityGridLayerVisible: (visible: boolean) => void;
  setPopularityGridLayerIntensity: (intensity: number) => void;
  setStandstillDevicesLayerVisible: (visible: boolean) => void;
  setStandstillDevicesLayerCompany: (company: CompanyEnum[]) => void;
  setStandstillDevicesMinTimeLayer: (minTime: number) => void;
  setSuburbAreaLayerVisible: (visible: boolean) => void;
  hoveredFeatureOnMap: HoveredFeature | null;
  setHoveredFeatureOnMap: (feature: HoveredFeature | null) => void;
  prevHoveredFeatureOnMap: HoveredFeature | null;
  setPrevHoveredFeatureOnMap: (feature: HoveredFeature | null) => void;
}

const defaultMapContext: MapContextProps = {
  mapInstance: null,
  setMapInstance: () => {},

  currentZoom: 0,
  setCurrentZoom: () => {},

  dataAutoUpdate: false,
  setDataAutoUpdate: () => {},

  mapStyle: defaultMapStyle.value,
  setMapStyle: () => {},

  lowDetailMapStyle: true,
  setLowDetailMapStyle: () => {},

  realtimeCarsharingDevicesLayerVisible: true,
  setRealtimeCarsharingDevicesLayerVisible: () => {},

  popularityGridLayerVisible: true,
  setPopularityGridLayerVisible: () => {},

  popularityGridLayerIntensity: 50,
  setPopularityGridLayerIntensity: () => {},

  standstillDevicesLayerVisible: true,
  setStandstillDevicesLayerVisible: () => {},

  standstillDevicesLayerCompany: [],
  setStandstillDevicesLayerCompany: () => {},

  standstillDevicesMinTimeLayer: 300,
  setStandstillDevicesMinTimeLayer: () => {},

  suburbAreaLayerVisible: true,
  setSuburbAreaLayerVisible: () => {},

  hoveredFeatureOnMap: null,
  setHoveredFeatureOnMap: () => {},
  prevHoveredFeatureOnMap: null,
  setPrevHoveredFeatureOnMap: () => {},
};

const MapContext = createContext<MapContextProps>(defaultMapContext);

export const useMapContext = () => useContext(MapContext);

const getStateFromLocalStorage = (prop: keyof PersistentProps) => {
  const storedData =
    (JSON.parse(
      localStorage.getItem("MapContextData") as any
    ) as PersistentProps) || undefined;
  return storedData?.[prop] === undefined
    ? defaultMapContext[prop]
    : (storedData?.[prop] as any);
};

export const MapContextProvider: React.FC<Props> = ({ children }) => {
  const [mapInstance, setMapInstance] = useState<OlMap | null>(null);

  const [mapStyle, setMapStyle] = useState<string>(
    getStateFromLocalStorage("mapStyle")
  );

  const [currentZoom, setCurrentZoom] = useState<number>(0);

  const [lowDetailMapStyle, setLowDetailMapStyle] = useState<boolean>(
    getStateFromLocalStorage("lowDetailMapStyle")
  );

  const [dataAutoUpdate, setDataAutoUpdate] = useState<boolean>(
    getStateFromLocalStorage("dataAutoUpdate")
  );

  const [
    realtimeCarsharingDevicesLayerVisible,
    setRealtimeCarsharingDevicesLayerVisible,
  ] = useState<boolean>(
    getStateFromLocalStorage("realtimeCarsharingDevicesLayerVisible")
  );

  const [popularityGridLayerVisible, setPopularityGridLayerVisible] =
    useState<boolean>(getStateFromLocalStorage("popularityGridLayerVisible"));

  const [popularityGridLayerIntensity, setPopularityGridLayerIntensity] =
    useState<number>(getStateFromLocalStorage("popularityGridLayerIntensity"));

  const [standstillDevicesLayerVisible, setStandstillDevicesLayerVisible] =
    useState<boolean>(
      getStateFromLocalStorage("standstillDevicesLayerVisible")
    );

  const [standstillDevicesLayerCompany, setStandstillDevicesLayerCompany] =
    useState<CompanyEnum[]>(
      getStateFromLocalStorage("standstillDevicesLayerCompany")
    );

  const [standstillDevicesMinTimeLayer, setStandstillDevicesMinTimeLayer] =
    useState<number>(getStateFromLocalStorage("standstillDevicesMinTimeLayer"));

  const [suburbAreaLayerVisible, setSuburbAreaLayerVisible] = useState<boolean>(
    getStateFromLocalStorage("suburbAreaLayerVisible")
  );

  const [hoveredFeatureOnMap, setHoveredFeatureOnMap] = useState<HoveredFeature | null>(null);
  const [prevHoveredFeatureOnMap, setPrevHoveredFeatureOnMap] = useState<HoveredFeature | null>(null);

  // store state in local storage
  const props: PersistentProps = {
    mapStyle,
    lowDetailMapStyle,
    dataAutoUpdate,
    realtimeCarsharingDevicesLayerVisible,
    popularityGridLayerVisible,
    popularityGridLayerIntensity,
    standstillDevicesLayerVisible,
    standstillDevicesLayerCompany,
    standstillDevicesMinTimeLayer,
    suburbAreaLayerVisible,
  };

  useEffect(() => {
    localStorage.setItem("MapContextData", JSON.stringify(props));
  }, [Array.from(Object.values(props))]);

  // useEffect(() => {
  //   setPrevHoveredFeatureOnMap(hoveredFeatureOnMap);
  // }, [hoveredFeatureOnMap])

  return (
    <MapContext.Provider
      value={{
        mapInstance,
        setMapInstance,
        currentZoom,
        setCurrentZoom,
        dataAutoUpdate,
        setDataAutoUpdate,
        mapStyle,
        setMapStyle,
        lowDetailMapStyle,
        setLowDetailMapStyle,
        realtimeCarsharingDevicesLayerVisible,
        setRealtimeCarsharingDevicesLayerVisible,
        popularityGridLayerVisible,
        setPopularityGridLayerVisible,
        popularityGridLayerIntensity,
        setPopularityGridLayerIntensity,
        standstillDevicesLayerVisible,
        setStandstillDevicesLayerVisible,
        standstillDevicesLayerCompany,
        setStandstillDevicesLayerCompany,
        standstillDevicesMinTimeLayer,
        setStandstillDevicesMinTimeLayer,
        suburbAreaLayerVisible,
        setSuburbAreaLayerVisible,
        hoveredFeatureOnMap, 
        setHoveredFeatureOnMap,
        prevHoveredFeatureOnMap,
        setPrevHoveredFeatureOnMap
      }}
    >
      {children}
    </MapContext.Provider>
  );
};
