import { api } from "@vuumly-common/common";
import { ChartData, ChartOptions, TooltipItem } from "chart.js";
import Color from "color";
import { useQuery, useTheme, useTranslate } from "hooks";
import _ from "lodash";
import { useMemo } from "react";
import { BaseBarChart } from "../../Charts";
import { TextH5 } from "../../Typography/Text";
import { BaseCard } from "../Base.card";

export const UtilizationHourOfDayBarChartCombined = () => {
  const theme = useTheme();
  const translate = useTranslate();
  const { data, isLoading } = useQuery<ChartData<"bar", Record<any, number>[]>>(
    {
      apiEndpoint: api.Endpoint.UtilizationHourOfDay,
    }
  );

  const clonedData = useMemo(() => {
    if (isLoading || !data?.datasets) {
      return;
    }
    const clonedData = _.cloneDeep(data);

    if (clonedData) {
      clonedData.labels = [];
    }

    clonedData?.datasets.forEach((dataset) => {
      const { label } = dataset;

      if (label === "operator_utilization_rate") {
        dataset.backgroundColor = dataset.data.map(() => {
          return Color(theme.accentColor).string();
        });

        dataset.hoverBackgroundColor = dataset.data.map(() => {
          return Color(theme.accentColorHover).string();
        });

        dataset.label = translate("Your utilization rate");
        dataset.parsing = {
          yAxisKey: "operator_utilization_rate",
        };
      } else if (label === "without_operator_overall_utilization_rate") {
        dataset.backgroundColor = dataset.data.map(() => {
          return Color(theme.otherOperatorBarColor).string();
        });

        dataset.hoverBackgroundColor = dataset.data.map(() => {
          return Color(theme.hoverOtherOperatorBarColor).string();
        });

        dataset.label = translate("Utilization rate for others");
        dataset.parsing = {
          yAxisKey: "without_operator_overall_utilization_rate",
        };
      } else {
        dataset.hidden = true;
        dataset.label = "none";
      }
      dataset.barPercentage = 0.999;
    });

    return clonedData;
  }, [data]);

  const options: ChartOptions<"bar"> = {
    parsing: {
      xAxisKey: "hour_of_day",
    },
    scales: {
      x: {
        title: {
          text: "Hour of day",
          display: true,
        },
        type: "time",
        time: {
          tooltipFormat: "HH:00",
          parser: "HH",
          unit: "hour",
          displayFormats: {
            hour: "HH",
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          text: "Utilization rate",
        },
        ticks: {
          padding: 10,
          callback: (value) => {
            return `${value}%`;
          },
        },
        border: {
          display: false,
        },
      },
    },

    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: (item: TooltipItem<"bar">) => {
            return `${item.dataset.label}: ${item.formattedValue}%`;
          },
        },
      },
    },
  };

  return (
    <BaseCard
      isLoading={isLoading}
      header={
        <TextH5>
          {translate("Utilization by hour of day (others vs. you)")}
        </TextH5>
      }
    >
      <div>
        <BaseBarChart options={options} data={clonedData} />
      </div>
    </BaseCard>
  );
};
