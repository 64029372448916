import { TextH2, TextH3 } from "components/Typography/Text";
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ButtonLink } from "../../components/Button";
import { RoutePathEnum } from "../../controllers/router";
import { usePageTitle, useRouter, useTranslate } from "../../hooks";
import { AuthWrapper } from "./AuthWrapper";

function ErrorMessage() {
  const translate = useTranslate();
  return (
    <div className="text-center space-y-4">
      <TextH3>{translate("verify_email_verification_failed")}</TextH3>
      <ButtonLink link={RoutePathEnum.SignIn}>
        {translate("verify_email_try_again")}
      </ButtonLink>
    </div>
  );
}

export function VerifyEmailPage() {
  const pageTitle = usePageTitle(RoutePathEnum.VerifyEmail);
  const [error, setError] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const auth = getAuth();
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
      }
      // The client SDK will parse the code from the link for you.
      if (!email) {
        // User cancelled the input
        console.log("User cancelled the input", "no email");
        setError(true);
        return;
      }

      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          window.localStorage.removeItem("emailForSignIn");
          //goTo(RoutePathEnum.Home);
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch((error) => {
          console.log("error", error);
          setError(true);
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }

    // return () => {
    //   searchParams.delete("mode");
    //   searchParams.delete("oobCode");
    //   searchParams.delete("apiKey");
    //   searchParams.delete("lang");
    //   console.log(1111111, searchParams.toString());
    //   setSearchParams(searchParams);
    // };
  }, []);

  return (
    <AuthWrapper hideTerms>
      <div className="flex flex-col space-y-2 ">
        {error ? <ErrorMessage /> : <TextH2>{pageTitle}</TextH2>}
      </div>
    </AuthWrapper>
  );
}
