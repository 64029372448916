import { TextLink } from "components/Typography/Text";
import { RoutePathEnum } from "controllers/router";
import { useTranslate } from "../../hooks";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import React, { useEffect, useRef, useState } from "react";
import { TripsLayer } from "deck.gl";
import { LightingEffect } from "deck.gl";
import { AmbientLight } from "deck.gl";
import { PointLight } from "deck.gl";

import { MapboxLayer } from "@deck.gl/mapbox/typed";

interface AuthWrapperProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  hideTerms?: boolean;
}

mapboxgl.accessToken =
  "pk.eyJ1IjoidHVtc2FsdiIsImEiOiJjbGw3c2JvcHEwZjg3M2ttaTdvdXl3ZjN3In0.oVCOGRg9N3O5S5_Jew9xGA";

const ambientLight = new AmbientLight({
  color: [255, 255, 255],
  intensity: 1.0,
});

const pointLight = new PointLight({
  color: [255, 255, 255],
  intensity: 2.0,
  position: [-74.05, 40.7, 8000],
});

const lightingEffect = new LightingEffect({ ambientLight, pointLight });

const material = {
  ambient: 0.1,
  diffuse: 0.6,
  shininess: 32,
  specularColor: [60, 64, 70],
};

const DEFAULT_THEME = {
  buildingColor: [74, 80, 87],
  trailColor0: [253, 128, 93],
  trailColor1: [23, 184, 190],
  material,
  effects: [lightingEffect],
};

const INITIAL_VIEW_STATE = {
  longitude: -73.98992,
  latitude: 40.7011672,
  zoom: 16,
  pitch: 45,
  bearing: -17.6,
};

export function AuthWrapper(props: AuthWrapperProps) {
  const { children, hideTerms } = props;
  const translate = useTranslate();
  const layersRef = useRef<MapboxLayer<any> | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);

  const [time, setTime] = useState(400);
  const [animation] = useState<any>({});

  let now: number;
  let then: number = 0;
  let elapsed: number;
  const fpsInterval: number = 1000 / 60;

  const animate = () => {
    animation.id = requestAnimationFrame(animate);

    // Calculate elapsed time since the last loop
    now = Date.now();
    elapsed = now - then;

    // If enough time has elapsed, draw the next frame
    if (elapsed > fpsInterval) {
      // Get ready for the next frame by setting then=now
      then = now - (elapsed % fpsInterval);

      // Your animation logic goes here
      setTime((t) => (t + 1.5) % 1800);
      // deckRef.current?.redraw({});
    }
  };

  useEffect(() => {
    animation.id = window.requestAnimationFrame(animate);

    return () => window.cancelAnimationFrame(animation.id);
  }, [animation]);

  useEffect(() => {
    //if (!mapRef.current) return;

    const map = new mapboxgl.Map({
      container: document.getElementById("map") as HTMLElement, // container ID
      center: [INITIAL_VIEW_STATE.longitude, INITIAL_VIEW_STATE.latitude], // starting position [lng, lat]
      //center: [24.1192156, 56.9462419], // starting position [lng, lat]
      style: "mapbox://styles/mapbox/light-v11",
      zoom: INITIAL_VIEW_STATE?.zoom,
      pitch: INITIAL_VIEW_STATE.pitch,
      bearing: INITIAL_VIEW_STATE.bearing,
      antialias: true,

      dragPan: false,
      dragRotate: false,
      scrollZoom: false,
      doubleClickZoom: false,
      boxZoom: false,
      keyboard: false,
      touchPitch: false,
      touchZoomRotate: false,
    });

    mapRef.current = map;

    map.on("style.load", () => {
      // Insert the layer beneath any symbol layer.
      const layers = map.getStyle().layers || [];
      const labelLayerId = layers.find(
        (layer) => layer?.type === "symbol" && layer.layout?.["text-field"]
      )?.id;

      for (var i = 0; i < layers.length; i++) {
        var layer = layers[i];

        // Check if the layer is a label layer
        if (layer.type === "symbol" && layer.layout?.["text-field"]) {
          // Set the visibility of the label layer to 'none'
          map.setLayoutProperty(layer.id, "visibility", "none");
        }
      }

      layersRef.current = new MapboxLayer({
        id: "trips",
        type: TripsLayer,
        data: "/data/trips.json",
        getPath: (d: any) => d.path,
        getTimestamps: (d: any) => d.timestamps,
        // @ts-ignore
        getColor: (d: any) =>
          d.vendor === 0
            ? DEFAULT_THEME.trailColor0
            : DEFAULT_THEME.trailColor1,
        opacity: 0.3,
        widthMinPixels: 2,
        rounded: true,
        trailLength: 180,
        currentTime: time,
        smooth: true,

        shadowEnabled: false,
      } as any);

      map.addLayer(layersRef.current);

      map.addLayer(
        {
          id: "add-3d-buildings",
          source: "composite",
          "source-layer": "building",
          filter: ["==", "extrude", "true"],
          type: "fill-extrusion",
          minzoom: 15,
          paint: {
            "fill-extrusion-color": "#cbd5e1",

            // Use an 'interpolate' expression to
            // add a smooth transition effect to
            // the buildings as the user zooms in.
            "fill-extrusion-height": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "height"],
            ],
            "fill-extrusion-base": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "min_height"],
            ],
            "fill-extrusion-opacity": 1,
          },
        },
        labelLayerId
      );
    });

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
      }

      console.log("deck unmount");
    };
  }, []);

  useEffect(() => {
    if (!mapRef.current || !layersRef.current) return;

    try {
      //@ts-ignore
      layersRef.current.setProps({ currentTime: time });
    } catch (e) {
      console.log("Updating when unmounted");
    }
  }, [animate]);

  return (
    <>
      <div className="container relative h-screen flex-col items-center justify-center grid lg:max-w-none lg:grid-cols-2 lg:px-0">
        <div className="relative hidden h-full flex-col bg-muted p-0 text-white dark:border-r lg:flex">
          {/* <div className="absolute inset-0" /> */}
          <div className="relative z-20 mt-auto">
            <div
              className="cursor-default"
              id="map"
              style={{ width: "100%", height: "100vh" }}
            ></div>
          </div>
        </div>

        <div className="lg:p-8">
          <div className="mx-auto flex w-full flex-col justify-center sm:w-[350px]">
            {children}
            {!hideTerms && (
              <p className="px-8 text-center text-sm text-muted-foreground mt-8">
                {translate("signin_by_continuing")}{" "}
                <TextLink
                  className="underline"
                  to={RoutePathEnum.Terms}
                  target="_blank"
                >
                  {translate("signin_terms_of_service")}
                </TextLink>{" "}
                {translate("signin_and")}{" "}
                <TextLink to={RoutePathEnum.Privacy} target="_blank">
                  {translate("signin_privacy_policy")}
                </TextLink>
                .
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
