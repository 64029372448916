import { useMapContext } from "context/MapContext";
import { translate } from "utils";
import { cn } from "utils/classnames";
import { mapStyles } from "../../pages/Distribution/MapStyles";
import { SelectPanel } from "./Select.panel";

export function MapStylePanel() {
  const { mapStyle, setMapStyle } = useMapContext();

  return (
    <div className={cn("border-b p-4")}>
      <div className="space-y-4">
        <SelectPanel
          value={mapStyle}
          options={mapStyles}
          label={translate("Map style")}
          onChange={(mapStyle) => setMapStyle(mapStyle)}
        />
        {/* <BaseSelect
          value={mapStyle}
          title={translate("Map style")}
          options={mapStyles}
          onChange={(mapStyle) => setMapStyle(mapStyle)}
        /> */}
        {/* <BaseCheckbox
          label={translate("Low detail map style")}
          value="lowDetailMapStyle"
          checked={lowDetailMapStyle}
          onChange={(state) => setLowDetailMapStyle(state === true)}
        /> */}
        {/* <BaseCheckbox
          label={translate("Data auto update")}
          value="dataAutoUpdate"
          checked={dataAutoUpdate}
          onChange={(state) => setDataAutoUpdate(state === true)}
        /> */}
      </div>
    </div>
  );
}
