import { geo } from "@vuumly-common/common";

export const defaultSettings: Settings = {
  opacity: 0.5,
  heatmap_min_ride_count: 100,
  geohash_cluster_size: geo.GeohashSize.Seven,
};

export interface Settings {
  opacity: number;
  heatmap_min_ride_count: number;
  geohash_cluster_size: geo.GeohashSize;
}
