import "ol/ol.css";
import { MapContextProvider, useMapContext } from "context/MapContext";
import { DashboardLayout, LayoutDefaults } from "../../components/Layouts";
import { BaseMap } from "components/Map";
import { useEffect } from "react";
import { DashboardHeader } from "../../components/Header";
import { MapSidebar } from "../../components/Sidebar";
import { usePopularityGridQuery } from "../../hooks/layers/usePopularityGridQuery.hook";
import {
  Layers,
  useFormatNumber,
  useGeohashMapLayer,
  usePageTitle,
  useTranslate,
} from "hooks";
import { BaseTooltip } from "../../components/Tooltip";
import { TextBody, TextMuted } from "../../components/Typography/Text";
import { RoutePathEnum } from "../../controllers/router";
import { DashboardContentWrapper } from "../../components/Wrapper";

const Content = () => {
  const { mapInstance } = useMapContext();
  const { formatShort } = useFormatNumber();
  const translate = useTranslate();

  const { settings, setSettings } = Layers.useHexmapPanelSettings();

  const { isLoading, data } = usePopularityGridQuery(settings);

  const { panel, layer, tooltip } = useGeohashMapLayer({
    data: data?.data,
    isLoading,
    setSettings,
    settings,
  });

  useEffect(() => {
    if (layer && mapInstance) {
      mapInstance?.addLayer(layer);

      // for some reason adding deckGL layer steals focus from map
      document.getElementById("map")?.focus();
    }

    return () => {
      if (!layer) return;
      mapInstance?.removeLayer(layer);
    };
  }, [layer]);

  useEffect(() => {
    function setFocus() {
      document.getElementById("map")?.focus();
    }
    mapInstance?.on("pointermove", setFocus);

    return () => {
      mapInstance?.un("pointermove", setFocus);
    };
  });

  function getTooltip() {
    if (!tooltip || tooltip.object === undefined) return null;

    return (
      <BaseTooltip>
        <div className="flex flex-col space-y-2">
          <TextBody className="tabular-nums">
            {translate("ride_count")}: {formatShort(tooltip.object.count)}
          </TextBody>
          <TextMuted>{tooltip.object.geohash}</TextMuted>
        </div>
      </BaseTooltip>
    );
  }

  return (
    <div className="flex">
      <div className="flex-1 relative">
        {!isLoading && (
          <MapSidebar>
            <div>{panel}</div>
          </MapSidebar>
        )}
        <BaseMap
          isLoading={isLoading}
          tooltip={getTooltip()}
          style={{
            //  height: 1000
            height: `calc(100vh - ${
              (document.getElementById("main-menu")?.offsetHeight ||
                LayoutDefaults.headerHeight) +
              (document.getElementById("page-layout")?.offsetHeight || 0)
            }px)`,
          }}
        />
      </div>
    </div>
  );
};

export function GeohashMap() {
  const title = usePageTitle(RoutePathEnum.Hexmap);
  return (
    <div>
      <DashboardContentWrapper>
        <DashboardHeader title={title} />
      </DashboardContentWrapper>
      <MapContextProvider>
        <Content />
      </MapContextProvider>
    </div>
  );
}
