import { api, time } from "@vuumly-common/common";
import { ChartData, ChartOptions } from "chart.js";
import { BaseCard, CardProps } from "components/Card";
import {
  useParseRideLength,
  useQuery,
  useTheme,
  useTimeGranularity,
} from "hooks";
import _ from "lodash";
import { useMemo } from "react";
import { translate } from "../../../utils";
import { BaseLineChart } from "../../Charts";
import { TimeGranularityTabs } from "../../Tabs/TimeGranularityTabs";
import { TextH5 } from "../../Typography/Text";

export const RideLengthTimeseriesCard = (props: CardProps) => {
  const { tabs, timeGranularity, changeGranularity, modifiedQueryParams } =
    useTimeGranularity();
  const parseRideLength = useParseRideLength();
  const { getRideLengthGroupColor } = useTheme();

  const { data, isLoading } = useQuery({
    apiEndpoint: api.Endpoint.LengthGroupTimeseries,
    params: modifiedQueryParams,
  });

  const clonedData = useMemo(() => {
    if (isLoading || !data?.datasets) {
      return;
    }

    const clonedData = _.cloneDeep(data) as ChartData<"line">;

    clonedData?.datasets.forEach((dataset, idx) => {
      const { label } = dataset;

      // if (label === "ride_count") {
      dataset.label = parseRideLength(label || "no_label");

      if (label) {
        dataset.backgroundColor = getRideLengthGroupColor(label);
        dataset.borderColor = dataset.backgroundColor;
      }

      if (idx > 2) {
        dataset.hidden = true;
      }
    });

    return clonedData;
  }, [data]);

  const options: ChartOptions<"line"> = {
    parsing: {
      xAxisKey: "date",
      yAxisKey: "ride_count",
    },

    scales: {
      x: {
        type: "timeseries",
      },
      y: {
        border: {
          display: false,
        },
        title: {
          text: "Ride count",
        },
      },
    },
  };

  const Header = () => {
    return (
      <div className="flex justify-between w-full">
        <TextH5>{translate("ride_length_timeseries_card_title")}</TextH5>
        <TimeGranularityTabs
          tabs={tabs}
          defaultValue={timeGranularity || time.GranularityEnum.Day}
          onChange={changeGranularity}
        />
      </div>
    );
  };

  return (
    <BaseCard isLoading={isLoading} header={<Header />}>
      <BaseLineChart options={options} data={clonedData} />
    </BaseCard>
  );
};
