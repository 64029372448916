import { api, db, time } from "@vuumly-common/common";
import {
  ChartData,
  useParseRideLength,
  useTheme,
  useTranslate,
  useUserContext,
} from "hooks";

interface Props {
  data?: api.response.StandartResponse;
  timeGranularity?: time.GranularityEnum | null;
}

export function useDataParser({ data, timeGranularity }: Props) {
  const { locale } = useUserContext();
  const parseRideLength = useParseRideLength();
  const { getDatasetColor } = useTheme();
  const translate = useTranslate();

  const parsedData: ChartData | undefined = data;

  if (parsedData) {
    if (!parsedData.rawLabels) {
      parsedData.formatted_label = translate(parsedData.label);
      parsedData.rawLabels = [...parsedData.labels];
      parsedData.shortLabels = [...parsedData.labels];

      parsedData.labels.forEach((label, index) => {
        if (parsedData.label_type === db.models.LabelTypeEnum.Date) {
          let longDate: string = label;
          let shortDate: string = label;

          if (timeGranularity === time.GranularityEnum.Day) {
            longDate = new Date(longDate).toLocaleDateString(locale, {
              dateStyle: "full",
            });

            shortDate = new Date(shortDate).toLocaleDateString(locale, {
              dateStyle: "short",
            });
          } else if (timeGranularity === time.GranularityEnum.Week) {
            longDate = new Date(longDate).toLocaleDateString(locale, {
              dateStyle: "long",
            });

            shortDate = new Date(shortDate).toLocaleDateString(locale, {
              month: "short",
              day: "numeric",
              year: "numeric",
            });
          } else {
            longDate = new Date(longDate).toLocaleDateString(locale, {
              month: "long",
              year: "numeric",
            });

            shortDate = new Date(shortDate).toLocaleDateString(locale, {
              month: "long",
              year: "numeric",
            });
          }

          parsedData.labels[index] = longDate;
          parsedData.shortLabels![index] = shortDate;
          // } else if (
          //   parsedData.label_type === db.models.LabelTypeEnum.TimeLength
          // ) {
          //   const timeLength = new Date(label).toISOString().substr(11, 8);
          //   parsedData.labels[index] = timeLength;
        } else {
          parsedData.labels[index] = translate(label);
        }
      });
    }

    parsedData.totalValue = 0;

    parsedData.labels.forEach((label, dimensionIdx) => {
      parsedData.datasets.forEach((dataset) => {
        if (
          dataset.data_type === db.models.DatasetTypeEnum.NumberArray ||
          dataset.data_type === db.models.DatasetTypeEnum.RideCountArray
        ) {
          parsedData.totalValue += dataset.data[dimensionIdx];
        }
      });
    });

    parsedData.datasets.forEach((dataset, index) => {
      if (!dataset.parsed) {
        // Parse dataset label
        dataset.raw_label = dataset.label;

        dataset.formattedData = [];
        dataset.tooltipData = [];
        dataset.percentageData = [];

        if (dataset.label.includes("ride_length_group")) {
          dataset.label = parseRideLength(dataset.id);
        }

        if (
          dataset.data_type === db.models.DatasetTypeEnum.NumberArray ||
          dataset.data_type === db.models.DatasetTypeEnum.RideCountArray
        ) {
          dataset.totalValue = dataset.data.reduce((acc, cur) => acc + cur, 0);

          if (dataset.totalValue) {
            dataset.totalValueFormatted = new Intl.NumberFormat(locale, {
              notation: "compact",
            }).format(dataset.totalValue);
          }

          dataset.averageValue = dataset.totalValue
            ? Math.round(dataset.totalValue / dataset.data.length)
            : 0;

          if (dataset.averageValue) {
            dataset.averageValueFormatted = new Intl.NumberFormat(locale, {
              notation: "compact",
            }).format(dataset.averageValue);
          }

          dataset.totalDatasetValue = 0;

          for (let item of dataset.data) {
            dataset.totalDatasetValue += item;

            const formattedValue = new Intl.NumberFormat(locale, {
              notation: "compact",
            }).format(item);

            dataset.formattedData.push(formattedValue);

            const formattedTooltip = `${translate(
              dataset.label
            )}: ${formattedValue}`;

            dataset.tooltipData.push(formattedTooltip);

            const total = (100 / (dataset.totalValue || 100)) * item;
            dataset.percentageData.push(total.toFixed(1) + "%");
          }

          //  dataset.backgroundColor = getDatasetColor(dataset.raw_label);
          //  dataset.hoverBackgroundColor = dataset.backgroundColor;

          dataset.parsed = true;
        } else if (dataset.data_type === db.models.DatasetTypeEnum.AreaArray) {
          for (let item of dataset.data) {
            if (item.includes("other_area")) {
              dataset.formattedData.push(translate("other_area"));
            } else {
              dataset.formattedData.push(item);
            }
          }
        } else if (
          dataset.data_type === db.models.DatasetTypeEnum.TimeLengthArray
        ) {
          for (let item of dataset.data) {
            dataset.formattedData.push(item + " " + translate("minutes_short"));
          }
        }
      }
    });

    parsedData.datasets.forEach((dataset) => {
      if (dataset.data_type === db.models.DatasetTypeEnum.NumberArray) {
        dataset.totalDatasetValuePercentage =
          (100 / (parsedData.totalValue || 100)) *
          (dataset.totalDatasetValue || 0);

        dataset.hidden = dataset.totalDatasetValuePercentage < 10;
      }
    });

    parsedData.time_granularity = timeGranularity || time.GranularityEnum.None;
  }

  return { parsedData };
}
