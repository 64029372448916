import { geo } from "@vuumly-common/common";

export const defaultSettings: Settings = {
  weight: 0.5,
  heatmap_min_ride_count: 5,
  geohash_cluster_size: geo.GeohashSize.Eight,
};

export interface Settings {
  weight: number;
  heatmap_min_ride_count: number;
  geohash_cluster_size: geo.GeohashSize;
}
